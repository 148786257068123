<template>
	<div class="RatingField" :class="{ error }">
		<span v-for="index in stars" :key="index"
			class="star"
			:class="index <= value ? 'active' : 'inactive'"
			@click="setValue(index)"
			:data-cy="'star-' + index"
		>
			<mdi star />
		</span>
		<span class="clear" v-if="value !== undefined && value !== null" @click="setValue(null)">
			Clear
		</span>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

// TODO: validation: required, unique, range, specific values

export default {
	name: 'RatingField',
	mixins: [ field ],
	props: {
		value: [ Number, String ],
		disabled: { type: Boolean, default: false },
	},
	data: () => ({
		model: null,
	}),
	watch: {
		value(n) {
			this.model = n
			this.onErrors([
				this.validateRequired(),
				this.validateRangeMax(),
				this.validateRangeMin(),
				this.validateIn(),
			])
		},
	},
	computed: {
		stars() {
			return Number(this.control.settings.stars) ?? 5
		},
	},
	methods: {
		setValue(v) {
			if (this.disabled) return
			this.$emit('input', v)
		},
	},
}
</script>

<style scoped>
.star { color: rgb(90, 101, 124); border-radius: 6px; padding: 5px 10px; font-size: 23px; cursor: pointer; display: inline-block; }
.star:hover { background: rgb(247, 249, 250); }
.star:hover { color: rgb(47, 123, 216); }
.star.active { color: rgb(0, 89, 200); }
svg { fill: currentColor; }
.clear { color: rgb(0, 89, 200); padding-left: 20px; cursor: pointer; }
</style>
