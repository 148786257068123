import moment from 'moment'

// this allows us to show an icon on entry links when schedules are present
// i have implemented this as a mixin to keep the EntryEditor component clean

// TODO: we use the data in EntryStatus.vue, but the RTE has its own status rendering.
//       possibly we can hack in some aspect of the sdk to provide schedules to make this work..

export default {
	inject: [ 'baseEndpoint'/*, 'spaceId', 'environment'*/ ],
	data: () => ({
		// TODO: this needs to update locally when we change a schedule so we have good UX updates on changes
		scheduledActions_all: null,
		scheduledActions_byId: {},
		scheduledActions_interval: null,
	}),
	provide() {
		return {
			ScheduledActions: this,
		}
	},
	methods: {
		async scheduledActions_load() {
			const fromDate = moment().toISOString()
			const untilDate = undefined
			const r = await this.$httpGet(this.baseEndpoint + '/spaces/' + this.spaceId + '/scheduled_actions', {
				'environment.sys.id': this.environment,
				//'order': '-scheduledFor.datetime',
				//'sys.status': 'scheduled',
				//'sys.status': this.filterStatus,
				'scheduledFor.datetime[gte]': fromDate,
				'scheduledFor.datetime[lte]': untilDate,
				limit: 1000,
			})
			r.items.forEach(item => { item.localDatetime = moment(item.scheduledFor.datetime).format().slice(0, 16) })
			r.items.sort((a, b) => a.localDatetime.localeCompare(b.localDatetime))
			this.scheduledActions_all = r.items

			const map = {}
			for (const item of r.items) {
				const id = item.entity.sys.id
				if (!map[id]) map[id] = []
				map[id].push(item)
			}
			this.scheduledActions_byId = map
		},
	},
	async mounted() {
		await this.scheduledActions_load()
		// reload every 10 minutes
		this.scheduledActions_interval = setInterval(() => this.scheduledActions_load(), 10 * 60 * 1000)
	},
}