
import Comment from './Comment.vue'
import CommentInput from './CommentInput.vue'
import EntryApiMixin from '../EntryApiMixin.js'
import SpinnerIco from '../SpinnerIco.vue'

// TODO: refresh regularly for updates of other users
//       server push?
// TODO: "comment for field" functionality
//       click tag -> scroll field into view
// TODO: load -> scroll to end
// TODO: // function for date
// TODO: # function for field
// TODO: AI chatbot for comments 'AI, translate to french'
// TODO: delete only on my own comments?

export default {
	name: 'Comments',
	components: { Comment, CommentInput, SpinnerIco },
	mixins: [ EntryApiMixin ],
	inject: [ 'permissions' ],
	props: {
		// TODO: should we pass in the whole entry?
		//       what we need are the fields for reference
		//       actually we would rather want the type of the entry
		entryId: String,
		entryModel: Object,
	},
	data: () => ({
		comments: [],
		newComment: {
			body: '',
			ref: null, // like fields.title.de
		},
	}),
	methods: {
		async load() {
			const r = await this.$httpGet(this.endpoint + '/entries/' + this.entryId + '/comments')
			this.comments = r.items
		}
	},
	async mounted() {
		this.load()
	},
}
