
import Field from './fields/Field.vue'
import EntryStatus from './fields/EntryStatus.vue'
import { formatDate } from '../utils'
import Spinner from './Spinner.vue'
import EntryApiMixin from './EntryApiMixin.js'
import Menu from '../components/Menu.vue'
import Head from '../views/Head.vue'
import TranslationSidebar from '../components/TranslationSidebar.vue'
import PublishButton from './PublishButton.vue'
import EntryAutoSaveMixin from './EntryAutoSaveMixin'
import ActionButton from './ActionButton.vue'
import Info from '../views/Info.vue'

// TODO: refactor: this has much in common with EntryEditor!

export default {
	name: 'AssetEditor',
	components: { Field, EntryStatus, Spinner, Menu, Head, TranslationSidebar, PublishButton, ActionButton, Info },
	inject: [ 'base', 'endpoint', 'defaultLocale', 'permissions', 'locales' ],
	mixins: [ EntryApiMixin, EntryAutoSaveMixin ],
	props: {
		entryId: String,
		value: [ Number, String ],
	},
	data: () => ({
		model: null,
		typeLookup: {},
		// TODO: also load this..
		editorInterface: {
			controls: [
				{ fieldId: 'title', widgetId: 'singleLine', localized: true },
				{ fieldId: 'description', widgetId: 'multipleLine', localized: true },
				{ fieldId: 'file' }, // TODO: widgetId
			],
		},
		loading: 0,
		initialLoading: true,
		contentType: {
			fields: [
				{ id: 'title', name: 'Title', type: 'Symbol', validations: [ { size: { max: 255 } } ], required: false },
				{ id: 'description', name: 'Description', type: 'Text', validations: [ { size: { max: 50000 } } ], required: false },
				{ id: 'file', name: 'File', type: 'File', required: true },
			],
		},
		sidebarTab: 'general',
		selectedLocaleCodes: [],
		error: null,
		tab: 'editor',
	}),
	computed: {
		typeFieldsById() {
			const r = {}
			for (const field of this.contentType.fields) {
				r[field.id] = field
			}
			return r
		},
		title() {
			if (!this.model) return 'Untitled'
			const f = this.contentType.displayField ?? 'title'
			const t = this.model.fields[f]?.[this.defaultLocale]
			return t ? t : 'Untitled'
		},
		localeLookup() {
			const r = {}
			for (const locale of this.locales)
				r[locale.code] = locale
			return r
		},
	},
	methods: {
		async loadAsset() {
			try {
				this.loading++
				const request: any = {}
				request['sys.id'] = this.entryId
				const assets = await this.$httpGet(this.endpoint + '/assets', request)
				const model = assets.items[0]
				if (!model) throw new Error('Asset not found')

				// create empty fields where missing based on the type info
				if (!model.fields) model.fields = {}
				for (const field of this.contentType.fields) {
					if (model.fields[field.id]) continue
					const value = { [ this.defaultLocale ]: undefined }
					model.fields[field.id] = value
				}

				this.model = model
			}
			catch (e) {
				this.error = e.message
			}
			finally {
				this.loading--
			}
		},
		autoSave() {
			// set the title if only the file was uploaded
			const fileName = this.model?.fields?.file?.[this.defaultLocale]?.fileName
			if (!this.model.fields.title?.[this.defaultLocale] && fileName) {
				if (!this.model.fields.title) this.model.fields.title
				this.model.fields.title[this.defaultLocale] = fileName
			}
			this.entryAutoSave()
/*			if (!this.autoSaveEnabled) return
			if (this.autoSaveTimer) {
				window.clearTimeout(this.autoSaveTimer)
			}
			this.autoSaveTimer = window.setTimeout(async () => {
				await this.saveAsset()
				this.autoSaveTimer = null
			}, 1000)*/
		},
		// wrapper for EntryAutoSaveMixin
		async saveEntry() {
			await this.saveAsset()
		},
		async saveAsset() {
			try {
				this.loading++
				const r = await this.$httpPut(this.endpoint + '/assets/' + this.model.sys.id, this.model, {
					headers: {
						'X-Contentful-Version': this.model.sys.version,
					},
				})
				if (r?.sys) {
					this.model.sys.version = r.sys.version
					this.model.sys.updatedAt = r.sys.updatedAt
					this.model.sys.updatedBy = r.sys.updatedBy
				}
				// we copy the file field back, as the server processes an uploadFrom and changes the content
				/* TODO: at one point i thought this logic is a good idea - do we need it?
				let hasChanges = false
				for (const locale of this.locales) {
					const file = r?.fields?.file?.[locale.code]
					if (file && file?.url != this.model.fields.file?.[locale.code]?.url)
						hasChanges = true
				}
				if (hasChanges)
				*/
				this.model.fields.file = r.fields.file ?? {}
			}
			finally {
				this.loading--
			}
		},
		async validateAndPublish() {
			// TODO
			this.publish()
		},
		async publish() {
			this.loading++
			const r = await this.$httpPut(this.endpoint + '/assets/' + this.model.sys.id + '/published', null, {
				headers: {
					'X-Contentful-Version': this.model.sys.version,
				},
			})
			if (r?.sys) {
				this.model.sys.version = r.sys.version
				this.model.sys.updatedAt = r.sys.updatedAt
				this.model.sys.updatedBy = r.sys.updatedBy
				this.model.sys.publishedAt = r.sys.publishedAt
				this.model.sys.publishedBy = r.sys.publishedBy
			}
			this.loading--
		},
		async deleteAsset() {
			if (!confirm('Do you really want to delete this asset?')) return
			this.loading++
			await this.$httpDelete(this.endpoint + '/assets/' + this.model.sys.id)
			this.loading--
			this.$emit('close', this.model)
		},
		formatDate,
		async loadContentType() {
			this.loading++
			const r = await this.$httpGet(this.endpoint + '/content_types/contentHubAsset')
			// force file field type
			r.fields.forEach(field => { if (field.id == 'file') field.type = 'File' })
			this.contentType = r
			this.loading--
		},
	},
	async mounted() {
		await this.loadContentType()
		await this.loadAsset()
		if (this.error) return

		this.initialLoading = false
		this.loadReferences()
		// HACK: i cannot easily figure out why autosave is triggered
		//       some ui component must update the model after we load it
		// for now, we only enable autosave after the components settled
		this.$nextTick(() => {
			this.initAutoSave()
			this.autoSaveEnabled = true
			// we always force assets to be on the default locale - this may not be nice,
			// but currently its necessary, because we dont want to let the user to upload to a non-default locale first..
			this.selectedLocaleCodes = [ this.defaultLocale ]
		})
	},
}
