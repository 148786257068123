export default class DemoGraph {

	locale: string
	httpClient: any
	endpoint: string
	$httpGet: Function
	$httpPut: Function
	$httpPost: Function

	constructor(httpClient, locale: string = 'en') {
		this.httpClient = httpClient
		this.endpoint = httpClient.endpoint
		this.$httpGet = httpClient.$httpGet
		this.$httpPut = httpClient.$httpPut
		this.$httpPost = httpClient.$httpPost
		this.locale = locale
	}

	async loadGraphNodeType() {
		return await this.$httpGet(this.endpoint + '/content_types/x_graph_node')
	}

	// TODO: update/reload type registry!
	async createGraphNodeType() {
		await this.$httpPut(this.endpoint + '/content_types/x_graph_node', {
			name: 'X Graph Node',
			displayField: 'title',
			fields: [
				{ id: 'title', name: 'Title', type: 'Symbol', required: true },
				{ id: 'type', name: 'Type', type: 'Symbol', required: true },
				{ id: 'color', name: 'Color', type: 'Symbol' },
				{ id: 'properties', name: 'Properties', type: 'Object' },
				// TODO: this does not allow us to name the refs.. should we invent some way to name them in a different field?
				{ id: 'references', name: 'References', type: 'Array', items: { type: 'Link', linkType: 'Entry' } },
			],
		})
		// TODO: create an editor_interface with propertiesEditor for properties
	}

	async createGraphNodeTypeIfNotExists() {
		try {
			await this.loadGraphNodeType()
			return false
		}
		catch (e) {
			await this.createGraphNodeType()
			return true
		}
	}

	async createNode(id, type, title, age, refs) {
		return await this.$httpPut(this.endpoint + '/entries/' + id, {
			fields: {
				title: { [ this.locale ]: title },
				type: { [ this.locale ]: type },
				properties: { [ this.locale ]: { age } },
				references: { [ this.locale ]: refs.map(r => ({ sys: { type: 'Link', linkType: 'Entry', id: r } })) },
			},
			sys: { contentType: { sys: { id: 'x_graph_node' } } },
		})
	}

	async createGraph(id, title, nodes, queries) {
		return await this.$httpPut(this.endpoint + '/entries/' + id, {
			fields: {
				title: { [ this.locale ]: title },
				nodes: { [ this.locale ]: nodes },
				links: { [ this.locale ]: [] },
				view: { [ this.locale ]: { pos: { x: 0, y: 0 }, zoom: 1 } },
				queries: { [ this.locale ]: queries },
			},
			sys: { contentType: { sys: { id: 'x_graph' } } },
		})
	}

	async createDemoGraph() {
		await this.createNode('X-GRAPH-DEMO-AA', 'Person', 'Alois Anker', 42, [ 'X-GRAPH-DEMO-BB' ])
		await this.createNode('X-GRAPH-DEMO-BB', 'Person', 'Bessy Baker', 38, [ 'X-GRAPH-DEMO-CC', 'X-GRAPH-DEMO-EE' ])
		await this.createNode('X-GRAPH-DEMO-CC', 'Person', 'Chuck Crass', 25, [ 'X-GRAPH-DEMO-DD' ])
		await this.createNode('X-GRAPH-DEMO-DD', 'Person', 'Dario Dafoe', 23, [ 'X-GRAPH-DEMO-EE' ])
		await this.createNode('X-GRAPH-DEMO-EE', 'Person', 'Elena Evert', 21, [ 'X-GRAPH-DEMO-CC' ])
		return await this.createGraph('X-GRAPH-DEMO', 'Demo Graph', [
			{ entryId: 'X-GRAPH-DEMO-AA', x: 160, y: 250 },
			{ entryId: 'X-GRAPH-DEMO-BB', x: 250, y: 100 },
			{ entryId: 'X-GRAPH-DEMO-CC', x: 330, y: 250 },
			{ entryId: 'X-GRAPH-DEMO-DD', x: 500, y: 250 },
			{ entryId: 'X-GRAPH-DEMO-EE', x: 400, y: 100 },
		], [
			{ id: 14264.0782671646, title: 'Persons', color: '#3C6', filter: { contentType: 'x_graph_node', search: '', filters: [
				{ id: 0.5239917533894924, field: 'type', scope: 'fields', type: 'Symbol', mode: 'eq', value: 'Person', query: { k: 'fields.type', v: 'Person' }, focus: false, storage: [ 'type', 'eq', 'Person' ] },
			], columns: null, order: null
		}}])
	}
}