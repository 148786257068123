
import Menu from './Menu.vue'

export default {
	name: 'TemplateBasedList',
	components: { Menu },
	props: {
		templates: [ Object, Array ], // name => template
		name: String,
		value: Array,
		separator: String,
		max: Number,
		handlerContext: [ Object, Array, String, Number, Boolean ],
	},
	computed: {
		options() {
			if (Array.isArray(this.templates))
				return this.templates.map(t => ({ name: t, template: t }))
			return Object.entries(this.templates).map(([name, template]) => ({ name, template }))
		},
	},
	methods	: {
		add(template, name) {
			if (name == '...') {
				if (template?.handler)
					template = template.handler(this.name, this.value, this.handlerContext)
				else
					template = prompt('Enter a ' + this.name + ':')
				if (!template) return
			}
			this.value.push(JSON.parse(JSON.stringify(template)))
		},
		del(item, i) {
			if (!confirm('Really delete this ' + this.name + '?')) return
			this.value.splice(i, 1)
		},
	},
}
