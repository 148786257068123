<template>
	<div class="webhooks" style="height: 100%;">
		<Head>
			<div class="heading" style="padding-left: 25px;">
				<h1>
					Webhooks
				</h1>
			</div>
		</Head>
		<div class="body" style="display: flex; height: 100%;">
			<div class="BaseTable" style="margin: 30px; flex: 1 1 0;">
				<table>
					<tr>
						<th>Webhook</th>
						<th>Status</th>
						<th>Url</th>
					</tr>
					<tr v-for="webhook of webhooks" :key="webhook.key" @click="$router.push(base + '/settings/webhooks/' + webhook.sys.id)">
						<td style="font-weight: bold;">{{ webhook.name }}</td>
						<!-- TODO: status display -->
						<td class="status">
							<EntryStatus :name="webhook.active ? 'active' : 'disabled'" style="display: inline-block;" />
						</td>
						<td class="url">{{ webhook.transformation?.method }} {{ webhook.url }}</td>
					</tr>
				</table>
			</div>
			<Sidebar>
				<div class="section">
					<button class="add" @click="addWebhook">Add Webhook</button>
				</div>
			</Sidebar>
		</div>
	</div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import Head from './Head.vue'
import EntryStatus from '../components/fields/EntryStatus.vue'

export default {
	name: 'Webhooks',
	components: { Sidebar, Head, EntryStatus },
	inject: [ 'base', 'baseEndpoint' ],
	data: () => ({
		webhooks: null,
	}),
	methods: {
		async load() {
			const webhooks = await this.$httpGet(this.baseEndpoint + '/spaces/' + this.$route.params.spaceId + '/webhook_definitions')
			this.webhooks = webhooks.items.sort((a, b) => a.name.localeCompare(b.name))
		},
		async addWebhook() {
			this.$router.push(this.base + '/settings/webhooks/new')
		},
	},
	async mounted() {
		document.title = 'Webhooks'
		await this.load()
	},
}
</script>

<style scoped>
table { width: 100%; }
table td, table th { padding-left: 10px !important; vertical-align: top; }
table th { height: auto; padding: 15px; }
td.status { width: 10%; }
td.url { font-family: monospace; width: 70%; word-break: break-all; }
button.add { border: 1px solid var(--primary); border-radius: 6px; cursor: pointer; width: 100%; font-weight: 500; outline: none; transition: background 0.1s ease-in-out 0s, opacity 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s; color: white; background-color: var(--primary); font-size: 0.875rem; padding: 10px 15px; }
</style>
