<template>
	<div class="EntryFilter" @click="onFilterClick">
		<div class="search" :class="{ focus }">
			<div class="openDetail" @click="detailOpen = true; $refs.input.focus()" data-cy="filter-openDetail">
				<mdi filter-variant-plus style="font-size: 16px;" />
				Filter
			</div>
			<div class="item FilterItem" v-if="showContentTypeSelector" data-cy="filteritem-contentType">
				<label>Content Type</label>
				<select
					v-model="value.contentType"
					@click="$event.captured = true"
					@change="$emit('input', value)"
					:style="{ width: ((value.contentType ? value.contentType.length : 4) + 7) + 'ch' }"
				>
					<option :value="null">{{ allowedContentTypes ? ('Any (' + allowedContentTypes.length + ')') : 'Any' }}</option>
					<option v-for="allowedType of allowedContentTypesWithFallback" :key="allowedType" :value="allowedType">
						{{ typeNameForTypeId(allowedType) }}
					</option>
				</select>
			</div>
			<FilterItem class="item FilterItem" v-for="filter of value.filters" :key="filter.id"
				:filter="filter"
				@click="filterSelected = filter;"
				:class="{ selected: filterSelected == filter }"
			/>
			<input type="text" data-cy="filter-input"
				:placeholder="placeholder"
				autocomplete="off"
				v-model="value.search"
				@keyup="onInputKeyup"
				@change="$emit('input', value)"
				@click="filterSelected = null"
				style="flex: 1 1 auto; min-width: 200px;"
				ref="input"
			/>
		</div>
		<div class="filterDetail" v-if="value.filters && detailOpen && filteredFields?.length" ref="filterDetail" data-cy="filter-detail">
			<table>
				<tr><th><label>Field</label></th><th>Content Type</th><th>Description</th></tr>
				<tr v-for="(field, f) of filteredFields" :key="field.contentType + '.' + field.id"
					:class="{
						[ 'type_' + field.contentType ]: true,
						selected: f == selectionIndex,
					}"
					@click="addFilter(field)"
					:data-cy="'filter-field-' + field.contentType + '.' + field.id"
					:ref="f == selectionIndex ? 'selectedField' : undefined"
				>
					<td><label>{{ field.id }}</label></td>
					<!-- TODO: proper name, map "_all" to "All Content Types" -->
					<td>{{ typeNameForTypeId(field.contentType) }}</td>
					<td>{{ field.description }}</td>
				</tr>
			</table>
			<div class="help">
				<div>
					Note that only certain field types are supported for filtering.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FilterItem from './FilterItem.vue'
import { getFields, newFilterForFieldOrName } from './FilterUtil'

export default {
	name: 'EntryFilter',
	components: { FilterItem },
	props: {
		locale: String,
		value: Object, // { contentType: '', search: '' }
		def: Object,
		allowedContentTypes: Array,
		placeholder: { type: String, default: 'Type to search' },
	},
	data: () => ({
		focus: false,
		detailOpen: false,
		filterSelected: null,
		selectionIndex: 0,
	}),
	computed: {
		showContentTypeSelector() {
			return this.value.contentType !== undefined
		},
		fields() {
			return getFields()
		},
		filteredFields() {
			return this.fields.filter(field => {
				// TODO: also search in name + description? CF does not
				if (this.value.search && field.id.toLowerCase().indexOf(this.value.search.toLowerCase()) < 0) return false
				if (this.value.contentType && field.contentType != '_all' && this.value.contentType != field.contentType) return false

				// TODO: actually implement these at the server and then activate
				if (field.type == 'Number') return false
				if (field.type == 'Date' && field.contentType != '_all') return false
				if (field.type == 'Link' && field.contentType != '_all') return false
				if (field.type == 'SymbolList' && ![ 'channels' ].includes(field.id) && field.contentType != '_all') return false
				if (field.type == 'Array' && ![ 'storyCategories' ].includes(field.id) && field.contentType != '_all') return false
				if (field.type == 'Boolean' && field.contentType != '_all') return false
				if (field.type == 'Object' && field.contentType != '_all') return false
				if (field.type == 'Asset' && field.contentType != '_all') return false

				return true
			})
		},
		allowedContentTypesWithFallback() {
			if (!this.allowedContentTypes?.length) {
				return Object.values(window['typeLookup']).map(t => t.sys.id)
					// TODO: filter by some kind of "hidden" prop instead
					.filter(t => ![ 'contentHubAsset', 'x_graph', 'x_graph_node' ].includes(t))
					.sort((a, b) => a.localeCompare(b))
			}
			return this.allowedContentTypes
				// TODO: filter by some kind of "hidden" prop instead
				.filter(t => ![ 'contentHubAsset', 'x_graph', 'x_graph_node' ].includes(t))
				.filter(e => e).sort((a, b) => a.localeCompare(b))
		},
	},
	watch: {
		filterSelected(n) {
			if (!n) return
			// whenever we select an item, we remove focus from the input
			this.$refs.input.blur()
		},
		value: {
			deep: true,
			handler() {
				this.resetSelection()
			},
		},
		openDetail() {
			this.resetSelection()
		},
	},
	methods: {
		addFilter(field) {
			this.value.filters.push(newFilterForFieldOrName(field))
			if (!this.value.contentType && field.contentType != '_all') {
				this.value.contentType = field.contentType
			}
			this.value.search = ''
			// TODO: set input focus on new field - how?
			//       the fields are actually part of the sub components
		},
		onFilterClick(event) {
			event.owner = this
			if (!this.focus) {
				this.focus = true
				if (!this.filterSelected && !event.captured) {
					this.$refs.input.focus()
				}
			}
		},
		resetSelection() {
			this.selectionIndex = -1
			if (this.$refs.filterDetail)
				this.$refs.filterDetail.scrollTo(0, 0)
		},
		onInputKeyup(event) {
			event.captured = true

			if (event.key == 'Escape') this.detailOpen = false
			else if (event.key == 'ArrowDown' && this.selectionIndex < this.filteredFields.length-1) {
				this.selectionIndex++
				if (this.$refs.selectedField?.[0])
					this.$refs.selectedField[0].scrollIntoView(false)
				if (this.selectionIndex > 3)
					this.$refs.filterDetail.scrollBy(0, 100)
				window.scrollTo(0, 0)
			}
			else if (event.key == 'ArrowUp' && this.selectionIndex > 0) {
				this.selectionIndex--
				if (this.$refs.selectedField?.[0])
					this.$refs.selectedField[0].scrollIntoView(false)
				if (this.selectionIndex > 3)
					this.$refs.filterDetail.scrollBy(0, 50)
				window.scrollTo(0, 0)
			}
			else if (event.key == 'Enter' && this.selectionIndex > -1) {
				this.addFilter(this.filteredFields[this.selectionIndex])
			}
			else {
				this.detailOpen = this.value.search != ''
			}

			// when we did not delete anything from the field, move the cursor to the last item
			// TODO: ideally: if the cursor is at 0 and not if == ''
			if (event.key == 'Backspace' && this.value.search == '' && this.value.filters && this.value.filters.length > 0) {
				this.filterSelected = this.value.filters[this.value.filters.length - 1]
			}
		},
		onWindowClick(event) {
			if (event.owner == this) return
			this.focus = false
			this.detailOpen = false
			this.filterSelected = null
		},
		// backspace to delete
		onWindowKeyup(event) {
			if (!this.focus) return
			if (!this.filterSelected) return
			if (event.captured) return
			if (event.key != 'Backspace') return
			if (event.target.nodeName == 'INPUT') return
			const i = this.value.filters.indexOf(this.filterSelected)
			this.value.filters.splice(i, 1)
			if (i > 0)
				this.filterSelected = this.value.filters[i-1]
			else if (this.value.filters.length > 0)
				this.filterSelected = this.value.filters[0]
			else
				this.$refs.input.focus()
		},
		typeNameForTypeId(id) {
			if (id == '_all') return 'All Content Types'
			return window['typeLookup'][id]?.name
		},
	},
	mounted() {
		window.addEventListener('click', this.onWindowClick)
		window.addEventListener('keyup', this.onWindowKeyup)
	},
	destroyed() {
		window.removeEventListener('click', this.onWindowClick)
		window.removeEventListener('keyup', this.onWindowKeyup)
	},
}
</script>

<style scoped>
.search { height: 42px; min-height: 42px; overflow: hidden; width: 100%; background-color: rgb(255, 255, 255); border: 1px solid rgb(207, 217, 224); border-radius: 6px; padding: 3px 50px 0 3px; display: flex; flex-wrap: wrap; width: 100%; box-sizing: border-box; position: relative; }
.search.focus { height: auto; border-color: var(--primary); outline: 3px solid rgb(152 203 255); }
.search select { -webkit-appearance: none; border: 0; border-radius: 5px; border-radius: 0 6px 6px 0; font-size: 14px; padding: 7px; margin-left: 5px; }
.search input { outline: none; margin-left: 5px; border: none; box-shadow: rgb(225 228 232 / 20%) 0px 2px 0px inset; color: rgb(65, 77, 99); font-family: var(--font-stack-primary); font-size: 0.875rem; line-height: 1.25rem; padding: 5px 0; }
.openDetail { font-size: 12px; position: absolute; right: 15px; top: 8px; z-index: 1; color: var(--primary); cursor: pointer; }
.openDetail svg { width: 18px; height: 18px; margin-right: 5px; fill: currentcolor; vertical-align: bottom; }

.item { position: relative; margin-right: 6px; margin-bottom: 3px; font-size: 14px; padding-left: 10px; font-weight: normal; border-radius: 6px; }
.item > select { height: 100%; }
.item.selected { outline: 1px solid var(--primary); }

.filterDetail { position: absolute; background: white; border: 1px solid var(--primary); font-size: 14px; border-radius: 6px; overflow: hidden; margin-top: 8px; width: 100%; max-height: 320px; overflow-y: auto; }
.filterDetail table { border-spacing: 0; width: 100%; }
.filterDetail th,
.filterDetail td { padding: 15px; color: rgb(65, 77, 99); }
.filterDetail tr.selected td { background: rgb(3, 111, 227, 0.05); }
.filterDetail tr.type__all td { font-weight: 600; }
.filterDetail td { border-top: 1px solid rgb(231, 235, 238); cursor: pointer; }
.filterDetail th { font-weight: 600; font-size: 0.75rem; text-align: left; background-color: rgb(247, 249, 250); }
.filterDetail td label { font-weight: normal; background: rgb(231, 235, 238); padding: 10px 8px; border-radius: 4px; }
.filterDetail tr:hover td { background: rgb(247, 249, 250); }
.filterDetail .help { display: flex; -webkit-box-align: center; align-items: center; background: rgb(232, 245, 255); border-top: 1px solid rgb(231, 235, 238); padding: 0.75rem 1rem; }
</style>