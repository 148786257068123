
import SystemTags from '../components/fields/SystemTags.vue'
import ContentTypesComboMulti from '../components/fields/ContentTypesComboMulti.vue'
import FieldWrapper from '../components/fields/FieldWrapper.vue'
import ActionButton from '../components/ActionButton.vue'
import { parsePolicies, stringifyPolicies, relations, buildRightsTree } from '../permission-utils'
import Menu from '../components/Menu.vue'
import ProviderMixin from './ProviderMixin'

export default {
	name: 'RoleEditDatalog',
	mixins: [ ProviderMixin ],
	components: { SystemTags, ContentTypesComboMulti, FieldWrapper, ActionButton, Menu },
	props: {
		role: Object,
	},
	data: () => ({
		id: null,
		// TODO: map this array to a string
		//       replacing the block marked as %GENERATED_UI - or appending if there is no such block
		policies: [],
		relations,
	}),
	computed: {
		filteredRelations() {
			return this.relations.filter(r => r.relation != 'user:isAdmin')
		},
		policyString() {
			return stringifyPolicies(this.policies, this.role.policies)
		},
		buildRightsTree() {
			return buildRightsTree(this.policies)
		},
	},
	methods: {
		addPermission(relation) {
			this.policies.push(JSON.parse(JSON.stringify(relation)))
		},
		afterLoad() {
			this.policies = parsePolicies(this.role.policies)
		},
		beforeSave() {
			this.role.policies = stringifyPolicies(this.policies, this.role.policies)
		},
	},
	mounted() {
		this.afterLoad()
	},
}
