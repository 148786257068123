<template>
	<div class="settings">
		<pre>
			TODO: implement M1
		</pre>
	</div>
</template>

<script>
export default {
	name: 'Settings',
}
</script>
