// https://chillimetrix.alturos.com/confluence/display/CH/Authorization
// https://chillimetrix.alturos.com/confluence/pages/viewpage.action?pageId=249262406

// TODO: implement new loadMePolicy:
//       - /users/me -> r.role.sys.id
//       - /roles/:id -> policy
//       only load /users/me/ui_policy_settings if old policy (or do we need it at all?)
// TODO: extract current implementations into a "datalog (permission?) adapter" structure
//       make sure, adapter can automatically switch based on returned data
//       decision needs to happen before the load of /users/me/policy
// TODO: implement iam adapter

import { parsePolicies, buildRightsTree, Action, Feature, Rights, userIsOrganisationAdmin, buildRightsTreeIam, parseIam, NewRights, statementConditionsMatch, ConditionContext } from './permission-utils'

// should we extract these adapters and also use them in the RoleEdit?

const datalogAdapter = {
	mePolicy: null,
	policies: null,

	buildRightsTree() {
		return buildRightsTree(this.policies)
	},
	userIsAdmin() {
		if (!this.mePolicy) return false
		return this.mePolicy.isAdmin
	},
	userCanUseFeature(feature: Feature) {
		if (!this.mePolicy) return false
		return this.mePolicy.features.includes(feature)
	},
	userIsAllowedActionOnType(context: ConditionContext, rights: Rights) {
		if (!rights) return false
		// at CT level we can ignore tags
		if (rights.deny === true) return false
		if (rights.allow === true) return true
		// on CT we give the user permission even if he is restricted to tags
		if ((rights.allow as [])?.length) return true
		return false
	},
	isAllowedForTags(context: ConditionContext, rights: Rights) {
		if (rights.deny === true) return false
		if (rights.deny !== false && rights.deny?.some?.(tag => context.tags.includes(tag))) return false
		if (rights.allow === true) return true
		if (rights.allow !== false && rights.allow?.some?.(tag => context.tags.includes(tag))) return true
		return false
	},
}

const iamAdapter = {
	policy: null,

	// TODO: how to properly integrate this?
	buildRightsTree() {
		return buildRightsTreeIam(this.policy.statement)
	},
	// TODO: add delegate methods here (extract from methods below)
	userIsAdmin() {
		// TODO: do we need to support conditions here?
		return this.policy.statement.some(s => s.action == '*' && s.resource == '*' && !s.condition?.length)
	},
	userCanUseFeature(feature: Feature) {
		// TODO: do we need to support conditions here?
		// TODO: how should this work here? do we actually have a 1:1 concept for feature?
		// TODO: "categories"
		//       d.query("user:canUseFeature(\$f);")
		//        .map { (it as DResult.DAssignment).assignment["\$f"] }
		//        .map { (it as DValue.DString).value }
		//        .filter { listOf("base","tag","space").contains(it) }
		//        .map {statements.add(Statement( effect = Effect.ALLOW, action = listOf("ch:$it*"), resource = listOf("*"),))}
		// "allow" on any rule that has ch:xxx
//			{
//				"effect": "allow",
//				"action": ["ch:base:upsertEntry"],
//				"resource": ["*"],
//			},
		return this.policy.statement.some(s => s.action.some(a => a.startsWith('ch:' + feature)))
	},
	/*
	const context: ConditionContext = {
		// TODO: fill resource
		resource: '',
		contentType,
	}
	*/
	checkRights(context: ConditionContext, rights: NewRights) {
		if (!rights) return false
		for (const ctStatement of rights.deny ?? [])
			if (statementConditionsMatch(ctStatement, context)) return false
		for (const ctStatement of rights.allow ?? [])
			if (statementConditionsMatch(ctStatement, context)) return true
		return false
	},
	userIsAllowedActionOnType(context: ConditionContext, rights: NewRights) {
		return this.checkRights(context, rights)
	},
	isAllowedForTags(context: ConditionContext, rights: NewRights) {
		return this.checkRights(context, rights)
	},
}

export default {
	inject: [ 'baseEndpoint', 'me' ],
	data: () => ({
		mePolicy: null,
		policies: null,
		// TODO: i think initializing with datalogAdapter is not good..
		adapter: datalogAdapter,
	}),
	provide() {
		return {
			// we provide ourselves to the children
			permissions: this,
		}
	},
	computed: {
		// TODO: refactor to use computed more to avoid code repetition and multi-calls..
		userContentTypesForCreate() {
			return this.userGetContentTypesForCreate
		},
		rightsTree() {
			return this.adapter.buildRightsTree()
		},
	},
	methods: {
		async loadMePolicy() {
			if (!this.$route.params.spaceId) return
			const spaceEndpoint = this.baseEndpoint + '/spaces/' + this.$route.params.spaceId

			// TODO: this file is being used in Root, where me is being loaded, but that needs time, so at this point here its not set
			//       can we improve this? currently we would load me twice at bootstrap
			//       could Root use the me we load here? should we defer loadMePolicy?
			//console.log('me', this.me)
			const me = await this.$httpGet(spaceEndpoint + '/users/me')
			if (me.role) {
				// TODO: should we store in this.role?
				const role = await this.$httpGet(spaceEndpoint + '/roles/' + me.role.sys.id)
				if (role.policy) {
					this.adapter = iamAdapter
					this.adapter.policy = role.policy
					if (localStorage.mockMePolicy == 'true') {
						console.warn('mocking mePolicy (localStorage.mockMePolicy)')
						// TODO: after load we also need to parse!
						this.adapter.policy = parseIam({
							"version": "2024-08-11",
							"statement": [
								{ "effect": "allow", "action": [ "ch:base:*" ], "resource": [ "*" ] },
								{ "effect": "allow", "action": [ "ch:entry:upsert", "ch:entry:delete", "ch:entry:publish", "ch:entry:read" ], "resource": [ "*" ], "condition": { "stringEquals": { "ch:entry/contentType": [ "contentHubAsset", "rteTest", "test" ] } } },
								{ "effect": "deny", "action": [ "ch:entry:delete" ], "resource": [ "*" ], "condition": { "stringEquals": { "ch:entry/contentType": [ "contentHubAsset", "test", "rteTest" ] } } },
								{ "effect": "allow", "action": [ "ch:tagEntry" ], "resource": [ "test" ] },
								{ "effect": "deny", "action": [ "ch:entry:upsert" ], "resource": [ "*" ], "condition": { "stringEquals": { "ch:entry/contentType": [ "tour" ] } } },
							],
						})
						console.log('this.adapter.policy', this.adapter.policy)
					}
					// this is not really needed for IAM, but there are some checks on it being set
					this.mePolicy = {}
					return
				}
			}

			this.adapter = datalogAdapter
			const mePolicy = await this.$httpGet(spaceEndpoint + '/users/me/ui_policy_settings')
			if (localStorage.mockMePolicy == 'true') {
				console.warn('mocking mePolicy (localStorage.mockMePolicy)')
				mePolicy.isAdmin = false
				mePolicy.features = ['entry']
				mePolicy.policies = `
					// GENERATED_UI
					user:canUseFeature("entry");
					user:isAllowed("read", [], []);
					user:isAllowed("upsert", [], ["ZillertalArena","webx"]);
					user:isAllowed("publish", [], ["ZillertalArena","webx"]);
					user:isAllowed("delete", [], ["ZillertalArena","webx"]);
					user:isDenied("upsert", ["region","productCategory"]);
					user:isDenied("delete", ["region","productCategory"]);
					user:tagWith("zillertalActivcard");
					user:tagWith("myzillertalappweb");
					user:tagWith("webx");
				`.replace(/^\s/g, '')
				/*
					user:isDenied("upsert", ["test"]);
					user:isDenied("delete", ["test"]);
				*/
			}

			// we pass false for extract, because we have to understand the whole policy
			this.policies = parsePolicies(mePolicy.policies, false)

			this.mePolicy = mePolicy
			this.adapter.mePolicy = mePolicy
			this.adapter.policies = this.policies
		},
		userIsAdmin() {
			return this.adapter.userIsAdmin()
		},
		userIsOrganisationAdmin() {
			if (!this.userIsAdmin()) return false
			if (!this.me) return false
			return userIsOrganisationAdmin(this.me.email)
		},
		userCanUseFeature(feature: Feature) {
			// we removed these features (included for all), but keep the permission checks
			if ((feature as any) == 'task') return true
			if ((feature as any) == 'comment') return true
			if ((feature as any) == 'base') return true

			if (this.userIsAdmin()) return true
			return this.adapter.userCanUseFeature(feature)
		},
		userGetContentTypesForCreate() {
			const all = Object.keys(window['typeLookup'])
			if (!this.mePolicy) return all
			// admins may do everything
			if (this.userIsAdmin()) return all
			// if the user can not use the entry feature, he can not create anything
 			if (!this.userCanUseFeature('entry')) return []

			const r = []
			for (const t in this.rightsTree) {
				if (!this.rightsTree[t].upsert?.allow) continue
				if (this.rightsTree[t].upsert?.deny) continue
				// at CT level we can ignore tags
				r.push(t)
			}
			return r
		},
		userIsAllowedActionOnType(action, contentType) {
			if (!this.mePolicy) return true
			// admins may do everything
			if (this.userIsAdmin()) return true
			// if the user can not use the entry feature, he can not create anything
 			if (!this.userCanUseFeature('entry')) return false
			const rights = this.rightsTree[contentType]?.[action]
			// TODO: how can we set resource here? we currently dont have that context..
			//       but this method IS being used also on specific resources.
			//       we will need to change interface
			const context: ConditionContext = { }
			return this.adapter.userIsAllowedActionOnType(context, rights)
		},
		userCanCreateAssets() {
			return this.userIsAllowedActionOnType('upsert', 'contentHubAsset')
		},
		userCanUseActionOnAsset(action: Action, asset: any) {
			//if (action == 'publish' && !this.userCanUseFeature('publish')) return false
			return this.userIsAllowedActionOnType(action, 'contentHubAsset')
		},
		isAllowedForTags(entryTags: string[], action: Action, contentType: string, resource: string) {
			const rights: Rights = this.rightsTree[contentType]?.[action]
			const context: ConditionContext = { resource, tags: entryTags }
			return this.adapter.isAllowedForTags(context, rights)
		},
		userIsAllowedActionOnEntry(action: Action, entry: any) {
			if (!this.mePolicy) return true
			if (this.userIsAdmin()) return true
			// NOTE: for the entry tags there is no fallback to 'all' when empty
			//if (action == 'publish' && !this.userCanUseFeature('publish')) return false
			return this.isAllowedForTags(entry?.metadata?.tags?.map(tag => tag?.sys?.id ?? tag) ?? [], action, entry.sys.contentType.sys.id)
		},
		iCan(action: Action, entity: any) {
			if (typeof entity == 'string') {
				return this.userIsAllowedActionOnType(action, entity)
			}
			if (entity?.sys?.type) {
				if (entity.sys.type == 'Asset') return this.userIsAllowedActionOnEntry(action, entity)
				if (entity.sys.type == 'Entry') return this.userIsAllowedActionOnEntry(action, entity)
				if (entity.sys.type == 'ContentType') return this.userIsAllowedActionOnType(action, entity.sys.id)
			}
		},
		iCanCreate(entity: any) { return this.iCan('upsert', entity) },
		iCanUpdate(entity: any) { return this.iCan('upsert', entity) },
		iCanDelete(entity: any) { return this.iCan('delete', entity) },
		iCanPublish(entity: any) { return this.iCan('publish', entity) },
		iCanUse(feature) { return this.userCanUseFeature(feature) },
	},
	async mounted() {
		// TODO: watch the spaceId?

		// we make sure that we only load the policies once
		await this.loadMePolicy()

		//console.log('UGCTFC', this.userGetContentTypesForCreate())
		//console.log('UCUAOE', this.userCanUseActionOnEntry('create', { sys: { contentType: { sys: { id: 'accommodationInfoGroup' } } }, tags: [] }))
	},
}