
import BaseTable from '../BaseTable.vue'
import EntryStatus from './EntryStatus.vue'
import { formatDate } from '../../utils'

export default {
	components: { EntryStatus },
	name: 'ModelTable',
	extends: BaseTable,
	inject: [ 'endpoint' ],
	props: {
		locale: String,
		// { search: '' }
		filter: Object,
		selectable: Boolean,
	},
	computed: {
		types() {
			return Object.values(window['typeLookup'])
				.filter((typ: any) => {
					if (this.filter.search && !typ.name.toLowerCase().includes(this.filter.search.toLowerCase())) return false
					return true
				})
				.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name))
		},
	},
	methods: {
		clickEntry(typ) {
			this.$emit('input', [ typ ])
		},
		userName(id: string) {
			const u = window['userLookup']?.[id]
			return u ? (u.firstName + ' ' + u.lastName) : id
		},
		formatDate,
	},
}
