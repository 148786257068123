
import LoadingSpinner from './LoadingSpinner.vue'
import Asset from './Asset.vue'
import SpinnerIco from '../SpinnerIco.vue'

export default {
	name: 'AssetList',
	components: { LoadingSpinner, Asset, SpinnerIco },
	inject: [ 'endpoint' ],
	props: {
		locale: String,
		filter: Object,
		selectable: Boolean,
		mode: { type: String, default: 'all' },
		value: Array,
	},
	data: () => ({
		loading: false,
		entries: [],
		loadingTimeout: null,
		currentFilter: {
			contentType: null,
			search: '',
		},
		selectedIds: [],
		message: '',
		limit: 20,
		skip: 0,
		total: 20,
	}),
	computed: {
		matchedEntries() {
			const r = []
			for (const entry of this.entries) {
				if (!entry.match) continue
				r.push(entry)
			}
			return r
		},
	},
	watch: {
		filter: {
			deep: true,
			handler() {
				this.filterChanged(true)
			},
		},
	},
	methods: {
		async filterChanged(force = false) {
			if (force || this.filter.contentType != this.currentFilter.contentType) {
				await this.loadEntries()
				this.currentFilter.contentType = this.filter.contentType
			}
			/*for (const entry of this.entries) {
				entry.match = false
					|| this.filter.search == ''
					|| entry.search.indexOf(this.filter.search.toLowerCase()) >= 0
					|| entry.sys.id.toLowerCase() == this.filter.search.toLowerCase()
			}*/
		},
		onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
			if (this.total > this.skip && scrollTop + clientHeight + 2 >= scrollHeight) {
				this.loadMore()
			}
		},
		async loadMore() {
			this.skip += this.limit
			if (this.loading) return
			// TODO: only do this when the last loading was successful
			this.loadEntries(true)
		},
		// TODO: debounce
		// TODO ARCH: where should the loading happen?
		//       here or somewhere else? a mixin?
		//       spaces/390osprlshgj/environments/staging
		//       cda: mIfWzNvZ2FA424_PAgjN1R6HfjK-yXyUk7fs1FC0Hcg
		//       preview: G1G8IbcPzo9sQ4F65HKRQt0xJAjql-9S3UrvHHMrYFM
		//       cma: CFPAT-Z3PYllV5uKNR4eZ_xev-yDyD-oVQ54n1S32yMtO41lY
		async loadEntries(append = false) {
			if (this.loadingTimeout)
				clearTimeout(this.loadingTimeout)

			this.loadingTimeout = window.setTimeout(async () => {
				this.loadingTimeout = null
				this.loading = true
				const request: any = {}

				request['sys.archivedAt[exists]'] = false

				request.order = '-sys.updatedAt'
				request.limit = this.limit
				request.skip = this.skip
				if (this.filter.search)
					request.query = this.filter.search

				// TODO: service implementation for this!
				//       only load certain data (sys.id, fields.title.*, sys.contentType.sys.id)!
				const entries = await this.$httpGet(this.endpoint + '/assets', request)
				this.total = entries.total
				entries.items = entries.items.filter(e => !!e)

				for (const entry of entries.items) {
					entry.selected = false
				}

				if (append)
					this.entries.push(...entries.items)
				else
					this.entries = entries.items
				this.loading = false
			}, 400)
		},
		/*async loadEntries() {
			// TODO: instead of preventing: load only part
			//       prevent picking of type "media" - there are too many entries!
			if (this.filter.contentType == 'media') {
				this.entries = []
				this.message = 'too many entries.'
				return
			}
			this.message = ''

			this.loading = true
			try {
				const res = await this.$httpGet(`/content/entries`, {
					contentType: this.filter.contentType,
					// search: this.filter.search,
					// TODO: service implementation for this!
					//       only load certain data (sys.id, fields.title.*, sys.contentType.sys.id)!
					select: 'minimal',
				})
				for (const entry of res) {
					entry.match = false
					entry.search = entry.fields.title ? JSON.stringify(Object.values(entry.fields.title)).toLowerCase() : ''
					entry.selected = false
				}
				this.entries = res
				this.loading = false
			}
			catch (e) {
				this.message = e.message == 'Request failed with status code 403' ? 'Unauthorized' : 'Error occured'
				this.entries = [];
				this.loading = false
			}
		},*/
		clickEntry(entry) {
			if (this.selectable) {
				const s = this.selectedIds.indexOf(entry.sys.id)
				if (s >= 0)
					this.selectedIds.splice(s, 1)
				else
					this.selectedIds.push(entry.sys.id)
				const lookup = {}
				for (const entry of this.entries) lookup[entry.sys.id] = entry
				this.$emit('input', this.selectedIds.map(id => lookup[id]))
			}
			else {
				this.$emit('input', [ entry ])
			}
		},
	},
	mounted() {
		this.filterChanged(true)
	},
}
