

// TODO: move this into Field instead so we can keep a slim comp tree?

export default {
	name: 'FieldWrapper',
	props: {
		locale: String,
		control: Object,
		def: Object,
		title: String,
	},
	inject: [ 'localeObject' ],
	data: () => ({
		focus: false,
		errors: [],
		warnings: [],
	}),
	computed: {
		required() {
			if (this.localeObject && !this.localeObject.default && this.localeObject.optional) return false
			return this.def?.required
		},
	},
	methods: {
		onFocus() {
			this.focus = true
		},
		onBlur() {
			this.focus = false
		},
		onErrors(errors) {
			//console.log('FieldWrapper.onErrors', errors)
			this.errors = errors
		},
		onWarnings(warnings) {
			this.warnings = warnings
		},
		onWrapper(we: any[]) {
			this[we[0]](we[1])
		},
	},
}
