<template>
	<div class="ContentPreviewEdit" style="height: 100%;">
		<Head :hasBack="true" @back="close()">
			<div class="heading">
				<h1>
					Content Preview
				</h1>
			</div>
			<div class="actions">
				<ActionButton class="delete" @click="del()" v-if="id != 'new'">Delete</ActionButton>
				<ActionButton class="save" @click="saveAndClose()">Save</ActionButton>
			</div>
		</Head>
		<div class="body" v-if="preview" style="display: flex; height: 100%;">
			<div class="main" style="margin: 30px; flex: 1 1 0;">
				<label for="name">Name (required)</label>
				<input type="text" class="input" id="name" v-model="preview.name" />

				<label for="url">URL (required)</label>
				<input type="text" class="input" id="url" v-model="preview.baseUrl" />
				<Info>
					JAMES applications typically have a "/preview" in their preview url, make sure to include this with the specified URL!<br />
					<br />
					JAMES supports a locale in the path, you may specify the locale as a placeholder in the URL with "{locale}".
					Example: https://my-james.com/{locale}/previews
				</Info>
			</div>
			<Sidebar>
				<div class="section">
					<h2>Which URL?</h2>
					<p>
						JAMES supports previews for some defined types.
						The URL points to a JAMES environment that is connected to this environment.
					</p>
					<p>
						Previews are per content type. Not all content types have previews.
						When editing an object, click the preview button in the sidebar to open the preview.
					</p>
				</div>
			</Sidebar>
		</div>
	</div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import Head from './Head.vue'
import ActionButton from '../components/ActionButton.vue'
import Info from './Info.vue'

export default {
	name: 'ContentPreviewEdit',
	components: { Sidebar, Head, ActionButton, Info },
	inject: [ 'base', 'endpoint' ],
	data: () => ({
		id: null,
		preview: null,
	}),
	methods: {
		async load() {
			if (this.id == 'new') {
				return this.preview = { description: '', configurations: [], sys: {} }
			}
			this.preview = await this.$httpGet(this.endpoint + '/preview_environments/' + this.id)
		},
		async save() {
			// TODO: map the url into configurations?
			/*{
				"name": "Preview Env Test",
				"description": "",
				"configurations": [
					{
						"url": "https://youtu.be/dQw4w9WgXcQ",
						"contentType": "course",
						"enabled": true,
						"example": false
					}
				]
			}*/
			if (this.id == 'new') {
				await this.$httpPost(this.endpoint + '/preview_environments/', this.preview)
				this.load()
				return
			}
			const preview = await this.$httpPut(this.endpoint + '/preview_environments/' + this.preview.sys.id, this.preview)
			//this.preview = previews.item
		},
		close() {
			this.$router.push(this.base + '/settings/previews')
		},
		async del() {
			if (!confirm('Are you sure you want to delete this preview?')) return
			await this.$httpDelete(this.endpoint + '/preview_environments/' + this.preview.sys.id)
			this.close()
		},
		async saveAndClose() {
			await this.save()
			this.close()
		},
	},
	async mounted() {
		this.id = this.$route.params.id
		document.title = 'Preview Edit'
		await this.load()
	},
}
</script>

<style scoped>
label { font-size: 14px; display: block; margin-top: 10px; margin-bottom: 4px; }
</style>
