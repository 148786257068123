
import Dialog from './Dialog.vue'
import Info from '../views/Info.vue'
import FieldWrapper from './fields/FieldWrapper.vue'
import EntryApiMixin from './EntryApiMixin.js'

export default {
	name: 'MultiTagDialog',
	components: { Dialog, Info, FieldWrapper },
	inject: [ 'endpoint' ],
	mixins: [ EntryApiMixin ],
	props: {
		// TODO: how does the tag model look?
		entries: Array,
		mode: { type: String, default: 'entry' }, // entry | asset
	},
	data: () => ({
		// entry id => [ tag id ]
		state: {},
		// these arrays contain stringified states
		undoStates: [],
		redoStates: [],
		tags: null,
		tagLookup: {},
		current: null,
	}),
	computed: {
		// TODO: maybe its not good to perform this after every change, but only @load?
		tagUses() {
			const tagUses = []
			const lookup = {}
			for (const entryId in this.state) {
				const tagIds = this.state[entryId]
				for (const tagId of tagIds) {
					if (lookup[tagId]) {
						lookup[tagId].count++
						continue
					}
					// TODO: also find the tag and set it on the use
					const tag = this.tagLookup[tagId]
					const tagUse = { count: 1, tagId, tag }
					lookup[tagId] = tagUse
					tagUses.push(tagUse)
				}
			}
			// TODO: sort by id?
			return tagUses
		},
	},
	methods: {
		// TODO: move to app?
		async loadTags() {
			const tags = await this.$httpGet(this.endpoint + '/tags?limit=1000')
			this.tags = tags.items
			const tagLookup = {}
			this.tags.forEach(tag => tagLookup[tag.sys.id] = tag)
			this.tagLookup = tagLookup
		},
		addToAll(tagId) {
			if (tagId == null) return
			this.pushState()
			for (const entryId in this.state) {
				const tagIds = this.state[entryId]
				if (tagIds.includes(tagId)) continue
				tagIds.push(tagId)
			}
		},
		removeFromAll(tagId) {
			this.pushState()
			for (const entryId in this.state) {
				const tagIds = this.state[entryId]
				if (!tagIds.includes(tagId)) continue
				tagIds.splice(tagIds.indexOf(tagId), 1)
			}
		},
		pushState(to = 'undo') {
			const states = to == 'undo' ? this.undoStates : this.redoStates
			states.push(JSON.stringify(this.state))
			// when performing an undoable action we clear the redo buffer
			if (to == 'undo') {
				this.redoStates = []
			}
		},
		popState(from = 'undo') {
			// when redoing, we have to record that as undoable state
			if (from == 'redo') {
				// ATT: we cant call pushState here as it will clear the redoStates
				this.undoStates.push(JSON.stringify(this.state))
			}
			if (from == 'undo') {
				this.pushState('redo')
			}
			const states = from == 'undo' ? this.undoStates : this.redoStates
			if (states.length == 0) return
			this.state = JSON.parse(states.pop())
			return this.state
		},
		async open() {
			await this.loadTags()
			const state = {}
			for (const entry of this.entries) {
				const links = entry.metadata.tags ?? []
				state[entry.sys.id] = links.map(link => link.sys.id)
			}
			this.state = state
			this.undoStates = []
			this.redoStates = []
			this.$refs.dialog.open()
		},
		close() {
			this.undoStates = []
			this.redoStates = []
			this.$refs.dialog.close()
		},
		async save() {
			// we update the entries passed in directly, so we dont need to reload the parent view
			for (const entry of this.entries) {
				entry.metadata.tags = this.state[entry.sys.id].map(tagId => ({ sys: { id: tagId, linkType: 'Tag', type: 'Link' }}))
			}

			// we load the current state in case a model was updated while the user interacted
			const ids = this.entries.map(e => e.sys.id)
			let entries
			if (this.mode == 'entry') entries = await this.loadEntries(ids)
			if (this.mode == 'asset') entries = await this.loadAssets(ids)
			const promises = []
			for (const entry of entries) {
				entry.metadata.tags = this.state[entry.sys.id].map(tagId => ({ sys: { id: tagId, linkType: 'Tag', type: 'Link' }}))
				if (this.mode == 'entry') promises.push(this.saveEntry(entry))
				if (this.mode == 'asset') promises.push(this.saveAsset(entry))
				// TODO: update the this.entries with version?
			}
			await Promise.all(promises)
			this.close()
		},
		onWindowKey(event) {
			event.stopImmediatePropagation()
			if (event.key === 'z' && (event.ctrlKey || event.metaKey) && !event.shiftKey) {
				this.popState('undo')
			}
			if (event.key === 'z' && (event.ctrlKey || event.metaKey) && event.shiftKey) {
				this.popState('redo')
			}
		},
	},
	async mounted() {
		// TODO: this may happen too often.
		//       currently we do this when the bulk menu shows.
		//       actually i would prefer if we only registered when the tags button is clicked.
		window.addEventListener('keydown', this.onWindowKey)
	},
	destroyed() {
		window.removeEventListener('keydown', this.onWindowKey)
	},
}
