var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.node)?_c('div',{staticClass:"ArrowStarter",staticStyle:{"position":"absolute","left":"0"},style:({
		opacity: _vm.dragging ? 1 : 0.3,
		'--color': _vm.color,
	})},[_c('div',{staticClass:"node",style:({ left: _vm.node.x - 25 + 'px', top: _vm.node.y - 25 + 'px' })}),(_vm.targetNode)?_c('div',{staticClass:"targetNode",style:({ left: _vm.targetNode.x - 25 + 'px', top: _vm.targetNode.y - 25 + 'px' })}):_vm._e(),_c('Link',{attrs:{"value":{ rel: '', id: 0 },"node1":_vm.node,"node2":_vm.targetNode ?? _vm.mousePos,"query2":{ color: _vm.color },"offset":_vm.targetNode ? 25 : 0,"strokeWidth":"3","strokeStyle":"dotted"}}),_c('div',{staticClass:"mouse",staticStyle:{"font-size":"20px","white-space":"nowrap","position":"absolute","margin-left":"-5px","margin-top":"-5px","background-color":"transparent","width":"10px","height":"10px","border-radius":"5px"},style:({
			left: _vm.mousePos.x + 'px',
			top: _vm.mousePos.y + 'px',
		}),on:{"mousedown":_vm.mousedown,"mouseup":_vm.mouseup}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }