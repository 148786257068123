<template>
	<div class="SessionSettings">
		<Head>
			<div class="heading" style="padding-left: 25px;">
				<h1>
					Session Settings
				</h1>
			</div>
		</Head>
		<div class="body" style="display: flex; flex-direction: column; gap: 20px; height: 100%; margin: 20px 50px; font-size: 14px;">
			<Info>
				Data will be saved to your localStorage immediately after change.
				After making changes please reload this page, so the application can initialize with these new settings!
			</Info>
			<div>
				<label>Endpoint</label>
				<input type="text" v-model="model.baseEndpoint" class="input" placeholder="https://cma.contenthub.dev" />
			</div>
			<div>
				<label>Upload Endpoint</label>
				<input type="text" v-model="model.uploadEndpoint" class="input" placeholder="https://upload.contenthub.dev" />
			</div>
			<div>
				<label>Entry table length</label>
				<input type="number" v-model="model.entryTableLength" class="input" placeholder="40" />
			</div>
			<div>
				<label>Skin</label>
				<select v-model="model.skin" class="input">
					<option value="default">default</option>
					<option value="mys">mys</option>
					<option value="alex">alex</option>
					<option value="marcel">marcel</option>
				</select>
			</div>
			<!-- TODO: toggle autosave? -->
			<!-- TODO: toggle detailed logging? -->
		</div>
	</div>
</template>

<script>
import Head from './Head.vue'
import Info from './Info.vue'

// TODO: app has to take the localstorage for its settings

export default {
	name: 'SessionSettings',
	components: { Head, Info },
	inject: [ 'settings' ],
	data: () => ({
		// ATT: default settings are defined in App
		model: {},
	}),
	watch: {
		model: {
			deep: true,
			handler(n, o) {
				window.localStorage.settings = JSON.stringify(this.model)
			},
		},
	},
	mounted() {
		const settings = JSON.parse(window.localStorage.settings ?? 'null')
		this.model = settings ? settings : this.settings
	},
}
</script>

<style scoped>
label { display: block; margin-bottom: 5px; }
</style>