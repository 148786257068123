
export default {
	name: 'Paginator',
	props: {
		locale: String,
		value: Number,
		limit: Number,
		total: Number,
	},
	computed: {
		from() {
			return this.value + 1
		},
		to() {
			return Math.min(this.total, this.value + this.limit)
		},
		lastPage() {
			return this.value + this.limit >= this.total
		},
	},
	methods: {
		prev() {
			if (this.value < this.limit)
				return this.$emit('input', 0)
			this.$emit('input', this.value - this.limit)
		},
		next() {
			if (this.value > this.total - this.limit) return
			this.$emit('input', this.value + this.limit)
		},
	},
}
