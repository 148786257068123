<template>
	<div class="ContentTypesMultiCombo">
		<Field
			class="Field"
			:entry="entry"
			:control="{ widgetId: 'tagEditor', sortable: false }"
			:field="{ name: 'Content Types', type: 'Array', items: { type: 'Symbol' }, validations: [ { in: contentTypeIds, size: { min: 0, max: 100 } } ] }"
			v-model="model"
			@input="$emit('input', $event)"
		/>
	</div>
</template>

<script>
import Field from './Field.vue'

export default {
	name: 'ContentTypesMultiCombo',
	components: { Field },
	inject: [ 'endpoint' ],
	props: {
		entry: Object,
		value: Array,
	},
	data: () => ({
		model: [],
	}),
	computed: {
		contentTypeIds() {
			return Object.values(window['typeLookup']).map(type => type.sys.id)
				.sort((a, b) => a.localeCompare(b))
		},
	},
	watch: {
		value(n) { this.model = n },
		model(n) { this.$emit('input', n) },
	},
	async mounted() {
		this.model = this.value
	},
}
</script>

<style scoped>
.Field { margin-top: 50px; }
</style>