<template>
	<div class="FieldCompare line body"
		:class="{ disabled }"
		v-if="!onlyDiff || onlyDiff && !disabled"
	>
		<div class="side left"
			@click="$refs.A.click()"
			:class="{ selected: value == 'A' }"
		>
			<input type="radio" v-model="model" value="A" ref="A" :name="name" />
			<div class="info" v-if="value == 'A' && !type">Field will be restored to this version</div>
			<div class="info" v-if="type == 'AUTO-A'">Recommended</div>
			<div class="info conflict" v-if="type == 'CONFLICT'"><mdi alert /></div>
			<FieldView v-model="valueA" :field="field" :entry="entry" :control="control" :locale="locale" />
		</div>
		<div class="side right"
			@click="$refs.B.click()"
			:class="{ selected: !disabled && value == 'B' }"
		>
			<input type="radio" v-model="model" value="B" ref="B" :name="name" />
			<div class="info" v-if="type == 'AUTO-B'">Recommended</div>
			<div class="info conflict" v-if="type == 'CONFLICT'">Conflict</div>
			<FieldView v-model="valueB" :field="field" :entry="entry" :control="control" :locale="locale" />
		</div>
	</div>
</template>

<script>
import FieldView from '../fields/FieldView.vue'

export default {
	name: 'FieldCompare',
	components: { FieldView },
	props: {
		entry: Object,
		control: Object,
		field: Object,
		valueA: [ String, Number, Object, Array, Boolean ],
		valueB: [ String, Number, Object, Array, Boolean ],
		value: String, // A | B
		onlyDiff: { type: Boolean, default: false },
		locale: String,
		type: String,
	},
	data: () => ({
		model: null,
	}),
	computed: {
		disabled() {
			return JSON.stringify(this.valueA) == JSON.stringify(this.valueB)
		},
		name() {
			let r = this.control.fieldId
			if (this.locale)
				r += '-' + this.locale
			return r
		},
	},
	watch: {
		model(n) {
			this.$emit('input', n)
		},
		value(n) {
			this.model = n
		},
	},
	mounted() {
		this.model = this.value
	},
}
</script>

<style scoped>
.line { width: 100%; display: flex; margin-bottom: -1px; }
.line.disabled { opacity: 0.25; pointer-events: none; }
.line .side { width: 50%; box-sizing: border-box; display: flex; align-items: center; position: relative; }
.line.body .side { border-right: 1px solid rgb(219, 219, 219); border-bottom: 1px solid rgba(0, 0, 0, 0.1); padding-top: 20px; padding-bottom: 20px; padding-right: 20px; cursor: pointer; font-size: 16px; }
.line.body .side.selected { outline: 1px solid var(--primary); outline-offset: -1px; background-color: rgba(91, 159, 239, 0.05); }
.line.body .side.left { border-left: 0; margin-right: -1px; }
.line.body .side.right { border-right: 0; }
input[type="radio"] { margin: 24px; }

.info { position: absolute; top: 0; background: var(--primary); padding: 6px; text-transform: uppercase; letter-spacing: 1px; font-size: 0.75rem; color: white; font-weight: 400; }
.conflict { background: var(--color-red-mid); }
.left .conflict { left: auto; right: 0; }
</style>