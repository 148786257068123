<script>
import EntryFilter from './EntryFilter.vue'
import { commonFields, assetFields } from './FilterUtil'

export default {
	name: 'AssetFilter',
	extends: EntryFilter,
	computed: {
		showContentTypeSelector() {
			return false
		},
		fields() {
			return [
				...commonFields.filter(f => f.id != 'contentType'),
				...assetFields,
			]
		},
	},
}
</script>
