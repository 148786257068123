
import EntryApiMixin from './components/EntryApiMixin'
import PermissionMixin from './PermissionMixin'
import MainMenu from './components/MainMenu.vue'
import ToastMessages from './components/ToastMessages.vue'
import Head from './views/Head.vue'
import ScheduledActionsMixin from './components/ScheduledActionsMixin'

export default {
	name: 'Root',
	components: { MainMenu, ToastMessages, Head },
	mixins: [ EntryApiMixin, PermissionMixin, ScheduledActionsMixin ],
	inject: [ 'baseEndpoint' ],
	provide() {
		return {
			spaceId: this.$route.params.spaceId,
			environment: this.$route.params.environment,
			base: '/spaces/' + this.$route.params.spaceId + '/environments/' + this.$route.params.environment,
			endpoint: this.endpoint,
			// TODO: actually we would need to provide the space and locales - how to update after load?
		}
	},
	data: () => ({
		typeLookup: null,
		initializing: true,
		space: null,
		locales: [],
		defaultLocale: null,
		fallbackLocale: null,
		uiConfig: null,
		me: null,
		tags: [],
	}),
	computed: {
		spaceId() {
			return this.$route.params.spaceId
		},
		environment() {
			return this.$route.params.environment
		},
		spaceEndpoint() {
			return this.baseEndpoint + '/spaces/' + this.$route.params.spaceId
		},
		endpoint() {
			return this.spaceEndpoint + '/environments/' + this.$route.params.environment
		},
	},
	methods: {
		routerBeforeEach(to, from, next) {
			const comp = this.$refs.current
			if (comp?.routerBeforeEach)
				comp.routerBeforeEach(to, from, next)
			else
				next()
		},
		async loadSpace() {
			const space = await this.$httpGet(this.spaceEndpoint)
			this.space = space
		},
		async loadTags() {
			const tags = await this.$httpGet(this.endpoint + '/tags?limit=1000')
			tags.items.sort((a, b) => a.name.localeCompare(b.name))
			this.tags = tags.items
			//const tagLookup = {}
			//this.tags.forEach(tag => tagLookup[tag.sys.id] = tag)
			//this.tagLookup = tagLookup
		},
		// TODO: when the user changes the locale settings, we have to reload
		async loadLocales() {
			const locales = await this.$httpGet(this.endpoint + '/locales')
			this.locales = locales.items
			const dl = this.locales.filter(locale => locale.default)[0]
			if (!dl) return
			this.defaultLocale = dl.code
			this.fallbackLocale = dl.fallbackCode
		},
		async loadUiConfig() {
			try {
				const uiConfig = await this.$httpGet(this.endpoint + '/ui_config')
				if (!uiConfig.additional) uiConfig.additional = {}
				this.uiConfig = uiConfig
			}
			catch (e) {}
		},
		async loadUsers() {
			// TODO: we should not need to do this here
			const request: any = {}
			request.limit = 1000
			const users = await this.$httpGet(this.spaceEndpoint + '/users', request)
			const userLookup = {}
			for (const user of users.items) {
				userLookup[user.sys.id] = user
			}
			this.userLookup = userLookup
			// TODO: find a better solution
			window['userLookup'] = userLookup
		},
		async loadMe() {
			const me = await this.$httpGet(this.spaceEndpoint + '/users/me')
			this.me = me
		},
		async loadEntries(ids) {
			const request: any = {}
			request['sys.id[in]'] = ids.join(',')
			const entries = await this.$httpGet(this.endpoint + '/entries', request)
			return entries.items
		},
	},
	async mounted() {
		if (this.$route.params.spaceId) {
			try {
				// TODO: parallel
				await this.loadSpace()
				await this.loadTags()
				await this.loadLocales()
				await this.loadUiConfig()
				await this.loadContentTypes()
				await this.loadUsers()
				await this.loadMe()
			}
			catch (e) {
				console.error('could not load', e)
			}
		}
		else {
			const typeLookup = {}
			this.typeLookup = typeLookup
			window['typeLookup'] = typeLookup
		}
		this.initializing = false
	},
}
