import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import SessionSettings from '../views/SessionSettings.vue'
import Model from '../views/Model.vue'
import Content from '../views/Content.vue'
import ContentEdit from '../views/ContentEdit.vue'
import ContentPreviews from '../views/ContentPreviews.vue'
import ContentPreviewEdit from '../views/ContentPreviewEdit.vue'
import Users from '../views/Users.vue'
import Roles from '../views/Roles.vue'
import RoleEdit from '../views/RoleEdit.vue'
import ContentTypeEdit from '../views/ContentTypeEdit.vue'
import Media from '../views/Media.vue'
import MediaEdit from '../views/MediaEdit.vue'
import Graphs from '../views/Graphs.vue'
import GraphEdit from '../views/GraphEdit.vue'
import ContentCompare from '../views/ContentCompare.vue'
import Settings from '../views/Settings.vue'
import Locales from '../views/Locales.vue'
import LocaleEdit from '../views/LocaleEdit.vue'
import ApiKeys from '../views/ApiKeys.vue'
import ApiKeyEdit from '../views/ApiKeyEdit.vue'
import Tags from '../views/Tags.vue'
import Environments from '../views/Environments.vue'
import Webhooks from '../views/Webhooks.vue'
import WebhookEdit from '../views/WebhookEdit.vue'
import UiConfig from '../views/UiConfig.vue'
import ScheduledActions from '../views/ScheduledActions.vue'

Vue.use(VueRouter)

// DOC https://v3.router.vuejs.org/guide/#html

const routes = [
	{ path: '/', name: 'Home', component: Home },
	{ path: '/session/settings', name: 'SessionSettings', component: SessionSettings },
	{ path: '/spaces/:spaceId', redirect: (to: any) => { return { path: '/spaces/' + to.params.spaceId + '/environments/master/entries' } } },
	{ path: '/spaces/:spaceId/environments/:environment', redirect: (to: any) => { return { path: '/spaces/' + to.params.spaceId + '/environments/' + to.params.environment + '/entries' } } },
	{ path: '/spaces/:spaceId/environments/:environment/content_types', name: 'Model', component: Model },
	{ path: '/spaces/:spaceId/environments/:environment/content_types/:id', name: 'ContentTypeEdit', component: ContentTypeEdit },
	{ path: '/spaces/:spaceId/environments/:environment/entries', name: 'Content', component: Content },
	{ path: '/spaces/:spaceId/environments/:environment/entries/:id', name: 'ContentEdit', component: ContentEdit },
	{ path: '/spaces/:spaceId/environments/:environment/entries/:id/compare/:version', name: 'ContentCompare', component: ContentCompare },
	{ path: '/spaces/:spaceId/environments/:environment/assets', name: 'Media', component: Media },
	{ path: '/spaces/:spaceId/environments/:environment/assets/:id', name: 'MediaEdit', component: MediaEdit },
	{ path: '/spaces/:spaceId/environments/:environment/graphs', name: 'Graphs', component: Graphs },
	{ path: '/spaces/:spaceId/environments/:environment/graphs/:id', name: 'GraphEdit', component: GraphEdit },
	{ path: '/spaces/:spaceId/environments/:environment/settings', name: 'Settings', component: Settings },
	{ path: '/spaces/:spaceId/environments/:environment/settings/locales', name: 'Locales', component: Locales },
	{ path: '/spaces/:spaceId/environments/:environment/settings/locales/:id', name: 'LocaleEdit', component: LocaleEdit },
	{ path: '/spaces/:spaceId/environments/:environment/settings/apikeys', name: 'ApiKeys', component: ApiKeys },
	{ path: '/spaces/:spaceId/environments/:environment/settings/apikeys/:id', name: 'ApiKeyEdit', component: ApiKeyEdit },
	{ path: '/spaces/:spaceId/environments/:environment/settings/tags', name: 'Tags', component: Tags },
	{ path: '/spaces/:spaceId/environments/:environment/settings/environments', name: 'Environments', component: Environments },
	{ path: '/spaces/:spaceId/environments/:environment/settings/previews', name: 'ContentPreviews', component: ContentPreviews },
	{ path: '/spaces/:spaceId/environments/:environment/settings/previews/:id', name: 'ContentPreviewEdit', component: ContentPreviewEdit },
	{ path: '/spaces/:spaceId/environments/:environment/settings/users', name: 'Users', component: Users },
	{ path: '/spaces/:spaceId/environments/:environment/settings/roles', name: 'Roles', component: Roles },
	{ path: '/spaces/:spaceId/environments/:environment/settings/roles/:id', name: 'RoleEdit', component: RoleEdit },
	{ path: '/spaces/:spaceId/environments/:environment/settings/webhooks', name: 'Webhooks', component: Webhooks },
	{ path: '/spaces/:spaceId/environments/:environment/settings/webhooks/:id', name: 'WebhookEdit', component: WebhookEdit },
	{ path: '/spaces/:spaceId/environments/:environment/settings/ui_config', name: 'UI Config', component: UiConfig },
	{ path: '/spaces/:spaceId/environments/:environment/settings/scheduled_actions', name: 'Scheduled Actions', component: ScheduledActions },
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
