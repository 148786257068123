<template>
	<div v-if="validation.enabled" class="row" style="align-items: center;">
		Between
		<input class="field" type="number" v-model="validation.size.min" placeholder="min" />
		and
		<input class="field" type="number" v-model="validation.size.max" placeholder="max" />
	</div>
</template>

<script>
export default {
	name: 'FieldSettingSize',
	props: {
		validation: Object,
	},
}
</script>

<style scoped>
.field { display: block; background-color: white; color: rgb(65, 77, 99); font-size: 0.875rem; line-height: 1.25rem; border-radius: 6px; box-shadow: rgb(225 228 232 / 20%) 0px 2px 0px inset; outline: none; border: 1px solid rgb(207, 217, 224); cursor: pointer; padding: 10px 1.5rem 10px 0.75rem; }
.field:focus { border: 1px solid rgb(0, 112, 243); }
</style>