
export default {
	name: 'BaseTable',
	props: {
		locale: String,
	},
	data: () => ({
		loading: false,
		message: '',
		limit: 20,
		skip: 0,
	}),
}
