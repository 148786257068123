<template>
	<div class="webhooks" style="height: 100%;">
		<Head>
			<div class="heading" style="padding-left: 25px;">
				<h1>
					Ui Config
				</h1>
			</div>
			<div class="actions">
				<ActionButton class="save" @click="saveAndClose()">Save</ActionButton>
			</div>
		</Head>
		<div class="body" style="margin: 25px; margin-bottom: 50px; font-size: 14px;" v-if="ui_config">
			<div>
				<h2>QR Code Generator</h2>
				<label for="name">Environment</label>
				<div class="fieldWrap">
					<input type="text" class="input" id="name" v-model="ui_config.additional.qrGeneratorEnvironment" />
				</div>
				<div class="help">
					The QR Code Generator symbol field appearance adds an 'environment' string into the generated codes.
				</div>
			</div>

			<div>
				<h2>Graphs</h2>
				<div class="fieldWrap">
					<input type="radio" id="disabled" v-model="ui_config.additional.features.graphs" :value="false" />
					<label for="disabled">Disabled</label>
					<input type="radio" id="enabled" v-model="ui_config.additional.features.graphs" :value="true" />
					<label for="enabled">Enabled</label>
				</div>
				<div class="help">
					Graphs is currently in ALPHA.
					Graphs allows you to create and edit graphs of your data.
					It is intended to allow better visualization for certain types of data that are best viewed spatially.
					Graphs will give you a new main menu item 'Graphs' leading to a new page where you can create and view graphs.
					Graphs will add 2 content types 'X Graph' and 'X Graph Node' and it will also add some test data for these types.
				</div>
			</div>

			<Info>After saving make sure to reload the page so the ui config is applied.</Info>
		</div>
	</div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import Head from './Head.vue'
import ActionButton from '../components/ActionButton.vue'
import Info from './Info.vue'

export default {
	name: 'UiConfig',
	components: { Sidebar, Head, ActionButton, Info },
	inject: [ 'base', 'endpoint' ],
	data: () => ({
		ui_config: null,
	}),
	methods: {
		async load() {
			const ui_config = await this.$httpGet(this.endpoint + '/ui_config')
			if (!ui_config.additional) ui_config.additional = {}
			if (!ui_config.additional.features) ui_config.additional.features = {}
			this.ui_config = ui_config
		},
		async saveAndClose() {
			await this.$httpPut(this.endpoint + '/ui_config', this.ui_config)
		},
	},
	async mounted() {
		document.title = 'UI Config'
		await this.load()
	},
}
</script>

<style scoped>
h2 { margin: 20px 0px 1rem; padding: 0px; font-weight: 600; color: rgb(17, 27, 43); font-size: 1rem; line-height: 1.5rem; }
.help { font-style: italic; margin: 10px 0; }
</style>
