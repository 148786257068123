
import LoadingSpinner from './LoadingSpinner.vue'
import Entry from './Entry.vue'
import { loadEntries } from '../../EntryApi'
import SpinnerIco from '../SpinnerIco.vue'

export default {
	name: 'EntryList',
	components: { LoadingSpinner, Entry, SpinnerIco },
	inject: [ 'endpoint' ],
	props: {
		locale: String,
		filter: Object,
		selectable: Boolean,
		mode: { type: String, default: 'all' },
		value: Array,
		allowedContentTypes: Array,
	},
	data: () => ({
		loading: false,
		entries: [],
		loadingTimeout: null,
		currentFilter: {
			contentType: null,
			search: '',
		},
		selectedIds: [],
		selectionCache: {},
		message: '',
		limit: 20,
		skip: 0,
		total: 20,
	}),
	computed: {
		matchedEntries() {
			return this.entries.filter(entry => entry.match)
		},
	},
	watch: {
		filter: {
			deep: true,
			handler() {
				this.filterChanged(true)
			},
		},
	},
	mounted() {
		this.filterChanged(true)
	},
	methods: {
		async filterChanged(force = false) {
			if (force || this.filter.contentType != this.currentFilter.contentType) {
				await this.loadEntries()
				this.currentFilter.contentType = this.filter.contentType
			}
			/*for (const entry of this.entries) {
				entry.match = false
					|| this.filter.search == ''
					|| entry.search?.indexOf?.(this.filter.search.toLowerCase()) >= 0
					|| entry.sys.id.toLowerCase() == this.filter.search.toLowerCase()
			}*/
		},
		onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
			if (this.total > this.skip && scrollTop + clientHeight + 2 >= scrollHeight) {
				this.loadMore()
			}
		},
		async loadMore() {
			this.skip += this.limit
			if (this.loading) return
			// TODO: only do this when the last loading was successful
			this.loadEntries(true)
		},
		// TODO ARCH: where should the loading happen?
		//       here or somewhere else? a mixin?
		//       spaces/390osprlshgj/environments/staging
		//       cda: mIfWzNvZ2FA424_PAgjN1R6HfjK-yXyUk7fs1FC0Hcg
		//       preview: G1G8IbcPzo9sQ4F65HKRQt0xJAjql-9S3UrvHHMrYFM
		//       cma: CFPAT-Z3PYllV5uKNR4eZ_xev-yDyD-oVQ54n1S32yMtO41lY
		async loadEntries(append = false) {
			if (this.loadingTimeout)
				clearTimeout(this.loadingTimeout)

			this.loadingTimeout = window.setTimeout(async () => {
				this.loadingTimeout = null
				this.loading = true

				if (this.allowedContentTypes?.length)
					this.filter.allowedContentTypes = this.allowedContentTypes

				const entries = await loadEntries(this.endpoint, this.filter, this.limit, this.skip)
				this.total = entries.total

				if (append)
					this.entries.push(...entries.items)
				else
					this.entries = entries.items
				this.loading = false
			}, 400)
		},
		clickEntry(entry) {
			if (this.selectable) {
				const s = this.selectedIds.indexOf(entry.sys.id)
				if (s >= 0)
					this.selectedIds.splice(s, 1)
				else
					this.selectedIds.push(entry.sys.id)
				const lookup = {}
				for (const entry of this.entries) lookup[entry.sys.id] = entry
				// in case the search changes, we remember the objects related to the selected ids
				this.selectedIds.forEach(id => {
					if (!lookup[id]) return
					this.selectionCache[id] = lookup[id]
				})
				this.$emit('input', this.selectedIds.map(id => this.selectionCache[id]))
			}
			else {
				this.$emit('input', [ entry ])
			}
		},
	},
}
