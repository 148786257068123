
import { loadEntries } from '../../EntryApi'
import EntryStatus from './EntryStatus.vue'
import BaseTable from '../BaseTable.vue'
import { formatDate, userName } from '../../utils'
import Paginator from '../Paginator.vue'
import TableColumnsMenu from '../TableColumnsMenu.vue'
import SpinnerAnimation from '../SpinnerAnimation.vue'
import SpinnerIco from '../SpinnerIco.vue'
import ActionButton from '../ActionButton.vue'
import EntryTableBulkActions from '../EntryTableBulkActions.vue'
import SortArrows from '../SortArrows.vue'
import VisualisationToggle from '../VisualisationToggle.vue'
import VisualisationTray from './VisualisationTray.vue'

// TODO: extract basic list L&F so that we can reuse it for example in Model

// TODO: this shares a lot with EntryList - create a common superComp and inherit?
//       extract some functionality into a mixin?

export default {
	name: 'EntryTable',
	extends: BaseTable,
	components: { EntryStatus, Paginator, TableColumnsMenu, SpinnerAnimation, SpinnerIco, ActionButton, EntryTableBulkActions, SortArrows, VisualisationToggle, VisualisationTray },
	inject: [ 'base', 'endpoint', 'defaultLocale', 'fallbackLocale', 'tags' ],
	props: {
		locale: String,
		filter: Object,
		selectable: Boolean,
	},
	data: () => ({
		loading: 1,
		entries: [],
		includes: {},
		timeout: null,
		message: '',
		limit: 40,
		skip: 0,
		total: 0,
		selection: [],
		selectionAll: false,
		loadEntriesPromise: null,
		requestNr: 0,
		debounceTimeout: null,
	}),
	computed: {
		selectedEntries() {
			this.selectionAll
			return this.entries.filter(entry => {
				return !!this.selection.includes(entry.sys.id)
			})
		},
		contentType() {
			const lookup = window['typeLookup']
			return lookup?.[this.filter?.contentType]
		},
		locationField() {
			return this.contentType?.fields?.find(f => f.type == 'Location')
		},
	},
	watch: {
		filter: {
			deep: true,
			handler() {
				this.filterChanged()
			},
		},
		skip() {
			this.debounceLoadEntries()
		},
		selectionAll(n) {
			const r = []
			if (n) {
				for (const entry of this.entries) {
					r.push(entry.sys.id)
				}
			}
			this.selection = r
		},
	},
	methods: {
		niceName(id) {
			id = id.replace(/([A-Z])/g, ' $1')
			id = id.charAt(0).toUpperCase() + id.slice(1)
			return id
		},
		getEntryValue(entry, column, format = 'full') {
			if (column.id == 'contentType') return this.getTypeName(entry)

			let value = entry?.[column.scope]?.[column.id]
			if (column.scope == 'fields') value = value?.[this.defaultLocale]

			// if we have a link array column we need to resolve the linked entries
			if (value && (value.sys?.linkType == 'Entry' || value.sys?.linkType == 'Asset')) value = [ value ]
			if (value && Array.isArray(value) && (value[0]?.sys?.linkType == 'Entry' || value[0]?.sys?.linkType == 'Asset')) {
				return value
					.map(v => {
						const sub = this.includes[v.sys.id]
						if (!sub) return v.sys.id
						const typeName = sub.sys.contentType.sys.id
						const type = window['typeLookup'][typeName]
						const titleField = type?.titleField ?? 'title'
						const title = sub?.fields?.[titleField]?.[this.defaultLocale]
						if (!title) return 'Untitled'
						return title
					})
					.join(', ')
			}
			if (value && Array.isArray(value) && (value[0]?.sys?.linkType == 'Tag')) {
				return value
					.map(v => this.tags?.find?.(t => t.sys.id == v.sys.id)?.name ?? v.sys.id)
					.join(', ')
			}

			if (column.type == 'Date') return this.formatDate(value, format)
			if (column.type == 'User' && value.sys) return this.userName(value.sys.id, format)
			if (typeof value != 'string') try { value = JSON.stringify(value) } catch (e) { value = 'ERR' }
			return value?.substring(0, 100) ?? ''
		},
		getTitle(entry) {
			const t = window['typeLookup'][entry.sys.contentType.sys.id]
			const df = t?.displayField ?? 'title'
			const dl = this.defaultLocale
			const fl = this.fallbackLocale
			return entry?.fields?.[df]?.[dl] ?? entry?.fields?.[df]?.[fl] ?? 'Untitled'
		},
		clip(str, len) {
			if (str.length > len) return str.substring(0, len) + '...'
			return str
		},
		getTypeName(entry) {
			const t = window['typeLookup'][entry.sys.contentType.sys.id]
			return t?.name
		},
		filterChanged() {
			this.skip = 0
			// TODO: a change only to "vis" should not trigger a reload!
			//       how can we distinguish these?
			this.debounceLoadEntries()
		},
		debounceLoadEntries(append = false) {
			if (this.debounceTimeout)
				window.clearTimeout(this.debounceTimeout)

			this.debounceTimeout = window.setTimeout(() => {
				this.loadEntries(append)
			}, 250)
		},
		async loadEntries(append = false) {
			this.loading++
			try {
				// TODO: we probably dont need this mechanism anymore with the AbortError in place
				const requestNr = ++this.requestNr

				// TODO: also deeper select for column.type == 'Link'?
				// TODO: can we make the "select" danymically only select the title field for these deeper selections?
				const include = this.filter.columns?.some?.(column => column.itemType == 'Link' || column.type == 'Link') ? 1 : 0

				this.filter.fields = this.filter.columns?.map?.(column => column.id) ?? []
				if (this.locationField)
					this.filter.fields.push(this.locationField.id)
				const loadEntriesPromise = loadEntries(this.endpoint, this.filter, this.limit, this.skip, include, 'EntryTable')
				this.loadEntriesPromise = loadEntriesPromise
				if (this.loadEntriesPromise != loadEntriesPromise) return
				const entries = await this.loadEntriesPromise
				// we ensure that the response belongs to the latest request
				if (requestNr != this.requestNr) return
				this.total = entries.total

				if (append)
					this.entries.push(...entries.items)
				else
					this.entries = entries.items

				const includes = {}
				for (const entry of entries.includes?.Asset ?? [])
					includes[entry.sys.id] = entry
				for (const entry of entries.includes?.Entry ?? [])
					includes[entry.sys.id] = entry
				this.includes = includes

				this.selection = []
			}
			catch (e) {
				// we pass along a key, this mechanism cancels previous requests.
				// these cancellations cause an abortError.
				if (e.name == 'AbortError') return

				// TODO: maybe we can move this to EntryApi.ts or maybe even httputil
				//      we would just have to identify "any valid el thats a child of root"
				//      and dispatch the event there..
				if (e.status == 401)
					this.$el.dispatchEvent(new CustomEvent('401', { bubbles: true, detail: e }))
				else {
					console.error(e)
					throw e
				}
			}
			finally {
				this.loading--
			}
		},
		clickEntry(entry) {
			if (this.selectable) {
				entry.selected = !entry.selected
				this.$emit('input', this.entries.filter((e) => e.selected))
			}
			else {
				this.$emit('input', [ entry ])
			}
		},
		// TODO: move out of this comp?
		mapHoverEntry(entry) {
			// TODO: scroll to this entry and highlight it?
			const el = this.$refs['entry-' + entry.sys.id]?.[0]
			if (!el) return
			el.scrollIntoView({ behavior: 'smooth', block: 'center' })
			el.style.backgroundColor = 'var(--color-element-light)'
			setTimeout(() => {
				el.style.backgroundColor = ''
			}, 1000)
		},
		userName,
		formatDate,
		// here we handle shift-selection
		// the normal selecting happens in the v-model binding
		clickCheckbox(event, entry) {
			if (!event.shiftKey) return
			if (this.selection.length == 0) return
			const id1 = this.selection[this.selection.length - 1]
			const id2 = entry.sys.id
			let marking = false
			for (const e of this.entries) {
				if (e.sys.id == id1 || e.sys.id == id2) {
					marking = !marking
				}
				if (marking) {
					e.selected = true
					if (!this.selection.includes(e.sys.id))
						this.selection.push(e.sys.id)
				}
			}
		},
	},
	mounted() {
		this.loading = 0
		this.loading++
		// we have to do this in a timeout because filterChanged will also only trigger on a timeout
		window.setTimeout(() => { this.loading-- }, 250)

		this.filterChanged(true)
	},
}
