<template>
	<ul class="TypePickerList" @mousedown="closeOnBlur = false">
		<li v-if="showPickEntry" @click="$emit('pickEntry')" class="item" data-cy="addExisting">Add existing content</li>
		<li class="search" style="position: sticky; top: 0;">
			<input type="text" placeholder="Search all content types" v-model="search" ref="search"
				@blur="blur"
			/>
		</li>
		<li class="heading" v-if="suggestedType">Suggested content type</li>
		<li class="sub item" v-if="suggestedType"
			@click="$emit('select', suggestedType)"
		>
			{{ suggestedType.name }}
		</li>
		<li class="heading">New content</li>
		<li class="sub item" v-for="contentType of matchingContentTypes" :key="contentType.sys.id"
			@click="$emit('select', contentType)"
			:data-cy="'type-' + contentType.sys.id"
		>
			{{ contentType.name }}
		</li>
		<!-- TODO: scrolling -->
	</ul>
</template>

<script>
// TODO: remove "suggested" if not in permitted
// TODO: remove Asset

// TODO: events for selection
// TODO: keyboard controls: down/up/enter/escape

export default {
	name: 'TypePickerList',
	inject: [ 'permissions' ],
	props: {
		showPickEntry: { type: Boolean, default: false },
		suggestedTypeName: String,
		allowedContentTypes: Array,
	},
	data: () => ({
		search: '',
		closeOnBlur: true,
	}),
	computed: {
		permittedTypes() {
			return this.permissions.userGetContentTypesForCreate()
		},
		contentTypes() {
			return Object.values(window['typeLookup'])
				// TODO: filter by some kind of "hidden" prop instead!
				.filter(t => ![ 'contentHubAsset', 'x_graph', 'x_graph_node' ].includes(t.sys.id))
				.filter(t => this.permittedTypes.includes(t.sys.id))
				.sort((a, b) => a.name.localeCompare(b.name))
		},
		suggestedType() {
			if (!this.permittedTypes.includes(this.suggestedTypeName)) return null
			if (!this.suggestedTypeName) return null
			return window['typeLookup'][this.suggestedTypeName]
		},
		matchingContentTypes() {
			return this.contentTypes.filter(t => {
				// TODO: includes() has bad performance
				if (this.allowedContentTypes && !this.allowedContentTypes.includes(t.sys.id)) return false
				const re = new RegExp(this.search, 'i')
				return !!re.exec(t.name + '--' + t.sys.id)
			})
		},
	},
	methods: {
		blur() {
			if (this.closeOnBlur)
				this.$emit('close')
			this.closeOnBlur = true
			this.$nextTick(() => {
				if (this.$refs.search) this.$refs.search.focus()
			})
		},
	},
	mounted() {
		this.$nextTick(() => {
			if (this.$refs.search) this.$refs.search.focus()
		})
	},
}
</script>

<style scoped>
.TypePickerList { overflow-y: auto; max-height: 320px; width: 290px; background: white; border-radius: 6px; list-style-type: none; padding: 0; display: inline-block; text-align: left; font-size: 14px; }
.TypePickerList .item { padding: 15px 18px; border-bottom: 1px solid #ddd; cursor: pointer; }
.TypePickerList .item:hover { background-color: #f6f8f9; }
.TypePickerList .item.sub { padding: 10px 18px; border-bottom: none; }
.TypePickerList .search input { width: calc(100% - 36px); margin: 5px;padding: 12px; border-radius: 6px; font-size: 14px; border: 1px solid rgb(207, 217, 224); outline: 0; }
.TypePickerList .search input:focus { border-color: rgb(0, 89, 200); box-shadow: rgb(152 203 255) 0px 0px 0px 3px; }
.TypePickerList .heading { padding: 5px 18px; font-family: var(--font-stack-primary); font-weight: 600; text-transform: uppercase; letter-spacing: 0.1rem; font-size: 12px; line-height: 20px; }
</style>