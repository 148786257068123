import { getEntryState, getValueState, userName } from '../utils'

type Conflict = {
	locale: string
	control: any,
	field: any,
	valueA: any,
	valueB: any,
	value: 'A' | 'B',
	type: 'AUTO-A' | 'AUTO-B' | 'CONFLICT',
}

type Conflicts = {
	model: any
	serverModel: any
	conflicts: Conflict[]
	hasConflicts: boolean
}

export default {
	inject: [ 'locales', 'defaultLocale', 'eventBus' ],
	data: () => ({
		autoSaveTimer: null,
		// a string containing a serialized version of the fields. this is used to prevent unnecessary autosaves. 
		lastModel: null,
		lastState: null,
		autoSaveRunning: false,
	}),
	methods: {
		initAutoSave() {
			this.lastModel = JSON.parse(JSON.stringify(this.model))
			this.lastState = getEntryState(this.model)
		},
		entryAutoSave() {
			// function is currently running -> wait for it to finish and try again
			if (this.autoSaveRunning) {
				window.setTimeout(() => this.entryAutoSave(), 250)
				return
			}
			// cancel pending autosave and start a new one
			if (this.autoSaveTimer) {
				window.clearTimeout(this.autoSaveTimer)
				this.autoSaveTimer = null
			}
			this.autoSaveTimer = window.setTimeout(async () => {
				this.autoSaveTimer = null

				// check if we actually even need to save
				let model = JSON.parse(JSON.stringify(this.model))
				const state = getEntryState(model)
				if (state == this.lastState) {
					return
				}

				if (localStorage.debug == 'true') {
					console.log('autosave OLD', this.lastState)
					console.log('autosave NEW', state)
				}

				// TODO: move this concern out of here?
				if (!this.permissions.iCanUpdate(this.model)) {
					this.eventBus.$emit('addToastMessage', 'You do not have permission to update this entry.', 'error')
					this.lastState = state
					return
				}

				this.autoSaveRunning = true
				model = await this.doSave(model)
				if (!model) return

				this.lastModel = model
				this.lastState = state

				this.autoSaveRunning = false
			}, 1000)
		},
		// only to be used by autosave
		async doSave(model) {
			try {
				// TODO: should we do the saving in a webworker to be sure it finishes?
				await this.saveEntry()
				return model
			}
			catch (e) {
				console.error('AUTOSAVE FAILED', e)
				// TODO: we need a structured information from the server about this error
				if (e?.message?.includes('Version mismatch')) {
					const conflicts = await this.getConflicts(model)
					if (conflicts.hasConflicts) {
						this.handleConflicts(conflicts, async (result) => {
							this.autoSaveRunning = false
							if (result != 'save') return
							console.log('CONFLICTS RESOLVED', conflicts)

							// apply the selected changes to the model
							for (const conflict of conflicts.conflicts) {
								const f = conflict.field.id
								const lc = conflict.locale
								if (!conflicts.model.fields[f]) conflicts.model.fields[f] = {}
								conflicts.model.fields[f][lc] = conflict.value == 'A' ? conflict.valueA : conflict.valueB
							}
							this.model = conflicts.model
							// TODO: the ui hangs somehow afterwards
							// TODO: another local change may be scheduled after this one
							//       and its probably not going to be compatible (may revert some changes decided here
							//       should we disregard them?
							//await this.doSave(this.model)
							return this.model
						})
					}
					else {
						this.eventBus.$emit('addToastMessage', 'Was able to merge automatically.', 'success')
						this.model = conflicts.model
						return this.model
					}
					return
				}
				this.autoSaveRunning = false
				return
			}
		},
		async getConflicts(model) {
			const serverModel = await this.fetchEntry(this.entryId)
			const serverUserId = serverModel.sys.updatedBy.sys.id
			const serverUserName = userName(serverUserId)
			const serverVersion = serverModel.sys.version
			const versionDiff = serverVersion - model.sys.version
			console.log('DEBUG getConflicts: versionDiff=', versionDiff, 'serverVersion=', serverVersion, 'serverUserId=', serverUserId, 'serverUserName=', serverUserName)
			const newModel = model
			const oldModel = this.lastModel
			const mergedModel = JSON.parse(JSON.stringify(serverModel))
			const r: Conflicts = { conflicts: [], model: mergedModel, serverModel, hasConflicts: false }
			for (const control of this.controlsMerged) {
				const field = control.field
				const f = field.id
				for (const locale of this.locales) {
					const lc = locale.code
					if (!control.field.localized && lc != this.defaultLocale) continue

					const ov = oldModel.fields[f]?.[lc]
					const nv = newModel.fields[f]?.[lc]
					const sv = serverModel.fields[f]?.[lc] //+ '-S'
					// we actually compare "states", which are 'compressed' string representations of the field values 
					const o = getValueState(ov)
					const n = getValueState(nv)
					const s = getValueState(sv)
					//console.log('CMP', field.id, lc, ov, nv, sv)
					const pushConflict = (type, value) => {
						r.conflicts.push({
							locale: lc,
							control,
							field: control.field,
							valueA: nv,
							valueB: sv,
							value,
							type,
						})
					}

					// TODO: we probably want to implement a VERY FAST endpoint for checking ONLY the most current version
					//       of an entry (not even checking permissions, caching, ..) so we can implement a frequent polling.
					//       that way we can avoid having very stale data in the UI.
					// TODO: i believe we could check if the user is the same as the one who made the change
					//       in that case we should just stick with the newer (or local?) value
					// else if (serverUserId == this.user.id && serverVersion == this.entry.sys.version) {
					// TODO: due to our aggressive autosave we can assume that a local modification is very recent
					//       however, its base version could be very old theoretically, as we do not poll for
					//       changes or server-push them.

					// always take the server change without even telling the user in certain cases
					if (f == 'schemaOrgAnnotation') {
						// TODO: we should even supress the toast when only these cases are present
						//       "TOAST  Version mismatch. The entry has been updated since you last fetched it."
						//       but this message is sent by the EntryApi i believe ..
						//       could we CANCEL it instead?
						console.log('silent merge B 1', f, sv)
						this.eventBus.$emit('removeToastMessageMatching', 'Version mismatch')
						mergedModel.fields[f][lc] = sv
					}
					// the schema.org bot only makes changes to the schemaOrgAnnotation field.
					// anything else MUST be dirty-read, so we always prefer the user input.
					// we can only make this assumption though, if no other user is involved, which we
					// can only do in the special circumstance of the version not being more ahead than 2 (1 change + 1 publish).
					// NOTE that this also needs the above case to be there (schemaOrgAnnotation), otherwise we would
					//      eliminate the bots work (which would probably also be ok, since any local change will probably
					//      lead to a publish anyhow and therefore trigger the bot again).
					else if (serverUserName.includes('schema.org') && serverUserName.includes('[BOT]') && versionDiff <= 2) {
						console.log('silent merge A 2', f)
						this.eventBus.$emit('removeToastMessageMatching', 'Version mismatch')
						mergedModel.fields[f][lc] = nv
					}
					// conflict: tell user
					else if (o != n && o != s && n != s) {
						console.log('MERGE CONFLICT', f, 'A', n, 'B', s)
						console.log('oldModel', oldModel, 'newModel', newModel, 'serverModel', serverModel)
						r.hasConflicts = true
						mergedModel.fields[f][lc] = sv
						pushConflict('CONFLICT', 'B')
					}
					// we have a change that the server is ok with
					else if (o == s && n != s) {
						if (!mergedModel.fields[f]) mergedModel.fields[f] = {}
						// TODO: we cannot use the state value as-is, we have to take the original from the model (getState modifies stuff)!
						mergedModel.fields[f][lc] = nv
						pushConflict('AUTO-A', 'A')
					}
					// the server has a change that we are ok with
					else if (o == n && o != s) {
						// TODO: we cannot use the state value as-is, we have to take the original from the model (getState modifies stuff)!
						mergedModel.fields[f][lc] = sv
						pushConflict('AUTO-B', 'B')
					}
					// else: we made the same change as the server or its the same as before for both.
					// -> already in mergedState.
				}
			}
			return r
		},
		handleConflicts(conflicts: Conflict[], callback: Function) {},
	},
}