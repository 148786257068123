<template>
	<div class="GraphMenu" v-if="visible" :style="{ left: x + 'px', top: y + 'px' }">
		<span>
			<div @click="$emit('zoomOut', { x, y })">&minus;</div>
			<span></span>
			<div @click="$emit('zoomIn', { x, y })">&plus;</div>
			<div @click="$emit('zoomToFit')">[ ]</div>
			<span class="spacer"></span>
			<!--<div>?</div>-->
			<div @click="hide">&times;</div>
		</span>
		<div v-if="!node && !rel" @click="$emit('addNode', { pos }); hide()">Add Gray Node</div>
		<div v-if="!node && !rel" @click="$emit('createEntry'); hide()">Create Entry</div>
		<div v-if="!node && !rel" @click="$emit('forceLayout')">Forcelayout 1s</div>

		<h1 v-if="node">{{ node?.title ?? entry?.fields?.title?.[defaultLocale] }}</h1>
		<!--<div v-if="node" @click="TODO hide()">add Relation</div>-->
		<div v-if="node" @click="$emit('deleteEntry', node); hide()">Delete Entry</div>
		<div v-if="node" @click="$emit('removeNode', node); hide()">Remove Node from Graph</div>

		<h1 v-if="rel">{{ rel?.rel }}</h1>
		<div v-if="rel" @click="$emit('deleteRel', rel); hide()">Delete Relation</div>
		<div v-if="rel" @click="$emit('removeRel', rel); hide()">Remove Relation from Graph</div>
		<!-- TODO: persist changes to the data -->
		<!--<div v-if="propertiesField">
			<JsonPropertiesField v-model="entry.fields[propertiesField.id][defaultLocale]"
				style="font-size: 13px;"
			/>
		</div>-->
	</div>
</template>

<script>
import JsonPropertiesField from '../fields/JsonPropertiesField.vue'

export default {
	name: 'GraphMenu',
	components: { JsonPropertiesField },
	inject: [ 'defaultLocale' ],
	data: () => ({
		visible: false,
		pos: { x: 0, y: 0 },
		x: 0,
		y: 0,
		node: null,
		rel: null,
		entry: null,
	}),
	computed: {
		type() {
			return window['typeLookup'][this.entry?.sys?.contentType?.sys?.id]
		},
		propertiesField() {
			// TODO: also check that control.widgetId == 'propertiesEditor'
			if (!this.type) return
			return this.type.fields.find(f => f.id == 'properties' && f.type == 'Object')
		},
	},
	methods: {
		/* public */ showAt(x, y, node, rel, entry, pos) {
			this.node = node
			this.entry = entry
			// create content in the properties field if necessary
			if (this.propertiesField && !entry.fields.properties?.[this.defaultLocale])
				entry.fields.properties = { ...entry.fields.properties, [ this.defaultLocale ]: {} }
			this.rel = rel
			this.x = x
			this.y = y
			this.pos = pos
			this.visible = true
		},
		/* public */ hide() {
			this.visible = false
		},
	},
}
</script>

<style scoped>
.GraphMenu { position: fixed; z-index: 999999; background: white; font-size: 13px;  display: flex; flex-direction: column; border-radius: 10px; border: 1px solid #eee; box-shadow: 1px 1px 10px rgba(0,0,0,0.2); user-select: none; overflow: hidden; }
.GraphMenu div { cursor: pointer; padding: 7px 10px; }
.GraphMenu h1 { font-size: 0.75rem; background: #f9f9f9; color: #999; border-bottom: 1px solid #eee; font-weight: 600; margin: 0; padding: 3px 10px; text-transform: uppercase; letter-spacing: 1px; }
.GraphMenu div:hover { background: #f9f9f9; }
.GraphMenu > div { display: flex; border-bottom: 1px solid #eee; cursor: pointer; }
.GraphMenu > span { display: flex; border-bottom: 1px solid #eee; }
.GraphMenu > * > div { min-width: 20px; text-align: center; padding: 5px; font-size: 16px; }
.GraphMenu > * > span { min-width: 5px; }
.spacer { flex-grow: 1; }
</style>