<template>
	<div style="width: 200px; height: 200px; background: transparent; border-radius: 100px;">
		<div class="solid">
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>

			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>

			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>

			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SpinnerIco',
	props: {
		// TODO: switch to a different look during loading
		//       maybe flickering segments?
		//       flying segments in?
		loading: [ Boolean, Number ],
	},
}
</script>


<style scoped>
/* a "crystal ball" in case we want to use it somewhere..
<b class="ball"></b>
b.ball {
  display: block;
  width: 200px;
  height: 200px;
  background-color: white;
  border-radius: 50%;
  box-shadow:
    inset -30px -30px 50px -10px rgba(84, 12, 171, 0.654),
    inset -50px -50px 40px 20px rgba(228, 31, 205, 0.451),
    inset -30px -40px 5px 90px rgba(222, 175, 255, 0.4),
    20px 60px 50px -60px rgba(0, 0, 0, 0.5)
  ;
}*/
.solid { position: relative; width: 200px; height: 200px; scale: 0.5; animation: spin 100s infinite linear; transform-style: preserve-3d; }
.solid .side { position: absolute; left: 0; bottom: 50%; border-bottom: 173px solid black; border-left: 100px solid transparent; border-right: 100px solid transparent; transform-origin: 50% 0%; }

/* bottom row */
.solid .side:nth-child( 1) { transform: translateY(-18px) translateX(-0px) rotateY( 72deg) rotateX(52.62deg); border-bottom-color: rgba(168, 28, 215, 0.4); }
.solid .side:nth-child( 2) { transform: translateY(-18px) translateX(-0px) rotateY(144deg) rotateX(52.62deg); border-bottom-color: rgba(79, 48, 183, 0.4); }
.solid .side:nth-child( 3) { transform: translateY(-18px) translateX(-0px) rotateY(216deg) rotateX(52.62deg); border-bottom-color: rgba(228, 31, 205, 0.451); }
.solid .side:nth-child( 4) { transform: translateY(-18px) translateX(-0px) rotateY(288deg) rotateX(52.62deg); border-bottom-color: rgba(78, 23, 116, 0.4); }
.solid .side:nth-child( 5) { transform: translateY(-18px) translateX(-0px) rotateY(360deg) rotateX(52.62deg); border-bottom-color: rgba(6, 27, 148, 0.612); }

/* top row */
.solid .side:nth-child( 6) { transform: translateY(361px) translateX(-0px) rotateY(468deg) rotateX(127.38deg); border-bottom-color: rgba(239, 1, 101, 0.4); }
.solid .side:nth-child( 7) { transform: translateY(361px) translateX(-0px) rotateY(540deg) rotateX(127.38deg); border-bottom-color: rgba(219, 112, 252, 0.4); }
.solid .side:nth-child( 8) { transform: translateY(361px) translateX(-0px) rotateY(612deg) rotateX(127.38deg); border-bottom-color: rgba(221, 73, 172, 0.4); }
.solid .side:nth-child( 9) { transform: translateY(361px) translateX(-0px) rotateY(684deg) rotateX(127.38deg); border-bottom-color: rgba(76, 15, 149, 0.496); }
.solid .side:nth-child(10) { transform: translateY(361px) translateX(-0px) rotateY(756deg) rotateX(127.38deg); border-bottom-color: rgba(4, 3, 3, 0.4); }

.solid .side:nth-child(11) { transform: translateY(86px) translateX(-0px) rotateY( 828deg) translateZ(170.1px) rotateX(-10.81deg); border-bottom-color: rgba(58, 97, 165, 0.4); }
.solid .side:nth-child(12) { transform: translateY(86px) translateX(-0px) rotateY( 900deg) translateZ(170.1px) rotateX(-10.81deg); border-bottom-color: rgba(137, 47, 157, 0.4); }
.solid .side:nth-child(13) { transform: translateY(86px) translateX(-0px) rotateY( 972deg) translateZ(170.1px) rotateX(-10.81deg); border-bottom-color: rgba(160, 147, 228, 0.4); }
.solid .side:nth-child(14) { transform: translateY(86px) translateX(-0px) rotateY(1044deg) translateZ(170.1px) rotateX(-10.81deg); border-bottom-color: rgba(131, 19, 192, 0.579); }
.solid .side:nth-child(15) { transform: translateY(86px) translateX(-0px) rotateY(1116deg) translateZ(170.1px) rotateX(-10.81deg); border-bottom-color: rgba(63, 27, 122, 0.4); }

.solid .side:nth-child(16) { transform: translateY(257px) translateX(-0px) rotateY(1152deg) rotateZ(180deg) translateZ(170.1px) rotateX(-10.81deg); border-bottom-color: rgba(95, 68, 164, 0.4); }
.solid .side:nth-child(17) { transform: translateY(257px) translateX(-0px) rotateY(1224deg) rotateZ(180deg) translateZ(170.1px) rotateX(-10.81deg); border-bottom-color: rgba(84, 12, 171, 0.654); }
.solid .side:nth-child(18) { transform: translateY(257px) translateX(-0px) rotateY(1296deg) rotateZ(180deg) translateZ(170.1px) rotateX(-10.81deg); border-bottom-color: rgba(222, 175, 255, 0.4); }
.solid .side:nth-child(19) { transform: translateY(257px) translateX(-0px) rotateY(1368deg) rotateZ(180deg) translateZ(170.1px) rotateX(-10.81deg); border-bottom-color: rgba(198, 6, 233, 0.4); }
.solid .side:nth-child(20) { transform: translateY(257px) translateX(-0px) rotateY(1440deg) rotateZ(180deg) translateZ(170.1px) rotateX(-10.81deg); border-bottom-color: rgba(198, 186, 219, 0.4); }

@keyframes spin {
	0% { transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
	100% { transform: rotateX(360deg) rotateY(720deg) rotateZ(1080deg); }
}
</style>