
export default {
	name: 'DataDictionaryValidation',
	props: {
		typ: Object,
		field: Object,
		k: String,
		value: [ String, Array, Object, Number ],
		prefix: String,
	},
}
