
import Dialog from './Dialog.vue'

export default {
	name: 'EnvironmentsMenu',
	components: { Dialog },
	inject: [ 'baseEndpoint', 'spaceId', 'environment', 'settings', 'endpoint', 'permissions' ],
	props: {
		value: Boolean, // main controlling property
	},
	data: () => ({
		spaces: [],
		displayEnvironments: false, // delayed controller for css attributes
		displayTimeout: null,
		renderEnvironments: false, // delayed controller for v-if (honouring animation times)
		renderTimeout: null,
	}),
	computed: {
		token() {
			return window.localStorage.token
		},
	},
	watch: {
		// TODO: this is pretty complicated.
		//       the main reason for this is that we dont want to render the elements when we dont need them.
		//       but the code here is not good..
		//       can we move this to a clean show-animator comp that is only handling this concern?
		value(n) {
			if (n) {
				window.clearTimeout(this.renderTimeout)
				window.clearTimeout(this.displayTimeout)
				this.renderEnvironments = true
				this.displayTimeout = window.setTimeout(() => {
					this.displayEnvironments = true
					this.load()
				}, 10)
			}
			else {
				window.clearTimeout(this.renderTimeout)
				window.clearTimeout(this.displayTimeout)
				this.displayEnvironments = false
				this.renderTimeout = window.setTimeout(() => {
					this.renderEnvironments = false
				}, 500)
			}
		},
	},
	methods: {
		async load() {
			try {
				const spaces = await this.$httpGet(this.settings.baseEndpoint + '/spaces')
				for (const space of spaces.items) {
					space.expanded = false
					space.environments = []
					if (space.sys.id == this.spaceId)
						await this.expand(space)
				}
				spaces.items.sort((a, b) => a.name.localeCompare(b.name))
				this.spaces = spaces.items
			}
			catch (e) {
				if (e.status == 401)
					this.$el.dispatchEvent(new CustomEvent('401', { bubbles: true, detail: e }))
			}
		},
		async loadEnvironments(space) {
			const environments = await this.$httpGet(this.settings.baseEndpoint + '/spaces/' + space.sys.id + '/environments')
			environments.items.sort((a, b) => {
				if (a.name == 'master') return -1
				if (b.name == 'master') return 1
				return a.name.localeCompare(b.name)
			})
			space.environments = environments.items
		},
		async expand(space) {
			try {
				space.expanded = !space.expanded
				if (!space.environments.length)
					await this.loadEnvironments(space)
			}
			catch (e) {
				if (e.status == 401)
					this.$el.dispatchEvent(new CustomEvent('401', { bubbles: true, detail: e }))
			}
		},
		async addSpace() {
			const name = prompt('New space name (letters, numbers, spaces, dashes)')
			if (!name) return
			console.log(name, name.match)
			if (!name.match(/^[a-zA-Z0-9\-_\.: ]+$/)) { alert(`Invalid space name '${ name }': only letters, numbers, spaces and dashes are allowed!`); return }

			await this.$httpPost(this.settings.baseEndpoint + '/spaces', {
				name,
				defaultLocale: 'de',
			})
			/*
			// TODO: this.endpoint contains /settings/undefined
			await this.$httpPost(this.endpoint + '/locales', {
				name: 'German',
				code: 'de',
				fallbackCode: null,
				default: true,
				contentManagementApi: true,
				contentDeliveryApi: true,
				optional: false,
			})
			*/
			await this.load()
		},
	},
	async mounted() {
		await this.load()
	},
}
