<template>
	<div class="JsonPropertiesField" :class="{ error }">
		<table>
			<tr v-for="(val, key) of value" :key="key">
				<th>{{ key }}</th>
				<td><input type="text" v-model="value[key]" :disabled="disabled" /></td>
			</tr>
		</table>
		<button @click="add" :disabled="disabled">&plus; Add Property</button>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

// TODO: fix data errors? show validation errors?
// TODO: validation: limit number of properties

export default {
	name: 'JsonPropertiesField',
	mixins: [ field ],
	props: {
		value: [ Object ],
	},
	data: () => ({
		model: null,
		autoModel: false,
	}),
	watch: {
		value(n) {
			this.onErrors([
				this.validateRequired(),
				this.validateObjectSize(),
			])
			this.model = JSON.stringify(n, null, 4)
		},
		model(n) {
			const errors = []
			if (n) {
				try {
					this.$emit('input', JSON.parse(n))
				}
				catch (e) {
					errors.push('This is not valid JSON')
				}
			}
			this.onErrors(errors)
		},
	},
	methods: {
		add() {
			const name = window.prompt('property name')
			if (!name) return
			this.value[name] = ''
			this.$forceUpdate()
		},
	},
	mounted() {
		this.model = JSON.stringify(this.value)
	},
}
</script>

<style scoped>
table, th, td { font-size: inherit; font-family: inherit; }
table { border-spacing: 0; }
th, td { padding: 0; padding-bottom: 5px; }
th { font-weight: normal; padding-right: 5px; text-align: left; }
td { text-align: right; }
input { width: 100px; }
button { width: 100%; }
</style>
