
export function getStatus(entry) {
	if (!entry || !entry.sys.updatedAt) return 'loading..'
	if (entry.sys.archivedAt) return 'archived'
	if (!entry.sys.publishedAt) return 'draft'
	if (entry.sys.updatedAt > entry.sys.publishedAt) return 'changed'
	return 'published'
}

export default {
	name: 'EntryStatus',
	inject: [ 'ScheduledActions' ],
	props: {
		locale: String,
		entry: Object,
		name: String,
		publishedName: { type: String, default: 'published' },
	},
	computed: {
		status() {
			if (this.name) return this.name
			// add recalc listeners
			this.entry.sys.version
			this.entry.sys.publishedAt
			const status = getStatus(this.entry)
			if (status == 'published')
				return this.publishedName
			return status
		},
		isScheduled() {
			if (!this.ScheduledActions) return false
			return !!this.ScheduledActions.scheduledActions_byId[this.entry.sys.id]
		},
		schedule() {
			if (!this.ScheduledActions) return
			const scheduledActions = this.ScheduledActions.scheduledActions_byId[this.entry.sys.id]
			if (!scheduledActions) return
			return scheduledActions.map((sa) => sa.scheduledFor.datetime + ': ' + sa.action
			).join('\n')
		},
	},
}
