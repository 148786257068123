// we use this mixin to centralise the provide logic for the common props of pages

export default {
	props: {
		me: Object,
		space: Object,
		tags: Array,
		locales: Array,
		uiConfig: Object,
		defaultLocale: String,
		fallbackLocale: String,
	},
	provide() {
		return {
			me: this.me,
			space: this.space,
			tags: this.tags,
			locales: this.locales,
			uiConfig: this.uiConfig,
			defaultLocale: this.defaultLocale,
			fallbackLocale: this.fallbackLocale,
		}
	},
}