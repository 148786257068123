<template>
	<div class="model">
		<Head>
			<div class="heading">
				<input type="text" v-model="filter.search" placeholder="Search Content Types" class="input search" />
			</div>
			<div class="actions">
				<mdi book-alphabet
					style="font-size: 35px; color: var(--color-text-light); corsor: pointer;"
					@click="view = view == '' ? 'DataDictionary' : ''"
				/>
				<button class="create" @click="$router.push(base + '/content_types/new')">Add Type</button>
			</div>
		</Head>
		<div class="body" style="margin: 20px;">
			<ModelTable v-if="view == ''"
				:filter="filter"
				@input="$router.push(base + '/content_types/' + $event[0].sys.id)"
			/>
			<DataDictionary v-if="view == 'DataDictionary'"
				:filter="filter"
			/>
		</div>
	</div>
</template>

<script>
import EntryFilter from '../components/fields/EntryFilter.vue'
import ModelTable from '../components/fields/ModelTable.vue'
import DataDictionary from './DataDictionary.vue'
import Head from './Head.vue'
import ProviderMixin from './ProviderMixin.js'

export default {
	name: 'Model',
	components: { EntryFilter, ModelTable, Head, DataDictionary },
	mixins: [ ProviderMixin ],
	inject: [ 'base' ],
	data: () => ({
		filter: { contentType: undefined, search: '', filters: [] },
		view: '',
	}),
	computed: {
		typeNames() {
			return Object.keys(window["typeLookup"])
		},
	},
	mounted() {
		// TODO-3: add name of space
		document.title = 'Content Model'
	},
}
</script>

<style scoped>
.head { display: flex; gap: 15px; padding: 10px 20px; border-bottom: 1px solid rgb(207, 217, 224); align-items: center;}
.search { margin-right: 10px; border: 1px solid #ddd; border-radius: 6px; width: 100%; }
.EntryFilter { flex-grow: 1; }
button.add { border: 1px solid #ddd; background: transparent; border-radius: 6px; cursor: pointer; font-size: 15px; font-weight: 500; padding: 10px 15px; }
button.create { white-space: nowrap; background-color: var(--primary); height: 40px; padding: 8px 15px; color: white; border: 0; border-radius: 6px; font-size: 14px; font-weight: 500; cursor: pointer; }
</style>