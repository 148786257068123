<template>
	<div class="DeeplTranslation">
		<div style="text-align: center;" v-if="!value?.length">
			<mdi translate crystal />
			<p>AI Translate will keep track of your changes and give you translation options.</p>
		</div>
		<div v-else class="items">
			<!-- ordering like it is on the left would be good.. -->
			<div v-for="item, i in value" :key="i" class="item"
				:class="{
					[ item.status ]: true,
				}"
			>
				<mdi close @click="value.splice(i, 1)" style="float: right; cursor: pointer;" />
				<div style="font-weight: 600;">{{ item.field.name }}</div>
				<div style="display: flex; gap: 5px; align-items: center; flex-wrap: wrap; margin-top: 5px;">
					<select v-model="item.sourceLocale" v-if="item.sourceLocales.length > 1" style="border: 0; background: transparent;">
						<option v-for="locale, l in item.sourceLocales" :key="l"
							:value="locale"
						>
							{{ locale }}
						</option>
					</select>
					<div v-else>{{ item.sourceLocale }}</div>
					<mdi chevron-right />
					<div v-for="locale in Object.keys(item.targetLocales)" :key="locale"
						style="display: flex; gap: 3px; align-items: center;"
					>
						<input type="checkbox" :id="'c-' + i + '-' + locale"
							:value="locale.code"
							:disabled="item.sourceLocale == locale"
							v-model="item.targetLocales[locale]"
						/>
						<!-- TODO: why is 'de' duplicate here? weirdness at import? -->
						<label :for="'c-' + i + '-' + locale"
							:disabled="item.sourceLocale == locale"
						>
							{{ locale }}
						</label>
					</div>
				</div>
				<!-- TODO: for RTE warn that images + links will be lost! -->
			</div>
			<div style="display: flex; gap: 10px; align-items: center;">
				<button @click="start" class="start" style="margin-top: 5px;">Translate</button>
				<!--<div>460.000 characters remaining</div>-->
			</div>
		</div>
		<button @click="$refs.translateOther.open()" class="start" style="margin-top: 10px;">Translate Other Text</button>
		<Dialog ref="translateOther">
			<div class="body" style="top: 0; display: flex; flex-direction: row; gap: 0; padding: 0px; border: 0; height: calc(100% - 0px); overflow: hidden;">
				<div style="flex: 1;">
					<textarea v-model="translateOtherText" style="border: 0; border-right: 1px solid #ddd; background: transparent; width: 100%; height: 100%; box-sizing: border-box; resize: none; padding: 15px;" placeholder="Write some text here.."></textarea>
				</div>
				<div style="flex: 1;">
					<div style="display: flex; gap: 10px; align-items: center; padding: 10px;">
						<select v-model="translateOtherSourceLocale" style="border: 0; background: transparent;">
							<option v-for="locale, l in locales" :key="l" :value="locale.code">{{ locale.name }}</option>
						</select>
						<mdi chevron-right />
						<select v-model="translateOtherTargetLocale" style="border: 0; background: transparent;">
							<option v-for="locale, l in locales" :key="l" :value="locale.code">{{ locale.name }}</option>
						</select>
						<ActionButton @click="translateOther" class="save">Translate</ActionButton>
					</div>
					<textarea v-model="translateOtherOutput" style="border: 0; border-right: 1px solid #ddd; background: transparent; width: 100%; height: calc(100% - 60px); box-sizing: border-box; resize: none; padding: 15px;"></textarea>
				</div>
			</div>
		</Dialog>
	</div>
</template>

<script>
import { documentToMarkdown } from 'contentful-rich-text-to-markdown'
import { richTextFromMarkdown } from '../../lib/rich-text-from-markdown/index'
import { deeplTranslate, startTranslationTracking, stopTranslationTracking } from './deepl.ts'
import ActionButton from '../ActionButton.vue'
import Dialog from '../Dialog.vue'

// TODO: the CF translate seems to have a max output length!
//       we might have to look for a different tool after all..

// TODO: for richtexts:
//       split rich text into main blocks
//       only translate the text blocks, skip the entry links
//       rejoin later - this will effectively also swap out any specific entries to the other language though..

// TODO: only if we actually have more than 1 locale
// TODO: filter out empty values?

export default {
	name: 'DeeplTranslation',
	components: { ActionButton, Dialog },
	inject: [ 'locales' ],
	props: {
		value: Array,
		entry: Object,
	},
	data: () => ({
		original: null,
		translateOtherText: '',
		translateOtherSourceLocale: 'de',
		translateOtherTargetLocale: 'en',
		translateOtherOutput: '',
	}),
	computed: {
		localeLookup() {
			const lookup = {}
			for (const locale of this.locales) lookup[locale.code] = locale
			return lookup
		},
	},
	methods: {
		async translateOther() {
			try {
				const r = await deeplTranslate(this.translateOtherText, this.translateOtherSourceLocale, this.translateOtherTargetLocale)
			}
			catch (e) {
				this.translateOtherOutput = 'Error translating - possibly unsupported language. ' + e.message
				return
			}
			console.log('r', r)
			this.translateOtherOutput = r
		},
		getText(fieldId, locale) {
			let value = this.entry.fields[fieldId][locale]
			if (value?.nodeType == 'document') {
				const md = documentToMarkdown(value)
				return md.content
			}
			return value
		},
		async setText(fieldId, locale, text) {
			let currentValue = this.entry.fields[fieldId][locale]
			// TODO: distinguish by field type instead!
			if (currentValue?.nodeType == 'document') {
				text = await richTextFromMarkdown(text)
				console.log('text', JSON.stringify(text))
			}
			this.entry.fields[fieldId][locale] = text
		},
		async translate(item, targetLocale) {
			// TODO: take current value from field instead of event?
			let value = item.value
			if (!value) return

			let text = this.getText(item.field.id, item.sourceLocale)
			text = await deeplTranslate(text, item.sourceLocale, targetLocale)

			const currentText = this.getText(item.field.id, targetLocale)
			if (currentText?.trim() == text?.trim()) {
				console.log('not updating', item.field.id, targetLocale, '(no change)')
				return
			}

			console.log('updating', item.field.id, targetLocale, text)
			this.setText(item.field.id, targetLocale, text)
		},
		async start() {
			stopTranslationTracking()
			for (const item of this.value) {
				for (const targetLocale of Object.keys(item.targetLocales)) {
					if (targetLocale == item.sourceLocale) continue
					if (!item.targetLocales[targetLocale]) continue
					console.log('translate', item.field.name, item.sourceLocale, '>', targetLocale)
					item.status = 'inprogress'
					try {
						await this.translate(item, targetLocale)
						item.status = 'done'
						// TODO: remove item
					}
					catch (e) {
						console.error('translation error', item, '->', targetLocale, e)
						item.status = 'error'
					}
				}
			}
			// TODO: sleep 1s
			// TODO: remove all items with status == done
			startTranslationTracking()
		},
	},
}
</script>

<style scoped>
.items { display: flex; flex-direction: column; gap: 5px; }
.item { padding: 10px; border: 1px solid #ddd; border-radius: 10px; background-color: white; }
.item.inprogress { border-left: 3px solid #f7e700; }
.item.done { border-left: 3px solid #00ba00; }
.item.error { border-left: 3px solid #d80000; }
button.start { font-size: 14px; background: white; color: black; padding: 8px 15px; border-radius: 6px; border: 1px solid #ddd; cursor: pointer; }
label[disabled] { opacity: 0.5; }
</style>