
import Info from '../views/Info.vue'

export default {
	name: 'ImporterMessageSidebar',
	components: { Info },
	props: {
		entryId: String,
	},
	computed: {
		source() {
			const m = this.entryId.match(/^([A-Z]{2,10})[_-]/)
			let r = m?.[1]
			if (r == 'MYS') r = 'MyServices'
			return r
		},
	},
}
