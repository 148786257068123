
import Menu from './Menu.vue'
import EnvironmentsMenu from './EnvironmentsMenu.vue'

export default {
	name: 'MainMenu',
	components: { Menu, EnvironmentsMenu },
	inject: [ 'environment', 'base', 'permissions' ],
	props: {
		space: Object,
		locales: Array,
		defaultLocale: String,
		me: Object,
		uiConfig: Object,
	},
	data: () => ({
		showEnvironments: false,
	}),
	methods: {
		logout() {
			console.log('user log out')
			window.localStorage.removeItem('token')
			this.$el.dispatchEvent(new CustomEvent('401', { bubbles: true, detail: { byUser: true } }))
		},
	},
	mounted() {
		this.showEnvironments = !this.$route.params.spaceId && this.$route.path.indexOf('/session') != 0
	},
}
