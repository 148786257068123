<template>
	<div class='TagsField'>
		<input type="text" class="input" v-model="current"
			placeholder="Type the value and hit enter"
			@keypress="keypress"
			v-if="!validations.in && !validations.inLinks"
			:disabled="disabled"
		/>

		<div style="position: relative;" v-if="validations.in || validations.inLinks">
			<!-- when we have an in validation we show a select -->
			<select class="input" v-model="current" @change="keypress({ code: 'Enter' })" v-if="validations.in">
				<option v-for="option of validations.in" :key="option" :value="option"
					:disabled="value && value.includes(option)"
				>{{ option }}</option>
			</select>

			<!-- links mode for system tags -->
			<select class="input" v-model="current" @change="keypress({ code: 'Enter' })" v-if="validations.inLinks">
				<option v-for="option of validations.inLinks" :key="option.sys.id" :value="option"
					:disabled="value && value.find(val => val.sys.id == option.sys.id)"
				>{{ getLabel(option) }}</option>
			</select>
			<div style="position: absolute; top: 12px; left: 10px; pointer-events: none; color: #ccc; font-size: 14px;">add&hellip;</div>
		</div>

		<div v-if="validationError" class="fieldError">
			{{ validationError }}
		</div>
		<div v-if="current" class="fieldWarning">
			You have entered content that will not be saved. Press enter to add the tag!
		</div>
		<div class="tags" v-if="value">
			<span class="tag" v-for="(val, v) of value" :key="val?.sys?.id ?? val"
				style="margin-right: 10px"
				:draggable="sortable"
				@dragstart="dragstart($event, val, v)"
				@dragover="dragover($event, val, v)"
				@dragend="dragend($event, val, v)"
				ondragstart="event.cancelBubble = true; event.target.style.opacity = '0.6';"
				ondragover="event.preventDefault(); event.cancelBubble = true;"
				ondragend="this.style.opacity = '1';"
				ondrop="event.cancelBubble = true;"
			>
				<span class="drag" v-if="sortable">
					<mdi drag />
				</span>
				{{ getLabel(val) }}
				<span class="close" v-if="!disabled" @click="deleteAt(v, val)">
					<mdi close />
					<span v-if="control?.separator" class="separator">{{ control?.separator }}</span>
				</span>
			</span>
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'TagsField',
	mixins: [ field ],
	inject: [ 'tags' ],
	props: {
		locale: String,
		value: Array,
		def: Object,
		control: Object,
	},
	data: () => ({
		current: '',
		dragged: null,
		draggedIndex: null,
		autoModel: false,
	}),
	computed: {
		// TODO: reimplement validation
		// TODO: validation: in, size min/max, pattern, ...
		// TODO: inLinks is similar to in. we need to also support this mode in the code below
		allowedValues() {
			return this.def?.items?.validations?.[0]?.in
		},
		validationError() {
			if (this.current !== '' && this.current != null
				&& this.allowedValues
				&& this.allowedValues.indexOf(this.current) < 0)
				return 'Value must be one of: ' + this.allowedValues.join(', ')

			return null
		},
		sortable() {
			return this.control?.sortable === undefined || this.control?.sortable == true
		},
		isSystemTags() {
			return this.def?.name == 'Tags' && this.def?.scope == 'metadata'
		},
	},
	watch: {
		value(n) {
			// TODO: should we do the validation at the server? CF seems to do that.
			this.onErrors([
				this.validateRequired(),
				this.validateArrayMax(),
				this.validateArrayMin(),
				// TODO: how should the regex be validated? do we need an "arrayRegex"?
				//this.validateRegexp(),
				//this.validateProhibitRegexp(),
				this.validateArrayIn(),
				this.validateArrayInLinks(),
			])
		},
	},
	methods: {
		getLabel(val) {
			if (this.isSystemTags) {
				if (val?.sys?.id) val = val.sys.id
				if (!this.tags) return val
				const tag = this.tags.find(tag => tag.sys.id == val)
				if (tag) return tag.name
			}
			return val
		},
		keypress(event) {
			if (event.code == 'Enter') {
				if (this.validationError) return
				this.value.splice(1000, 0, this.current)
				this.current = ''
			}
		},
		deleteAt(v, val) {
			if (!this.validations.in && !this.validations.inLinks)
				this.current = val
			this.value.splice(v, 1)
		},
		dragstart(event, item, i) {
			this.dragged = item
			this.draggedIndex = i
		},
		dragover(event, item, i) {
			if (!this.dragged) return
			if (i == this.draggedIndex) return
			// remove dragged item at its position
			this.value.splice(this.draggedIndex, 1)
			// insert the dragged item 
			this.value.splice(i, 0, this.dragged)
			this.draggedIndex = i
		},
		dragend(event, item, i) {
			this.dragged = null
		},
	},
	mounted() {
		// ensure that we have an array on the model when starting
		this.$nextTick(() => {
			if (!this.value)
				this.$emit('input', [])
		})
	},
}
</script>

<style scoped>
.tags { padding: 10px 0; margin: 10px 0; }
.tag { background: rgb(231, 235, 238); border-radius: 4px; padding: 8px; margin-bottom: 5px; color: rgb(65, 77, 99); display: inline-flex; }
.tag svg { font-size: 18px; fill: currentColor; }
.tag .close { border-left: 1px solid silver; margin: -8px -8px -8px 8px; padding: 8px; cursor: pointer; }
.tag .close:hover { background-color: rgb(207, 217, 224); }
.tag .drag { margin: -10px; padding: 10px; color: rgb(103, 114, 138); cursor: grab; }

.fieldWarning { color: darkorange; }
.fieldError { color: red; }

.separator { background: black; font-size: x-small; color: white; padding: 1px 3px; font-weight: bold; position: absolute; rotate: -90deg; margin-top: 2px; margin-left: -1px; }
.tag:last-child .separator { display: none; }
</style>