
import MyServicesBurgerMenu from '../components/MyServicesBurgerMenu.vue'

export default {
	components: { MyServicesBurgerMenu },
	name: 'Head',
	props: {
		hasBack: { type: Boolean, default: false },
	},
}
