// To avoid running into rate limits we bulk requests for Entries we offer an API
// to load individual entries, but this loader will join them up into multi-calls.
// a loadFunction(ids: string[]) must be passed with the contructor,
// it will be called 200ms after the first load() call with all ids requested in the meantime.

// This loader waits for some time before making an api request to load an entry
// in case more requests are coming in. Finally it makes a bulk request for all
// required entries.
export default class BulkLoader {
	public loadFunction: Function

	constructor(loadFunction: Function) {
		this.loadFunction = loadFunction
	}

	async sleep(ms: number) {
		return new Promise(r => setTimeout(r, ms))
	}

	// we synchronize all calls on this promise
	private queuePromise: undefined | Promise<{ [index: string]: any }>
	public queuedIds: string[] = []

	// the user should call this method to enqueue requests
	async load(id: string, autoStart: boolean = true) {
		this.queuedIds.push(id)
		if (!autoStart) return
		if (!this.queuePromise)
			this.queuePromise = this.runLoad()
		const lookup = await this.queuePromise
		return lookup[id]
	}

	// this function actually performs the load at the service
	async runLoad() {
		// we wait some time before starting the request, so some ids can be collected
		await this.sleep(200)

		const ids = this.queuedIds

		// by unsetting we enable filling the next queue
		this.queuedIds = [] as string[]
		this.queuePromise = undefined

		const lookup: any = {}
		const chunkSize = 50
		for (let i = 0; i < ids.length; i += chunkSize) {
			const chunk = ids.slice(i, i + chunkSize)
			// TODO: we could do this in parallel - is that ok for the server?
			const items = await this.loadFunction(chunk)
			for (const item of items) {
				// TODO: this requires a certain item structure - actually thats the only point..
				lookup[item.sys.id] = item
			}
		}
		return lookup
	}
}
