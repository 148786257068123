<template>
	<div class="AssetMultiUpload">
		<center>
			<span v-show="!files.length" class="fileInputWrap">
				<button>Select Files&hellip;</button>
				<input type="file" multiple ref="picker" style="opacity: 0; font-size: 20px;" />
			</span>
		</center>
		<div class="files">
			<div v-for="file, f in files" :key="f" class="file" ref="file"
				:class="{
					progress: file.progress !== null && file.progress !== 100 && file.progress !== -1,
					done: file.progress == 100,
					error: file.progress == -1,
				}"
			>
				<div class="imageWrap">
					<img :src="file.url" :alt="file.name" />
				</div>
				<div style="flex-grow: 1; display: flex; gap: 10px; align-items: end;">
					<div class="scalingMultilineInput" style="flex-grow: 1;">
						<textarea v-model="file.name" :disabled="file.progress !== null" />
						<div>{{ file.name }}</div>
					</div>
					<mdi close v-if="file.progress == null" @click="files.splice(f, 1)" />
					<mdi progress-upload v-if="file.progress != null && file.progress != 100 && file.progress != -1" />
					<mdi check v-if="file.progress != null && file.progress == 100" />
					<mdi alert-circle-outline v-if="file.progress == -1" :title="file.message" />
				</div>
			</div>
		</div>
		<div class="buttons" style="margin-top: 20px;">
			<ActionButton class="save" @click="upload" v-if="files.length" :disabled="finished">
				Upload {{ files.length }} files
			</ActionButton>
		</div>
	</div>
</template>

<script>
import ActionButton from './ActionButton.vue'
import EntryApiMixin from './EntryApiMixin.js'

// TODO: add progress bar?

async function sleep(ms) { return new Promise((resolve) => setTimeout(resolve, ms)) }

export default {
	components: { ActionButton },
	mixins: [ EntryApiMixin ],
	inject: [ 'settings', 'defaultLocale', 'endpoint' ],
	data: () => ({
		files: [],
		finished: false,
	}),
	methods: {
		onChange(e) {
			const fileList = this.$refs.picker.files

			this.files = Array.from(fileList).map((f) => ({
				fileName: f.name,
				name: f.name.replace(/\.\w+$/, ''),
				size: f.size,
				type: f.type,
				url: URL.createObjectURL(f),
				progress: null,
				file: f,
				message: null,
			}))
		},
		async processFile(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.onload = async (event) => {
					console.log(event)
					file.progress = 0

					let content = event.target.result
					try {
						const upload = await this.$httpPost(this.settings.uploadEndpoint, content, { headers: { 'as-use': false } })
						file.progress = 66
						upload.sys.space = undefined
						let asset = await this.$httpPost(this.endpoint + '/assets', this.newAsset({
							fields: {
								file: {
									[ this.defaultLocale ]: {
										contentType: file.type,
										fileName: file.fileName,
										uploadFrom: upload,
									}
								},
								title: {
									[ this.defaultLocale ]: file.name,
								},
							},
						}), {
							headers: {
								'content-type': 'application/vnd.contentful.management.v1+json',
							},
						})
						file.progress = 90
						/*asset = await this.$httpPut(this.endpoint + '/assets/' + asset.sys.id + '/published', null, {
							headers: {
								'X-Contentful-Version': asset.sys.version,
							},
						})*/
						file.progress = 100
						resolve(asset)
					}
					catch (e) {
						console.error('Failed to upload', e)
						file.message = e.message
						file.progress = -1
						resolve(undefined)
					}
				}
				reader.readAsArrayBuffer(file.file)
			})
			
		},
		async upload() {
			let f = 0
			for (const file of this.files) {
				this.$refs.file[f]?.scrollIntoView?.({ behavior: 'smooth', block: 'center' })
				await this.processFile(file)
				f++
			}
			this.finished = true
			this.$emit('done')
		},
	},
	mounted() {
		this.$refs.picker.addEventListener('change', this.onChange)
	},
	beforeDestroy() {
		this.$refs.picker.removeEventListener('change', this.onChange)
	},
}
</script>

<style scoped>
.fileInputWrap { display: inline-block; position: relative; border-radius: 5px; overflow: hidden; margin: 100px auto; }
.fileInputWrap button { position: absolute; inset: 0; pointer-events: none; background: var(--color-blue-mid); color: white; border: none; }

.files { display: flex; gap: 20px; flex-wrap: wrap; }

.file { display: flex; flex-direction: column; gap: 5px; width: 150px; padding: 10px; border: 1px solid #ddd; border-radius: 5px; box-shadow: 1px 1px 10px rgba(0,0,0,0.1); outline-offset: -1px; }
.file.progress { outline: 2px solid #ffd000;}
.file.done { outline: 2px solid var(--color-green-mid); }
.file.error { outline: 2px solid var(--color-red-mid); }

.imageWrap { width: 150px; height: 100px; display: flex; align-items: center; background: #eee; outline: 1px solid #ddd; box-shadow: inset 1px 1px 10px rgba(0,0,0,0.075); }
.imageWrap img { max-width: 100%; max-height: 100%; margin: auto; box-shadow: 1px 1px 10px rgba(0,0,0,0.075); }

.scalingMultilineInput { position: relative; font-size: 12px; word-break: break-all; min-height: 10px; }
.scalingMultilineInput * { font-size: inherit; font-weight: inherit; word-break: inherit; padding: 0; margin: 0; border: 0; font-family: inherit; }
.scalingMultilineInput textarea { position: absolute; inset: 0; resize: none; }

.file .mdi { font-size: 20px; }
.file.progress .mdi { color: #d6b41a; }
.file.done .mdi { color: var(--color-green-mid); }
.file.error .mdi { color: var(--color-red-mid); }
</style>