<template>
	<div class="JsonWgs84PolygonField" :class="{ error }">
		<GoogleMap
			v-if="value"
			:editPolyline="value.points"
			style="height: calc(100vh - 200px); max-height: 500px;"
		/>
	</div>
</template>

<script>
import GoogleMap from '../GoogleMap.vue'
import { field } from './FieldMixin.js'

/* {
	"format": "WGS84",
	"points": "8.687244,46.768947,769 8.687344,46.768933,770..."
} */

// TODO: this is currently display-only
// TODO: how to start the path on an empty state?
//       input for from and to?
//       geocode each and draw a line?
//       use routing to get the path?
// TODO: support multiple shapes? configurable in field def?
// TODO: emit changed event
// TODO: make all JsonX fields switchable to the JSON representation?
//       give all some common superclass or common comp/mixin?
// TODO: be lenient on all JsonX fields: do not destroy incompatible data
//       -> show a warning + allow to edit the pure JSON
// TODO: in a pure JSON field: format detector, show info:
//       this seems to be WGS84 data, there is a dedicated field editor for that
//       even allow switching on-the-fly?

export default {
	components: { GoogleMap },
	name: 'JsonWgs84PolygonField',
	mixins: [ field ],
	props: {
		value: [ Object, Array ],
	},
	data: () => ({
		autoModel: false,
		rows: 0,
		columns: 0,
	}),
/*	watch: {
		// TODO: emit changes
		value(n) {
			this.rows = this.value?.tableData?.length ?? 4
			this.columns = this.value?.tableData?.[0]?.length ?? 2
			this.onErrors([
				this.validateRequired(),
			])
		},
		rows(n, o) {
			if (n == o) return
			this.$emit('input', this.newModel())
		},
		columns(n, o) {
			if (n == o) return
			this.$emit('input', this.newModel())
		},
	},*/
	methods: {
/*		newModel() {
			const a = []
			for (let r = 0; r < this.rows; r++) {
				const row = []
				for (let c = 0; c < this.columns; c++) {
					const defaultValue = r == 0 ? `Column ${ c + 1 }` : ''
					row.push(this.value?.tableData?.[r]?.[c] ?? defaultValue)
				}
				a.push(row)
			}
			return { tableData: a }
		},*/
		validate() {
			this.onErrors([
				this.validateRequired(),
			])
		},
	},
	mounted() {
/*		this.rows = this.value?.tableData?.length ?? 4
		this.columns = this.value?.tableData?.[0]?.length ?? 2
		if (!this.value) {
			const a = Array(this.rows).fill(Array(this.columns).fill(''))
			this.$emit('input', { tableData: a })
		}*/
	},
}
</script>

<style scoped>
</style>
