<template>
	<div class="sidebar">
		<slot />
	</div>
</template>

<script>
// ATT: to be included within a display: flex container!
//      left comp should be flex: 1 1 0

export default {
	name: 'Sidebar',
}
</script>

<style scoped>
.XXXSidebar { position: absolute; width: 315px; right: 0; top: 0; bottom: 0; background: yellow; padding: 25px; background-color: rgb(247, 249, 250); border-left: 1px solid rgb(231, 235, 238); }

.sidebar { width: 360px; background-color: rgb(250 250 250); border-left: 1px solid #cfd9e0; }
.sidebar .tabs > span { padding: 18px; }
.sidebar .content { padding: 15px; display: flex; flex-direction: column; gap: 30px; }
.sidebar .status button {
	background-color: rgb(0, 133, 57);
	color: white;
	border: 1px solid rgb(0, 133, 57);
	box-shadow: rgb(25 37 50 / 8%) 0px 1px 0px;
	border-radius: 6px;
	cursor: pointer;
	display: flex;
	width: 100%;
	overflow: hidden;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	font-family: var(--font-stack-primary);
	font-weight: 500;
	text-decoration: none;
	transition: background 0.1s ease-in-out 0s, opacity 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
	font-size: 0.875rem;
	padding: 10px 30px;
}
.sidebar .status button svg { font-size: 17px; margin-left: 3px; }
.sidebar > .content > div { display: flex; flex-direction: column; gap: 13px; color: rgb(90, 101, 124); margin-top: 5px; }

/* TODO: move to App */
.sidebar h2 { font-size: 0.75rem; font-weight: 500; text-transform: uppercase; color: rgb(90, 101, 124); letter-spacing: 1px; font-family: var(--font-stack-primary); /*border-bottom: 1px solid silver; padding: 0 0 5px 0;*/ margin: 0 0 5px 0; }

.sidebar .section { margin: 25px; font-weight: 400; color: rgb(27, 39, 58); font-size: 0.875rem; line-height: 1.25rem; }
.sidebar .section p { margin: 10px 0 15px; }
</style>
