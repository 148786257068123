<template>
	<div style="width: 200px; height: 200px; background: transparent; border-radius: 100px;"
		:style="{ '--spin': spin }"
		:class="{ opaque }"
	>
		<div class="solid">
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
			<div class="side"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SpinnerOct',
	props: {
		opaque: { type: Boolean, default: false },
	},
	computed: {
		spin() {
			const y = Math.round(Math.random() * 2000) - 1000
			const z = Math.round(Math.random() * 3000) - 1500
			return 'rotateX(360deg) rotateY(' + y + 'deg) rotateZ(' + z + 'deg)'
		},
	},
}
</script>


<style scoped>
.solid { position: relative; width: 200px; height: 200px; scale: 0.5; animation: spin 20s infinite linear; transform-style: preserve-3d; }
.solid .side { position: absolute; left: 0; bottom: 50%; border-bottom: 173px solid black; border-left: 100px solid transparent; border-right: 100px solid transparent; transform-origin: 50% 100%; opacity: 0.4; }
.opaque .solid .side { opacity: 1; }

.solid .side:nth-child( 1) { transform: rotateY( 90deg) translateZ(100px) rotateX( 35.27deg); border-bottom-color: rgba(168, 28, 215); }
.solid .side:nth-child( 2) { transform: rotateY(180deg) translateZ(100px) rotateX( 35.27deg); border-bottom-color: rgba(79, 48, 183); }
.solid .side:nth-child( 3) { transform: rotateY(270deg) translateZ(100px) rotateX( 35.27deg); border-bottom-color: rgba(228, 31, 205); }
.solid .side:nth-child( 4) { transform: rotateY(360deg) translateZ(100px) rotateX( 35.27deg); border-bottom-color: rgba(222, 175, 255); }
.solid .side:nth-child( 5) { transform: rotateY(450deg) translateZ(100px) rotateX(144.73deg); border-bottom-color: rgba(6, 27, 148); }
.solid .side:nth-child( 6) { transform: rotateY(540deg) translateZ(100px) rotateX(144.73deg); border-bottom-color: rgba(239, 1, 101); }
.solid .side:nth-child( 7) { transform: rotateY(630deg) translateZ(100px) rotateX(144.73deg); border-bottom-color: rgba(219, 112, 252); }
.solid .side:nth-child( 8) { transform: rotateY(720deg) translateZ(100px) rotateX(144.73deg); border-bottom-color: rgba(160, 147, 228); }

@keyframes spin {
	0% { transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
	100% { transform: var(--spin); }
}
</style>