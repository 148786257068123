<template>
	<div class="block" :class="type">
		<mdi v-if="type == 'alert'" alert />
		<mdi v-else information-slab-circle />
		<slot />
	</div>
</template>

<script>

export default {
	name: 'Info',
	props: {
		type: String,
	},
}
</script>

<style scoped>
.block { position: relative; padding: 1rem; padding-left: 3rem; border-radius: 6px; border: 1px solid rgb(152, 203, 255); background-color: rgb(232, 245, 255); margin-top: 1rem; color: rgb(27, 39, 58); line-height: 150%; }
.mdi { position: absolute; left: 1rem; color: rgb(0, 89, 200); fill: currentColor; font-size: 18px; }

.error,
.alert { border-color: var(--color-red-mid); background-color: var(--color-red-lightest); }
.error .mdi,
.alert .mdi { color: var(--color-red-mid); }

.warning,
.warn { border-color: rgb(255, 217, 0); background-color: rgb(255, 248, 204); }
.warning .mdi,
.warn .mdi { color: rgb(255, 191, 0); }
</style>