import { httpFetch } from '../EntryApi'

// TODO: does this really have any value as mixin?
//       i think we should rather just import the EntryApi

export function buildQueryUrl(url, query) {
	if (!query) query = {}
	for (const i in query) if (query[i] === undefined || query[i] === null) delete query[i]
	url += url.indexOf('?') < 0 ? '?' : '&'
	return url + new URLSearchParams(query).toString()
}

const HttpUtil = {
	install(Vue, options) {
		Vue.prototype.$httpFetch = async function(method, url, body, options) {
			return await httpFetch(method, url, body, options)
		}
		Vue.prototype.$httpGet = async function(url, query) {
			return await httpFetch('GET', buildQueryUrl(url, query))
		}
		Vue.prototype.$httpPut = async function(url, object, options) {
			return await httpFetch('PUT', url, object, options)
		}
		Vue.prototype.$httpPost = async function(url, object, options) {
			return await httpFetch('POST', url, object, options)
		}
		Vue.prototype.$httpDelete = async function(url, options) {
			return await httpFetch('DELETE', url, undefined, options)
		}
	},
}

export default HttpUtil