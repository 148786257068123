<template>
	<div class="media">
		<!--
			v-model="editor.entry"
			-->
		<AssetEditor class="Editor"
			:entryId="$route.params.id"
			@close="$router.push(base + '/assets')"
		/>
	</div>
</template>

<script>
import AssetEditor from '../components/AssetEditor.vue'
import ProviderMixin from './ProviderMixin.js'

export default {
	name: 'MediaEdit',
	components: { AssetEditor },
	mixins: [ ProviderMixin ],
	inject: [ 'base' ],
}
</script>
