
import SystemTags from '../components/fields/SystemTags.vue'
import ContentTypesComboMulti from '../components/fields/ContentTypesComboMulti.vue'
import FieldWrapper from '../components/fields/FieldWrapper.vue'
import ActionButton from '../components/ActionButton.vue'
import { parseIam, stringifyIam, relations, buildRightsTreeIam } from '../permission-utils'
import Menu from '../components/Menu.vue'
import ProviderMixin from './ProviderMixin'
import TemplateBasedList from '../components/TemplateBasedList.vue'
import RoleConditionEditor from '../components/RoleConditionEditor.vue'

function notab(s) {
	return s.replace(/\t/g, '').replace(/^\n/, '').trim()
}

// Format Docs
// https://chillimetrix.alturos.com/confluence/pages/viewpage.action?pageId=249262406

export default {
	name: 'RoleEditIam',
	mixins: [ ProviderMixin ],
	components: { SystemTags, ContentTypesComboMulti, FieldWrapper, ActionButton, Menu, TemplateBasedList, RoleConditionEditor },
	inject: [ 'spaceId', 'environment' ],
	props: {
		role: Object,
	},
	data: () => ({
		mock: {
			"version": "2024-08-11",
			"statement": [
				/*
				{
					"effect": "allow",
					"action": ["ch:useFeature"],
					"resource": ["*"],
					"condition": {
						"stringEquals": {"ch:feature/name": ["entry","base"]}
					},
				},
				*/
				{
					"effect": "deny",
					"action": ["ch:upsertEntry"],
					"resource": ["*"],
					"condition": {
						"stringEquals": {"ch:entry/contentType": ["locationTest","numberTest"]},
					},
				},
				{
					"effect": "allow",
					"action": ["ch:upsertEntry"],
					"resource": ["*"],
					"condition": {
						"listContains": {
							"ch:entry/tags": [ "hugo", "test" ],
						},
					},
				},
				{ // ATT: this is an admin right
					"effect": "allow",
					"action": ["*"],
					"resource": ["*"],
				},
				{
					"effect": "allow",
					"action": ["ch:upsertEntry"],
					"resource": ["ch:bali1333-master/entries/OUTDOORACTIVE-1234567890"],
				},
				{
					"effect": "allow",
					"action": ["ch:tagEntry"],
					"resource": ["hugo"]
				}
			]
		},
		statementTemplates: {
			Allow: { effect: 'allow', action: [], resource: [ '*' ], condition: [] },
			Deny: { effect: 'deny', action: [], resource: [ '*' ], condition: [] },
			// TODO: how to replace this?
//			'Allow Features': { effect: 'allow', action: [ 'ch:useFeature' ], resource: [ '*' ], condition: [ { type: 'stringEquals', key: 'ch:feature/name', param: [ 'role', 'entry', 'tags', 'bulk' ] } ] },
			'Allow Content Types': { effect: 'allow', action: [ 'ch:entry:read', 'ch:entry:upsert', 'ch:entry:delete', 'ch:entry:publish' ], resource: [ '*' ], condition: [ { type: 'stringEquals', key: 'ch:entry/contentType', param: [] } ] },
			'Deny Content Types': { effect: 'deny', action: [ 'ch:entry:read', 'ch:entry:upsert', 'ch:entry:delete', 'ch:entry:publish' ], resource: [ '*' ], condition: [ { type: 'stringEquals', key: 'ch:entry/contentType', param: [] } ] },
		},
		id: null,
		policy: null,
		relations,
		update: 0,
	}),
	computed: {
		policyString() {
			this.update
			return stringifyIam(this.policy)
		},
		buildRightsTree() {
			this.update
			if (!this.policy) return null
			return buildRightsTreeIam(this.policy.statement)
		},
	},
	watch: {
		policies: {
			handler() { this.update++ },
			deep: true,
		},
	},
	methods: {
		addPermission(relation) {
			//this.policies.push(JSON.parse(JSON.stringify(relation)))
		},
		afterLoad() {
			this.policy = parseIam(this.role.policy)
		},
		beforeSave() {
			//this.role.policies = stringifyPolicies(this.policies, this.role.policies)
		},
		// TODO: move this mapping responsibility to RoleConditionEditor?
		addResourceHandler(name, value, handlerContext) {
			const prefix = 'ch:' + this.spaceId + '-' + this.environment
			const r = prompt(notab(`
				Enter a resource name.
				Place a * at the end of your resource name to wildcard it:
				- * matches all resources
				- ${ prefix }/entries/\* matches any entry or asset
				- ${ prefix }/entries/MYS-* matches any MyServices entry or asset
			`), prefix + '/entries/\*')
			if (!r) return
			return r
		},
	},
	mounted() {
		this.afterLoad()
	},
}
