<template>
	<Dialog ref="create" v-if="newField">
		<h1>Add field</h1>
		<div class="body" style="padding: 15px 25px; font-size: 14px; font-weight: normal;">
			<div class="row">
				<div>
					<label for="name">Name (required)</label>
					<input id="name" class="field" type="text" v-model="name" />
				</div>
				<div>
					<label for="id">Field ID</label>
					<input id="id" class="field" type="text" v-model="id" />
				</div>
			</div>
			<div class="types" v-if="selectedType == null">
				<div v-for="type of types" :key="type.id" :class="type.id"
					@click="selectedType = type"
				>
					<mdi :icon="type.mdi" crystal style="float: right;" />
					<h2>{{ type.title }}</h2>
					{{ type.text }}
				</div>
			</div>
			<div v-else class="fields">
				<div v-if="selectedType.id == 'Symbol'" class="radioRow">
					<input type="radio" v-model="textType" name="textType" value="short" id="short" />
					<label for="short">
						Short text, exact search
						<p>Enables sorting, 256 characters max.<br>Use this for titles, names, tags, URLs, e-mail addresses</p>
					</label>
					<input type="radio" v-model="textType" name="textType" value="long" id="long" />
					<label for="long">
						Long text, full text search
						<p>No sorting, 50k characters max.<br>Use this for descriptions, text paragraphs, articles</p>
					</label>
				</div>
				<div v-if="selectedType.id == 'Symbol' && textType == 'short'" class="radioRow">
					<input type="checkbox" v-model="isArray" id="list" />
					<label for="list">
						List
						<p>Select this if there is more than one value to store, like several names or a list of ingredients.<br>API response will include a separate block for each field.</p>
					</label>
				</div>
				<div v-if="selectedType.id == 'Number'" class="radioRow">
					<input type="radio" v-model="numberType" name="numberType" value="integer" id="integer" />
					<label for="integer">
						Integer
						<p>1, 2, 3, 5, 8, 13, …</p>
					</label>
					<input type="radio" v-model="numberType" name="numberType" value="decimal" id="decimal" />
					<label for="decimal">
						Decimal
						<p>3.14159265389</p>
					</label>
				</div>
				<div v-if="selectedType.id == 'media'" class="radioRow">
					<input type="radio" v-model="isArray" name="isArray" :value="false" id="single" />
					<label for="single">
						One file
						<p>For example, a single photo or one PDF file.</p>
					</label>
					<input type="radio" v-model="isArray" name="isArray" :value="true" id="multi" />
					<label for="multi">
						Multiple files
						<p>For example several photos, PDF files, etc. API response will include a separate block for each field.</p>
					</label>
				</div>
				<div v-if="selectedType.id == 'reference'" class="radioRow">
					<input type="radio" v-model="isArray" name="isArray" :value="false" id="single" />
					<label for="single">
						One entry
						<p>For example, a blog post can reference its only author.</p>
					</label>
					<input type="radio" v-model="isArray" name="isArray" :value="true" id="multi" />
					<label for="multi">
						Multiple entries
						<p>For example, a blog post can reference its several authors. API response will include a separate block for each field.</p>
					</label>
				</div>
			</div>
		</div>
		<div class="buttons">
			<div v-if="selectedType">
				<ActionButton @click="selectedType = null" class="link">Change type</ActionButton>
			</div>
			<ActionButton class="neutral" @click="confirm" :disabled="!valid" style="text-transform: capitalize;">Add {{ effectiveTypeName ?? '' }} Field</ActionButton>
		</div>
	</Dialog>
</template>

<script>
import Dialog from '../Dialog.vue'
import ActionButton from '../ActionButton.vue'
// TODO: validate uniqueness of name and id

export default {
	name: 'FieldSettingsDialog',
	components: { Dialog, ActionButton },
	props: {},
	data: () => ({
		id: '',
		name: '',
		newField: {},
		selectedType: null,
		textType: null,
		numberType: null,
		isArray: false,
		// all types as an array to build the ui from
		types: [
			{ id: 'Symbol', icon: '📝', mdi: 'text', title: 'Text', text: 'Titles, names, paragraphs, list of names' },
			{ id: 'RichText', icon: '🤑', mdi: 'format-float-center', title: 'Rich Text', text: 'Text formatting with references and media' },
			{ id: 'Number', icon: '🔢', mdi: 'numeric', title: 'Number', text: 'ID, order number, rating, quantity' },
			{ id: 'Date', icon: '📅', mdi: 'calendar-blank', title: 'Date and time', text: 'Event dates' },
			{ id: 'Location', icon: '📍', mdi: 'map-marker', title: 'Location', text: 'Coordinates: latitude and longitude' },
			{ id: 'media', icon: '🖼️', mdi: 'image', title: 'Media', text: 'Images, videos, PDFs and other files' },
			{ id: 'Boolean', icon: '☯️', mdi: 'toggle-switch-outline', title: 'Boolean', text: 'Yes or no, 1 or 0, true or false' },
			{ id: 'Object', icon: '🤖', mdi: 'code-json', title: 'JSON object', text: 'Data in JSON format' },
			{ id: 'reference', icon: '🔗', mdi: 'link', title: 'Reference', text: 'For example, a blog post can reference its author(s)' },
		],
	}),
	watch: {
		name(n, o) {
			const oid = this.camelCase(o)
			if (this.id == oid) this.id = this.camelCase(n)
			if (n == '') this.id = ''
		},
		id() {
			this.id = this.id.replace(/[^a-zA-Z0-9_]/g, '')
		},
	},
	computed: {
		valid() {
			return this.name != '' && this.id != '' && this.selectedType != null
		},
		effectiveTypeName() {
			let type = this.selectedType?.id
			if (type == 'Symbol' && this.textType == 'long') return 'Text'
			return type
		},
		fieldDef() {
			let type = this.selectedType.id
			let linkType
			let items
			if (type == 'media') {
				type = 'Link'
				linkType = 'Asset'
			}
			if (type == 'reference') {
				type = 'Link'
				linkType = 'Entry'
			}
			if (type == 'Number' && this.numberType == 'integer') {
				type = 'Integer'
			}
			if (type == 'Number' && this.numberType == 'decimal') {
				type = 'Number'
			}
			// TODO: decimal type??
			if (type == 'Symbol' && this.textType == 'short' && this.isArray) {
				type = 'Array'
				items = { type: 'Symbol', validations: [] }
			}
			if (type == 'Link' && this.isArray) {
				type = 'Array'
				linkType = undefined
				items = { type: 'Link', linkType: 'Entry', validations: [] }
			}
			if (type == 'Symbol' && this.textType == 'long') {
				type = 'Text'
			}
			return {
				id: this.id,
				name: this.name,
				localized: false,
				required: false,
				disabled: false,
				omitted: false,
				type,
				linkType,
				items,
				validations: [],
//				textType: this.textType,
//				numberType: this.numberType,
			}
		}
	},
	methods: {
		camelCase(s) {
			return s?.toLowerCase()?.replace?.(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())?.trim() ?? ''
		},
		open() {
			this.id = ''
			this.name = ''
			this.newField = {}
			this.selectedType = null
			this.textType = 'short'
			this.numberType = 'integer'
			this.isArray = false

			this.$refs.create.open()
		},
		close() {
			this.$refs.create.close()
		},
		confirm() {
			this.$emit('confirm', this.fieldDef)
			this.close()
		},
	},
}
</script>

<style scoped>
.types { display: grid; grid-template-columns: repeat(3, 1fr); gap: 10px; grid-auto-rows: minmax(100px, auto); }
.types > div { border: 1px solid var(--color-element-mid); padding: 10px; cursor: pointer; border-radius: 5px; }
.types > div:hover { border-color: var(--color-blue-mid); }
.types > div > h2 { margin: 0; padding: 0; margin-bottom: 5px; font-size: 16px; }
.types > div > .mdi { float: right; filter: grayscale(1); opacity: 0.7; transition: filter 0.2s, opacity 0.2s; font-size: 72px; margin-left: 10px; }
.types > div:hover { /*background: rgba(211, 169, 239, 0.6);*/ }
.types > div:hover > .mdi { filter: grayscale(0); /*filter: grayscale(0) invert(0) sepia(1) hue-rotate(240deg) brightness(1.1);*/ opacity: 1; }
.fields { display: flex; flex-direction: column; gap: 10px; }
.row { display: flex; gap: 10px; }
.row > * { flex-grow: 1; display: flex; flex-direction: column; gap: 5px; }
.row .field { margin-bottom: 10px; }
.radioRow { display: flex; gap: 10px; align-items: start; }
.radioRow label { flex: 1; font-weight: 500; }
.radioRow label p { color: gray; font-weight: normal; }
.field { display: block; background-color: white; color: rgb(65, 77, 99); font-size: 0.875rem; line-height: 1.25rem; border-radius: 6px; box-shadow: rgb(225 228 232 / 20%) 0px 2px 0px inset; outline: none; border: 1px solid rgb(207, 217, 224); cursor: pointer; padding: 10px 1.5rem 10px 0.75rem; }
.field:focus { border: 1px solid rgb(0, 112, 243); }
</style>