<template>
	<div class="ContentPreviews" style="height: 100%;">
		<Head>
			<div class="heading" style="padding-left: 25px;">
				<h1>
					Content Previews
				</h1>
			</div>
		</Head>
		<div class="body" style="display: flex; height: 100%;">
			<div class="BaseTable" style="margin: 30px; flex: 1 1 0;">
				<table>
					<tr>
						<th>Preview Platform</th>
						<th>URL</th>
					</tr>
					<tr v-for="preview of previews" :key="preview.key"
						@click="$router.push(base + '/settings/previews/' + preview.sys.id)"
					>
						<td>
							{{ preview.name }}
						</td>
						<td>
							{{ preview.baseUrl }}
						</td>
					</tr>
				</table>
				<button class="create" @click="create">&plus; Create preview</button>
			</div>
			<!--<Sidebar>
				<div class="section">
					<h2>Documentation</h2>
					<p>
						Adding a content preview generates a link in the entry editor, referring to your custom preview environment.
					</p>
					<p>
						<a style="text-decoration: none;" href="https://www.contentful.com/help/setup-content-preview/?utm_source=webapp&utm_medium=knowledge-base-content_preview&utm_campaign=in-app-help">
							Read the guide
						</a>
						before setting up a custom content preview.
					</p>
				</div>
			</Sidebar>-->
		</div>
	</div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import Head from './Head.vue'

export default {
	name: 'ContentPreviews',
	components: { Sidebar, Head },
	inject: [ 'base', 'endpoint' ],
	data: () => ({
		previews: null,
	}),
	methods: {
		async load() {
			const previews = await this.$httpGet(this.endpoint + '/preview_environments')
			this.previews = previews.items
		},
		async create() {
			this.$router.push(this.base + '/settings/previews/new')
		},
	},
	async mounted() {
		document.title = 'Previews'
		await this.load()
	},
}
</script>

<style scoped>
table { width: 100%; }
table th { height: auto; padding: 15px; }
button.create { margin-top: 15px; border: 1px solid #ddd; background: transparent; border-radius: 6px; cursor: pointer; font-size: 15px; font-weight: 500; padding: 10px 15px; }
</style>
