<template>
	<div class="LocationField">
		<GoogleMap ref="map" style="min-height: 300px;" crosshairs :init="init" />
		<div class="modeSelector">
			<div>
				<input type="radio" class="mode" v-model="mode" value="address" :name="id" :id="id + '_a'" />
				<label :for="id + '_a'">Address</label>
			</div>
			<div>
				<input type="radio" class="mode" v-model="mode" value="coordinates" :name="id" :id="id + '_c'" />
				<label :for="id + '_c'">Coordinates</label>
			</div>
		</div>
		<div class="coordinates">
			<div v-if="mode == 'address'" style="display: flex; gap: 15px; width: 100%;">
				<input type="text" class="input" v-model="address"
					placeholder="Address"
					@keyup.enter="geocode(address)"
				/>
			</div>
			<div v-if="mode == 'coordinates'" class="latlon">
				Latitude
				<input type="number" class="input"
					placeholder="Latitude"
					v-model="lat"
					@focus="$emit('focus', $event)"
					@blur="$emit('blur', $event)"
					@keyup.enter="centerMap(); reverseGeocode(lat, lon)"
					:disabled="disabled"
					id="latitude"
				/>
				Longitude
				<input type="number" class="input"
					placeholder="Longitude"
					v-model="lon"
					@focus="$emit('focus', $event)"
					@blur="$emit('blur', $event)"
					@keyup.enter="centerMap(); reverseGeocode(lat, lon)"
					:disabled="disabled"
				/>
			</div>
			<span class="clear" v-if="!disabled && value !== undefined && value !== null" @click="$emit('input', undefined)">
				Clear
			</span>
		</div>
	</div>
</template>

<script>
import GoogleMap from '../GoogleMap.vue'
import { field } from './FieldMixin.js'

export default {
	name: 'LocationField',
	components: { GoogleMap },
	mixins: [ field ],
	props: {
		value: Object,
		locale: String,
		disabled: { type: Boolean, default: false },
	},
	data: () => ({
		autoModel: false,
		lat: '',
		lon: '',
		id: 'LF_' + Math.random(),
		google: null,
		timeout: null,
		mode: 'address',
		address: '',
	}),
	computed: {
		map() {
			return this.$refs.map?.map
		},
		geocoder() {
			return this.$refs.map?.geocoder
		},
	},
	watch: {
		// ATT: we have to set in the order { lon, lat }
		//      because contentful does it like that and our autosave compares between what it gets from CF and the current value
		lat(n) {
			const v = { lat: this.fix(this.lat), lon: this.fix(this.lon) }
			this.$emit('input', !v.lat && !v.lon ? undefined : v)
		},
		lon(n) {
			const v = { lat: this.fix(this.lat), lon: this.fix(this.lon) }
			this.$emit('input', !v.lat && !v.lon ? undefined : v)
		},
		value(n) {
			this.onErrors([
				// TODO: is 0/0 a valid required location?
				this.validateRequired(),
			])
			if (n == null) {
				this.address = ''
				return
			}
			this.lat = this.value.lat
			this.lon = this.value.lon
			this.reverseGeocode(this.lat, this.lon)
		},
	},
	methods: {
		async reverseGeocode(lat, lon) {
			if (!lat || !lon) return
			this.geocoder?.geocode({ location: { lat: lat, lng: lon } }, (results, status) => {
				// if (status !== 'OK' || !results[0]) {
				// 	throw new Error(status)
				// }
				if (results?.length > 0) {
					this.address = results[0].formatted_address
					return this.address
				}
			})
		},
		async geocode(address) {
			if (!address) {
				// TODO: do we still need this? refactor?
				//       test empty value case
				const google = this.google
				if (this.value?.lat || this.value?.lon) {
					this.map.setCenter(new google.maps.LatLng(this.value?.lat ?? 0, this.value?.lon ?? 0))
					this.map.setZoom(6)
					return
				}
				this.map.setCenter(new google.maps.LatLng(47, 11))
				this.map.setZoom(3)
				// TODO?
				if (this.value) {
					this.value.lat = ''
					this.value.lon = ''
				}
				return
			}
// TODO: geocode never comes back - why?
			this.geocoder?.geocode({ address: address ?? this.address }, (results, status) => {
				console.log('geocode done', results, status)
				// if (status !== 'OK' || !results[0]) {
				// 	throw new Error(status)
				// }
				if (results?.length > 0) {
					this.map.setCenter(results[0].geometry.location)
					this.map.fitBounds(results[0].geometry.viewport)
				}
			})
		},
		centerMap() {
			this.map.setCenter({
				lat: Number(this.value?.lat ?? 0),
				lng: Number(this.value?.lon ?? 0),
			})
		},
		fix(n) {
			return Number((n + '').substring(0, 16))
		},
		init() {
			try {
				this.map.addListener('center_changed', () => {
					const pos = this.map.getCenter()
					this.lat = this.fix(pos.lat())
					this.lon = this.fix(pos.lng())
					// TODO: put the geocoding on a timeout so we dont send too many requests while the user is dragging
					this.reverseGeocode(this.lat, this.lon)
				})
				this.map.setZoom(12)
				// TODO: this case does not work because we dont persinst the address. 
				//if (!this.value.lat || !this.value.lon) {
				//	this.geocode(this.address)
				//}
				//else {
				this.centerMap()
				this.reverseGeocode(this.lat, this.lon)
			}
			catch (e) {
				console.error('LocationField.mounted', e)
			}
		},
	},
	async mounted() {
		this.lat = this.value?.lat ?? 0
		this.lon = this.value?.lon ?? 0
	},
}
</script>

<style scoped>
div { color: gray; }
.mapWrap { position: relative; }
.map { height: 300px; background: #eee; }
.crosshair { position: absolute; width: 1px; height: 60px; background: #000; top: 50%; left: 50%; transform: translate(-50%, -50%); pointer-events: none; }
.crosshair::before { content: ""; position: absolute; width: 60px; height: 1px; background: #000; top: 50%; left: 50%; transform: translate(-50%, -50%); }
.modeSelector { display: flex; width: 100%; padding: 15px 0 15px 0; }
.modeSelector > div { width: 100%; }
.modeSelector label { margin-left: 5px; }
.coordinates { display: flex; gap: 15px; }
.coordinates .latlon { display: flex; gap: 10px; width: 100%; align-items: center; }
.clear { color: rgb(0, 89, 200); cursor: pointer; padding-top: 10px; margin-left: 20px; }
</style>