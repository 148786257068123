
import { field } from './FieldMixin.js'

export default {
	name: 'DropdownField',
	mixins: [ field ],
	props: {
		// TODO: Array is not actually valid, but (probably in connection with SystemTags on the RoleEdit) warned about this
		value: [ Number, String, Object, Array ],
	},
	data: () => ({
		model: null,
	}),
	computed: {
		allowedValues() {
			return this.validations?.in ?? this.validations?.inLinks ?? []
		},
	},
	watch: {
		value(n) {
			this.onErrors([
				this.validateRequired(),
			])
		},
	},
}
