<template>
	<button @click="handleClick"
		:class="{ loading }"
		:disabled="loading || disabled"
	>
		<div class="text">
			<slot />
		</div>
		<SpinnerOct class="spinner" v-if="loading" opaque />
	</button>
</template>

<script>
import SpinnerOct from './SpinnerOct.vue'

export default {
	components: { SpinnerOct },
	props: {
		confirm: Boolean,
		disabled: Boolean,
	},
	data() {
		return {
			loading: false,
		}
	},
	methods: {
		async handleClick() {
			if (this.confirm && !window.confirm('Are you sure?')) return
			// TODO: add timeout?
			this.loading = true
			try {
				await this.$listeners.click()
			}
			catch (e) {
				console.warn('Action failed', e)
			}
			this.loading = false
		}
	}
}
</script>

<style scoped>
button {
	display: flex;
	gap: 3px;
	align-items: center;
	justify-content: center;
	padding: 7px 15px;
	border: none;
	border-radius: 5px;
	color: white;
	cursor: pointer;
	background: var(--col);
	transition: background-color 0.5s;
}
button:hover { outline: 1px solid var(--col); outline-offset: 2px; }
button:active { outline-offset: 1px; }
button:disabled { background: #666 !important; cursor: not-allowed; }
button.loading { padding-right: 5px; }

button.wide { width: 100%; }

button .text { padding: 4px 0; }
.spinner { zoom: 0.1; filter: brightness(1.2); }

button.cancel,
button.light { background: white; color: #666; outline: 1px solid #999; }
button.cancel:hover,
button.light:hover { }

button.primary,
button.add,
button.neutral { --col: var(--primary); }
button.primary .spinner,
button.add .spinner,
button.neutral .spinner { filter: brightness(1.2) hue-rotate(250deg) }

button.ok,
button.publish,
button.save { --col: var(--color-green-mid); }
button.publish .spinner,
button.save .spinner { filter: brightness(1.2) hue-rotate(200deg) }

button.delete { --col: var(--color-red-mid); }
button.delete .spinner { filter: brightness(1.2) hue-rotate(60deg) }

button.link { background: transparent; color: var(--primary); border: 0; padding: 0 5px; text-decoration: none; }
button.link.neutral { color: var(--primary); }
button.link.delete { color: var(--delete); }
</style>