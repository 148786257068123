<template>
	<div class="webhooks" style="height: 100%;">
		<Head :hasBack="true" @back="close()" v-if="webhook">
			<div class="heading">
				<h1 v-if="webhook" style="display: flex; width: 100%;">
					<div style="flex: 1;">
						{{ webhook.name ? webhook.name : 'New webhook' }}
						<EntryStatus :name="webhook.active ? 'active' : 'disabled'" style="display: inline-block; margin-left: 5px;" />
					</div>
				</h1>
			</div>
			<div class="actions">
				<ActionButton class="cancel" @click="close()">Cancel</ActionButton>
				<ActionButton class="delete" @click="deleteWebhook()" v-if="id != 'new'">Delete</ActionButton>
				<ActionButton class="save" @click="saveAndClose()">Save</ActionButton>
			</div>
		</Head>
		<div class="body" style="margin: 25px; margin-bottom: 50px; font-size: 14px;" v-if="webhook">
			<h2>Details</h2>
			<label for="name">Name (required)</label>
			<div class="fieldWrap">
				<input type="text" class="input" id="name" v-model="webhook.name" />
			</div>
			<label for="url">Url (required)</label>
			<div class="fieldWrap">
				<select class="input" id="method" v-model="webhook.transformation.method"
					style="width: 120px;"
				>
					<option value="POST">POST</option>
					<option value="GET">GET</option>
					<option value="PUT">PUT</option>
					<option value="PATCH">PATCH</option>
					<option value="DELETE">DELETE</option>
				</select>
				<input type="text" class="input" id="url" v-model="webhook.url" />
			</div>
			<div class="checkbox">
				<input type="checkbox" id="active" v-model="webhook.active" />
				<label for="active">Active</label>
				Webhook calls will be performed for the configured events.
			</div>

			<h2>Triggers</h2>
			Specify for what kind of events this webhook should be triggered.
			<div>
				<input type="radio" value="all" id="all" v-model="topics" />
				<label for="all">Trigger for all events</label>
			</div>
			<div>
				<input type="radio" value="specific" id="specific" v-model="topics" />
				<label for="specific">Select specific triggering events</label>
			</div>
			<div v-if="topics == 'specific'">
				<h3>Content Events</h3>
				Webhooks from these core entities are most commonly used to monitor when the state of any content from your space has been edited or updated. Useful for triggering website rebuilds, cache invalidation or automated tests.
				<table class="events">
					<tr>
						<th></th>
						<th v-for="event, e of supportedEvents" :key="'th.' + e">
							{{ event.replace(/_/g, ' ') }}
							<br />
							<mdi checkbox-multiple-marked style="color: #aaa;" @click="topicsSelectAllEvent(event)" />
						</th>
					</tr>
					<tr v-for="entityType, et of supportedEntityTypes" :key="et">
						<th style="text-align: left;">
							{{ entityType }}
							<mdi checkbox-multiple-marked style="color: #aaa; float: right;" @click="topicsSelectAllEntityType(entityType)" />
						</th>
						<td v-for="event, e of supportedEvents" :key="e">
							<input type="checkbox" v-model="webhook.topics"
								:value="entityType + '.' + event"
								:disabled="!supportedTopics.includes(entityType + '.' + event)"
							/>
						</td>
					</tr>
				</table>
				<!--
				<h3>Other API Events</h3>
				We also offer webhooks for other entities within your space that can be used in conjunction with the core Content Events above, or on their own for special use cases. To discover more about how these each of these events can be used, check out our 
				TABLE
				-->
			</div>
			<h3>Filters</h3>
			This webhook will trigger only for entities matching the filters defined below.
			<div v-for="(filter, f) of filters" :key="'f.' + f" class="filter">
				<div class="fieldWrap">
					<select class="input" v-model="filter.doc">
						<option value="sys.environment.sys.id">Environment</option>
						<option value="sys.contentType.sys.id">Content Type</option>
						<option value="sys.id">Id</option>
						<option value="sys.createdBy.sys.id">Created by</option>
						<option value="sys.updatedBy.sys.id">Updated by</option>
					</select>
					<select class="input" v-model="filter.op">
						<option value="equals">equals</option>
						<!--<option value="notequals">not equals</option>-->
						<option value="in">in</option>
						<!--<option value="notin">not in</option>
						<option value="regexp">regexp</option>
						<option value="notregexp">not regexp</option>-->
					</select>
					<input type="text" class="input" v-model="filter.value" />
					<ActionButton class="link delete" @click="filters.splice(f, 1)">Remove</ActionButton>
				</div>
			</div>
			<!--<pre>{{ webhook.filters }}</pre>-->
			<div>
				<ActionButton class="link neutral" @click="filters.push({ doc: 'sys.environment.sys.id', op: 'equals', value: '' })">+ Add filter</ActionButton>
			</div>

			<h2>Headers</h2>
			<div v-for="(header, h) of webhook.headers" :key="h" class="header">
				<div class="fieldWrap">
					<input type="text" class="input" v-model="header.key" />
					<input type="text" class="input" v-model="header.value" />
					<ActionButton class="link delete" @click="webhook.headers.splice(h, 1)">Remove</ActionButton>
				</div>
			</div>
			<div>
				<ActionButton class="link neutral" @click="webhook.headers.push({ key: '', value: '' })">+ Add custom header</ActionButton>
				<ActionButton class="link neutral" @click="webhook.headers.push({ key: '', value: '', secret: true })">+ Add secret header</ActionButton>
				<ActionButton class="link neutral" @click="webhook.headers.push({ key: 'Authorization', value: 'Basic ', secret: true })">+ Add HTTP Basic Auth header</ActionButton>
			</div>
			<h3>Content Type</h3>
			<select class="input" v-model="contentType">
				<option value="">other (define as custom header above)</option>
				<option v-for="ct of contentTypes" :key="ct" :value="ct">{{ ct }}</option>
			</select>
			<div class="fieldInfo">Select one of allowed MIME types to be used as the value of the Content-Type header. Any custom Content-Type header will be ignored.</div>

			<h2>Payload</h2>
			You can customize the webhook payload to match the format expected by the service your webhook calls.
			<div>
				<input type="radio" value="default" id="payloadDefault" v-model="payload" />
				<label for="payloadDefault">Use default payload</label>
			</div>
			<div>
				<input type="radio" value="custom" id="payloadCustom" v-model="payload" />
				<label for="payloadCustom">Customize the webhook payload</label>
			</div>
			<div v-if="payload == 'custom'">
				<textarea class="input payload code" v-model="body" :class="{ error: bodyError }"></textarea>
				<div v-if="bodyError" class="error">{{ bodyError }}</div>
				<div class="fieldInfo">Custom payload can be any valid JSON value. To resolve a value from the original webhook payload use a JSON pointer wrapped with curly braces.</div>
				Example:
<pre class="code">{
	"entityId": "{ /payload/sys/id }",
	"spaceId": "{ /payload/sys/space/sys/id }",
	"parameters": {
		"text": "Entity version: { /payload/sys/version }"
	}
}</pre>
			</div>
		</div>
	</div>
</template>

<script>
import Head from './Head.vue'
import Info from './Info.vue'
import ActionButton from '../components/ActionButton.vue'
import EntryStatus from '../components/fields/EntryStatus.vue'

export default {
	name: 'WebhookEdit',
	components: { Head, Info, ActionButton, EntryStatus },
	inject: [ 'base', 'baseEndpoint' ],
	props: {
//		id: String,
	},
	data: () => ({
		id: null,
		webhook: null,
		bodyError: null,
		supportedTopics: [
			'Entry.create', 'Entry.save', 'Entry.auto_save', 'Entry.archive', 'Entry.unarchive', 'Entry.publish', 'Entry.unpublish', 'Entry.delete',
			'Asset.create', 'Asset.save', 'Asset.auto_save', 'Asset.archive', 'Asset.unarchive', 'Asset.publish', 'Asset.unpublish', 'Asset.delete',
			'ContentType.create', 'ContentType.save', 'ContentType.publish', 'ContentType.unpublish', 'ContentType.delete',
			'Task.create', 'Task.save', 'Task.delete',
			'Comment.create', 'Comment.delete',
		],
		contentTypes: [
			'application/vnd.contentful.management.v1+json',
			'application/vnd.contentful.management.v1+json; charset=utf-8',
			'application/json',
			'application/json; charset=utf-8',
			'application/x-www-form-urlencoded',
			'application/x-www-form-urlencoded; charset=utf-8',
		],
		body: null,
		filters: [],
	}),
	computed: {
		supportedEvents() {
			return [ ...new Set(this.supportedTopics.map(topic => topic.split('.')[1])) ]
		},
		supportedEntityTypes() {
			return [ ...new Set(this.supportedTopics.map(topic => topic.split('.')[0])) ]
		},
		endpoint() {
			return this.baseEndpoint + '/spaces/' + this.$route.params.spaceId
		},
		topics: {
			get() {
				return this.webhook.topics.length == 1 && this.webhook.topics[0] == '*.*' ? 'all' : 'specific'
			},
			set(value) {
				this.webhook.topics = value == 'all' ? [ '*.*' ] : []
			},
		},
		payload: {
			get() {
				return this.webhook.transformation.body ? 'custom' : 'default'
			},
			set(value) {
				if (value == 'default') {
					this.webhook.transformation.body = undefined
				}
				else {
					this.webhook.transformation.body = {
						userId: '{ /user/sys/id }',
						entityId: '{ /payload/sys/id }',
					}
				}
			},
		},
		contentType: {
			get() {
				return this.webhook.headers.find(header => header.key == 'Content-Type')?.value ?? ''
			},
			set(value) {
				const header = this.webhook.headers.find(header => header.key == 'Content-Type')
				if (header) header.value = value
				else this.webhook.headers.push({ key: 'Content-Type', value })
			},
		},
	},
	watch: {
		body(n) {
			this.bodyError = null
			try {
				this.webhook.transformation.body = n === undefined ? n : JSON.parse(n)
			}
			catch (e) {
				this.bodyError = e
			}
		},
		filters: {
			deep: true,
			handler(n) {
				this.setFilters(n)
			},
		},
	},
	methods: {
		async load() {
			const r = await this.$httpGet(this.endpoint + '/webhook_definitions/' + this.id)
			if (!r.filters) r.filters = []
/*r.filters = [
  { "equals": [ { "doc": "sys.environment.sys.id" }, "master" ] },
  { "not": { "equals": [ { "doc": "sys.contentType.sys.id" }, "story" ] } },
  { "regexp": [ { "doc": "sys.id" }, { "pattern": "1234$" } ] },
  { "not": { "in": [ { "doc": "sys.createdBy.sys.id" }, [ "123", "456" ] ] } },
  { "in": [ { "doc": "sys.updatedBy.sys.id" }, [ "123", "456" ] ] },
]*/
			if (!r.transformation) r.transformation = { }
			if (!r.transformation.method) r.transformation.method = 'POST'
			if (!r.transformation.body) r.transformation.body = null
			if (!r.headers) r.headers = []
			this.webhook = r
			this.filters = this.getFilters()
			this.body = JSON.stringify(this.webhook.transformation.body, null, 4)
		},
		// we map from/to this very weird serverside structure:
		// { "equals": [ { "doc": "sys.environment.sys.id" }, "master" ] }
		// { "not": { "equals": [ { "doc": "sys.environment.sys.id" }, "master" ] } }
		getFilters() {
			if (!this.webhook?.filters) return []
			return this.webhook.filters.map(filter => {
				if (Object.keys(filter).length == 0) return
				let key = Object.keys(filter)[0]
				let op = key
				if (op == 'not') {
					filter = filter[op]
					key = Object.keys(filter)[0]
					op += key
				}
				const params = filter[key]
				let value = params[1]
				if (key == 'regexp') value = value.pattern
				if (key == 'in') value = value?.join?.(',') ?? ''
				return { doc: params[0].doc, op, value }
			}).filter(f => f)
		},
		setFilters(value) {
			if (!this.webhook) return
			this.webhook.filters = value.map(filter => {
				let key = filter.op
				let not = false
				if (key.startsWith('not')) {
					not = true
					key = key.slice(3)
				}
				let p2 = filter.value
				if (key == 'regexp') p2 = { pattern: p2 }
				if (key == 'in') p2 = p2?.split?.(',') ?? []
				const params = [ { doc: filter.doc }, p2 ]
				if (not) return { not: { [ key ]: params } }
				return { [ key ]: params }
			})
		},
		async save() {
			if (this.bodyError) throw new Error('Invalid JSON in payload')
			this.webhook.transformation.body = this.payload == 'default' ? undefined : JSON.parse(this.body)

			if (this.id == 'new') {
				const r = await this.$httpPost(this.endpoint + '/webhook_definitions/', this.webhook)
				// TODO: handle save error before we replace model!
				this.id = r.sys.id
				this.$router.replace(this.base + '/settings/webhooks/' + r.sys.id)
				this.load()
				return
			}

			const webhooks = await this.$httpPut(this.endpoint + '/webhook_definitions/' + this.webhook.sys.id, this.webhook)
			this.webhooks = webhooks.items
		},
		close() {
			this.$router.push(this.base + '/settings/webhooks')
		},
		async deleteWebhook() {
			if (!confirm('Are you sure you want to delete this webhook?')) return
			await this.$httpDelete(this.endpoint + '/webhook_definitions/' + this.webhook.sys.id)
			this.close()
		},
		async saveAndClose() {
			try {
				await this.save()
				this.close()
			}
			catch (e) {
				console.log('Error: ', e)
				alert('Error: ' + e.message)
			}
		},
		topicsSelectAllEvent(event) {
			const anyFalse = this.supportedTopics.filter(topic => topic.endsWith('.' + event)).some(topic => !this.webhook.topics.includes(topic))
			this.webhook.topics = this.webhook.topics.filter(topic => !topic.endsWith('.' + event))
			if (anyFalse) this.webhook.topics.push(...this.supportedTopics.filter(topic => topic.endsWith('.' + event)))
		},
		topicsSelectAllEntityType(entityType) {
			const anyFalse = this.supportedTopics.filter(topic => topic.startsWith(entityType + '.')).some(topic => !this.webhook.topics.includes(topic))
			this.webhook.topics = this.webhook.topics.filter(topic => !topic.startsWith(entityType + '.'))
			if (anyFalse) this.webhook.topics.push(...this.supportedTopics.filter(topic => topic.startsWith(entityType + '.')))
		},
	},
	async mounted() {
		this.id = this.$route.params.id
		if (this.id == 'new') {
			this.webhook = {
				name: '',
				url: '',
				httpBasicUsername: null,
				topics: [ '*.*' ],
				filters: [],
				transformation: {
					method: 'POST',
					//contentType: 'application/json',
					body: null,
				},
				active: false,
				headers: [
					{ key: 'Content-Type', value: 'application/json' },
				],
			}
			return
		}
		else {
			await this.load()
		}
		document.title = 'Webhook - ' + this.webhook.name
	},
}
</script>

<style scoped>
h2 { margin: 20px 0px 1rem; padding: 0px; font-weight: 600; color: rgb(17, 27, 43); font-size: 1rem; line-height: 1.5rem; }
.checkbox { padding-left: 25px; position: relative; margin-bottom: 25px; color: #999; }
.checkbox input { position: absolute; left: 0; }
.checkbox label { font-weight: 600; display: block; color: rgb(17, 27, 43); margin-bottom: 3px; }
.fieldWrap { display: flex; gap: 10px; margin-top: 5px; margin-bottom: 10px; }
.events { width: 100%; border-collapse: collapse; border-spacing: 0; }
.events td, .events th { border: 1px solid #eee; padding: 5px; }
.events th { background: #fafafa; text-transform: capitalize; }
.events td { text-align: center; }
div.error { color: var(--color-red-mid); }
textarea.payload { min-width: 100%; max-width: 100%; min-height: 200px; max-height: 1000px; margin: 5px 0; font-family: monospace; tab-size: 35px; }
textarea.error { border-color: var(--color-red-mid); color: var(--color-red-mid); }
input[type="radio"] { margin-top: 10px; }
.fieldInfo { color: #999; margin-top: 5px; }
</style>