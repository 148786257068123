<template>
	<div class="MultiTypePicker">
		<Field
			style="margin-top: 50px;"
			:control="{ widgetId: 'tagEditor', sortable: false }"
			:field="{ name: 'Add Content Type', type: 'Array', items: { type: 'Symbol' }, validations: [ { in: tags, size: { min: 0, max: 100 } } ] }"
			v-model="model"
		/>
	</div>
</template>

<script>
import Field from './fields/Field.vue'

export default {
	name: 'MultiTypePicker',
	components: { Field },
	inject: [ 'endpoint' ],
	props: {
		value: Array,
	},
	data: () => ({
		model: [],
	}),
	computed: {
		tags() {
			return Object.values(window.typeLookup).map(t => t.sys.id).sort((a, b) => a.localeCompare(b))
		},
	},
	watch: {
		value(n) { this.model = n },
		model(n) { this.$emit('input', n) },
	},
	async mounted() {
		this.model = this.value
	},
}
</script>

<style scoped>
</style>