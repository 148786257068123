
import { field } from './FieldMixin.js'
import { buildRequestParamsFromFilter } from '../../EntryApi'

export default {
	name: 'SlugField',
	mixins: [ field ],
	inject: [ 'defaultLocale', 'fallbackLocale', 'endpoint' ],
	props: {
		value: String,
		entry: Object,
		locale: String,
	},
	data: () => ({
		model: null,
		autoFill: true,
		otherEntry: null,
		warnings: [],
	}),
	computed: {
		contentType() {
			if (!this.entry?.sys) return undefined
			const id = this.entry.sys.contentType.sys.id
			return window['typeLookup'][id]
		},
		titleField() {
			return this.contentType.fields.filter(field => field.id == this.contentType.displayField)[0]
		},
		titleLocale() {
			if (this.titleField.localized)
				return this.locale
			return this.defaultLocale
		},
		titleValue() {
			return this.entry.fields?.[this.contentType.displayField]?.[this.titleLocale] ?? ''
		},
	},
	watch: {
		async value() {
			await this.searchOther()
			this.validate()
		},
		titleValue(n) {
			this.onTitleChanged(n)
		},
	},
	methods: {
		autoFillValue(v: string) {
			v = v
				.toLowerCase()
				.replace(/ä/g, 'ae')
				.replace(/ü/g, 'ue')
				.replace(/ö/g, 'oe')
				.replace(/ß/g, 'ss')
				.replace(/\s+/g, '-')
				.replace(/[^a-z0-9-]/g, '')
				.replace(/--+/g, '-')
			this.$emit('input', v)
		},
		// we stop autofilling after manual inputs, except when field is cleared
		stopAutoFill() {
			this.autoFill = this.model == ''
		},
		onTitleChanged(n) {
			// TODO: if the entry is published AND the field was empty at that point, we should actually autofill
			//       but how can we do that? we would need to know the field value at publication time..
			//       i guess it would be easier to just not allow publishing empty slugs.
			if (this.entry.sys.publishedAt) return
			if (!this.autoFill) return
			this.autoFillValue(n)
		},
		toggleAutoFill() {
			this.autoFill = this.autoFillValue(this.titleValue) == this.value?.[this.defaultLocale] ?? ''
		},
		async searchOther() {
			this.otherEntry = null
			if (!this.entry) return
			if (!this.model) return
			const params = new URLSearchParams({
				limit: '1',
				select: 'sys%2Cfields.title',
				content_type: this.entry.sys.contentType.sys.id,
				[ 'fields.' + this.def.id ]: this.model,
				'sys.id[ne]': this.entry.sys.id,
				...buildRequestParamsFromFilter({ mode: 'eq', query: { k: 'sys.status', v: 'published' } }),
			})
			const otherEntry = await this.$httpGet(this.endpoint + `/entries?` + params.toString())
			if (otherEntry.items.length)
				this.otherEntry = otherEntry.items[0]
			this.validate()
		},
		// TODO: can we easily generalize this to other field types that can be unique?
		validateUnique() {
			if (/*this.validations.unique &&*/ this.otherEntry) {
				return 'BE AWARE: The published entry ' + this.otherEntry.sys.id + ' already has this ' + this.def.name + ' set!'
			}
		},
		validate() {
			this.onErrors([
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
				this.validateRegexp(),
				this.validateProhibitRegexp(),
				this.validateIn(),
				//this.validateUnique(),
			])
			this.onWarnings([
				this.validateUnique(),
			])
		},
	},
	mounted() {
		this.toggleAutoFill()
		this.searchOther()
	},
}
