<template>
	<div v-if="typeHasValidation(validation)" class="checkboxBlock"
		:class="{ disabled }"
	>
		<h3 v-if="headline" style="margin-left: -30px;">{{ headline }}</h3>
		<input :id="'checkbox' + rand" type="checkbox" v-model="validations[validation].enabled" />
		<label :for="'checkbox' + rand">{{ title }}</label>
		<p>
			<slot name="help" />
		</p>
		<slot v-if="validations[validation].enabled" />
		<div v-if="validations[validation].enabled && hasMessage" style="margin-top: 10px;">
			<label :for="'message' + rand">Custom error message</label>
			<input :id="'message' + rand" class="field message" type="text"
				v-model="validations[validation].message"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FieldSettingValidation',
	props: {
		title: String,
		validation: String,
		validations: Object,
		typeHasValidation: Function,
		hasMessage: { type: Boolean, default: true },
		headline: String,
		disabled: Boolean,
	},
	data: () => ({
		rand: Math.random(),
	}),
}
</script>

<style scoped>
.checkboxBlock { padding-left: 30px; position: relative; }
.checkboxBlock > input[type="checkbox"] { position: absolute; left: 0px; }
.checkboxBlock > label { font-weight: 500; }
.checkboxBlock p { color: gray; }
.checkboxBlock.disabled { opacity: 0.5; }
.field { display: block; background-color: white; color: rgb(65, 77, 99); font-size: 0.875rem; line-height: 1.25rem; border-radius: 6px; box-shadow: rgb(225 228 232 / 20%) 0px 2px 0px inset; outline: none; border: 1px solid rgb(207, 217, 224); cursor: pointer; padding: 10px 1.5rem 10px 0.75rem; }
.field:focus { border: 1px solid rgb(0, 112, 243); }
.field.message,
.field.wide { width: calc(100% - 40px); }
</style>