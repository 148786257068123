<template>
	<div class="content">
		<Head style="padding-left: 20px;">
			<EntryFilter v-model="filter" :def="def" />
			<div>
				<button class="create" @click="$refs.create.open()" style="display: block;" data-cy="create">
					Add Entry
					<mdi chevron-down />
				</button>
				<Menu ref="create" right="20px" :autoCloseOnClick="false">
					<TypePickerList :suggestedTypeName="filter.contentType"
						@select="create($event)"
						@close="$refs.create.close()"
					/>
				</Menu>
			</div>
		</Head>
		<div class="body">
			<Filters
				class="filters"
				:currentFilter="filter"
				style="position: fixed; top: 100px; bottom: 0; left: 0; overflow: auto;"
				@setFilter="setFilter"
			/>
			<div class="entryTableContainer">
				<EntryTable
					:filter="filter"
					:selectable="false"
					@input="selected"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import EntryFilter from '../components/fields/EntryFilter.vue'
import EntryTable from '../components/fields/EntryTable.vue'
import Menu from '../components/Menu.vue'
import TypePickerList from '../components/TypePickerList.vue'
import Head from './Head.vue'
import ProviderMixin from './ProviderMixin.js'
import EntryApiMixin from '../components/EntryApiMixin.js'
import Filters from './Filters.vue'
import { columnFieldsForColumnIds, newFilterForStorage } from '../components/fields/FilterUtil'
import { visFromParam, visToParam } from '../components/VisualisationUtil'

export default {
	name: 'Content',
	components: { EntryFilter, EntryTable, TypePickerList, Menu, Head, Filters },
	mixins: [ ProviderMixin, EntryApiMixin ],
	inject: [ 'base', 'endpoint', 'spaceId', 'environment', 'permissions' ],
	data: () => ({
		filter: { contentType: null, search: '', filters: [], columns: null, order: null, vis: [] },
		// TODO: ??
		def: {"id":"x","name":"x","type":"Array","localized":false,"required":false,"validations":[],"disabled":false,"omitted":false,"items":{"type":"Link","validations":[],"linkType":"Entry"}},
		sidebarTab: 'filters',
	}),
	watch: {
		filter: {
			deep: true,
			handler(filter) {
				// TODO: maybe this piece of code should be generalized somewhere
				//       potentially Filters should emit change events containing this data
				// symmetric to Filters.vue
				const query = {
					contentType: filter.contentType,
					search: filter.search,
					filters: filter.filters.map(f => f.storage),
					columns: filter.columns?.map?.(column => column.id) ?? [],
					vis: visToParam(filter.vis),
				}
				this.$router.replace({ query }).catch(() => {})
				localStorage['filterQuery'] = JSON.stringify({ ...query, spaceId: this.spaceId, environment: this.environment })
			},
		},
	},
	methods: {
		routerBeforeEach(to, from, next) {
			// TODO: if we only changed the filter -> return here
			next()
		},
		selected(entries) {
			this.$router.push(this.base + '/entries/' + entries[0].sys.id)
		},
		async create(contentType) {
			const entry = await this.createEntry(contentType.sys.id)
			this.$router.push(this.base + '/entries/' + entry.sys.id)
		},
		setFilter(filter) {
			this.filter.contentType = filter.contentType
			this.filter.filters = filter.filters
			this.filter.search = filter.search
			this.filter.columns = filter.columns
			this.filter.order = filter.order
			this.filter.vis = filter.vis
		},
	},
	mounted() {
		// TODO-3: add name of space
		document.title = 'Content'

		let view = this.$route.query
		if (!view || Object.keys(view).length == 0) {
			view = JSON.parse(localStorage['filterQuery'] ?? '{}')
			if (view.spaceId != this.spaceId || view.environment != this.environment) view = {}
		}
		if (view) {
			
			// fix query format encoding
			if (!view.filters) view.filters = []
			if (!Array.isArray(view.filters)) view.filters = [view.filters]
			if (view.filters.length > 0 && typeof view.filters[0] == 'string')
				view.filters = view.filters.map(f => f.split(',', 3))
			if (!view.columns) view.columns = []
			if (!Array.isArray(view.columns)) view.columns = [ view.columns ]
			if (!view.contentType) view.contentType = null

			if (!view.columns?.length) view.columns = null
			let columns = columnFieldsForColumnIds(view.columns)
			if (!columns?.length) columns = null

			this.setFilter({
				contentType: view.contentType,
				filters: view.filters?.map?.(f => newFilterForStorage(f)) ?? [],
				search: view.search,
				columns,
				vis: visFromParam(view.vis),
			})
		}
	},
}
</script>

<style scoped>
.EntryFilter { flex-grow: 1; position: relative; z-index: 1000; }
h1 { flex-shrink: 0; font-size: 20px; font-weight: 600; margin: 0; padding: 0; width: 250px; padding: 5px 0;}
button.create { white-space: nowrap; background-color: var(--primary); height: 40px; padding: 8px 10px 8px 15px; color: white; border: 0; border-radius: 6px; font-size: 14px; font-weight: 500; cursor: pointer; }
button.create .icon { font-size: 14px; fill: currentColor; vertical-align: middle; }
.entryTableContainer { flex-grow: 1; margin: 20px; margin-left: 280px; }

@media (max-width: 800px) {
	.filters { display: none; }
	.entryTableContainer { margin-left: 20px; }
}
</style>