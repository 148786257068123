
import ActionButton from '../ActionButton.vue'
export default {
	components: { ActionButton },
	name: 'Cropper',
	props: {
		w: Number,
		h: Number,
		aspect: Object, // { w: 16, h: 9 } for example
	},
	data: () => ({
		screen: { x: 0, y: 0, w: 0, h: 0 },
		current: { x: 0, y: 0, w: 0, h: 0 },
		lastPos: { x: 0, y: 0 },
	}),
	computed: {
		effective() {
			return {
				x: Math.floor(this.w * this.current.x / this.screen.w),
				y: Math.floor(this.h * this.current.y / this.screen.h),
				w: Math.floor(this.w * this.current.w / this.screen.w),
				h: Math.floor(this.h * this.current.h / this.screen.h),
			}
		},
	},
	methods: {
		scaleStart(e) {
			this.lastPos.x = e.clientX
			this.lastPos.y = e.clientY
			document.addEventListener('mousemove', this.scaleMove)
			document.addEventListener('mouseup', this.scaleEnd)
			e.preventDefault()
			e.stopPropagation()
			return false
		},
		scaleMove(e) {
			const dx = e.clientX - this.lastPos.x
			const dy = e.clientY - this.lastPos.y
			this.lastPos.x = e.clientX
			this.lastPos.y = e.clientY
			this.current.w += dx
			this.current.h += dy

			if (this.current.w < 30) this.current.w = 30
			if (this.current.h < 30) this.current.h = 30
			if (this.current.x + this.current.w > this.screen.w) this.current.w = this.screen.w - this.current.x
			if (this.current.y + this.current.h > this.screen.h) this.current.h = this.screen.h - this.current.y
			this.fixAspect()

			e.preventDefault()
			e.stopPropagation()
			return false
		},
		fixAspect() {
			if (!this.aspect) return
			const aspect = this.aspect.w / this.aspect.h
			const currentAspect = this.current.w / this.current.h
			if (currentAspect > aspect)
				this.current.w = this.current.h * aspect
			else
				this.current.h = this.current.w / aspect
		},
		scaleEnd() {
			document.removeEventListener('mousemove', this.scaleMove)
			document.removeEventListener('mouseup', this.scaleEnd)
		},
		dragStart(e) {
			this.lastPos.x = e.clientX
			this.lastPos.y = e.clientY
			document.addEventListener('mousemove', this.dragMove)
			document.addEventListener('mouseup', this.dragEnd)
			e.preventDefault()
			e.stopPropagation()
			return false
		},
		dragMove(e) {
			const dx = e.clientX - this.lastPos.x
			const dy = e.clientY - this.lastPos.y
			this.lastPos.x = e.clientX
			this.lastPos.y = e.clientY
			this.current.x += dx
			this.current.y += dy
			if (this.current.x < 0) this.current.x = 0
			if (this.current.y < 0) this.current.y = 0
			if (this.current.x + this.current.w > this.screen.w) this.current.x = this.screen.w - this.current.w
			if (this.current.y + this.current.h > this.screen.h) this.current.y = this.screen.h - this.current.h
			e.preventDefault()
			e.stopPropagation()
			return false
		},
		dragEnd() {
			document.removeEventListener('mousemove', this.dragMove)
			document.removeEventListener('mouseup', this.dragEnd)
		},
	},
	mounted() {
		// ATT: careful to only use this comp once the image is loaded!
		this.screen.w = this.$el.clientWidth
		this.screen.h = this.$el.clientHeight
		this.current.w = this.screen.w
		this.current.h = this.screen.h
		this.fixAspect()
	},
}
