<template>
	<div class="DateField" :class="{ error }">
		<!--
		v={{ value }}
		d={{ date }}
		t={{ time }}
		z={{ timezone }}
		-->
		<div style="display: flex; gap: 15px;">
			<input type="date" class="input" v-model="date" @focus="onFocus" @blur="onBlur" :disabled="disabled" style="max-width: 200px;" />
			<input type="text" class="input" v-if="hasTime" v-model="time" @focus="onFocus" @blur="blurTime(); onBlur()" style="width: 100px;" placeholder="00:00" :disabled="disabled" />
			<select class="input" v-if="hasTimezone" v-model="timezone" @focus="onFocus" @blur="onBlur" :disabled="disabled" style="width: auto;">
				<option value="-12:00">UTC-12:00</option>
				<option value="-11:00">UTC-11:00</option>
				<option value="-10:00">UTC-10:00</option>
				<option value="-09:00">UTC-09:00</option>
				<option value="-08:00">UTC-08:00</option>
				<option value="-07:00">UTC-07:00</option>
				<option value="-06:00">UTC-06:00</option>
				<option value="-05:00">UTC-05:00</option>
				<option value="-04:00">UTC-04:00</option>
				<option value="-03:00">UTC-03:00</option>
				<option value="-02:00">UTC-02:00</option>
				<option value="-01:00">UTC-01:00</option>
				<option value="+00:00">UTC+00:00</option>
				<option value="+01:00">UTC+01:00</option>
				<option value="+02:00">UTC+02:00</option>
				<option value="+03:00">UTC+03:00</option>
				<option value="+04:00">UTC+04:00</option>
				<option value="+05:00">UTC+05:00</option>
				<option value="+06:00">UTC+06:00</option>
				<option value="+07:00">UTC+07:00</option>
				<option value="+08:00">UTC+08:00</option>
				<option value="+09:00">UTC+09:00</option>
				<option value="+10:00">UTC+10:00</option>
				<option value="+11:00">UTC+11:00</option>
				<option value="+12:00">UTC+12:00</option>
				<!-- TODO: for each TZ -->
			</select>
			<span class="clear" v-if="!disabled && value !== undefined && value !== null" @click="$emit('input', undefined)">
				Clear
			</span>
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'DateField',
	mixins: [ field ],
	props: {
		value: [ Number, String ],
	},
	data: () => ({
		date: '',
		time: '',
		timezone: '',
		model: undefined,
	}),
	computed: {
		hasTime() {
			return this.control?.settings?.format != 'dateonly' ?? true
		},
		hasTimezone() {
			return (this.control?.settings?.format != 'dateonly' && this.control?.settings?.format != 'time') ?? true
		},
	},
	watch: {
		value(n) {
			// TODO: validate min, max, etc.
			this.onErrors([
				this.validateRequired(),
				//this.validateMax(),
				//this.validateMin(),
				//this.validateIn(),
			])

			const r = this.destructValue(n)
			this.date = r.date
			this.time = r.time
			this.timezone = r.timezone
		},
		date(n) {
			this.$emit('input', this.buildValue(n, this.time, this.timezone))
		},
		time(n) {
			n = n.substring(0, 5)
			if (n.length == 5)
				this.$emit('input', this.buildValue(this.date, n, this.timezone))
		},
		timezone(n) {
			console.log(n)
			this.$emit('input', this.buildValue(this.date, this.time, n))
		},
	},
	methods: {
		destructValue(n) {
			if (!n) return { date: '', time: '', timezone: '' }
			const match = n.match(/(\d{4}-\d{2}-\d{2})(T(\d{2}:\d{2})(:\d{2})?([+-]\d{2}:\d{2})?)?/)
			if (!match) return { date: '', time: '', timezone: '' }
			const date = match[1] ?? ''
			const time = match[3] ?? ''
			const timezone = match[5] ?? '+01:00'
			return { date, time, timezone }
		},
		buildValue(date, time, timezone) {
			let value = date
			if (!value) return value
			if (this.hasTime) {
				const match = time.match(/^((\d{1,2})(:(\d{1,2})?)?)/)
				console.log(match)
				let h = match?.[2] ?? '00'
				let m = match?.[4] ?? '00'
				if (h.length == 1) h = '0' + h
				if (m.length == 0) m = '00'
				if (m.length == 1) m = '0' + m
				value += 'T' + h + ':' + m + ':00'
			}
			if (this.hasTimezone) value += timezone
			return value
		},
		blurTime() {
			this.$emit('input', this.buildValue(this.date, this.time, this.timezone))
		},
		test() {
			const testDestruct = (s, d, t, z) => {
				const r = this.destructValue(s)
				if (r.date != d || r.time != t || r.timezone != z)
					console.error('testDestruct failed', s, r, d, t, z)
			}
			testDestruct('2000-01-01T00:00:00+01:00', '2000-01-01', '00:00', '+01:00')
			testDestruct('2000-01-01T00:00:00-01:00', '2000-01-01', '00:00', '-01:00')
			testDestruct('2000-01-01T00:00+01:00', '2000-01-01', '00:00', '+01:00')
			testDestruct('2000-01-01T00:01:00', '2000-01-01', '00:01', '+00:00')
			testDestruct('2000-01-01T01:00', '2000-01-01', '01:00', '+00:00')
			testDestruct('2000-01-01', '2000-01-01', '', '+00:00')
		},
	},
	mounted() {
		const n = this.value
		this.date = n ? n.substring(0, 10) : ''
		this.time = n ? n.substring(11, 16) : ''
		this.timezone = n ? n.substring(16, 32) : ''
	},
}
</script>

<style scoped>
.clear { color: rgb(0, 89, 200); cursor: pointer; padding-top: 10px; }
</style>
