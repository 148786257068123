import { render, staticRenderFns } from "./RoleConditionEditor.vue?vue&type=template&id=460c44d3&scoped=true&"
import script from "./RoleConditionEditor.vue?vue&type=script&lang=ts&"
export * from "./RoleConditionEditor.vue?vue&type=script&lang=ts&"
import style1 from "./RoleConditionEditor.vue?vue&type=style&index=1&id=460c44d3&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "460c44d3",
  null
  
)

export default component.exports