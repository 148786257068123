<template>
	<div class="media">
		<StackingView
			ref="view"
			:link="{ id: $route.params.id, type: 'Entry' }"
			sysType="entry"
			@close="$router.push(base + '/entries')"
		/>
	</div>
</template>

<script>
import StackingView from '../components/StackingView.vue'
import ProviderMixin from './ProviderMixin.js'

export default {
	name: 'ContentEdit',
	components: { StackingView, },
	mixins: [ ProviderMixin ],
	inject: [ 'base' ],
	methods: {
		routerBeforeEach(to, from, next) {
			this.$refs.view.routerBeforeEach(to, from, next)
		},
	},
}
</script>
