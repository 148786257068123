<template>
	<div style="display: relative; padding: 0;">
		<!-- TODO: move wrap to a dialog comp -->
		<h2 style="width: 100%;">Add existing entries</h2>
		<div class="body" style="position: absolute; left: 0; right: 0; top: 60px; bottom: 60px;">
			<div class="top">
				<div>Search for an entry:</div>
				<div class="mode">
					<span v-if="mode == 'all'" @click="mode = 'selected'">Show selected entries ({{ count }})</span>
					<span v-else @click="mode = 'all'">Show all entries</span>
				</div>
			</div>
			<EntryFilter
				v-model="filter"
				:def="def"
				:allowedContentTypes="allowedContentTypes"
				style="position: relative; margin-bottom: 15px; z-index: 1000;"
			/>
			<!-- TODO: we should probably not emit directly - only after user hits OK -->
			<EntryList
				:filter="filter"
				:selectable="true"
				:allowedContentTypes="allowedContentTypes"
				style="position: absolute; left: 25px; right: 25px; top: 100px; bottom: 20px; padding-top: 20px;"
				@input="selected = $event; $emit('input', selected)"
				:mode="mode"
			/>
		</div>
		<div class="foot" style="position: absolute; left: 0; right: 0; bottom: 0; padding: 25px;">
			<ActionButton class="cancel" @click="cancel" data-cy="cancel">
				Cancel
			</ActionButton>
			<Menu ref="addMenu" class="addMenu" :autoCloseOnClick="false">
				<TypePickerList
					@select="createEntry($event)"
					@close="menuOpen = false"
					:allowedContentTypes="allowedContentTypes"
				/>
			</Menu>
			<ActionButton v-if="supportsCreate" @click="$refs.addMenu.open()" class="ok" style="position: relative;">
			<!-- menuOpen = !menuOpen -->
				<mdi plus /> Create new entry <mdi chevron-down />
			</ActionButton>
			<ActionButton class="ok" :disabled="count == 0" @click="$emit('ok', selected)" data-cy="confirm">
				<span v-if="count == 0">Select entries</span>
				<span v-if="count == 1">Insert 1 entry</span>
				<span v-if="count > 1">Insert {{ count }} entries</span>
			</ActionButton>
		</div>
	</div>
</template>

<script>
import ActionButton from '../ActionButton.vue'
import Menu from '../Menu.vue'
import TypePickerList from '../TypePickerList.vue'
// TODO: single-select-mode
//       list-select event -> close dialog, emit

import EntryFilter from './EntryFilter.vue'
import EntryList from './EntryList.vue'
import EntryApiMixin from '../EntryApiMixin'

export default {
	name: 'EntryPicker',
	mixins: [ EntryApiMixin ],
	components: { EntryFilter, EntryList, ActionButton, TypePickerList, Menu },
	inject: [ 'endpoint' ],
	props: {
		locale: String,
		// { contentType: '', search: '', filters: [ { 'id': 0.9246, 'field': 'updatedAt', 'scope': 'sys', 'type': 'Date', 'mode': 'lt', 'value': '2022-08-17', 'query': { 'k': 'sys.updatedAt[lt]', 'v': '2022-08-17 '} } ] }
		filter: Object,
		def: Object,
		allowedContentTypes: Array,
	},
	data: () => ({
		mode: 'all',
		selected: [],
		menuOpen: false,
	}),
	computed: {
		count() {
			return this.selected.length
		},
		supportsCreate() {
			return !!this.$listeners.create
		},
	},
	methods: {
		async cancel() {
			this.$emit('cancel')
		},
		async createEntry(contentType) {
			this.menuOpen = false
			if (typeof contentType == 'string')
				contentType = window['typeLookup'][contentType]
			const entry = await this.$httpPost(this.endpoint + '/entries', this.newEntry(), {
				headers: {
					'X-Contentful-Content-Type': contentType.sys.id,
				},
			})
			const link = { sys: { id: entry.sys.id, linkType: 'Entry', type: 'Link' } }
			this.$emit('create', entry)
		},
	},
}
</script>

<style scoped>
h2 { margin: 0; padding: 20px 25px; font-size: 16px; font-weight: 600; border-bottom: 1px solid #ddd; text-align: left; }
.body { padding: 17px 25px; }
button[disabled] { opacity: 0.5; }
.foot { display: flex; gap: 10px; justify-content: flex-end; padding: 20px 0; }
.foot button.cancel { color: rgb(17, 27, 43); background-color: white; }
.foot button.ok { color: white; background-color: rgb(0, 133, 57); border: 0; }
.top { display: flex; -webkit-box-pack: justify; justify-content: space-between; font-size: 14px; margin-bottom: 15px; letter-spacing: -0.5px; }
.mode { color: rgb(0, 89, 200); cursor: pointer; font-weight: 500; letter-spacing: 0px; }
.mode:hover { text-decoration: underline; }
</style>