<template>
	<div class="GraphQueries">
		<div class="section">
			<h2>Queries</h2>
			<div class="queries">
				<div class="query" v-for="query of queries" :key="query.id"
					:style="{ '--col': query.color }"
					@click="select(query)"
				>
					<div class="color"></div>
					<div class="title">{{ query.title }}</div>
				</div>
				<div class="query add" style="{ '--col': transparent }"
					@click="add"
				>
					<div class="color"><mdi plus /></div>
					<div class="title">Add Query</div>
				</div>
			</div>
		</div>
		<Dialog class="Dialog" ref="editFilterDialog" :height="450" :width="600">
			<h1>Edit Query</h1>
			<div v-if="selectedQuery" style="font-size: 14px; padding: 15px 25px; font-weight: normal; position: absolute; top: 56px; bottom: 70px; left: 0; right: 0; overflow-y: auto;">
				<div style="display: flex; gap: 10px;">
					<div>
						<label>
							Title
							<span>(required)</span>
						</label>
						<input v-model="selectedQuery.title" type="text" style="font-size: 14px; border-radius: 6px; border: 1px solid silver; padding: 10px; width: 100%; box-sizing: border-box; margin: 5px 0;" />
					</div>
					<div style="position: relative;">
						<label>
							Color
							<span>(required)</span>
						</label>
						<div class="color" :style="{ '--col': selectedQuery.color }" />
						<input v-model="selectedQuery.color" type="text" style="font-size: 14px; border-radius: 6px; border: 1px solid silver; padding: 10px; width: 100%; box-sizing: border-box; margin: 5px 0;"
							@focus="colorFocussed = true"
							@blur="colorFocussed = false"
						/>
						<div class="colorPalette" v-if="colorFocussed">
							<div class="color" v-for="col of colorPalette" :key="col" :style="{ '--col': col }"
								@mousedown="selectedQuery.color = col"
							></div>
						</div>
					</div>
				</div>
				<div style="position: relative; margin-top: 10px; z-index: 1;">
					<EntryFilter v-model="selectedQuery.filter" :def="def" />
				</div>
					<EntryList
						:filter="selectedQuery.filter"
						style="position: relative; overflow: auto; margin-top: 10px;"
					/>
			</div>
			<div class="buttons">
				<ActionButton class="delete" @click="remove(selectedQuery)">Delete</ActionButton>
				<ActionButton class="primary" @click="confirm(selectedQuery)">OK</ActionButton>
			</div>
		</Dialog>
	</div>
</template>

<script>
import ActionButton from '../ActionButton.vue'
import Dialog from '../Dialog.vue'
import EntryFilter from '../fields/EntryFilter.vue'
import EntryList from '../fields/EntryList.vue'

// TODO: delete query

export default {
	name: 'GraphQueries',
	components: { Dialog, EntryFilter, EntryList, ActionButton },
	props: {
		queries: Array,
	},
	data: () => ({
		selectedQuery: null,
		// TODO: ??
		def: {"id":"x","name":"x","type":"Array","localized":false,"required":false,"validations":[],"disabled":false,"omitted":false,"items":{"type":"Link","validations":[],"linkType":"Entry"}},
		colorFocussed: false,
		colorPalette: [ '#3C6', '#63A', '#C39', '#A90', '#D79', '#5CE', '#FC5', '#8C9' ],
	}),
	methods: {
		add() {
			this.selectedQuery = {
				id: Math.random() * 99999,
				title: 'New Query',
				color: '#000',
				filter: { contentType: null, search: '', filters: [], columns: null, order: null },
			}
			this.select(this.selectedQuery)
		},
		select(query) {
			this.$emit('select', query)
			this.selectedQuery = JSON.parse(JSON.stringify(query))
			this.$refs.editFilterDialog.open()
		},
		confirm(query) {
			const idx = this.queries.findIndex(q => q.id == query.id)
			if (idx >= 0)
				this.queries.splice(idx, 1, query)
			else
				this.queries.push(query)
			this.$refs.editFilterDialog.close()
			this.selectedQuery = null
		},
		remove(query) {
			// TODO: we should also delete the nodes related ONLY to this query (?)
			const idx = this.queries.findIndex(q => q.id == query.id)
			if (idx > -1)
				this.queries.splice(idx, 1)
			this.selectedQuery = null
			this.$refs.editFilterDialog.close()
		},
	},
}
</script>

<style scoped>
.queries { display: flex; flex-direction: column; gap: 5px; margin-top: 10px; }
.query { display: flex; align-items: center; gap: 5px; cursor: pointer; }
.color { background-color: var(--col); width: 20px; height: 20px; border-radius: 10px; }
.Dialog .color { position: absolute; right: 10px; bottom: 15px; z-index: 999; background-color: var(--col); width: 20px; height: 20px; border-radius: 10px; }
.query.add .color { outline: 1.5px dashed var(--primary); color: var(--primary); line-height: 17px; text-align: center; outline-offset: -1px; }
.query.add .title { color: var(--primary); }
button { width: 100%; }
.colorPalette { position: absolute; background: white; border: 2px solid var(--primary); border-top-width: 1px; left: 0; right: 0; top: 54px; border-bottom-left-radius: 5px; border-bottom-right-radius: 5px; padding: 2px 5px; z-index: 9; }
.colorPalette .color { position: static; display: inline-block; width: 12px; height: 12px; margin-right: 5px; cursor: pointer; }
</style>