import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Mys from './MYS'
//import VeauryVuePlugin from 'veaury/webpack'
import MDI from '@/components/MDI.vue'
import ActionButton from '@/components/ActionButton.vue'
import Dialog from '@/components/Dialog.vue'
import SystemTags from '@/components/fields/SystemTags.vue'

Vue.config.productionTip = false
//Vue.use(VeauryVuePlugin, { type: 'react' })

// TODO: sentry wrapping destroys the stack trace in the browser
//       figure out a way to do sentry but keeping the dev workflow working
/*Sentry.init({
	Vue,
	// TODO: configure this via env?
	dsn: 'https://7e9d91729ce2267eadbc922cacf55ee0@o4505822393860096.ingest.sentry.io/4505827039772672',
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
		}),
		new Sentry.Replay(),
		// TODO: other integrations?
	],

	// TODO: configure all this via env?
	// ATT: in prod this should be low!
	// 1.0 = capture 100% of transactions for performance monitoring
	tracesSampleRate: 1.0,
	tracePropagationTargets: [
		'localhost',
		/^https:\/\/contentgraph-editor\.lakeside\.peaksolution\.com/,
		/^https:\/\/editor\.staging\.contenthub\.dev/,
		/^https:\/\/editor\.qa\.contenthub\.dev/,
		/^https:\/\/editor\.contenthub\.dev/,
	],
	// 1.0 = capture 100% of replay of all sessions
	replaysSessionSampleRate: 0.0,
	// 1.0 = capture 100% of replay sessions with an error
	replaysOnErrorSampleRate: 0.0,
})*/

let settings = JSON.parse(window.localStorage.settings ?? 'null')
if (!settings) {
	settings = {
		entryTableLength: 10,
		skin: 'default',
	}
}
let baseHost = location.host.replace('editor.', '')
if (baseHost.indexOf('localhost') != -1)
	baseHost = localStorage.baseHost ? localStorage.baseHost : 'contenthub.dev' // 'qa.contenthub.dev'
settings.baseEndpoint = 'https://cma.' + baseHost
settings.uploadEndpoint = 'https://upload.' + baseHost

// in a deployed environment we hide some errors that are not relevant
// but on localhost we want to have proper error lines which would break otherwise
if (location.href.indexOf('localhost') != -1) {
	const originalConsoleError = console.error
	console.error = (...args) => {
		if (typeof args[0] == 'string') {
			if (args[0].startsWith('[Vue warn]: Injection "endpoint"')) return
			if (args[0].startsWith('Warning: Function components cannot be given refs.')) return
		}
		originalConsoleError(...args)
	}
}

async function init() {
	let token
	let pathname
	const mode = new URLSearchParams(window.location.search).get('mode') ?? 'Standalone'
	if (mode == 'MyServices') {
		if (!document.referrer) {
			alert('Cannot open MyServices mode outside of MyServices')
			throw new Error('no referrer')
		}

		const mys = new Mys(new URL(document.referrer).origin, {
			localeChanged(locale) {
				console.log('localeChanged', locale)
			}
		})

		let [ client, mysUser, config ] = await Promise.all([ mys.getClient(), mys.getUser(), mys.getAppConfig() ])
		console.log('mys', client, mysUser, config)

		pathname = `/spaces/${client.fields.contentfulSpace.de}/environments/master/entries`

		// we allow settings to be overridden in the MyServices app settings
		settings = { ...settings, ...config.settings }

		if (!mysUser?.sys?.id) {
			alert('Could not get user from MyServices.')
			throw new Error('No user')
		}

		token = mysUser.kc_token

		// probably an impersonated user -> show a specific error message
		if (!token) {
			alert('Content-Hub does not support MyServices impersonation. Please login with your own account.')
			throw new Error('No token, probably because of impersonation.')
		}

		/*
		// token exchange
		const response = await fetch(settings.baseEndpoint + '/auth/loginWithExternalToken', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				access_token: mysUser.kc_token,
			})
		})
		token = (await response.json()).token

		if (!token) { throw new Error("not auth token")}
		*/
	}
	else if (mode == 'Standalone') {
		const query = new URLSearchParams(window.location.search)
		const queryToken = query.get('token')
		const storageToken = window.localStorage.token
		token = queryToken ?? storageToken
		if (token == 'null') token = null

		// TODO: decode the token, read the name and expiry and display somewhere

		// when forceLogin is true, we instruct MYS to show the login screen regardless of the current login state
		const forwardToAuthPage = (forceLogin = false) => {
			console.log('resetting token and sending to auth')
			window.localStorage.token = null

			// TODO: this must be removed once mys auth works fully
			if (false) {
				window.location.href = settings.baseEndpoint.replace('//cma.', '//auth.')
				return
			}

			let url = 'https://myservices.alturos.com/auth'
			if (settings.baseEndpoint.indexOf('.qa.') != -1 || settings.baseEndpoint.indexOf('.staging.') != -1)
				url = 'https://myservices.lakeside.peaksolution.com/auth'
			// TODO: have some automatic mode for localhost testing?
			if (location.href.indexOf('localhost:8081') != -1)
				url = 'http://localhost:8080/auth'
			let query = {
				response_type: 'token',
				client_id: 'ContentHub',
				redirect_uri: window.location.href,
				scope: 'login+profile',
				//state: '',
				forceLogin: forceLogin ? 'true' : 'false',
				spaceId: window.location.href.match(/spaces\/([^/]+)/)?.[1],
			}
			window.location.href = url + '?' + new URLSearchParams(query).toString()
		}

		// some pages dispatch a 401 event
		document.body.addEventListener('401', (e: any) => {
			console.log('got 401', e?.detail)
			// 401 happened on a page that was the login target -> prevent loops
			if (location.href.indexOf('token=') != -1) {
				window.alert('probable auth problem. got invalid token!')
				console.error('token', queryToken, e)
				return
			}
			forwardToAuthPage(e?.detail?.byUser)
		})

		if (!token) {
			forwardToAuthPage()
			return
		}
		query.delete('token')
		pathname = window.location.pathname + '?' + query.toString()
	}
	else {
		throw new Error('Unknown mode: ' + mode)
	}

	if (!token) throw new Error('No token')

	window.localStorage.token = token
	router.push(pathname)

	Vue.component('mdi', MDI)
	Vue.component('ActionButton', ActionButton)
	Vue.component('Dialog', Dialog)
	// ATT: i would prefer not to have SystemTags global, but it had to be to make the
	//      extends on AssetFilter work, because otherwise we had some circular dependency
	Vue.component('SystemTags', SystemTags)

	const v = new Vue({
		router,
		render: h => h(App),
		provide() {
			return {
				settings
			}
		},
	}).$mount('#app')

	// we forward the router before event into the application, so each component may intercept it
	router.beforeEach((to, from, next) => {
		const app = v.$children[0] as any
		app.routerBeforeEach(to, from, next)
	})
}
init()
