<template>
	<div class="Filter" style="border-right: 1px solid #eee;">
		<div class="folder" style="border: 0;">
			<div class="item">
				<div class="title">Standard</div>
			</div>
			<div class="view">
				<div class="item">
					<div class="title" @click="selectView({ contentType: null, filters: [], search: '', columns: null })"><mdi all-inclusive /> All</div>
				</div>
				<div class="item">
					<div class="title" @click="setFilter('new')"><mdi star-outline /> New</div>
				</div>
				<div class="item">
					<div class="title" @click="setFilter('draft')"><mdi lightbulb-outline /> Draft</div>
				</div>
				<div class="item">
					<div class="title" @click="setFilter('published')"><mdi check /> Published</div>
				</div>
				<div class="item">
					<div class="title" @click="setFilter('changed')"><mdi delta /> Changed</div>
				</div>
				<div class="item">
					<div class="title" @click="setFilter('myCreated')"><mdi account-outline /> My Created</div>
				</div>
				<div class="item">
					<div class="title" @click="setFilter('myUpdated')"><mdi account-outline /> My Updated</div>
				</div>
			</div>
		</div>
		<div class="folder" v-if="allViews.length > 10">
			<div style="padding: 5px 10px 5px 0;">
				<input type="text" class="input" placeholder="Find.." v-model="filter" />
			</div>
		</div>
		<div>
			<template v-for="folder of uiConfig?.entryListViews ?? []">
				<div v-if="visibleIds.has(folder.id)" :key="'f-' + folder.id" class="folder">
					<div class="item folderHead"
						:class="{
							dragtarget: folder == dragTargetFolder && !dragTarget,
						}"
						@dragover="dragover($event, null, folder)"
						ondragover="event.preventDefault(); event.cancelBubble = true;"
						ondragend="event.preventDefault(); this.style.opacity = '1';"
						ondrop="event.cancelBubble = true;"
					>
						<div class="title">
							{{ folder.title }}
						</div>
						<button @click="$refs['folderMenu-' + folder.id][0].open()" class="dots">
							<mdi dots-horizontal />
						</button>
						<Menu :ref="'folderMenu-' + folder.id">
							<ul>
								<li @click="create(currentFilter, folder)">Save current Filter...</li>
								<li @click="createFolder(folder)">Create folder...</li>
								<li @click="deleteFolder(folder)" v-if="folder.title != 'Custom'">Delete folder...</li>
							</ul>
						</Menu>
					</div>
					<template v-for="view of folder.views">
						<div
							v-if="visibleIds.has(view.id)"
							:key="'v-' + view.id"
							class="view"
							:class="{
								selected: view.id == selectedViewId,
								dragtarget: view == dragTarget,
							}"
							@click="selectView(view)"
							draggable
							@dragstart="dragstart($event, view, folder)"
							@dragover="dragover($event, view, folder)"
							@dragend="dragend($event)"
							ondragstart="event.cancelBubble = true; event.target.style.opacity = '0.6';"
							ondragover="event.preventDefault(); event.cancelBubble = true;"
							ondragend="event.preventDefault(); this.style.opacity = '1';"
							ondrop="event.cancelBubble = true;"
						>
							<div class="item">
								<div class="title">
									<mdi bookmark-outline />
									{{ view.title }}
								</div>
								<button @click.prevent.stop="$refs['viewMenu-' + folder.id + '-' + view.id][0].open()" class="dots">
									<mdi dots-horizontal />
								</button>
								<Menu :ref="'viewMenu-' + folder.id + '-' + view.id">
									<ul>
										<li class="heading">{{ view.title }}</li>
										<li v-if="view.id == selectedViewId" @click.stop="update(view, currentFilter)">update</li>
										<li @click.stop="rename(view)">rename</li>
										<li @click.stop="remove(view)">delete</li>
									</ul>
								</Menu>
							</div>
						</div>
					</template>
				</div>
			</template>
		</div>
		<div v-if="!uiConfig?.entryListViews?.[0]?.views?.length">
			<button @click="create(currentFilter)" class="create" style="margin-top: 10px;">Save Filter&hellip;</button>
		</div>
	</div>
</template>

<script>
import { columnFieldsForColumnIds, newFilterForStorage } from '../components/fields/FilterUtil'
import Menu from '../components/Menu.vue'

export default {
	name: 'Filters',
	components: { Menu },
	inject: [ 'space', 'spaceId', 'base', 'baseEndpoint', 'me' ],
	props: {
		currentFilter: Object,
		name: { type: String, default: null },
	},
	data: () => ({
		uiConfig: null,
		selectedViewId: null,
		filter: '',
		dragging: null,
		draggingFolder: null,
		dragTarget: null,
		dragTargetFolder: null,
	}),
	computed: {
		visibleIds() {
			if (!this.uiConfig) return []
			const search = this.filter.toLowerCase()
			const r = new Set()
			this.uiConfig.entryListViews.forEach(f => {
				if (!search || f.title?.toLowerCase?.()?.includes?.(search)) {
					r.add(f.id)
				}
				f.views.forEach(v => {
					if (search && !v.title?.toLowerCase?.()?.includes?.(search)) return
					r.add(f.id)
					r.add(v.id)
				})
			})
			return r
		},
		allViews() {
			if (!this.uiConfig) return []
			const r = []
			this.uiConfig.entryListViews.forEach(f => {
				f.views.forEach(v => {
					r.push(v)
				})
			})
			return r
		},
		endpoint() {
			// we want cross-env filters we always load/store on the master env
			return this.baseEndpoint + '/spaces/' + this.spaceId + '/environments/master'
		},
	},
	methods: {
		dragstart(e, view, folder) {
			this.dragging = view
			this.draggingFolder = folder
		},
		dragover(e, view, folder) {
			this.dragTarget = view
			this.dragTargetFolder = folder
		},
		async dragend(e) {
			if (!this.draggingFolder) return
			this.draggingFolder.views = this.draggingFolder.views.filter(v => v.id != this.dragging.id)
			// TODO: drag onto folder title -> insert at first position
			// TODO: drag aftter self: index off..
			const targetIndex = this.dragTargetFolder.views.indexOf(this.dragTarget)
			// TODO: -1
			this.dragTargetFolder.views.splice(targetIndex + 1, 0, this.dragging)

			this.dragTarget = null
			this.dragTargetFolder = null
			this.dragging = null
			this.draggingFolder = null

			await this.save(this.uiConfig)
		},
		// map the current filter and other ui settings to a storeable view
		mapFilterToView(filter, view) {
			view.contentType = filter.contentType
			view.search = filter.search
			view.filters = filter.filters.map(f => f.storage)
			view.columns = filter.columns?.map?.(column => column.id) ?? []
			// TODO: unfortunately our view does not support custom ordering yet..
			// order: { fieldId: "updated", direction: 'ascending' }
			return view
		},
		getNewView(filter) {
			return this.mapFilterToView(filter, {
				id: 'V-' + Math.random(),
				title: 'view',
				// TODO? should we support this?
				roles: [],
			})
		},
		async load(name = undefined) {
			if (!name) name = this.name
			try {
				return await this.$httpGet(this.endpoint + '/ui_config' + (name ? '/' + name : ''))
			}
			catch (e) {
				return {
					assetListViews: [],
					entryListViews: [
						// TODO: these are actually folders
						{
							id: 'F-' + Math.random(),
							title: 'Custom',
							views: [],
						},
					],
					homeViews: [],
					sys: { /*id: 'SHARED'*/ },
				}
			}
		},
		async save(data, name = undefined) {
			if (!name) name = this.name
			const url = this.endpoint + '/ui_config' + (name ? '/' + name : '')
			try {
				await this.$httpGet(url)
			}
			catch (e) {
				return await this.$httpPost(url, data)
			}
			return await this.$httpPut(url, data)
		},
		async create(filter, folder = undefined, name = undefined) {
			const view = this.getNewView(filter)
			view.title = window.prompt('Title', filter.search ?? '')
			if (!view.title) return

			const id = 'VF-' + Math.random()
			let uiConfig = await this.load(name)
			if (!uiConfig) {
				uiConfig = {
					assetListViews: [],
					entryListViews: [
						{
							id,
							title: 'Custom',
							views: [],
						},
					],
					homeViews: [],
					sys: { /*id: 'SHARED'*/ },
				}
			}

			// merge in new view
			let found = false
			uiConfig.entryListViews.forEach(f => {
				if (!folder || f.id != folder.id) return
				// when we find the given folder -> add there
				f.views.push(view)
				found = true
			})
			if (!found) {
				// create the default folder if it doesnt exist
				if (!uiConfig.entryListViews[0])
					uiConfig.entryListViews[0] = { id, title: 'Custom', views: [] }
				// else just add to the first folder
				uiConfig.entryListViews[0].views.push(view)
			}

			await this.save(uiConfig, name)
			this.uiConfig = uiConfig
			this.selectedViewId = id
		},
		async createFolder(afterFolder = undefined) {
			// TODO: implement 'afterFolder' logic
			// TODO: only allow certain names
			// TODO: prevent duplicates
			const title = window.prompt('Title', '')
			if (!title) return
			const id = 'F-' + Math.random()
			let uiConfig = await this.load(this.name)
			uiConfig.entryListViews.push({
				id,
				title,
				views: [],
			})
			await this.save(uiConfig)
			this.uiConfig = uiConfig
		},
		async deleteFolder(folder) {
			if (!window.confirm('Delete folder ' + folder.title + '?')) return
			let uiConfig = await this.load(this.name)
			uiConfig.entryListViews = uiConfig.entryListViews.filter(f => f.id != folder.id)
			await this.save(uiConfig)
			this.uiConfig = uiConfig
		},
		async remove(view, name = undefined) {
			let uiConfig = await this.load(name)
			uiConfig.entryListViews.forEach(f => {
				f.views = f.views.filter(v => v.id != view.id)
			})
			await this.save(uiConfig, name)
			this.uiConfig = uiConfig
		},
		async rename(view, name = undefined) {
			const title = window.prompt('Title', view.title)
			if (!title) return
			let uiConfig = await this.load(name)
			uiConfig.entryListViews.forEach(f => {
				f.views.forEach(v => {
					if (v.id != view.id) return
					v.title = title
				})
			})
			await this.save(uiConfig, name)
			this.uiConfig = uiConfig
		},
		async update(view, filter, name = undefined) {
			let uiConfig = await this.load(name)
			uiConfig.entryListViews.forEach(f => {
				f.views.forEach(v => {
					if (v.id != view.id) return
					this.mapFilterToView(filter, v)
					console.log('MAPPED', v, 'from', filter)
				})
			})
			await this.save(uiConfig, name)
			this.uiConfig = uiConfig
		},
		selectView(view) {
			// TODO: write directly back to currentFilter instead?
			this.$emit('setFilter', {
				contentType: view.contentType,
				filters: view.filters.map(f => newFilterForStorage(f)),
				search: view.search,
				columns: columnFieldsForColumnIds(view.columns),
				order: view.order,
			})
			this.selectedViewId = view.id
		},
		setFilter(name) {
			if (name == 'new') {
				this.$emit('setFilter', {
					contentType: this.currentFilter.contentType,
					filters: [ newFilterForStorage([ 'createdAt', 'gt', new Date().toISOString().substring(0, 10) ]) ],
					search: '',
					columns: this.currentFilter.columns,
					order: [ '-sys.updatedAt' ],
				})
				return
			}
			if (name == 'myCreated') {
				this.$emit('setFilter', {
					contentType: this.currentFilter.contentType,
					// TODO: how to get current user?
					filters: [ newFilterForStorage([ 'createdBy', 'eq', this.me.sys.id ]) ],
					search: '',
					columns: this.currentFilter.columns,
					order: [ '-sys.updatedAt' ],
				})
				return
			}
			if (name == 'myUpdated') {
				this.$emit('setFilter', {
					contentType: this.currentFilter.contentType,
					// TODO: how to get current user?
					filters: [ newFilterForStorage([ 'updatedBy', 'eq', this.me.sys.id ]) ],
					search: '',
					columns: this.currentFilter.columns,
					order: [ '-sys.updatedAt' ],
				})
				return
			}

			const addStatusFilter = (status) => {
				this.$emit('setFilter', {
					contentType: this.currentFilter.contentType,
					filters: [ newFilterForStorage([ 'status', 'eq', status ]) ],
					search: '',
					columns: this.currentFilter.columns,
					order: [ '-sys.updatedAt' ],
				})
			}
			if (name == 'draft') {
				return addStatusFilter(name)
			}
			if (name == 'published') {
				return addStatusFilter(name)
			}
			if (name == 'changed') {
				return addStatusFilter(name)
			}
		},
	},
	async mounted() {
		// TODO: currently we load twice - once here and once in Root..
		this.uiConfig = await this.load(this.name)
	},
}
</script>

<style scoped>
.Filter { padding: 0 0 0 0; width: 260px; font-size: 11pt; }
.mdi { color: #999; }
.item { padding: 8px 10px; display: flex; gap: 5px; }
.title { flex-grow: 1; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
.dots { background: transparent; border: 0; font-size: 14pt; cursor: pointer; }
.folder { border-top: 1px solid #eee; margin-top: 5px; padding-top: 5px; padding-left: 10px;}
.folder > .item .title { color: #666; font-weight: 500; text-transform: uppercase; letter-spacing: 2px; font-size: 10pt; }
.view > .item { text-transform: capitalize; cursor: pointer; }
.view.selected > .item,
.view > .item:hover { background: var(--page-hover); border-radius: 5px 0 0 5px; }
.view .dots { visibility: hidden; }
.view.selected .dots,
.view:hover .dots { visibility: visible; }
svg { width: 1em; height: 1em; fill: currentColor; }
button.create { white-space: nowrap; background-color: var(--primary); padding: 8px 10px 8px 15px; color: white; border: 0; border-radius: 6px; font-size: 14px; font-weight: 500; cursor: pointer; margin-left: 10px; }

.folderHead,
.view { border-bottom: 2px solid transparent; }
.folderHead.dragtarget,
.view.dragtarget { border-color: var(--primary); }
</style>