<template>
	<div class="JsonTableField" :class="{ error }">
		Columns <input type="number" v-model="columns" class="input dim" min="1" :disabled="disabled" />
		Rows <input type="number" v-model="rows" class="input dim" min="1" :disabled="disabled" />
		<table v-if="value" style="width: 100%;">
			<tr v-for="row, r of value?.tableData ?? []" :key="r">
				<td v-for="val, c of row" :key="r + '_' + c" :class="{ header: r == 0 }">
					<input type="text" v-model="row[c]" class="input cell" @blur="$emit('change', value)" :disabled="disabled" />
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'JsonTableField',
	mixins: [ field ],
	props: {
		value: [ Object, Array ],
	},
	data: () => ({
		autoModel: false,
		rows: 0,
		columns: 0,
	}),
	watch: {
		// TODO: emit changes
		value(n) {
			this.rows = this.value?.tableData?.length ?? 4
			this.columns = this.value?.tableData?.[0]?.length ?? 2
			this.onErrors([
				this.validateRequired(),
			])
		},
		rows(n, o) {
			if (n == o) return
			this.$emit('input', this.newModel())
		},
		columns(n, o) {
			if (n == o) return
			this.$emit('input', this.newModel())
		},
	},
	methods: {
		newModel() {
			const a = []
			for (let r = 0; r < this.rows; r++) {
				const row = []
				for (let c = 0; c < this.columns; c++) {
					const defaultValue = r == 0 ? `Column ${ c + 1 }` : ''
					row.push(this.value?.tableData?.[r]?.[c] ?? defaultValue)
				}
				a.push(row)
			}
			return { tableData: a }
		},
		validate() {
			this.onErrors([
				this.validateRequired(),
			])
		},
	},
	mounted() {
		this.rows = this.value?.tableData?.length ?? 4
		this.columns = this.value?.tableData?.[0]?.length ?? 2
		if (!this.value) {
			const a = Array(this.rows).fill(Array(this.columns).fill(''))
			this.$emit('input', { tableData: a })
		}
	},
}
</script>

<style scoped>
.dim { width: 60px; margin-right: 10px; text-align: center; }
.cell { text-align: center; }
.header .cell { font-weight: bold; }
</style>
