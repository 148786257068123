<template>
	<div class="GoogleMap"
		:class="{
			expanded,
		}"
	>
		<div ref="map" class="map" id="map" />
		<div class="crosshair" v-if="crosshairs" />
		<button class="expand" v-if="!expanded" @click="expanded = !expanded"><mdi arrow-expand-vertical /></button>
		<button class="collapse" v-if="expanded" @click="expanded = !expanded"><mdi arrow-collapse-vertical /></button>
	</div>
</template>

<script>
// TODO: make this comp fully reusable for LocationField!
// TODO: try this filter once the key works again
//       filter: brightness(0.5) sepia(1) hue-rotate(-155deg) saturate(11.5) brightness(1.4);
// TODO: do the bounds-fitting for the expanded view, not the collapsed one
// TODO: emit click event -> then scroll to that in list?
// TODO: markers: detail panel?

export default {
	name: 'Map',
	inject: [ 'defaultLocale' ],
	props: {
		entries: Array,
		field: Object,
		crosshairs: Boolean,
		init: Function,
		editPolyline: String,
	},
	data: () => ({
		lat: '',
		lon: '',
		id: 'LF_' + Math.random(),
		map: null,
		geocoder: null,
		google: null,
		timeout: null,
		mode: 'address',
		address: '',
		expanded: false,
	}),
	watch: {
		// ATT: we have to set in the order { lon, lat }
		//      because contentful does it like that and our autosave compares between what it gets from CF and the current value
		lat(n) {
			const v = { lat: n, lon: this.lon }
			this.$emit('input', !v.lat && !v.lon ? undefined : v)
		},
		lon(n) {
			const v = { lat: this.lat, lon: n }
			this.$emit('input', !v.lat && !v.lon ? undefined : v)
		},
		value(n) {
			this.onErrors([
				// TODO: is 0/0 a valid required location?
				this.validateRequired(),
			])
			if (n == null) {
				this.address = ''
				return
			}
			this.lat = this.value.lat
			this.lon = this.value.lon
			this.reverseGeocode(this.lat, this.lon)
		},
		entries() {
			if (this.map) this.showMarkers()
		},
		expanded(n) {
			this.$nextTick(() => {
				if (this.expanded)
					this.map.setZoom(this.map.getZoom() + 2)
				else
					this.map.setZoom(this.map.getZoom() - 2)
				//this.showMarkers()
			})
		},
	},
	methods: {
		// TODO: migrate geocoding here from location field?
/* created this with https://boxy-svg.com/app and took the path
<!doctype html>
<svg viewBox="-10.274 -26.206 20.636 26.248" width="20.636" height="26.248">
  <path d="M 10.362 -15.888 C 10.362 -8.084 1.583 -1.014 0.044 0.042 C -1.528 -0.966 -10.274 -8.132 -10.274 -15.888 C -10.274 -22.448 -5.655 -26.206 0.044 -26.206 C 5.742 -26.206 10.362 -21.587 10.362 -15.888 Z" style="fill: rgb(50, 0, 124); paint-order: fill;"></path>
</svg>
 */
		showMarkers() {
			const icon = {
				anchor: new google.maps.Point(0, 0),
				path: 'M 10.362 -15.888 C 10.362 -8.084 1.583 -1.014 0.044 0.042 C -1.528 -0.966 -10.274 -8.132 -10.274 -15.888 C -10.274 -22.448 -5.655 -26.206 0.044 -26.206 C 5.742 -26.206 10.362 -21.587 10.362 -15.888 Z',
				fillColor: 'rgb(3, 111, 228)',
				fillOpacity: 1,
				strokeColor: 'rgb(0, 64, 133)',
				strokeWeight: 1,
				scale: 0.66,
			}
			const bounds = new google.maps.LatLngBounds()
			let count = 0
			this.entries.forEach(entry => {
				const f = entry.fields[this.field?.id]?.[ this.defaultLocale ]
				if (!f) return
				const position = { lat: f.lat, lng: f.lon }
				if (!position.lat || !position.lng) return
				count++
				bounds.extend(position)
				const marker = new google.maps.Marker({
					position,
					map: this.map,
					title: entry.title,
					icon,
				})
				marker.addListener('mouseover', () => {
					this.$emit('hoverEntry', entry)
				})
				marker.addListener('click', () => {
					this.$emit('clickEntry', entry)
				})
			})
			if (!count) {
				bounds.extend({ lat: 47, lng: 11 })
				bounds.extend({ lat: 47, lng: 8 })
			}
			this.map.setCenter(bounds.getCenter())

			// TODO
			// TODO: as a workaround we currently observe the expanded state.
			// we need to fit the bounds to the expanded map, not the collapsed one
			// to do this we manipulate the bounds
			const newBounds = { east: bounds.getNorthEast().lng(), north: bounds.getNorthEast().lat(), south: bounds.getSouthWest().lat(), west: bounds.getSouthWest().lng() }
			const minH = 100
			const maxH = 400
			const w = this.$el.offsetWidth
			const h = maxH //this.$el.offsetHeight
			// 1. fit the bounds into the aspect ration -> new bounds
			// TODO: fit bounds to expanded aspect ratio
			// we need to being north + south closer together until aspect is matched?
			// if the aspect is > w/h
			let dy = Math.abs(newBounds.north - newBounds.south)

			this.map.fitBounds(newBounds)
			if (this.map.getZoom() > 12) this.map.setZoom(12)
		},
	},
	async mounted() {
		const { Map } = await google.maps.importLibrary('maps')
		this.geocoder = new google.maps.Geocoder()

		// TODO: expand [] button to expand window height
		this.map = new Map(document.getElementById('map'), {
			center: { lat: 0, lng: 0 },
			zoom: 8,
			scrollwheel: false,
			fullscreenControl: false,
			mapTypeControl: false,
			navigationControl: false,
			streetViewControl: false,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
		})

		// TODO: support open and closed poly? or separate control for polyline?
		// Define the LatLng coordinates for the polygon's path.
		if (this.editPolyline) {
			const triangleCoords = this.editPolyline.split(' ').map(c => {
				const [ lon, lat, alt ] = c.split(',')
				return { lat: parseFloat(lat), lng: parseFloat(lon) }
			})
			const bermudaTriangle = new google.maps.Polyline({
				path: triangleCoords,
				strokeColor: 'rgb(3, 111, 228)',
				strokeOpacity: 1,
				strokeWeight: 3,
				fillColor: 'rgb(3, 111, 228)',
				fillOpacity: 0.35,
				//editable: true,
			});
			bermudaTriangle.setMap(this.map);
			// fit bounds
			const bounds = new google.maps.LatLngBounds()
			for (const coord of triangleCoords) {
				bounds.extend(coord)
			}
			this.map.fitBounds(bounds)
		}

		if (this.entries) this.showMarkers()
		if (this.init) this.init(this.map)
	},
}
</script>

<style scoped>
.GoogleMap { position: relative; height: 100px; }
.GoogleMap.expanded { height: 400px; }
div { color: gray; }
.map { height: 100%; background: #eee; }
.crosshair { position: absolute; width: 1px; height: 60px; background: #000; top: 50%; left: 50%; transform: translate(-50%, -50%); pointer-events: none; }
.crosshair::before { content: ""; position: absolute; width: 60px; height: 1px; background: #000; top: 50%; left: 50%; transform: translate(-50%, -50%); }
.modeSelector { display: flex; width: 100%; padding: 15px 0 15px 0; }
.modeSelector > div { width: 100%; }
.modeSelector label { margin-left: 5px; }
.coordinates { display: flex; gap: 15px; }
.coordinates .latlon { display: flex; gap: 10px; width: 100%; align-items: center; }

.expand,
.collapse { position: absolute; top: 10px; right: 10px; padding: 5px 8px; background: white; border: 0; border-radius: 20px; font-size: 22px; box-shadow: 0 0 5px rgba(0,0,0,0.1); }
</style>