
import EntryApiMixin from '../EntryApiMixin.js'
import { userName, userEmail } from '../../utils'

// TODO: validation: description required + assignee required
// TODO: update mode (other header, other button text)

export default {
	name: 'TaskForm',
	mixins: [ EntryApiMixin ],
	props: {
		entryId: String,
		value: Object,
		editing: Boolean,
	},
	computed: {
		users() {
			const users = Object.values((window as any).userLookup)
			users.sort((a, b) => {
				a = userName((a as any).sys.id)
				b = userName((b as any).sys.id)
				return (a as string).localeCompare(b as string)
			})
			return users
		},
	},
	methods: {
		keyup(e) {
			if (e.key == 'Enter' && (e.ctrlKey == true || e.metaKey == true)) {
				this.post()
			}
		},
		async post() {
			const r = await this.$httpPost(this.endpoint + '/entries/' + this.entryId + '/tasks', {
				...this.value,
				dueDate: this.value.dueDate ? this.value.dueDate + 'T00:00:00Z' : null,
				assignedTo: { sys: { id: this.value.assignedTo, linkType: 'User', type: 'Link' } },
			})
			this.$emit('post', this.message)
			this.value.body = ''
		},
		async put() {
			const r = await this.$httpPut(this.endpoint + '/entries/' + this.entryId + '/tasks/' + this.value.sys.id, {
				...this.value,
				assignedTo: { sys: { id: this.value.assignedTo, linkType: 'User', type: 'Link' } },
			})
			this.$emit('post', this.message)
		},
		userName,
		userEmail,
	},
}
