
import EntryApiMixin from './EntryApiMixin'
import MultiTagDialog from './MultiTagDialog.vue'
import SpinnerOct from './SpinnerOct.vue'

async function sleep(ms: number) { return new Promise(r => setTimeout(r, ms)) }

// TODO: use EntryApiMixin instead of some of these?
export default {
	mixins: [ EntryApiMixin ],
	components: { MultiTagDialog, SpinnerOct },
	inject: [ 'endpoint', 'defaultLocale', 'fallbackLocale', 'permissions' ],
	props: {
		selectedEntries: Array,
	},
	data: () => ({
		loadingAction: null,
		// id => { title: '', status: 'pending' | 'progress' | 'success' | 'error', message: '' }
		bulkStatus: {
			/*1: { title: 'one', status: 'success', message: '' },
			2: { title: 'two', status: 'error', message: 'Something went wrong' },
			3: { title: 'three', status: 'progress', message: '' },
			4: { title: 'four', status: 'pending', message: '' },*/
		},
	}),
	computed: {
		selectedEntriesPublished() {
			return this.selectedEntries.filter(entry => {
				// TODO: refactor: introduce a util func getStatus - this is also in EntryStatus end in EntryTable
				if (entry.sys.archivedAt) return false
				if (!entry.sys.publishedAt) return false
				if (entry.sys.updatedAt > entry.sys.publishedAt) return false
				return true
			})
		},
		selectedEntriesChanged() {
			return this.selectedEntries.filter(entry => {
				// TODO: refactor: introduce a util func getStatus - this is also in EntryStatus end in EntryTable
				if (entry.sys.archivedAt) return false
				if (!entry.sys.publishedAt) return false
				if (entry.sys.updatedAt > entry.sys.publishedAt) return true
				return false
			})
		},
		canDuplicate() {
			if (!this.iCanAll('upsert')) return false
			return this.selectedEntries.length != 0
		},
		canUnpublish() {
			if (!this.iCanAll('publish')) return false
			return this.selectedEntriesPublished.length != 0 || this.selectedEntriesChanged.length != 0
		},
		canPublish() {
			if (!this.iCanAll('publish')) return false
			return this.selectedEntriesPublished.length != this.selectedEntries.length
		},
		canArchive() {
			if (!this.iCanAll('archive')) return false
			return this.selectedEntriesPublished.length == 0 && this.selectedEntriesChanged.length == 0
		},
		canManageTags() {
			if (!this.iCanAll('upsert')) return false
			 return this.selectedEntries.length != 0
		},
		canDelete() {
			if (!this.iCanAll('delete')) return false
			return this.selectedEntriesPublished.length == 0 && this.selectedEntriesChanged.length == 0
		},
	},
	methods: {
		iCanAll(action) {
			for (const entry of this.selectedEntries) {
				if (!this.permissions.iCan(action, entry))
					return false
			}
			return true
		},
		async bulk(name, action) {
			this.loadingAction = name
			const bulkStatus = {}
			let errorCount = 0
			for (const entry of this.selectedEntries) {
				bulkStatus[entry.sys.id] = {
					// TODO: use title field instead
					title: entry.fields?.title?.[this.defaultLocale],
					status: 'pending',
					message: null,
				}
			}
			this.bulkStatus = bulkStatus
			for (const entry of this.selectedEntries) {
				try {
					bulkStatus[entry.sys.id].status = 'progress'
					await sleep(200)
					await action(entry)
					bulkStatus[entry.sys.id].status = 'success'
				}
				catch (e) {
					bulkStatus[entry.sys.id].status = 'error'
					bulkStatus[entry.sys.id].message = e.message
					errorCount++
				}
			}
			if (!errorCount) {
				await sleep(1000)
				this.$emit('complete')
			}
		},
		async bulkDuplicate() {
			if (!window.confirm(`Duplicate ${ this.selectedEntries.length } entries?`)) return
			this.bulk('duplicate', async (e) => await this.duplicate(e))
		},
		// TODO: there are actually bulk actions in the api..
		//       https://www.contentful.com/developers/docs/references/content-management-api/#/reference/bulk-actions
		//       but only for publish and unpublish
		async bulkDelete() {
			// TODO: show dialog:
			//       permanently delete X entries?
			//       Once you delete an entry, it’s gone for good and cannot be retrieved. We suggest archiving if you need to retrieve it later.
			//       cancel, archive instead, permanently delete
			if (!window.confirm(`Permanently delete ${ this.selectedEntries.length } entries?`)) return
			this.bulk('delete', async (e) => await this.delete(e))
		},
		async bulkArchive() {
			// TODO: show dialog?
			if (!window.confirm(`Archive ${ this.selectedEntries.length } entries?`)) return
			this.bulk('archive', async (e) => await this.archive(e))
		},
		async bulkPublish() {
			// TODO: show dialog?
			if (!window.confirm(`Publish ${ this.selectedEntries.length } entries?`)) return
			this.bulk('publish', async (e) => await this.publish(e))
		},
		async bulkUnublish() {
			// TODO: show dialog?
			if (!window.confirm(`Publish ${ this.selectedEntries.length } entries?`)) return
			this.bulk('unpublish', async (e) => await this.unpublish(e))
		},
		async duplicate(model) {
			const typeId = model.sys.contentType.sys.id
			model = await this.$httpGet(this.endpoint + '/entries/' + model.sys.id)
			model = JSON.parse(JSON.stringify(model))
			delete model.sys
			if (this.model?.fields?.title) {
				for (const locale in model.fields.title) {
					model.fields.title[locale] = model.fields.title[locale] + ' (copy)'
				}
			}
			model = await this.createEntry(typeId, this.newEntry(model))
		},
		// TODO: use EntryApiMixin instead
		async publish(entry) {
			const r = await this.$httpPut(this.endpoint + '/entries/' + entry.sys.id + '/published', null, {
				headers: {
					'X-Contentful-Version': entry.sys.version,
				},
			})
			if (r?.sys?.version) entry.sys.version = r.sys.version
			if (r?.sys?.publishedAt) entry.sys.publishedAt = r.sys.publishedAt
		},
		async unpublish(entry) {
			const r = await this.$httpDelete(this.endpoint + '/entries/' + entry.sys.id + '/published', {
				headers: {
					'X-Contentful-Version': entry.sys.version,
				},
			})
			if (r?.sys?.version) entry.sys.version = r.sys.version
			if (r?.sys?.publishedAt) entry.sys.publishedAt = r.sys.publishedAt
		},
		async archive(entry) {
			const r = await this.$httpPut(this.endpoint + '/entries/' + entry.sys.id + '/archived', null, {
				headers: {
					// TODO: is this really needed? what for?
					'X-Contentful-Skip-Transformation': true,
				},
			})
			if (r?.sys?.version) entry.sys.version = r.sys.version
			if (r?.sys?.archivedAt) entry.sys.archivedAt = r.sys.archivedAt
		},
		async delete(entry) {
			await this.$httpDelete(this.endpoint + '/entries/' + entry.sys.id)
			// TODO: how to purge entry from list?
		},
	},
}
