
import { field } from './FieldMixin.js'
import QrcodeVue from 'qrcode.vue'
import ActionButton from '../ActionButton.vue'

export default {
	name: 'QrGeneratorField',
	components: { QrcodeVue, ActionButton },
	mixins: [ field ],
	inject: [ 'uiConfig' ],
	props: {
		value: String,
	},
	watch: {
		value(n) {
			this.onErrors([
				this.validateRequired(),
			])
		},
	},
	methods: {
		generate() {
			const env = this.uiConfig.additional?.qrGeneratorEnvironment
			const code = Math.random().toString(36).substring(2, 12)
			const url = 'http://alturos.com/q/' + env + '/' + code
			this.model = url
		},
	},
	mounted() {
		this.$nextTick(() => {
			if (!this.value) {
				this.generate()
			}
		})
	},
}
