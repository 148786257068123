<template>
	<div class="Users" style="height: 100%;">
		<Head>
			<div class="heading" style="padding-left: 25px;">
				<h1>
					Users
				</h1>
			</div>
			<div class="actions">
				<ActionButton class="add" @click="startAdd">&plus; Add user</ActionButton>
			</div>
		</Head>
		<div class="body" style="display: flex; height: 100%;">
			<div class="BaseTable" style="margin: 30px; flex: 1 1 0;">
				<table>
					<tr>
						<th>Name</th>
						<th>Email</th>
					</tr>
					<tr v-for="user of users" :key="user.key"
						@click="startEdit(user)"
					>
						<td v-if="user.firstName != user.email">
							{{ user.firstName }}
							{{ user.lastName }}
						</td>
						<td v-else>
							INVITED
						</td>
						<td>
							{{ user.email }}
						</td>
					</tr>
				</table>
			</div>
			<Sidebar>
				<div class="section">
					Users actually exist on a global level (not on the space level where you currently are), see <a href="/users">Users</a>.<br />
					<br />
					Here you may assign roles to users for this space.<br />
					<br />
					Click an existing user to edit their roles.<br />
				</div>
			</Sidebar>
		</div>
		<Dialog ref="add" :height="400" :width="500">
			<h1>Add Users to this Space</h1>
			<div class="body">
				<label>Enter Email Address</label>
				<input type="text" class="input" placeholder="Email" v-model="email" />
				<div style="margin-top: 15px;">Select a role for the user:</div>
				<!-- TODO: multi-column would be nice for long lists.. -->
				<div v-for="role of roles" :key="role.sys.id" style="margin-top: 10px;">
					<input type="radio" v-model="selectedRoleId" :id="role.sys.id" :value="role.sys.id" style="margin-right: 5px;" />
					<label :for="role.sys.id" :title="role.description">{{ role.name }}</label>
				</div>
			</div>
			<div class="buttons">
				<ActionButton class="cancel" @click="$refs.add.close()">Cancel</ActionButton>
				<ActionButton class="save" @click="saveAdd()" :disabled="!email || !selectedRoleId">Add</ActionButton>
			</div>
		</Dialog>
		<Dialog ref="edit" :height="350" :width="500">
			<h1>Roles of {{ selectedUser?.email }}</h1>
			<div class="body" v-if="selectedUser">
				<!-- TODO: multi-column would be nice for long lists.. -->
				<div v-for="role of roles" :key="role.sys.id" style="margin-top: 10px;">
					<input type="radio" v-model="selectedRoleId" :id="role.sys.id" :value="role.sys.id" style="margin-right: 5px;" />
					<label :for="role.sys.id" :title="role.description">{{ role.name }}</label>
				</div>
			</div>
			<div class="buttons">
				<ActionButton class="delete" @click="del()">Delete</ActionButton>
				<div style="flex-grow: 1;"></div>
				<ActionButton class="cancel" @click="$refs.edit.close()">Cancel</ActionButton>
				<ActionButton class="save" @click="saveEdit()">Save</ActionButton>
			</div>
		</Dialog>
	</div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import Head from './Head.vue'
import Dialog from '../components/Dialog.vue'
import ActionButton from '../components/ActionButton.vue'

// TODO: /spaces/{spaceId}/users/{username}

export default {
	name: 'Users',
	components: { Sidebar, Head, Dialog, ActionButton },
	inject: [ 'base', 'endpoint', 'baseEndpoint', 'spaceId' ],
	data: () => ({
		users: null,
		roles: null,
		// TODO: create a "newUser" object instead of email and selectedRole
		email: '',
		selectedRoleId: null,
		selectedUser: null,
	}),
	methods: {
		async loadUsers() {
			const r = await this.$httpGet(`${ this.baseEndpoint }/spaces/${ this.spaceId }/users?limit=1000`)
			r.items.sort((a, b) => a.email.localeCompare(b.email))
			this.users = r.items
		},
		async loadUser() {
			const r = await this.$httpGet(`${ this.baseEndpoint }/spaces/${ this.spaceId }/users/${ this.selectedUser.email }`)
			this.selectedUser = r
			this.selectedRoleId = r.role.sys.id
		},
		async loadRoles() {
			const r = await this.$httpGet(`${ this.baseEndpoint }/spaces/${ this.spaceId }/roles`)
			this.roles = r.items
		},
		startAdd() {
			this.selectedUser = {
				sys: {
					id: 'new',
				},
			}
			this.selectedRoleId = null
			this.$refs.add.open()
		},
		async saveAdd() {
			const r = await this.$httpPut(`${ this.baseEndpoint }/spaces/${ this.spaceId }/roles/${ this.selectedRoleId }/user/${ this.email }`)
			// TODO: show errors if any
			this.$refs.add.close()
			this.loadUsers()
		},
		async startEdit(user) {
			this.selectedUser = user
			await this.loadUser()
			this.$refs.edit.open()
		},
		async saveEdit() {
			this.email = this.selectedUser.email
			const r1 = await this.$httpDelete(`${ this.baseEndpoint }/spaces/${ this.spaceId }/roles/${ this.selectedUser.role.sys.id }/user/${ this.email }`)
			const r2 = await this.$httpPut(`${ this.baseEndpoint }/spaces/${ this.spaceId }/roles/${ this.selectedRoleId }/user/${ this.email }`)
			this.selectedUser = null
			this.$refs.edit.close()
			this.loadUsers()
		},
		async del() {
			if (!confirm('Are you sure you want to delete this user?')) return
			this.email = this.selectedUser.email
			const r1 = await this.$httpDelete(`${ this.baseEndpoint }/spaces/${ this.spaceId }/roles/${ this.selectedUser.role.sys.id }/user/${ this.email }`)
			this.selectedUser = null
			this.$refs.edit.close()
			this.loadUsers()
		},
	},
	async mounted() {
		document.title = 'Users'
		await this.loadUsers()
		await this.loadRoles()
	},
}
</script>

<style scoped>
table { width: 100%; }
table th { height: auto; padding: 15px; }
</style>
