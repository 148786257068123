
export default {
	name: 'Dialog',
	props: {
		width: Number,
		height: Number,
	},
	data: () => ({
		isOpen: false,
		callback: null,
	}),
	computed: {
		style() {
			return {
				width: this.width ? this.width + 'px' : undefined,
				height: this.height ? this.height + 'px' : undefined,
			}
		},
	},
	methods: {
		open(callback = null) {
			this.isOpen = true
			this.callback = callback
			document.body.classList.add('DialogOpen')
		},
		close(result = null) {
			this.isOpen = false
			if (this.callback) this.callback(result)
			document.body.classList.remove('DialogOpen')
			this.$emit('close')
		},
		cancel() {
			this.close()
			this.$emit('cancel')
		},
		onWheel(e) {
			e.stopPropagation()
			e.preventDefault()
			return false
		},
	},
	// TODO: key listener for ESC
	beforeDestroy() {
		document.body.classList.remove('DialogOpen')
	},
}
