<template>
	<div class="MyServicesBurgerMenu">
		<div class="items">
			<div @click="$refs.menu.open()" class="item">
				<mdi menu />
			</div>
			<div @click="$router.push(base + '/entries')" class="item">
				<mdi fountain-pen-tip />
			</div>
			<div @click="$router.push(base + '/assets')" class="item">
				<mdi image-outline />
			</div>
		</div>
		<Menu ref="menu">
			<ul>
				<!--
				<li @click="$router.push(base + '/entries')" data-cy="locales">
					<mdi fountain-pen-tip />
					Content
				</li>
				<li @click="$router.push(base + '/assets')" data-cy="locales">
					<mdi image-outline />
					Media
				</li>
				-->
				<li class="heading">Environment Settings</li>
				<li @click="$router.push(base + '/settings/locales')" v-if="permissions.userIsAdmin()" data-cy="locales">Locales</li>
				<li @click="$router.push(base + '/settings/tags')" data-cy="tags">Tags</li>
				<li @click="$router.push(base + '/settings/previews')" v-if="permissions.userIsAdmin()" data-cy="tags">Previews</li>
				<li @click="$router.push(base + '/settings/ui_config')" v-if="permissions.userIsAdmin()" data-cy="ui_config">UI Config</li>
				<li @click="$router.push(base + '/settings/scheduled_actions')" data-cy="scheduled_actions">Scheduled Actions</li>
				<li class="heading">Space Settings</li>
				<li @click="$router.push(base + '/settings/environments')" v-if="permissions.userIsAdmin()" data-cy="environments">Environments</li>
				<li @click="$router.push(base + '/settings/users')" v-if="permissions.iCanUse('role')" data-cy="users">Users</li>
				<li @click="$router.push(base + '/settings/roles')" v-if="permissions.iCanUse('role')" data-cy="users">Roles &amp; Permissions</li>
				<li @click="$router.push(base + '/settings/webhooks')" v-if="permissions.userIsAdmin()" data-cy="webhooks">Webhooks</li>
				<li @click="$router.push(base + '/settings/apikeys')" v-if="permissions.userIsAdmin()" data-cy="apikeys">API Keys</li>
				<li @click="openInNew"><mdi open-in-new /> Pop out</li>
			</ul>
		</Menu>
	</div>
</template>

<script>
import Menu from './Menu.vue'
// this menu is a replacement for the MainMenu designed to fit into the
// MyServices NavBar alongside the "head" part of each CE view.

export default {
	components: { Menu },
	name: 'MyServicesBurgerMenu',
	inject: [ 'base', 'permissions' ],
	methods: {
		openInNew() {
			let url = window.location.href
			url += (url.includes('?') ? '&' : '?')
			url += 'token=' + localStorage.getItem('token')
			window.open(url, '_blank')
		},
	},
}
</script>

<style scoped>
.items { display: flex; gap: 0px; }
.item { cursor: pointer; padding: 7px; border-radius: 40px; }
.item:hover { background: #eee; }
.mdi { font-size: 16pt; }
</style>

<style>
.MyServicesBurgerMenu { display: none; }
.MyServicesBurgerMenu svg { vertical-align: middle; }

.skin-mys .MyServicesBurgerMenu { display: block; }
</style>