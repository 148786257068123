export const field = {
	props: {
		locale: String,
		def: Object,
		control: Object,
		title: String,
		wrapper: Object,
		disabled: Boolean,
	},
	data: () => ({
		model: null,
		error: false,
		warning: false,
		autoModel: true,
		modalContent: null,
		modalParent: null,
	}),
	inject: [ 'localeObject' ],
	watch: {
		// 2-way-binding
		value(n) {
			if (!this.autoModel) return
			this.model = n
		},
		model(n) {
			if (!this.autoModel) return
			this.$emit('input', n)
		},
	},
	computed: {
		// we convert the field validations into a structure that is easier to work with in vue
		validations() {
			const r = {
				required: this.required,
			}
			// TODO: is it valid to just chuck in the item validations with the field validations? IMO yes.
			for (const validation of [ ...(this.def?.validations ?? []), ...(this.def?.items?.validations ?? []) ]) {
				for (const k in validation) {
					if (k == 'message') continue
					if (typeof validation[k] != 'object') validation[k] = { value: validation[k] }
					validation[k].message = validation.message
					r[k] = validation[k]

					// TODO: can we write this more elegantly?
					// node validations (RTF) have a special, deeper structure
					if (k == 'nodes') {
						const nodeValidations = {}
						for (const type in r.nodes) { // type = 'embedded-entry-block' | etc.
							if (type == 'message') continue
							nodeValidations[type] = {}
							for (const subval of r.nodes[type]) {
								for (const sk in subval) {
									if (sk == 'message') continue
									nodeValidations[type][sk] = { message: subval.message }
									if (typeof subval[sk] == 'object' && !Array.isArray(subval[sk]))
										Object.assign(nodeValidations[type][sk], subval[sk])
									else
										nodeValidations[type][sk].value = subval[sk]
								}
							}
						}
						r.nodes = nodeValidations
					}
				}
			}
			return r
		},
		required() {
			if (this.localeObject && !this.localeObject.default && this.localeObject.optional) return false
			return this.def?.required
		},
	},
	methods: {
		onFocus(e) {
			this.$emit('focus', e)
			this.$emit('wrapper', [ 'onFocus', e ])
		},
		onBlur(e) {
			this.$emit('blur', e)
			this.$emit('wrapper', [ 'onBlur', e ])
		},
		onErrors(errors) {
			errors = errors.filter(e => !!e)
			this.error = !!errors.length
			this.$emit('errors', errors)
			this.$emit('wrapper', [ 'onErrors', errors ])
		},
		onWarnings(warnings) {
			warnings = warnings.filter(w => !!w)
			this.warning = !!warnings.length
			this.$emit('warnings', warnings)
			this.$emit('wrapper', [ 'onWarnings', warnings ])
		},
		$modal(content = undefined) {
			this.$nextTick(() => {
				if (!content) content = this.$refs.modal
				this.modalContent = content
				const body = document.getElementById('app')
				this.modalParent = content.parentNode
				if (content.parentNode) {
					content.parentNode.removeChild(content)
				}
				body.appendChild(content)
			})
		},
		$unmodal() {
			this.$nextTick(() => {
				try {
					const body = document.getElementById('app')
					const content = this.modalContent
					body.removeChild(content)
					if (this.modalParent?.addChild) {
						this.modalParent.addChild(content)
					}
					this.modalContent = null
				}
				catch (e) {
					console.warn('unmodal failed', e)
				}
			})
		},
		validateRequired() {
			if (this.validations.required && (this.value === undefined || this.value === null || this.value === '' || this.value.length === 0)) {
				return 'Required'
			}
		},
		validateMax() {
			const val = this.validations.size?.max ?? this.max
			if (val === null || val === undefined) return
			if ((this.value?.length ?? 0) > val) {
				 return this.validations.size?.message ?? 'Please shorten the text so it\'s no longer than ' + val + ' characters.'
			}
		},
		validateMin() {
			const val = this.validations.size?.min
			if (val === null || val === undefined) return
			if ((this.value?.length ?? 0) < val) {
				return this.validations.size.message ?? 'Please write more text, so it\'s longer than ' + val + ' characters.'
			}
		},
		validateRangeMax() {
			const val = this.validations.range?.max
			if (val === null || val === undefined) return
			if (this.validations.range?.max && this.value > val) {
				 return this.validations.range?.message ?? 'Please enter a number below or equal to ' + val + '.'
			}
		},
		validateRangeMin() {
			const val = this.validations.range?.min
			if (val === null || val === undefined) return
			if (this.value < val) {
				return this.validations.range?.message ?? 'Please enter a number above or equal to ' + val + '.'
			}
		},
		validateArrayMax() {
			const val = this.validations.size?.max
			if (val === null || val === undefined) return
			if ((this.value?.length ?? 0) > val) {
				 return this.validations.size.message ?? 'Max ' + val + ' items allowed, please remove ' + ((this.value?.length ?? 0) - val) + '.'
			}
		},
		validateArrayMin() {
			const val = this.validations.size?.min
			if (val === null || val === undefined) return
			if ((this.value?.length ?? 0) < val) {
				return this.validations.size.message ?? 'Min ' + val + ' items required, please add ' + (val - (this.value?.length ?? 0)) + '.'
			}
		},
		validateRegexp() {
			if (this.validations.regexp) {
				if (this.value && !new RegExp(this.validations.regexp.pattern, this.validations.regexp.flags ?? undefined).exec(this.value)) {
					return this.validations.regexp.message ?? 'Input does not match the expected format. Please edit and try again.'
				}
			}
		},
		validateProhibitRegexp() {
			if (this.validations.prohibitRegexp) {
				if (new RegExp(this.validations.prohibitRegexp.pattern, this.validations.prohibitRegexp.flags ?? undefined).exec(this.value)) {
					return this.validations.prohibitRegexp.message ?? 'Input does not match the expected format. Please edit and try again.'
				}
			}
		},
		validateIn(allowedValues) {
			if (!allowedValues)
				allowedValues = this.validations.in
			if (!allowedValues?.length) return
			if (this.value === '' || this.value === undefined || this.value === null) return
			if (this.value !== '' && allowedValues && allowedValues.indexOf(this.value) < 0) {
				return this.validations.in.message ?? 'Value must be one of: ' + allowedValues.join(', ')
			}
		},
		validateArrayIn(allowedValues) {
			if (!allowedValues)
				allowedValues = this.validations.in
			if (!allowedValues?.length) return
			// TODO: this will give many same warnings - instead we should explicitely state which item is faulty and either mark or mention that.
			if (this.value && this.value !== '' && allowedValues) {
				for (const value of this.value) {
					if (allowedValues.indexOf(value) < 0) {
						return this.validations.in.message ?? 'Each item must be one of: ' + allowedValues.join(', ')
					}
				}
			}
		},
		validateArrayInLinks(allowedValues) {
			if (!allowedValues)
				allowedValues = this.validations.inLinks?.map(link => link.sys.id)
			if (allowedValues?.length === 0) return
			// TODO: this will give many same warnings - instead we should explicitely state which item is faulty and either mark or mention that.
			if (this.value && allowedValues) {
				for (const value of this.value) {
					if (allowedValues.indexOf(value.sys?.id) < 0) {
						return this.validations.inLinks.message ?? 'Each item must be one of: ' + allowedValues.join(', ')
					}
				}
			}
		},
		// TODO: these 2 dont work like this - the value actually contains a list of links!
		validateTypeIn() {
			// TODO: we cannot validate in this direction:
			//       we only have a link in hand and we dont know its content type
			//       the link view does resolve the type, but that does not filter back into the model of this..
			//       can we make this validator "passive"?
			//       the entry view would actually validate and it emits validation events

			/*const allowedValues = this.validations.in
			if (this.value !== '' && allowedValues && allowedValues.indexOf(this.value) < 0) {
				return this.validations.in.message ?? 'Value must be one of: ' + allowedValues.join(', ')
			}
			return this.validateIn(this.validations.linkContentType)*/
		},
		validateArrayTypeIn() {
			//return this.validateArrayIn(this.validations.linkContentType)
		},
		validateObjectSize() {
			if (!this.value) return
			if (this.validations.size?.min && Object.keys(this.value).length < this.validations.size?.min) {
				 return this.validations.size.message ?? 'Please specify at least ' + this.validations.size?.min + ' properties in this object.'
			}
			if (this.validations.size?.max && Object.keys(this.value).length > this.validations.size?.max) {
				 return this.validations.size.message ?? 'Please do not specify more than ' + this.validations.size?.max + ' properties in this object.'
			}
		},
	},
	mounted() {
		if (!this.autoModel) return
		this.model = this.value
	},
}