<template>
	<div class="PublishButton"
		:class="{ loading, disabled }"
	>
		<div class="animated">
			<!-- TODO: the publish button should be disabled when there are validation messages on the page -->
			<div v-if="isChanged"
				style="width: 100%; display: flex; border-top-right-radius: 0; border-bottom-right-radius: 0;"
			>
				<button class="primary"
					:class="{ disabled }"
					@click="$emit('publish', $event)"
					style="flex: 1; border-top-right-radius: 0; border-bottom-right-radius: 0;"
				>
					Publish changes
				</button>
				<button class="primary" @click="$refs.changeStatusPublish.open()" style="width: 50px; padding: 0; border-top-left-radius: 0; border-bottom-left-radius: 0;">
					<mdi chevron-down style="font-size: 20px;" />
				</button>
			</div>
			<Menu ref="changeStatusPublish" style="position: absolute; width: 140px;">
				<ul>
					<!--<li @click="archiveEntry">Archive</li>-->
					<li @click="$emit('publish')">Publish now</li>
					<li @click="openScheduleDialog" v-if="iCanSchedule"><mdi clock-outline /> Schedule&hellip;</li>
				</ul>
			</Menu>

			<button class="primary" @click="$refs.changeStatus.open()" v-if="isPublishedWithoutChanges">
				Change Status
				<mdi chevron-down style="font-size: 20px; line-height: 10px;" />
			</button>
			<Menu ref="changeStatus" style="position: absolute;">
				<ul>
					<li @click="$emit('unpublish')">Unpublish now</li>
					<li @click="openScheduleDialog" v-if="iCanSchedule"><mdi clock-outline /> Schedule&hellip;</li>
					<!--<li @click="§emit('archive')">Archive</li>-->
				</ul>
			</Menu>
		</div>

		<div class="ScheduledActions">
			<div class="ScheduledAction" v-for="scheduledAction in scheduledActions" :key="scheduledAction.sys.id"
				:class="'action-' + scheduledAction.action"
			>
				<div style="display: flex;">
					<div class="action" style="flex-grow: 1;">
						<mdi clock-outline />
						{{ scheduledAction.action }}
					</div>
					<Menu :ref="'menu-' + scheduledAction.sys.id" style="position: absolute; right: 20px; width: 100px;">
						<ul>
							<li @click="openScheduleDialog(scheduledAction)">Edit&hellip;</li>
							<li @click="deleteScheduledAction(scheduledAction)">Delete</li>
							<li @click="$router.push(base + '/settings/scheduled_actions')" v-if="permissions.userIsAdmin()">See All</li>
						</ul>
					</Menu>
					<mdi dots-horizontal
						v-if="iCanSchedule"
						@click="$refs['menu-' + scheduledAction.sys.id][0].open()"
					/>
				</div>
				@
				{{ formatDatePoint(scheduledAction.scheduledFor.datetime) }}
				{{ formatTimePoint(scheduledAction.scheduledFor.datetime) }}
				<!--{{ scheduledAction.scheduledFor.timezone }}-->
			</div>
		</div>

		<Dialog ref="scheduleDialog" :width="400" :height="460">
			<h1>Schedule</h1>
			<div class="body" style="font-weight: normal;">
				<div style="display: flex; gap: 10px; align-items: center; padding: 20px 0 10px;">
					<input type="radio" id="publish" name="action" value="publish" v-model="action" style="margin: 0;" />
					<label for="publish">Publish</label>
					<input type="radio" id="unpublish" name="action" value="unpublish" v-model="action" style="margin: 0;" />
					<label for="unpublish">Unpublish</label>
				</div>
				<label for="publishDate" v-if="action == 'publish'">Publish date &amp; time</label>
				<label for="publishDate" v-if="action == 'unpublish'">Unpublish date &amp; time</label>
				<input class="date" type="datetime-local" id="publishDate" v-model="datetime" />
				<Info style="margin-top: 0;">Your current time zone will be used.</Info>
				<Info style="margin-top: 0;" type="warn">Please make sure that it will be possible to publish this entry at the given time! Execution errors will be shown in Settings &gt; Scheduled Actions.</Info>
			</div>
			<div class="buttons">
				<ActionButton @click="$refs.scheduleDialog.close()" class="cancel">Cancel</ActionButton>
				<ActionButton @click="scheduleEntry" class="save" :disabled="!datetime">Schedule</ActionButton>
			</div>
		</Dialog>
	</div>
</template>

<script>
import Info from '../views/Info.vue'
import ActionButton from './ActionButton.vue'
import Dialog from './Dialog.vue'
import Menu from './Menu.vue'
import { formatDatePoint, formatTimePoint } from '../utils'
import moment from 'moment'

export default {
	components: { Menu, Dialog, ActionButton, Info },
	inject: [ 'base', 'baseEndpoint', 'spaceId', 'environment', 'permissions' ],
	props: {
		entry: Object,
		disabled: Boolean,
		loading: Boolean,
		showSchedule: { type: Boolean, default: true },
	},
	data: () => ({
		datetime: null,
		editingId: null,
		action: null,
		scheduledActions: [],
	}),
	computed: {
		isPublishedWithoutChanges() {
			return this.entry.sys.publishedAt && this.entry.sys.updatedAt <= this.entry.sys.publishedAt
		},
		isChanged() {
			return !this.entry.sys.publishedAt || this.entry.sys.updatedAt > this.entry.sys.publishedAt
		},
		iCanSchedule() {
			return this.permissions.iCan('publish', this.entry)
		},
	},
	methods: {
		formatDatePoint,
		formatTimePoint,
		openScheduleDialog(scheduledAction = null) {
			// by default we get an event in here..
			if (scheduledAction.button !== undefined) scheduledAction = null
			this.editingId = scheduledAction?.sys?.id
			this.datetime = scheduledAction ? scheduledAction.localDatetime : moment().endOf('day').format().slice(0, 16)
			// TODO: if entry is published, default to unpublish
			this.action = scheduledAction?.action ?? 'publish'
			this.$refs.scheduleDialog.open()
		},
		async loadScheduledActions() {
			try {
				const r = await this.$httpGet(this.baseEndpoint + '/spaces/' + this.spaceId + '/scheduled_actions', {
					'entity.sys.id': this.entry.sys.id,
					'environment.sys.id': this.environment,
					//'order': '-scheduledFor.datetime',
					'sys.status[in]': 'scheduled',
					'scheduledFor.datetime[gte]': moment().toISOString(),
					limit: 1000,
				})
				// map to something that our control understands
				r.items.forEach(item => { item.localDatetime = moment(item.scheduledFor.datetime).format().slice(0, 16) })
				r.items.sort((a, b) => a.localDatetime.localeCompare(b.localDatetime))
				this.scheduledActions = r.items
			}
			catch (e) {}
		},
		async scheduleEntry() {
			if (this.editingId)
				await this.updateScheduledAction()
			else
				await this.createScheduledAction()
		},
		async createScheduledAction() {
			const r = await this.$httpPost(this.baseEndpoint + '/spaces/' + this.spaceId + '/scheduled_actions', {
				entity: { sys: { type: 'Link', linkType: this.entry.sys.type, id: this.entry.sys.id } },
				action: this.action,
				scheduledFor: {
					datetime: moment(this.datetime).format(),
					//timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				},
				environment: { sys: { type: 'Link', linkType: 'Environment', id: this.environment } },
			})
			this.$refs.scheduleDialog.close()
			await this.loadScheduledActions()
		},
		async updateScheduledAction() {
			const r = await this.$httpPut(this.baseEndpoint + '/spaces/' + this.spaceId + '/scheduled_actions/' + this.editingId, {
				entity: { sys: { type: 'Link', linkType: this.entry.sys.type, id: this.entry.sys.id } },
				action: this.action,
				scheduledFor: {
					datetime: moment(this.datetime).format(),
					//timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				},
				environment: { sys: { type: 'Link', linkType: 'Environment', id: this.environment } },
			})
			this.$refs.scheduleDialog.close()
			await this.loadScheduledActions()
		},
		async deleteScheduledAction(scheduledAction) {
			const r = await this.$httpDelete(this.baseEndpoint + '/spaces/' + this.spaceId + '/scheduled_actions/' + scheduledAction.sys.id + '?environment.sys.id=' + this.environment)
			await this.loadScheduledActions()
		},
	},
	async mounted() {
		if (this.showSchedule && this.iCanSchedule)
			await this.loadScheduledActions()
	},
}
</script>

<style scoped>
.PublishButton.disabled { pointer-events: none; cursor: not-allowed; }
.PublishButton.disabled button.primary { background: rgb(147, 147, 147); cursor: not-allowed; }

button.primary {
	background: white;
	color: #333;
	border: 0;
	box-shadow: rgb(25 37 50 / 8%) 0px 1px 0px;
	cursor: pointer;
	display: flex;
	width: 100%;
	overflow: hidden;
	flex-shrink: 0;
	justify-content: center;
	align-items: center;
	font-family: var(--font-stack-primary);
	font-weight: 500;
	text-decoration: none;
	transition: background 0.1s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
	font-size: 0.875rem;
	padding: 13px 30px;
	border-radius: 5px;
	background-color: var(--color-green-mid);
	color: white;
}
button.primary:hover { background-color: var(--color-green-base); }
input.date { padding: 10px; border-radius: 5px; border: 1px solid #ddd; width: 100%; box-sizing: border-box; }

.animated { border-radius: 5px; transition: box-shadow 0.3s ease-out; }
.loading .animated {
	--fp: 4px;
	--fn: -4px;
	--hp: 2px;
	--hn: -2px;	
	box-shadow:
		0 0 var(--fp) -1px rgba(0,0,0,0.3),
		var(--fp) var(--fp) var(--fp) 0 #4b40a999,
		var(--hn) var(--hp) var(--fp) var(--hp) #dd7ed1cc,
		var(--fp) var(--fp) 0 var(--fp) #d38cdd99,
		var(--hp) var(--hn) var(--fp) var(--hp) #aa8bb3,
		var(--fn) var(--fp) 0 var(--fp) #8250c2,
		var(--fp) var(--fn) 0 var(--fp) #7f55bd,
		var(--fn) var(--fn) 0 var(--fp) #d6c4f3;
}

.ScheduledActions { margin-top: 10px; display: flex; flex-direction: column; gap: 5px;}
.ScheduledAction { background: white; border: 1px solid #ddd; padding: 10px; border-radius: 5px; }
.ScheduledAction { border-left: 3px solid var(--col); }
.action-publish { --col: var(--color-green-mid); }
.action-unpublish { --col: orange; }
.action { border-radius: 4px; font-weight: 500; color: var(--col); }
</style>