<template>
	<div>
		<Spinner v-if="loading" style="margin-top: 150px;" />
		<Head :hasBack="true" @back="$router.push(base + '/content_types')" v-if="type && !loading">
			<div class="heading">
				<h1>
					{{ type.name }}
				</h1>
			</div>
			<div class="actions">
				<ActionButton @click="deleteType" class="delete" v-if="id != 'new'" confirm>Delete</ActionButton>
				<ActionButton @click="save" class="save">Save</ActionButton>
			</div>
		</Head>
		<div v-if="type && !loading" class="body" style="display: flex; gap: 20px; flex-direction: column; height: 100%; max-width: 800px; margin: 0 auto; padding: 30px;">
			<div class="row">
				<div>
					<label for="name">Name (required)</label>
					<input id="name" class="field" type="text" v-model="type.name" />
				</div>
				<div>
					<label for="id">Field ID</label>
					<input id="id" class="field" type="text" v-model="id" :disabled="!isNew" />
				</div>
			</div>
			<div class="row">
				<div>
					<label for="description">Description</label>
					<input id="description" class="field" type="text" v-model="type.description" />
				</div>
			</div>
			<div class="fields" v-if="type">
				<div class="BaseTable" style="flex: 1 1 0;">
					<table v-if="type.fields?.length > 0" style="width: 100%;">
						<tr>
							<th></th>
							<th>Name</th>
							<th class="group">Group</th>
							<th>Type</th>
							<th></th>
							<th></th>
						</tr>
						<tr v-for="(field, f) of type.fields" :key="'f' + f"
							draggable
							@dragstart="dragstart($event, field, f)"
							@dragover="dragover($event, field, f)"
							@dragend="dragend($event, field, f)"
							ondrop="event.cancelBubble = true"
						>
							<td style="width: 20px;" @mouseover="dragHot = true" @mouseout="dragHot = false">
								<mdi drag-vertical />
							</td>
							<td>
								{{ field.name }}
								<span v-if="type.displayField == field.id" class="statusLabel">title</span>
							</td>
							<td class="group">
								{{ controlsMap?.[field.id]?.settings?.group }}
							</td>
							<td style="width: 100px;">
								{{ field.type }}
								<span v-if="field.type == 'Array'">({{ field.items.type }})</span>
							</td>
							<td style="width: 80px;" class="icons">
								<mdi asterisk v-if="field.required" />
								<mdi translate v-if="field.localized" />
								<mdi eye-off v-if="field.disabled" />
							</td>
							<td style="width: 50px;">
								<button class="link" @click="editField(field)">Edit</button>
								<!-- TODO: ... menu with options
								set field as entry title
								hide field when
								disable in response
								delete
								-->
							</td>
						</tr>
					</table>
					<button class="addToTable" @click="$refs.create.open()">&plus; Add field</button>
				</div>
				<pre class="code" style="margin-top: 40px;">{{ json }}</pre>
				<FieldTypePicker ref="create" @confirm="addField" />
				<FieldSettingsDialog ref="edit"
					@delete="deleteField($event)"
					@confirm="updateField($event.field, $event.control)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
// http://localhost:8080/spaces/testing/environments/master/content_types/lessonCopy

// TODO: ... menu: delete, duplicate
// TODO: save
// TODO: tabs for fields (nr), settings (dame + description), json preview, sidebar?, editors? etc
// TODO: fields draggable
// TODO: add field

import Spinner from '../components/Spinner.vue'
import LoadingSpinner from '../components/fields/LoadingSpinner.vue'
import FieldTypePicker from '../components/fields/FieldTypePicker.vue'
import EntryApiMixin from '../components/EntryApiMixin.js'
import FieldSettingsDialog from '../components/fields/FieldSettingsDialog.vue'
import Head from './Head.vue'
import Sidebar from './Sidebar.vue'
import ProviderMixin from './ProviderMixin.js'
import ActionButton from '../components/ActionButton.vue'

	async function sleep(ms) {
		return new Promise(r => setTimeout(r, ms))
	}

export default {
	name: 'ContentTypeEdit',
	mixins: [ EntryApiMixin, ProviderMixin ],
	components: { Spinner, LoadingSpinner, FieldTypePicker, FieldSettingsDialog, Head, Sidebar, ActionButton },
	inject: [ 'base', 'endpoint' ],
	data: () => ({
		id: null,
		type: null, // content_type
		editor_interface: null,
		controlsMap: {},
		isNew: false,
		loading: false,
		dragHot: false,
		dragged: null,
		draggedIndex: null,
	}),
	computed: {
		json() {
			return JSON.stringify({ ...this.type, sys: '<REDACTED>' }, null, 2)
		},
	},
	watch: {
		'type.name'(n, o) {
			const oid = this.camelCase(o)
			if (this.id == oid) this.id = this.camelCase(n)
			if (n == '') this.id = ''
		},
		id() {
			this.id = this.id.replace(/[^a-zA-Z0-9_]/g, '')
		},
	},
	methods: {
		dragstart(event, item, i) {
			if (!this.dragHot) {
				event.preventDefault()
				return
			}
			this.dragged = item
			this.draggedIndex = i
			event.cancelBubble = true
			event.target.style.opacity = '0.6'
		},
		dragover(event, item, i) {
			if (!this.dragged) return
			if (i == this.draggedIndex) return
			// remove dragged item at its position
			this.type.fields.splice(this.draggedIndex, 1)
			// insert the dragged item 
			this.type.fields.splice(i, 0, this.dragged)
			this.draggedIndex = i
			event.preventDefault()
			event.cancelBubble = true
		},
		dragend(event, item, i) {
			this.dragged = null
			event.target.style.opacity = '1'
		},
		camelCase(s) {
			return s?.toLowerCase()?.replace?.(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())?.trim() ?? ''
		},
		addField(field) {
			this.type.fields.push(field)
			this.controlsMap[field.id] = { fieldId: field.id, widgetNameSpace: 'builtIn', settings: {} }
			this.editField(field)
		},
		editField(field) {
			this.$refs.edit.open(field, this.controlsMap[field.id], this.type)
		},
		deleteField(field) {
			const index = this.type.fields.findIndex(f => f.id == field.id)
			if (index < 0) throw new Error('Field not found')
			this.type.fields.splice(index, 1)
			delete this.controlsMap[field.id]
		},
		updateField(field, control) {
			const fieldIndex = this.type.fields.findIndex(f => f.id == field.id)
			if (fieldIndex < 0) throw new Error('Field not found')
			this.type.fields.splice(fieldIndex, 1, field)
			const controlIndex = this.editor_interface.controls.findIndex(c => c.fieldId == field.id)
			if (controlIndex >= 0)
				this.editor_interface.controls.splice(controlIndex, 1, control)
			else
				this.editor_interface.controls.push(control)
			this.controlsMap[field.id] = control
		},
		async load() {
			this.loading = true
			try {
				this.type = await this.fetchContentType(this.id)
				try {
					this.editor_interface = await this.$httpGet(this.endpoint + '/content_types/' + this.id + '/editor_interface') ?? { controls: [], sys: { id: this.id } }
				}
				catch {
					// no controls found -> need to create it
					this.editor_interface = {
						controls: [],
						sys: { id: this.id, type: 'EditorInterface' },
					}
				}

				// map to local model
				const controlsMap = {}
				// make sure we have a control for every field
				for (const field of this.type.fields) controlsMap[field.id] = { fieldId: field.id, widgetNameSpace: 'builtIn', settings: {} }
				// make sure we have a settings object for every field
				for (const control of this.editor_interface.controls) controlsMap[control.fieldId] = { settings: {}, ...control }
				this.controlsMap = controlsMap
			}
			catch (e) {
				// TODO: server should give a 404, currently we get a generic 500
				console.error(e)
			}
			this.loading = false
		},
		async deleteType() {
			if (!confirm('Delete content type ' + this.type.name + '?')) return
			await this.$httpDelete(this.endpoint + '/content_types/' + this.type.sys.id)
			this.$router.push(this.base + '/content_types')
		},
		async test() {
			return new Promise(async (resolve, reject) => {
				try {
					await sleep(1000)
					resolve() // Resolve the promise when the save operation is done
				}
				catch (error) {
					reject(error) // Reject the promise if there's an error
				}
			})
		},
		async save() {
			if (this.isNew) this.type.sys.id = this.id
			this.editor_interface.sys.id = this.type.sys.id

			// map local model to server model
			const controls = []
			// only store controls for fields that have a widget
			for (const field of this.type.fields) {
				const control = this.controlsMap[field.id]
				//if (!control.widgetId) continue
				controls.push(control)
			}
			this.editor_interface.controls = controls

			await this.$httpPut(this.endpoint + '/content_types/' + this.type.sys.id, this.type)
			await this.$httpPut(this.endpoint + '/content_types/' + this.type.sys.id + '/editor_interface', this.editor_interface)

			// TODO: navigate to list?

			// on create we forward to the new type's page so the url is correct
			if (this.isNew) this.$router.push(this.base + '/content_types/' + this.type.sys.id)
		},
	},
	async mounted() {
		this.id = this.$route.params.id
		if (this.id == 'new') {
			this.isNew = true
			this.type = {
				name: 'New',
				fields: [],
				sys: { id: 'new' },
			}
			this.editor_interface = {
				controls: [],
				sys: { id: 'new' },
			}
			return
		}
		await this.load()
	},
}			
</script>

<style scoped>
.row { display: flex; gap: 10px; }
.row > * { flex-grow: 1; display: flex; flex-direction: column; gap: 5px; }
.row .field { margin-bottom: 10px; }
.field { display: block; background-color: white; color: rgb(65, 77, 99); font-size: 0.875rem; line-height: 1.25rem; border-radius: 6px; box-shadow: rgb(225 228 232 / 20%) 0px 2px 0px inset; outline: none; border: 1px solid rgb(207, 217, 224); cursor: pointer; padding: 10px 1.5rem 10px 0.75rem; }
.field:focus { border: 1px solid rgb(0, 112, 243); }
.field[disabled] { background: #fafafa; }
.group { color: #aaa; }

.fields { width: 100%; }
.icon { width: 1em; height: 1em; fill: currentColor; }
.icons .mdi { font-size: larger; }
button.addToTable { width: 100%; background-color: transparent; color: gray !important; height: 40px; padding: 8px 10px 8px 15px; color: white; border: 1px dashed gray; border-radius: 6px; font-size: 14px; font-weight: 500; cursor: pointer; }
button.addToTable:hover { background: #f9f9f9; }
</style>