
// TODO: Unique field - check if any other entry with same value
import { field } from './FieldMixin.js'

export default {
	name: 'TextField',
	mixins: [ field ],
	props: {
		value: [ Number, String ],
	},
	data: () => ({
		model: null,
	}),
	// TODO: convert validations structure into something more workable - where?
	computed: {
		min() {
			return this.validations.size?.min
		},
		max() {
			if (this.def.type == 'Symbol')
				return this.validations.size?.max ?? 255
			return this.validations.size?.max ?? 50000
		},
	},
	watch: {
		value(n) {
			// TODO: should we do the validation at the server? CF seems to do that.
			this.onErrors([
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
				this.validateRegexp(),
				this.validateProhibitRegexp(),
				this.validateIn(),
			])
		},
	},
}
