<template>
	<div class="GraphEdit">
		<NodeGraph />
	</div>
</template>

<script>
import NodeGraph from '../components/NodeGraph/NodeGraph.vue'
import ProviderMixin from './ProviderMixin.js'

export default {
	components: { NodeGraph },
	mixins: [ ProviderMixin ],
	inject: [ 'base' ],
}
</script>
