
// TODO: ... menu for delete
// TODO: allow reply?
//       would need parent comment id (and in storage would need to store parent timestamp)
//          order by parent_timestamp, timestamp

import EntryApiMixin from '../EntryApiMixin.js'
import { formatDate, userName } from '../../utils'

export default {
	name: 'Comment',
	mixins: [ EntryApiMixin ],
	props: {
		value: Object,
		entryId: String,
	},
	computed: {
		body() {
			return this.value.body
				.replace(/@([a-z0-9_-]+)/gi, (_, userId) => '@' + userName(userId))
		},
	},
	methods: {
		async del() {
			if (!confirm('Delete comment?')) return
			await this.$httpDelete(this.endpoint + '/entries/' + this.entryId + '/comments/' + this.value.sys.id)
			this.$emit('delete', this.value.sys.id)
		},
		formatDate,
		userName,
	},
}
