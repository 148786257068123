<template>
	<div v-if="node" class="ArrowStarter" style="position: absolute; left: 0;"
		:style="{
			opacity: dragging ? 1 : 0.3,
			'--color': color,
		}"
	>
		<div class="node" :style="{ left: node.x - 25 + 'px', top: node.y - 25 + 'px' }"></div>
		<div class="targetNode" v-if="targetNode" :style="{ left: targetNode.x - 25 + 'px', top: targetNode.y - 25 + 'px' }"></div>
		<Link :value="{ rel: '', id: 0 }" :node1="node" :node2="targetNode ?? mousePos" :query2="{ color }" :offset="targetNode ? 25 : 0" strokeWidth="3" strokeStyle="dotted" />
		<div class="mouse" style="font-size: 20px; white-space: nowrap; position: absolute; margin-left: -5px; margin-top: -5px; background-color: transparent; width: 10px; height: 10px; border-radius: 5px;"
			@mousedown="mousedown"
			@mouseup="mouseup"
			:style="{
				left: mousePos.x + 'px',
				top: mousePos.y + 'px',
			}"
		>
		</div>
	</div>
</template>

<script>
import Link from './Link.vue'
export default {
	name: 'ArrowDragger',
	components: { Link },
	props: {
		zoom: Number,
		pos: Object,
		color: { type: String, default: 'var(--primary)' },
	},
	data: () => ({
		node: null,
		mousePos: { x: 0, y: 0 },
		dragging: false,
		targetNode: null,
	}),
	computed: {
		d() {
			const dx = this.node.x - this.mousePos.x
			const dy = this.node.y - this.mousePos.y
			return Math.sqrt(dx * dx + dy * dy)
		},
	},
	methods: {
		// public
		mouseoverNode(node, e) {
			if (this.dragging)
				this.targetNode = node
			else
				this.node = node
		},
		// public
		mouseoutNode(node, e) {
			if (this.dragging)
				this.targetNode = null
		},
		// public
		mouseupNode(node, e) {
			if (this.dragging) {
				this.dragging = false
				this.$emit('addLink', {
					node1: this.node,
					node2: this.targetNode,
				})
				this.targetNode = null
				this.node = null
			}
		},
		// public
		updateMousePos(p) {
			this.mousePos = p
			if (!this.node) return
			// too far away -> cancel
			if (this.d > 55 && !this.dragging)
				this.node = null
		},
		mousedown(e) {
			if (e.button !== 0) return
			this.dragging = true
			e.preventDefault()
			e.stopPropagation()
		},
		mouseup(e) {
			this.dragging = false
			this.node = null
		},
	},
}
</script>

<style scoped>
.ArrowStarter { cursor: pointer; }
.node,
.targetNode { position: absolute; outline: 3px dotted var(--color); width: 50px; height: 50px; border-radius: 25px; background: transparent; }
</style>