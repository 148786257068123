<template>
	<div class="FilterItem item filter" :class="filter.type" :data-cy="'filteritem-' + filter.field"
		@click="$emit('click', $event)"
		@dblclick="$emit('dblclick', $event)"
		v-if="filter.type != 'Hidden'"
	>
		<label>
			{{ filter.field }}
		</label>

		<span class="mode" v-if="modes?.length">
			<select v-model="filter.mode" data-cy="filteritem-mode"
				:style="{ width: (i18n[filter.mode]?.length + 4) + 'ch' }"
			>
				<option v-for="mode in modes" :key="mode" :value="mode">{{ i18n[mode] }}</option>
			</select>
		</span>

		<input type="text" v-model="filter.value" v-if="filter.type == 'Symbol' && !filter.in" ref="mainInput" :style="{ width: (length + 3) + 'ch' }" data-cy="filteritem-input" />

		<!-- TAGS -->
		<!--
		<select v-model="filter.value" v-if="filter.type == 'SymbolList' && !filter.in && filter.field == 'tags'" :style="{ width: (length + 3) + 'ch' }" data-cy="filteritem-input">
			<option v-for="(option, o) of tags ?? []" :key="'fo-' + o" :value="option.sys.id">{{ option.name }}</option>
		</select>
		-->
		<button v-if="filter.type == 'SymbolList' && !filter.in && filter.field == 'tags'" @click="dialogTags = selectedTags; $refs.tagsPicker.open()">
			{{ getTagLabel(arrayValue[0]) ?? '' }}
			<mdi plus-circle />
			<span v-if="arrayValue.length > 1">{{ arrayValue.length - 1 }}</span>
		</button>
		<Dialog v-if="filter.type == 'SymbolList' && !filter.in && filter.field == 'tags'" ref="tagsPicker" :width="500" :height="300">
			<h1>Select tags</h1>
			<div class="body">
				<SystemTags v-model="dialogTags" />
			</div>
			<div class="buttons">
				<ActionButton @click="$refs.tagsPicker.close()" class="cancel">Cancel</ActionButton>
				<ActionButton @click="selectedTags = dialogTags; $refs.tagsPicker.close()" class="save">Save</ActionButton>
			</div>
		</Dialog>

		<select v-model="filter.value" ref="mainInput" v-if="(filter.type == 'Symbol' || filter.type == 'SymbolList') && filter.in && isArray(filter.in)" data-cy="filteritem-input">
			<option v-for="val of filter.in" :key="val" :value="val">{{ val }}</option>
		</select>

		<select v-model="filter.value" ref="mainInput" v-if="(filter.type == 'Symbol' || filter.type == 'SymbolList') && filter.in && isObject(filter.in)" data-cy="filteritem-input">
			<option v-for="(option, o) of filter.in" :key="'fo-' + o" :value="o">{{ option }}</option>
		</select>

		<input type="text" v-model="filter.value" ref="mainInput" v-if="filter.type == 'Array' && filter.itemType == 'Link'" data-cy="filteritem-input" />

		<!-- TODO: i think these types dont actually exist -->
		<input type="text" v-model="filter.value" ref="mainInput" :style="{ width: (length + 3) + 'ch' }" v-if="filter.type == 'Url' || filter.type == 'Slug'" data-cy="filteritem-input" />
		<input type="number" v-model="filter.value" ref="mainInput" :style="{ width: ((filter.value + '').length + 3) + 'ch' }" v-if="filter.type == 'Number' || filter.type == 'Integer'" data-cy="filteritem-input" />
		<input type="date" v-model="filter.value" ref="mainInput" :style="{ width: (filter.value.length + 3) + 'ch' }" v-if="filter.type == 'Date'" data-cy="filteritem-input" />
		<select v-model="filter.value" :style="{ width: (length + 3) + 'ch' }" v-if="filter.type == 'Boolean'" data-cy="filteritem-input">
			<option value="true">Yes</option>
			<option value="false">No</option>
		</select>
		<input type="text" v-model="filter.value" ref="mainInput" :style="{ width: (length + 3) + 'ch' }" v-if="filter.type == 'Text' || filter.type == 'RichText'" data-cy="filteritem-input" />
		<select v-model="filter.value" ref="mainInput" v-if="filter.type == 'User'" :style="{ width: (14 + 4) + 'ch' }" data-cy="filteritem-input">
			<option v-for="user of users" :key="user.sys.id" :value="user.sys.id">{{ user.firstName }} {{ user.lastName }}</option>
		</select>
	</div>
</template>

<script>
import { storageForFilter } from './FilterUtil'

// TODO: support Link types - should be picker of certain types
// TODO: support location search? CF doesnt support it, but i think we could offer a map with radius

export default {
	name: 'FilterItem',
	inject: [ 'endpoint', 'tags' ],
	props: {
		// { id: 0.23237938279, field: 'updatedAt', type: 'Date', scope: 'sys', mode: 'is', value: '2022-12-22', query: { k: 'sys.updatedAt[lt]', v: '2022-12-22' } })
		filter: Object,
	},
	data: () => ({
		symbolModes: ['eq', 'ne', 'match'],
		symbolInModes: ['eq', 'ne'],
		continualModes: ['eq', 'ne', 'lt', 'lte', 'gt', 'gte'],
		// TODO: CF actually supports these modes (see tags):
		// not tagged:		metadata.tags[exists]: false
		// any:				metadata.tags[exists]: true
		// include one of:	metadata.tags.sys.id[in]: aaaa
		// dont include:	metadata.tags.sys.id[nin]: aaaa
		// include all of:	metadata.tags.sys.id[all]: aaaa
		symbolListModes: [ 'in', 'nin' ],
		linkListModes: [ 'in', 'nin', 'all' ],
		i18n: {
			is: 'is',
			eq: 'is',
			ne: 'is not',
			lt: 'is less than',
			lte: 'is less than or equal to',
			gt: 'is greater than',
			gte: 'is greater than or equal to',
			match: 'matches',
			in: 'include',
			nin: 'dont include',
			all: 'include all of',
		},
		dialogTags: [],
	}),
	computed: {
		length() {
			return Math.min(this.filter.value.length, 25)
		},
		users() {
			return Object.values(window.userLookup)
		},
		modes() {
			if (this.filter.type == 'Symbol' && !this.filter.in) return this.symbolModes
			if (this.filter.type == 'Symbol' && this.filter.in) return this.symbolInModes
			if (this.filter.type == 'SymbolList') return this.symbolListModes
			if (this.filter.type == 'Array') return this.linkListModes
			if (this.filter.type == 'Number' || this.filter.type == 'Integer' || this.filter.type == 'Date') return this.continualModes
		},
		arrayValue() {
			return this.filter.value?.split?.(',')?.filter(i => !!i) ?? []
		},
		selectedTags: {
			get() {
				return this.arrayValue.map(id => ({ sys: { id, type: 'Link', linkType: 'Tag' } }))
			},
			set(v) {
				this.filter.value = v.map(tag => tag.sys.id).join(',')
			},
		},
	},
	watch: {
		// map the ui settings to query params
		'filter.mode'(n, o) {
			this.buildQuery()
		},
		'filter.value'(n, o) {
			this.buildQuery()
		},
		value(n, o) {
			this.buildQuery()
		},
	},
	methods: {
		getTagLabel(id) {
			const tag = this.tags?.find(tag => tag.sys.id == id)
			return tag?.name
		},
		// TODO: we should actually move this mapping into EntryApi - doesnt make sense to build it in the ui in prod.
		buildQuery() {
			let queryMode = '[' + this.filter.mode + ']'
			if (this.filter.mode == 'eq') queryMode = ''
			if (this.filter.type == 'Text') queryMode = '[match]'
			if (this.filter.type == 'RichText') queryMode = '[match]'
			//const prefix = this.filter.scope == 'fields' ? '' : (this.filter.scope + '.')
			const prefix = this.filter.scope + '.'
			let key = prefix + this.filter.field
			if (this.filter.type == 'User') key += '.sys.id'
			if (this.filter.field == 'tags') key += '.sys.id'
			if (this.filter.type == 'Array' && this.filter.itemType == 'Link') key += '.sys.id'
			key += queryMode
			this.filter.query.k = key
			this.filter.query.v = this.filter.value
			this.filter.storage = storageForFilter(this.filter)
		},
		isArray(o) { return typeof o == 'object' && Array.isArray(o) },
		isObject(o) { return typeof o == 'object' && !Array.isArray(o) },
	},
	async mounted() {
		if (this.filter.focus) {
			this.$nextTick(() => {
				this.$refs.mainInput?.focus()
				this.filter.focus = false
			})
		}
		this.buildQuery()
	},
}
</script>

<style scoped>
.item { display: flex; margin-right: 6px; margin-bottom: 3px; font-size: 14px; padding-left: 10px; border-radius: 6px; }
.item:hover { outline: 1px solid var(--primary); }
.item > label { padding: 8px 5px 8px 0; user-select: none; }
/* TODO: add a down arrow on the select on safari - i think we'll need to add an addl element */
.item > input,
.item > button,
.item > select { text-align: center; border: 0; outline: none; line-height: 1.25rem; padding: 0; height: 100%; border-radius: 0 6px 6px 0; font-family: var(--font-stack-primary); font-size: 14px; -webkit-appearance: none; }
.item > button { padding-left: 10px; padding-right: 10px; }
.filter.selected { outline: 1px solid var(--primary); }
.item .mode { position: relative; height: 100%; margin-left: 5px; }
.item .mode::after { content: ' '; z-index: 1; position: absolute; width: 50%; right: 0; top: 0; bottom: 0; }
.item .mode select { background: white; color: var(--col); border: 0; position: relative; top: 7px; z-index: 2; appearance: none; padding: 2px 0; text-align: center; border-radius: 10px; font-size: 12px; font-weight: normal; }
input[type="date"] { min-width: 120px; }

::-webkit-calendar-picker-indicator { background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="black" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>'); }
</style>

<style>
.FilterItem .SystemTags { padding-top: 0; }

/* we style this globally because EntryFilter has a Content Type control */
.FilterItem {
	background-color: var(--color-element-light);
	color: #333;
	--bg: var(--color-element-light);
	--col: #333;
	font-weight: bold;
}
.FilterItem label { font-weight: normal; }
.FilterItem .mode { color: var(--col); font-weight: normal; }
.FilterItem .mode::after { background: var(--bg); }
.FilterItem input,
.FilterItem button,
.FilterItem select { background-color: var(--bg); color: var(--col); font-weight: 600; text-shadow: 0 0 3px white; }
</style>