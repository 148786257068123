<template>
	<div class="NumberField" :class="{ error }">
		<input type="number" class="input" v-model="model" @focus="onFocus" @blur="onBlur" :disabled="disabled"
			:step="def.type == 'Integer' ? 1 : undefined"
		/>
		<span class="clear" v-if="!disabled && value !== undefined && value !== null" @click="$emit('input', undefined)">
			Clear
		</span>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'NumberField',
	mixins: [ field ],
	props: {
		value: [ Number, String ],
	},
	data: () => ({
		model: null,
	}),
	watch: {
		value(n) {
			this.model = n
			this.validate()
		},
		model(n) {
			this.$emit('input', Number(n))
		},
	},
	methods: {
		validateInteger() {
			if (this.model && this.def.type == 'Integer' && !Number.isInteger(this.model))
				return 'Must be an integer'
		},
		validate() {
			this.onErrors([
				this.validations.required && isNaN(this.value) ? 'Required' : null,
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
				this.validateIn(),
				this.validateInteger(),
			])
		},
	},
	mounted() {
		this.model = this.value
		this.validate()
	},
}
</script>

<style scoped>
.NumberField { display: flex; align-items: center; }
.clear { color: rgb(0, 89, 200); padding-left: 20px; cursor: pointer; }
</style>
