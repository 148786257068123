
import Task from './Task.vue'
import TaskForm from './TaskForm.vue'
import EntryApiMixin from '../EntryApiMixin.js'

export default {
	name: 'Tasks',
	components: { Task, TaskForm },
	mixins: [ EntryApiMixin ],
	inject: [ 'permissions' ],
	props: {
		// TODO: should we pass in the whole entry?
		//       what we need are the fields for reference
		//       actually we would rather want the type of the entry
		entryId: String,
		entryModel: Object,
	},
	data: () => ({
		tasks: [],
		newTask: null,
	}),
	computed: {
		activeTasks() {
			return this.tasks.filter(task => task.status == 'active')
		},
		resolvedTasks() {
			return this.tasks.filter(task => task.status == 'resolved')
		},
	},
	methods: {
		create() {
			this.newTask = {
				body: '',
				assignedTo: null,
				dueDate: null,
				status: 'active',
			}
		},
		async load() {
			const r = await this.$httpGet(this.endpoint + '/entries/' + this.entryId + '/tasks')
			this.tasks = r.items
		}
	},
	async mounted() {
		this.load()
	},
}
