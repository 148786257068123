<template>
	<div class="SystemTags">
		<Field
			v-if="!single"
			:entry="entry"
			:control="{ widgetId: 'tagEditor', sortable: false, separator }"
			:field="{ scope: 'metadata', name: 'Tags', type: 'Array', items: { type: 'Symbol' , validations: [ { inLinks: tagLinks, size: { min: 0, max: 100 } } ] } }"
			v-model="model"
		/>
		<Field
			v-else
			:entry="entry"
			:control="{ widgetId: 'dropdown', sortable: false }"
			:field="{ scope: 'metadata', name: 'Tags', type: 'Symbol', validations: [ { inLinks: tagLinks, size: { min: 1, max: 1 } } ] }"
			v-model="model"
		/>
	</div>
</template>

<script>
import Field from './Field.vue'

// TODO: we currently trigger autosave with system tags.
//       contentful however does a PATCH request with a body like this:
//       [{"op":"replace","path":"/metadata/tags","value":[{"sys":{"id":"alturosfamily","type":"Link","linkType":"Tag"}}]}]
//       do we also want to support this in CM2?

export default {
	name: 'SystemTags',
	components: { Field },
	inject: [ 'endpoint', 'tags' ],
	props: {
		entry: Object,
		value: [ Array, String, Object ],
		single: Boolean,
		separator: String,
	},
	data: () => ({
		model: [],
		tagsLoaded: [],
	}),
	computed: {
		tagLinks() {
			const tags = this.tags ?? this.tagsLoaded
			return tags.map(tag => this.toLink(tag))
		},
	},
	watch: {
		value(n) { this.model = n },
		model(n) { this.$emit('input', n) },
	},
	methods: {
		// TODO: move to app?
		async load() {
			const tags = await this.$httpGet(this.endpoint + '/tags?limit=1000')
			tags.items.sort((a, b) => a.sys.id.localeCompare(b.sys.id))
			this.tagsLoaded = tags.items
		},
		toLink(tag) {
			if (typeof tag === 'string') return { sys: { id: tag, linkType: 'Tag', type: 'Link' } }
			if (tag.sys) return { sys: { id: tag.sys.id, linkType: 'Tag', type: 'Link' } }
			return tag
		},
	},
	async mounted() {
		if (!this.tags) await this.load()
		if (Array.isArray(this.value)) {
			this.model = this.value.map(v => this.toLink(v))
		}
		else {
			this.model = this.toLink(this.value)
		}
	},
}
</script>

<style scoped>
</style>