<template>
	<div class="apiKeys" style="height: 100%;">
		<Head>
			<div class="heading" style="padding-left: 25px;">
				<h1>
					API Keys
				</h1>
			</div>
		</Head>
		<div class="body" style="display: flex; height: 100%;">
			<div class="BaseTable" style="margin: 30px; flex: 1 1 0;">
				<table v-if="apiKeys?.length">
					<tr>
						<th>Name</th>
						<th>Descrption</th>
					</tr>
					<tr v-for="apiKey of apiKeys" :key="apiKey.key" @click="$router.push(base + '/settings/apiKeys/' + apiKey.sys.id)">
						<td>
							{{ apiKey.name }}
							<span v-if="apiKey.default" class="statusLabel">default</span>
						</td>
						<td>{{ apiKey.description }}</td>
					</tr>
				</table>
				<div v-else style="text-align: center; padding: 100px;">
					<mdi key-chain-variant crystal />
				</div>
			</div>
			<Sidebar>
				<div class="section" v-if="apiKeys">
					<!--
					<h2>Usage</h2>
					<p>You are using {{ apiKeys.length }} out of 100 API Keys available in this environment.</p>
					-->
					<button class="add" @click="addApiKey">Add API Key</button>
				</div>
			</Sidebar>
		</div>
	</div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import Head from './Head.vue'

export default {
	name: 'ApiKeys',
	components: { Sidebar, Head },
	inject: [ 'base', 'baseEndpoint', 'spaceId' ],
	data: () => ({
		apiKeys: null,
	}),
	computed: {
		endpoint() {
			return this.baseEndpoint + '/spaces/' + this.spaceId
		},
	},
	methods: {
		async load() {
			const apiKeys = await this.$httpGet(this.endpoint + '/api_keys')
			apiKeys.items.sort((a, b) => a.name.localeCompare(b.name))
			this.apiKeys = apiKeys.items
		},
		async addApiKey() {
			this.$router.push(this.base + '/settings/apikeys/new')
		},
	},
	async mounted() {
		document.title = 'API Keys'
		await this.load()
	},
}
</script>

<style scoped>
table { width: 100%; }
table th { height: auto; padding: 15px; }
button.add { border: 1px solid var(--primary); border-radius: 6px; cursor: pointer; width: 100%; font-weight: 500; outline: none; transition: background 0.1s ease-in-out 0s, opacity 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s; color: white; background-color: var(--primary); font-size: 0.875rem; padding: 10px 15px; }
</style>
