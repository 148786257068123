
import SpinnerAnimation from './SpinnerAnimation.vue'

export default {
	components: { SpinnerAnimation },
	name: 'Spinner',
	props: {
		locale: String,
		error: String,
	},
	data: () => ({
		random: Math.random(),
		texts: [
			'Good things take time.',
			//'90% stolen, 10% love.',
			'Working for you..',
			'What\'s for dinner today?',
			'Tell me a story!',
			'Details make stories come alive.',
			'Combing the desert',
			'Making it so',
			'Finding the sweet spot',
			'Beaming you up, captain',
			'Try not, do or do not.',
			'Congratulations, you are being rescued.',
			'There\'s always a bigger fish.',
			'This is the way.',
			'Squaring the circle',
			'Proving the Riemann hypothesis',
			'Go ahead, make my day.',
			'Show me the money!',
			'Here\'s Johnny!',
			'Just keep swimming.',
			'Smile and wave, boys!',
			'Wait a second!',
			'You come at the king, you best not miss.',
			'I learned one thing about jigsaw puzzles.. an hour later you\'re hungry again.',
			'Time is an illusion. Lunchtime doubly so.',
			'Don\'t panic.',
			'You\'re a wizard, Harry.', // TODO: user name here
			'If you can read this we havent met our 100 millisecond goal on this one.',
			'You\'re gonna need a bigger boat.',
			'You complete me.',
			'You had me at hello.',
			'Maybe stories are just data with a soul.',
			'Content is anything that adds value to the reader’s life.',
			'Make your customers the hero of your stories.',
			'Write like it\'s ' + new Date().getFullYear(),
			'Honey, where\'s my supersuit?',
			'I\'m not impatient - I just dont have time to wait around!',
			'The difference between the almost right word and the right word is the difference between the lightning bug and the lightning.',
			'Easy reading is damn hard writing.',
			'All good writing leaves something unexpressed.',
			'The purpose of a writer is to keep civilization from destroying itself.',
			'Description begins in the writer\'s imagination but should finish in the reader\'s.',
			'If you can\'t explain it simply, you don\'t understand it well enough.',
			'Good writing is like a windowpane.',
			'Don\'t be afraid of the blank page.',
			'Leave the reader wanting more.',
		],
	}),
	computed: {
		text() {
			if (this.error) return this.error
			return this.texts[Math.floor(this.random * this.texts.length)]
		},
	},
}
