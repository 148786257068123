<template>
	<div class="JsonField" :class="{ error }">
		<div>JSON</div>
		<textarea type="text" class="input" v-model="model" @focus="onFocus" @blur="onBlur" :disabled="disabled" />
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

// TODO: validation: required
// TODO: validation: limit number of properties
// TODO: field auto height

export default {
	name: 'JsonField',
	mixins: [ field ],
	props: {
		value: [ Object, Array, String, Number, Boolean ],
	},
	data: () => ({
		model: null,
		autoModel: false,
	}),
	watch: {
		value(n) {
			this.onErrors([
				this.validateRequired(),
				this.validateObjectSize(),
			])
			this.model = JSON.stringify(n, null, 4)
		},
		model(n) {
			const errors = []
			if (n) {
				try {
					const v = JSON.parse(n)
					//this.$emit('input', JSON.parse(n))
				}
				catch (e) {
					errors.push('This is not valid JSON')
				}
			}
			this.onErrors(errors)
		},
	},
	methods: {
		onBlur(e) {
			this.$emit('blur', e)
			this.$emit('wrapper', [ 'onBlur', e ])
			if (!this.model) {
				this.$emit('input', undefined)
			}
			else {
				try {
					this.$emit('input', JSON.parse(this.model))
				}
				catch (e) {
					const errors = []
					errors.push('This is not valid JSON! JSON fields will not be saved until they are valid JSON.')
					this.onErrors(errors)
				}
			}
		},
	},
	mounted() {
		this.model = JSON.stringify(this.value)
	},
}
</script>

<style scoped>
.JsonField { background: rgb(231, 234, 236); border-radius: 6px; }
.JsonField > div { padding: 5px 10px; font-weight: bold; color: rgb(114, 125, 132); }
.input { min-height: 140px; max-height: 1000px; width: 100%; resize: vertical; font-family: monospace; }
</style>
