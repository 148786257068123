<template>
	<div class="Graphs">
		<Head>
			<div style="flex-grow: 1;"></div>
			<div>
				<ActionButton class="add" @click="add">Add Graph</ActionButton>
			</div>
		</Head>
		<div class="body" style="margin: 20px;">
			<div v-for="graph in graphs" :key="graph.sys.id">
				<router-link :to="base + '/graphs/' + graph.sys.id">
					{{ graph.fields.title[defaultLocale] }}
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import Head from './Head.vue'
import ProviderMixin from './ProviderMixin.js'
import DemoGraph from './DemoGraph.ts'
import ActionButton from '../components/ActionButton.vue'

// TODO: check if type "graph" exists, if not -> ask user if we should create automatically

export default {
	name: 'Graphs',
	components: { Head, ActionButton },
	mixins: [ ProviderMixin ],
	inject: [ 'base', 'endpoint' ],
	data: () => ({
		id: 'x_graph',
		type: null,
		graphs: [],
	}),
	methods: {
		async loadGraphType() {
			this.type = await this.$httpGet(this.endpoint + '/content_types/' + this.id)
		},
		// TODO: update/reload type registry!
		async createGraphType() {
			this.type = await this.$httpPut(this.endpoint + '/content_types/' + this.id, {
				name: 'X Graph',
				displayField: 'title',
				fields: [
					{ id: 'title', name: 'Title', type: 'Symbol', required: true },
					{ id: 'nodes', name: 'Nodes', type: 'Object' },
					{ id: 'links', name: 'Links', type: 'Object' },
					{ id: 'view', name: 'View', type: 'Object' },
					{ id: 'queries', name: 'Queries', type: 'Object' },
				],
			})
		},
		async loadList() {
			const r = await this.$httpGet(this.endpoint + '/entries?content_type=x_graph')
			this.graphs = r.items
		},
		add() {
			const id = 'GRAPH-' + Math.random().toString(36).substring(2, 10).toUpperCase()
			this.$router.push(this.base + '/graphs/' + id)
		},
	},
	async mounted() {
		// TODO-3: add name of space
		document.title = 'Graphs'
		// if the x_graph type is not there -> create it
		try {
			await this.loadGraphType()
		}
		catch (e) {
			await this.createGraphType()
		}

		// Demo
		const dg = new DemoGraph(this, this.defaultLocale)
		const r = await dg.createGraphNodeTypeIfNotExists()
		if (r) await dg.createDemoGraph()

		await this.loadList()
	},
}
</script>

<style scoped>
h1 { flex-shrink: 0; font-size: 20px; font-weight: 600; margin: 0; padding: 0; width: 250px; padding: 5px 0; }
</style>