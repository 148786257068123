export const commonFields: any = [
	{ id: 'contentType', contentType: '_all', description: 'The type of the entry', type: 'String', scope: 'sys' },

	{ id: 'updatedAt', contentType: '_all', description: 'Date an entry was last updated', type: 'Date', scope: 'sys' },
	{ id: 'createdAt', contentType: '_all', description: 'Date an entry was created', type: 'Date', scope: 'sys' },
	{ id: 'publishedAt', contentType: '_all', description: 'Date an entry was last published', type: 'Date', scope: 'sys' },
	{ id: 'firstPublishedAt', contentType: '_all', description: 'Date an entry was first published', type: 'Date', scope: 'sys' },
	{ id: 'updatedBy', contentType: '_all', description: 'The user who last updated an entry', type: 'User', scope: 'sys' },
	{ id: 'createdBy', contentType: '_all', description: 'The user who created an entry', type: 'User', scope: 'sys' },
	{ id: 'publishedBy', contentType: '_all', description: 'The user who last published an entry', type: 'User', scope: 'sys' },
	{ id: 'version', contentType: '_all', description: 'An entry\'s unique identifier', type: 'Number', scope: 'sys' },
	{ id: 'id', contentType: '_all', description: 'The user who last updated an entry', type: 'Symbol', scope: 'sys' },
	{ id: 'status', contentType: '_all', description: 'Current status of the entry', type: 'Symbol', scope: 'sys', in: { any: 'Any (except Archived)', published: 'Published', changed: 'Changed', draft: 'Draft', /*archived: 'Archived'*/ } },
	{ id: 'tags', contentType: '_all', description: 'Tags on the entry', type: 'SymbolList', scope: 'metadata' },
	//{ id: 'archived', contentType: '_all', description: 'The entry is archived', type: 'Boolean', scope: 'sys' },
]

export const assetFields: any = [
	{ id: 'file.contentType', contentType: '_all', description: 'File Type', type: 'Symbol', scope: 'fields', in: { 'image/png': 'png', 'image/jpeg': 'jpg', 'image/svg': 'svg', 'video/mp4': 'mp4', 'audio/mp3': 'mp3', 'audio/mpeg': 'mpeg audio', 'application/xml': 'xml', 'application/pdf': 'pdf' } },
	{ id: 'width', contentType: '_all', description: 'Media width', type: 'Number', scope: 'sys' },
	{ id: 'height', contentType: '_all', description: 'Media height', type: 'Number', scope: 'sys' },
	{ id: 'size', contentType: '_all', description: 'Media file size', type: 'Number', scope: 'sys' },
	{ id: 'type', contentType: '_all', description: '', type: 'Symbol', scope: 'sys' },
	{ id: 'title', contentType: '_all', description: 'Title of the media', type: 'Symbol', scope: 'sys' },
	{ id: 'description', contentType: '_all', description: '', type: 'Symbol', scope: 'sys' },
	{ id: 'fileName', contentType: '_all', description: 'File name', type: 'Symbol', scope: 'sys' },
]

export function getFieldsForContentTypes() {
	const r = []
	for (const contentType of Object.values(window['typeLookup'])) {
		for (const field of (contentType as any).fields) {
			let type = field.type
			if (field.type == 'Array' && field.items.type == 'Symbol') type = 'SymbolList'
			r.push({
				id: field.id,
				name: field.name,
				contentType: (contentType as any).sys.id,
				description: field.name,
				type,
				itemType: field.items?.type,
				scope: 'fields',
				in: field.validations.filter(v => v.in)?.[0]?.in ?? field.items?.validations?.filter?.(v => v.in)?.[0]?.in,
			})
		}
	}
	return r
}

export function getFields() {
	const r = commonFields.filter(f => f.id != 'contentType')
	r.push(...getFieldsForContentTypes())
	r.push(...assetFields)
	return r
}

export function getColumnFields() {
	const r = commonFields.filter(f => f.id != 'status' && f.id != 'firstPublishedAt' && f.id != 'archived')
	r.push(...getFieldsForContentTypes())
	return r.sort((a, b) => a.id.localeCompare(b.id))
}

export type Filter = {
	id: number
	field: string
	scope: string
	type: string
	itemType: string
	in: string[] | { [key: string]: string } | null
	mode: string
	value: any
	query: { k: string, v: string }
	focus: boolean
	storage: string[] | null
}

const defaultFieldsIds = [ 'contentType', 'updatedAt', 'updatedBy' ]

export function columnFieldsForColumnIds(ids) {
	if (ids == null) ids = defaultFieldsIds.slice()
	const r = []
	const fields = getColumnFields()
	for (const id of ids) {
		const field = fields.find(f => f.id == id)
		if (field) r.push(field)
	}
	return r
}

export function newFilterForFieldOrName(fieldNameOrField): Filter {
	let field = fieldNameOrField
	if (typeof fieldNameOrField == 'string') {
		// TODO: this will often call getFields... should we rather pass in the fields?
		field = getFields().find(f => f.id == fieldNameOrField)
		if (!field) return
	}

	// TODO: set mode dependent on type
	let value: any = ''
	if (field.type == 'Date') value = new Date().toISOString().substring(0, 10)
	if (field.type == 'Number') value = 1
	if (field.type == 'Boolean') value = 'Yes'
	// TODO: my intention of this object is to persist it later.
	//       but adding the field.in does not fit with that. actually we need to add that in some other way.
	//       maybe by having a method getIn(type, fieldId) here and pass that as a param? 
	let mode = 'eq'
	if (field.type == 'SymbolList') mode = 'in'
	if (field.type == 'Array') mode = 'in'
	return {
		id: Math.random(),
		field: field.id,
		scope: field.scope,
		type: field.type,
		itemType: field.itemType,
		in: field.in,
		mode,
		value,
		query: { k: '', v: '' },
		focus: true,
		storage: null,
	}
}

export function storageForFilter(filter) {
	return [ filter.field, filter.mode, filter.value ]
}

export function newFilterForStorage(storage) {
	const filter = newFilterForFieldOrName(storage[0])
	filter.mode = storage[1]
	filter.value = storage[2]
	return filter
}