export type TranslationItem = {
	value: string,
	field: any,
	control: any,
	sourceLocales: string[],
	sourceLocale: string,
	targetLocales: { [locale: string]: boolean },
	status: 'new' | 'inprogress' | 'done' | 'error',
}

let tracking = true
export function startTranslationTracking() { tracking = true }
export function stopTranslationTracking() { tracking = false }

export function addTranslationItem(array, value, field, control, locale, locales, original: any): TranslationItem {
	if (!tracking) return
	if (field.localized == false) return
	if (control.widgetId == 'slugEditor') return
	if (field.type != 'Symbol' && field.type != 'Text') return

	const a = JSON.stringify(value ?? 'null')
	const b = JSON.stringify(original?.fields?.[field.id]?.[locale] ?? 'null')
	if (a === undefined && b === undefined || a == b) {
		// remove translation item if exists
		// TODO: maybe we should only remove the source locale and delete if this is the only source locale?
		for (let i = 0; i < array.length; i++) {
			if (array[i].field.id != field.id) continue
			array[i].sourceLocales = array[i].sourceLocales.filter(l => l != locale)
			if (array[i].sourceLocales.length > 0) continue
			array.splice(i, 1)
		}
		return
	}

	// update existing item if it exists
	for (const item of array) {
		// TODO: be more clever about locales? if the user manually changed a locale remove it from the "todo" list?
		if (item.field.id == field.id) {
			item.value = value
			if (!item.sourceLocales.includes(locale)) item.sourceLocales.push(locale)
			item.targetLocales[locale] = false
			return
		}
	}
	const targetLocales = {}
	for (const l of locales) {
		targetLocales[l] = l != locale
	}
	array.push({ value, field, control, sourceLocales: [ locale ], sourceLocale: locale, targetLocales, status: 'new' })
}

// ATT: this is not actually using deepl at the moment, but a cloudflare translate worker
export async function deeplTranslate(text, source_lang, target_lang) {
	//let url = 'https://api-free.deepl.com/v2/translate'
	// url += '?' + new URLSearchParams({ text, target_lang }).toString()
	let url = 'https://translate.contenthub.dev/'
	const request = {
		method: 'POST',
		headers: {
//			authorization: 'Bearer ' + this.apiKey,
//			accept: 'application/json, text/plain, */*',
			'content-type': 'application/json',
		},
		body: JSON.stringify({ text, source_lang, target_lang }),
	}
	const response = await fetch(url, request)
	return (await response.json()).response
}
