<template>
	<div style="display: relative; padding: 0;">
		<!-- TODO: move wrap to a dialog comp -->
		<h2 style="width: 100%;">Add existing assets</h2>
		<div class="body" style="position: absolute; left: 0; right: 0; top: 60px; bottom: 60px;">
			<div class="top">
				<div>Search for an asset:</div>
				<div class="mode">
					<span v-if="mode == 'all'" @click="mode = 'selected'">Show selected assets ({{ count }})</span>
					<span v-else @click="mode = 'all'">Show all assets</span>
				</div>
			</div>
			<AssetFilter
				v-model="filter"
				:def="def"
				style="margin-bottom: 15px; z-index: 1; position: relative;"
			/>
			<!-- TODO: we should probably not emit directly - only after user hits OK -->
			<AssetList
				:filter="filter"
				:selectable="true"
				style="position: absolute; left: 25px; right: 25px; top: 100px; bottom: 20px; padding-top: 20px;"
				@input="selected = $event; $emit('input', selected)"
				:mode="mode"
			/>
		</div>
		<div class="foot" style="position: absolute; left: 0; right: 0; bottom: 0; padding: 25px;">
			<button class="cancel" @click="$emit('cancel', selected)">
				Cancel
			</button>
			<ActionButton v-if="supportsCreate" @click="createAsset" class="ok">
				<mdi plus /> Create new asset
			</ActionButton>
			<button class="ok" :disabled="count == 0" @click="$emit('ok', selected)">
				<span v-if="count == 0">Select assets</span>
				<span v-if="count == 1">Insert 1 asset</span>
				<span v-if="count > 1">Insert {{ count }} assets</span>
			</button>
		</div>
	</div>
</template>

<script>
// TODO: single-select-mode
//       list-select event -> close dialog, emit

import AssetFilter from './AssetFilter.vue'
import AssetList from './AssetList.vue'
import Menu from '../Menu.vue'
import ActionButton from '../ActionButton.vue'
import TypePickerList from '../TypePickerList.vue'
import EntryApiMixin from '../EntryApiMixin'

export default {
	name: 'AssetPicker',
	mixins: [ EntryApiMixin ],
	components: { AssetFilter, AssetList, Menu, ActionButton, TypePickerList },
	inject: [ 'endpoint' ],
	props: {
		locale: String,
		filter: Object, // { contentType: '', search: '' }
		def: Object,
	},
	data: () => ({
		mode: 'all',
		selected: [],
	}),
	computed: {
		count() {
			return this.selected.length
		},
		supportsCreate() {
			return !!this.$listeners.create
		},
	},
	methods: {
		async createAsset() {
			this.menuOpen = false
			if (typeof contentType == 'string')
				contentType = window['typeLookup'][contentType]
			const asset = await this.$httpPost(this.endpoint + '/assets', this.newAsset(), {})
			const link = { sys: { id: asset.sys.id, linkType: 'Asset', type: 'Link' } }
			this.$emit('create', asset)
		},
	},
}
</script>

<style scoped>
h2 { margin: 0; padding: 20px 25px; font-size: 16px; font-weight: 600; border-bottom: 1px solid #ddd; text-align: left; }
.body { padding: 17px 25px; }
button[disabled] { opacity: 0.5; }
.foot { display: flex; gap: 10px; justify-content: flex-end; padding: 20px 0; }
.foot button { font-size: 14px; border-radius: 6px; cursor: pointer; }
.foot button.cancel { color: rgb(17, 27, 43); background-color: white; border: 1px solid silver; padding: 9px 15px; }
.foot button.ok { color: white; background-color: rgb(0, 133, 57); border: 0; padding: 10px 15px; }
.top { display: flex; -webkit-box-pack: justify; justify-content: space-between; font-size: 14px; margin-bottom: 15px; letter-spacing: -0.5px; }
.mode { color: rgb(0, 89, 200); cursor: pointer; font-weight: 500; letter-spacing: 0px; }
.mode:hover { text-decoration: underline; }
</style>