<template>
	<div class="Link"
		v-if="node1 && node2 && !node1.removed && !node2.removed"
		@mouseover="$emit('mouseover', value)"
		@mouseout="$emit('mouseout', value)"
		:class="{ flip }"
		:style="{
			left: node1.x + 'px',
			top: node1.y + 'px',
			width: length + 'px',
			transform: 'rotate(' + angle + 'rad)',
			transformOrigin: '0 0',
			borderColor: color,
			borderBottomWidth: strokeWidth + 'px',
			borderBottomStyle: strokeStyle,
		}"
	>
		<div class="labels">
			<div class="middle" :style="{ color }">{{ value.rel }}</div>
		</div>
		<div class="head" :style="{ color, zoom: (strokeWidth-1)/3+1 }">➤</div>
	</div>
</template>

<script>
// TODO: draw circle for self-ref
// TODO: show stubbed links somehow on nodes?
export default {
	name: 'Link',
	props: {
		// { id, rel }
		value: Object,
		node1: Object,
		node2: Object,
		query2: Object,
		offset: { type: Number, default: 25 },
		strokeWidth: { type: String, default: '1px' },
		strokeStyle: { type: String, default: 'solid' },
	},
	data: () => ({
	}),
	computed: {
		length() {
			const dx = this.node1.x - this.node2.x
			const dy = this.node1.y - this.node2.y
			return Math.sqrt(dx * dx + dy * dy) - this.offset
		},
		angle() {
			const dx = this.node1.x - this.node2.x
			const dy = this.node1.y - this.node2.y
			return Math.PI + Math.atan2(dy, dx)
		},
		flip() {
			return this.angle > Math.PI / 2 && this.angle < Math.PI * 1.5
		},
		color() {
			return this.query2?.color ?? '#bbb'
		},
	},
}
</script>

<style scoped>
.Link { position: absolute; border-bottom: 1px solid black; font-size: 11px; }
.labels { position: absolute; display: flex; width: 100%; padding-left: 12px; }
.labels .middle { flex: 1; padding: 2px; text-align: center; text-transform: uppercase; }
.flip .labels .middle { transform: rotate(180deg); }
.head { position: absolute; right: -2px; top: -7px; font-size: 12px; }
</style>