
import EntryEditor from './EntryEditor.vue'
import AssetEditor from './AssetEditor.vue'

export default {
	name: 'StackingView',
	components: { EntryEditor, AssetEditor },
	props: {
		value: [ Object ],
		link: { type: Object }, // { id: string, type: 'Entry' | Asset }
	},
	data: () => ({
		editorStack: [],
	}),
	methods: {
		entrySelected(entries) {
			this.editorPush(entries[0])
		},
		// we want to be able to navigate back and forth honoring the editor stack
		// this is done by modifying the url to reflect the current stack:
		// .../entries/CURRENTLY-VIEWED-ENTRY?stack=FIRST-ON-STACK,SECOND-ON-STACK
		// in this example the real stack would look like this:
		// FIRST-ON-STACK -> SECOND-ON-STACK -> CURRENTLY-VIEWED-ENTRY
		routerBeforeEach(to, from, next) {
			const requestedStack = to.query.stack?.split?.(',') ?? []
			requestedStack.push(to.params.id)
			this.restoreStack(requestedStack)
			next()
		},
		restoreStack(requestedStack: string[]) {

			// remove excess editors
			while (this.editorStack.length > requestedStack.length) {
				// TODO: do we have to call the callback?
				//       the purpose of these callbacks is to reload the parent when the child closes
				//       (see AssetsField + ReferencesField)
				//       maybe for this use case its not needed to call these..
				//       BUT: it is probably required to REGISTER them - but how?
				//            probably we'll have to find a different mechanism than the callbacks, maybe a bus.
				this.editorStack.pop()
			}

			for (let i = 0; i < requestedStack.length; i++) {
				if (this.editorStack[i]?.link?.id == requestedStack[i]) continue

				// on mismatch: remove all after
				while (this.editorStack.length > i) {
					this.editorStack.pop()
				}

				// add missing editor
				this.editorPush(
					{ id: requestedStack[i], type: 'Entry' },
					// TODO: we dont have callbacks here.. what are they needed for?
					//       see TODO above!
					undefined,
					// dont update url when restoring stack to avoid having duplicate history states
					false,
				)
			}
		},
		// modify url to reflect current editor stack
		updateUrl() {
			let path = this.$route.path.replace(/\/entries\/.*/, '/entries/')
			let stack = [...this.editorStack]
			const current = stack.pop()
			path += current.link.id
			// TODO: do this instead?
			//       this.$router.push({ path: "/products", query: { id: 1 } });
			if (stack.length > 0) {
				path += '?stack=' + stack.map(e => e.link.id).join(',')
			}
			if (this.$route.path != path) {
				this.$router.push(path)
			}
		},
		editorPush(link, callback, updateUrl = true) {
			this.editorStack.push({
				level: this.editorStack.length,
				// support both sys objects and refs
				link: { id: link.id, type: link.linkType ?? link.type },
				callback,
				// TODO: check if schema could not be found -> error
				//schema: window['contentTypes'][entry.sys.contentType.sys.id],
			})
			if (updateUrl) {
				this.updateUrl(link)
			}
		},
		// TODO: here we would like to send a signal to all displays of this entry to reload themselves.
		//       something like a broadcast to all Entry instances
		editorPop(savedObject, updateUrl = true) {
			if (this.editorStack.length == 1) {
				this.$emit('close')
				return
			}
			const editor = this.editorStack.pop()
			if (updateUrl) {
				this.updateUrl()
			}
			if (editor.callback) {
				editor.callback(savedObject)
			}
		},
	},
	mounted() {
		//this.editorPush(this.link, (...args: any[]) => { console.log('SVCB', args) })
		const requestedStack = this.$route.query.stack?.split?.(',') ?? []
		requestedStack.push(this.link.id)
		this.restoreStack(requestedStack)
	},
}
