<template>
	<div class="scheduledActions" style="height: 100%;">
		<Head>
			<div class="heading" style="padding-left: 25px;">
				<h1>Scheduled Actions</h1>
			</div>
		</Head>
		<div class="tabs">
			<span v-for="mode of modes" :key="mode"
				:class="{ active: currentMode == mode }"
				@click="currentMode = mode"
			>
				{{ mode }}
			</span>
		</div>
		<div class="body" style="display: flex; height: 100%;">
			<div class="BaseTable" style="margin: 30px; flex: 1 1 0;">
				<table v-if="days.length">
					<!-- TODO: group nicely by dates -->
					<tbody v-for="day of days" :key="day.date">
						<tr>
							<th colspan="2" style="font-weight: 600; font-size: 16px;">{{ day.date }}</th>
						</tr>
						<tr v-for="scheduledAction of day.scheduledActions" :key="scheduledAction.key"
							:class="'action-' + scheduledAction.action"
						>
							<td style="width: 120px;">
								<div class="timeAndTimezone">
									<div class="time">
										@
										<b>{{ formatTimePoint(scheduledAction.scheduledFor.datetime) }}</b>
									</div>
									<!--
									<div class="timezone">{{ scheduledAction.scheduledFor.timezone }}</div>
									-->
								</div>
								<div class="action">
									<mdi clock-outline />
									{{ scheduledAction.action }}
								</div>
							</td>
							<td>
								<Entry :entry="scheduledAction.entity"
									@click="$router.push(base + '/entries/' + scheduledAction.entity.sys.id)"
								>
									<template #actions>&nbsp;</template>
								</Entry>
								<div v-if="scheduledAction.sys.status == 'failed'">
									<div v-if="scheduledAction.error" class="error">
										<div style="margin-bottom: 10px;">Was not able to run the action!</div>
										<mdi alert-circle-outline />
										{{ scheduledAction.error.message }}
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<div v-else style="text-align: center; padding-top: 150px;">
					<mdi clock-outline crystal />
					<div>No actions scheduled.</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import Head from './Head.vue'
import EntryStatus from '../components/fields/EntryStatus.vue'
import { formatDatePoint, formatTimePoint } from '../utils'
import ProviderMixin from './ProviderMixin'
import Entry from '../components/fields/Entry.vue'
import moment from 'moment'

export default {
	name: 'ScheduledActions',
	mixins: [ ProviderMixin ],
	components: { Sidebar, Head, EntryStatus, Entry },
	inject: [ 'base', 'baseEndpoint', 'endpoint', 'spaceId', 'environment', 'entryLoader' ],
	data: () => ({
		scheduledActions: null,
		//filterStatus: 'error',
		//filterStatuses: [ 'scheduled', 'error', 'succeeded' ],
		currentMode: 'recent', // recent | scheduled | past
		modes: [ 'recent', 'scheduled', 'past' ],
	}),
	computed: {
		days() {
			const r = {}
			if (!this.scheduledActions) return []
			this.scheduledActions.forEach((scheduledAction) => {
				const date = formatDatePoint(scheduledAction.scheduledFor.datetime)
				const day = r[date] || { date, scheduledActions: [] }
				day.scheduledActions.push(scheduledAction)
				r[date] = day
			})
			return Object.values(r)
		},
	},
	watch: {
		async currentMode() {
			await this.load()
		},
	},
	methods: {
		formatDatePoint,
		formatTimePoint,
		async load() {
			let fromDate
			let untilDate
			if (this.currentMode == 'recent') {
				fromDate = moment().subtract(2, 'weeks').startOf('day').toISOString()
				untilDate = moment().endOf('day').toISOString()
			}
			else if (this.currentMode == 'scheduled') {
				fromDate = moment().toISOString()
			}
			else if (this.currentMode == 'past') {
				fromDate = moment().subtract(3, 'months').startOf('day').toISOString()
				untilDate = moment().toISOString()
			}
			const r = await this.$httpGet(this.baseEndpoint + '/spaces/' + this.spaceId + '/scheduled_actions', {
				'environment.sys.id': this.environment,
				//'order': '-scheduledFor.datetime',
				//'sys.status': 'scheduled',
				//'sys.status': this.filterStatus,
				'scheduledFor.datetime[gte]': fromDate,
				'scheduledFor.datetime[lte]': untilDate,
				limit: 1000,
			})
			r.items.forEach(item => { item.localDatetime = moment(item.scheduledFor.datetime).format().slice(0, 16) })
			r.items.sort((a, b) => a.localDatetime.localeCompare(b.localDatetime))
			const actions = r.items

			actions.forEach(async (scheduledAction) => {
				const entryId = scheduledAction.entity.sys.id
				this.entryLoader.load(entryId, false)
			})
			const lookup = await this.entryLoader.runLoad()

			actions.forEach((scheduledAction) => {
				scheduledAction.entity = lookup?.[scheduledAction.entity.sys.id]
			})

			this.scheduledActions = actions
		},
	},
	async mounted() {
		document.title = 'Scheduled Actions'
		await this.load()
	},
}
</script>

<style scoped>
table { width: 100%; }
table td, table th { padding-left: 10px !important; vertical-align: top; }
table th { height: auto; padding: 15px; }
td.status { width: 10%; }
td.url { font-family: monospace; width: 70%; word-break: break-all; }
button.add { border: 1px solid var(--primary); border-radius: 6px; cursor: pointer; width: 100%; font-weight: 500; outline: none; transition: background 0.1s ease-in-out 0s, opacity 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s; color: white; background-color: var(--primary); font-size: 0.875rem; padding: 10px 15px; }
.action-publish { --col: var(--color-green-mid); }
.action-unpublish { --col: orange; }
.action { color: var(--col); font-weight: 500; font-size: 16px; }
.timeAndTimezone { margin-top: 10px; margin-bottom: 10px; }
.timezone { font-size: 12px; }
.error { color: var(--error); margin-top: 10px; }
.active { color: var(--primary); border-bottom: 2px solid var(--primary); }
.tabs { padding-left: 15px; font-size: 14px; text-transform: capitalize; }
</style>
