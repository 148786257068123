
import EntryApiMixin from '../EntryApiMixin.js'
import { getUsers, userName, userEmail } from '../../utils'

// TODO: should we allow newlines like contentful does?
//       would need textarea then!
// TODO: allow cursor navigation in the mention overlay?

export default {
	name: 'CommentInput',
	mixins: [ EntryApiMixin ],
	props: {
		entryId: String,
		// TODO: accept parent comment id?
		value: Object,
		entryModel: Object,
	},
	data: () => ({
		mentionOpen: false,
		filter: '',
		fieldsOpen: false,
		users: [],
	}),
	computed: {
		filteredUsers() {
			return this.users.filter(user => {
				const text = user.sys.id + ' ' + user.firstName + ' ' + user.lastName + ' ' + user.email
				return text.toLowerCase().includes(this.filter.toLowerCase())
			}).filter(user => !!user.firstName || !!user.lastName)
		},
		filteredFields() {
			return Object.keys(this.entryModel.fields).filter((field: any) => {
				const text = field
				return text.toLowerCase().includes(this.filter.toLowerCase())
			})
		},
	},
	methods: {
		// returns the current command, for example '@ale' for an input of '@'
		getCommandFromCaret(prefix) {
			const to = this.$refs.input.selectionStart
			// look for the next @ left of the caret
			const from = this.value.body.lastIndexOf(prefix, to)
			if (from < 0) return
			const text = this.value.body.substring(from, to)
			if (text == '') return
			// if the match contains a non-word-character -> discard
			// TODO: this does not work for multi char prefixes
			if (text.match(new RegExp('[^a-zA-Z0-9' + prefix + ']'))) return
			return { from, to, text }
		},
		commandSelect(prefix, text) {
			const m = this.getCommandFromCaret(prefix)
			if (!m) return
			this.value.body = this.value.body.substring(0, m.from) + prefix + text + ' ' + this.value.body.substring(m.to)
			this.mentionOpen = false
			this.fieldsOpen = false
			this.$nextTick(() => {
				this.$refs.input.selectionStart = m.from + text.length + 2
				this.$refs.input.selectionEnd = m.from + text.length + 2
				this.$refs.input.focus()
			})
		},
		handleCaretMove(event) {
			let filter

			filter = this.getCommandFromCaret('@')
			this.mentionOpen = !!filter
			if (filter) {
				this.filter = filter.text.substring(1)
			}

			filter = this.getCommandFromCaret('#')
			this.fieldsOpen = !!filter
			if (filter) {
				this.filter = filter.text.substring(1)
			}
		},
		keyup(e) {
			this.handleCaretMove(e)
			if (e.key == 'Enter' && (e.ctrlKey == true || e.metaKey == true)) {
				this.post()
			}
		},
		async post() {
			this.mentionOpen = false
			const body = this.replaceUserTags(this.value.body)
			const r = await this.$httpPost(this.endpoint + '/entries/' + this.entryId + '/comments',
				{
					body,
				},
				{
					headers: {
						'x-contentful-parent-entity-reference': this.value.ref,
					},
				},
			)
			this.$emit('post', this.message)
			this.value.body = ''
		},
		userName,
		userEmail,
		userToTag(user) {
			return user.email.replace(/[^\w\.@_+-]/, '_')
		},
		userFromTag(tag) {
			return this.users.find(user => this.userToTag(user) == tag)
		},
		replaceUserTags(text) {
			text = ' ' + text
			return text
				.replace(/(\W)@([\w\.@_+-]+)/gi, (_, prefix, tag) => {
					const user = this.userFromTag(tag)
					if (!user) return prefix + '@' + tag
					return prefix + '@' + user.sys.id
				})
				.trim()
		},
	},
	mounted() {
		this.users = getUsers()
	},
}
