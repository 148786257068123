
import EntryStatus from './fields/EntryStatus.vue'
import { formatDate, userName } from '../utils'

export default {
	name: 'VersionSidebar',
	components: { EntryStatus },
	inject: [ 'base', 'endpoint' ],
	props: {
		entryId: String,
	},
	data: () => ({
		versions: null,
		selectedVersion: null,
	}),
	methods: {
		async load() {
			const versions = await this.$httpGet(this.endpoint + '/entries/' + this.entryId + '/snapshots?limit=7&select=sys%2Csnapshot.sys')
			this.versions = versions.items
		},
		compare() {
			this.$router.push(this.base + '/entries/' + this.entryId + '/compare/' + this.selectedVersion)
		},
		formatDate,
		userName,
	},
	async mounted() {
		await this.load()
	},
}
