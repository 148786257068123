<template>
	<div class="TextField" :class="{ error }">
		<textarea type="text" class="input" v-model="model" @focus="onFocus" @blur="onBlur" :disabled="disabled" />
		<div class="info">
			<span class="status" :class="{ error }" v-if="value !== null && value !== undefined">{{ value ? value.length : 0 }} characters</span>
			<span class="capacity" v-if="max && !min">Maximum {{ max }} characters</span>
			<span class="capacity" v-if="!max && min">Requires minimum {{ max }} characters</span>
			<span class="capacity" v-if="max && min">Requires between {{ min }} and {{ max }} characters</span>
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'TextField',
	mixins: [ field ],
	props: {
		value: [ Number, String ],
	},
	data: () => ({
		model: null,
	}),
	computed: {
		min() {
			return this.validations.size?.min
		},
		max() {
			return this.validations.size?.max ?? 50000
		},
	},
	watch: {
		value(n) {
			this.onErrors([
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
				this.validateRegexp(),
				this.validateProhibitRegexp(),
				this.validateIn(),
			])
		},
	},
}
</script>

<style scoped>
.input { min-height: 80px; max-height: 1000px; width: 100%; resize: vertical; }
.info { display: flex; -webkit-box-pack: justify; justify-content: space-between; font-size: 0.875rem; margin-top: 0.5rem; color: rgb(65, 77, 99); }
.info .status.error { color: var(--error); }
</style>
