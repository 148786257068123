<template>
	<div class="UrlField" :class="{ error }">
		<input type="text" class="input" v-model="model" @focus="onFocus" @blur="onBlur" :disabled="disabled" />
		<!-- TODO: site preview (embedly?) - needed? -->
	</div>
</template>

<script>
// TODO: validation: match pattern
import { field } from './FieldMixin.js'

export default {
	name: 'UrlField',
	mixins: [ field ],
	props: {
		value: [ String ],
	},
	data: () => ({
		model: null,
	}),
	watch: {
		value(n) {
			this.onErrors([
				this.validateRequired(),
				this.validateMax(),
				this.validateMin(),
				this.validateRegexp(),
				this.validateProhibitRegexp(),
				this.validateIn(),
			])
		},
	},
}
</script>

<style scoped>
</style>
