<template>
	<div class='TogglesField'>
		<div class="tags" v-if="value">
			<template v-for="val of allowedValues">
				<div class="group" v-if="groups?.[val]" :key="'g-' + groups?.[val]">
					{{ groups?.[val] }}
				</div>
				<span class="tag" :key="val?.sys?.id ?? val"
					:class="{ active: value.includes(val?.sys?.id ?? val) }"
					style="margin-right: 10px"
					@click="toggle(val?.sys?.id ?? val)"
				>
					<!-- TODO: classes? icons? slots? -->
					<slot name="icon" :value="val" />
					{{ val?.sys?.id ?? val }}
				</span>
			</template>
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'TogglesField',
	mixins: [ field ],
	props: {
		locale: String,
		value: Array,
		def: Object,
		control: Object,
		groups: Object,
	},
	data: () => ({
		current: '',
		autoModel: false,
	}),
	computed: {
		// TODO: inLinks is similar to in. we need to also support this mode in the code below
		allowedValues() {
			return this.def?.items?.validations?.[0]?.in
		},
		sortable() {
			return this.control?.sortable === undefined || this.control?.sortable == true
		},
	},
	watch: {
		value(n) {
			// TODO: should we do the validation at the server? CF seems to do that.
			this.onErrors([
				this.validateRequired(),
				this.validateArrayMax(),
				this.validateArrayMin(),
//				this.validateArrayIn(),
//				this.validateArrayInLinks(),
			])
		},
	},
	methods: {
		toggle(val) {
			if (this.disabled) return
			const index = this.value?.indexOf?.(val)
			if (index < 0)
				this.value.push(val)
			else
				this.value.splice(index, 1)
		},
	},
	mounted() {
		// ensure that we have an array on the model when starting
		this.$nextTick(() => {
			if (!this.value)
				this.$emit('input', [])
		})
	},
}
</script>

<style scoped>
.tags { padding: 10px 0; margin: 0; }
.tag { background: rgb(231, 235, 238); border-radius: 4px; padding: 8px; margin-bottom: 5px; color: rgb(65, 77, 99); display: inline-flex; cursor: pointer; }
.tag svg { font-size: 18px; fill: currentColor; }
.tag .close { border-left: 1px solid silver; margin: -8px -8px -8px 8px; padding: 8px; cursor: pointer; }
.tag .close:hover { background-color: rgb(207, 217, 224); }
.tag.active { color: white; background-color: rgb(0, 112, 243); }
.mdi { opacity: 0.75; font-size: 16px; margin-right: 4px; }
.group { color: gray; margin-bottom: 5px; }
</style>