<template>
	<div class="toastMessages" @mouseover="suspendClose = true" @mouseout="endSuspend">
		<div v-for="toastMessage of toastMessages" :key="toastMessage.id" class="toastMessage" :class="toastMessage.type">
			<mdi close @click="close(toastMessage, true)" style="float: right; margin-right: -5px;" />
			{{ clip(toastMessage.message, 200) }}
		</div>
	</div>
</template>

<script>
export default {
	inject: [ 'eventBus' ],
	data: () => ({
		toastMessages: [],
		suspendClose: false,
	}),
	methods: {
		addToastMessage(message, type = 'info', expires = 7000) {
			if (typeof message == 'string')
				message = message.replace(/com\.alturos\S*/g, '')

			if (type == 'error') console.error('TOAST', message)
			else if (type == 'warn') console.warn('TOAST', message)
			else console.log('TOAST', message)

			const toastMessage = { message, type, id: Date.now() }
			this.toastMessages.push(toastMessage)
			setTimeout(() => { this.close(toastMessage) }, expires)
		},
		removeToastMessageMatching(substring) {
			this.toastMessages = this.toastMessages.filter(t => !t.message?.includes?.(substring))
		},
		close(toastMessage, force = false) {
			toastMessage.closed = true
			if (this.suspendClose && !force) return
			this.toastMessages = this.toastMessages.filter(t => t != toastMessage)
		},
		endSuspend() {
			this.suspendClose = false
			window.setTimeout(() => {
				if (this.suspendClose) return
				this.toastMessages = this.toastMessages.filter(t => !t.closed)
			}, 50)
		},
		onEntryApiError(e) {
			this.addToastMessage(e.detail.message, 'error')
		},
		test() {
			window.setTimeout(() => {
				const type = ['info', 'success', 'warning', 'error'][Math.floor(Math.random() * 4)]
				this.addToastMessage('com.alturos.some.package This is a toast message. The text may also be very long.', type)
				this.test()
			}, Math.random() * 3000)
		},
		clip(text, length) {
			if (text.length <= length) return text
			return text.substr(0, length - 3) + '...'
		},
	},
	async mounted() {
		document.body.addEventListener('EntryApiError', this.onEntryApiError)
		this.eventBus.$on('addToastMessage', this.addToastMessage)
		this.eventBus.$on('removeToastMessageMatching', this.removeToastMessageMatching)
		//this.test()
	},
	beforeDestroy() {
		document.body.removeEventListener('EntryApiEvent', this.onEntryApiError)
		this.eventBus.$off('addToastMessage', this.addToastMessage)
		this.eventBus.$off('removeToastMessageMatching', this.removeToastMessageMatching)
	},
}
</script>

<style scoped>
.toastMessages { position: fixed; bottom: 10px; left: 50%; transform: translate(-50%, 0); z-index: 999999; display: flex; flex-direction: column; gap: 5px; }
.toastMessage { transition: 0.3s; cursor: pointer; font-size: 13px; width: 300px; padding: 10px 15px; border-radius: 3px; background: white; border: 0px solid #eee; box-shadow: 1px 1px 5px -1px rgba(0,0,0,0.1), inset 2px 2px 10px -5px white; word-break: break-all; }
.toastMessage.info { background: #f0f0f0; border-color: #b9b9b9; }
.toastMessage.success { background: rgb(205, 243, 198); border-color: #add0b5; }
.toastMessage.warn,
.toastMessage.warning { background: #fff3cd; border-color: #d7c99e; }
.toastMessage.error { background: #f8d7da; border-color: #d9adb2; }
</style>