<template>
	<div class="apiKeys" style="height: 100%;">
		<Head :hasBack="true" @back="close()" v-if="apiKey">
			<div class="heading">
				<h1 v-if="apiKey" style="display: flex; width: 100%;">
					<div style="flex: 1;">{{ apiKey.name ? apiKey.name : 'New apiKey' }}</div>
				</h1>
			</div>
			<div class="actions">
				<ActionButton class="delete" @click="deleteKey()" v-if="!isNew">Delete</ActionButton>
				<ActionButton class="save" @click="save()">Save</ActionButton>
			</div>
		</Head>
		<div class="body" style="margin: 25px; margin-bottom: 50px; font-size: 14px;" v-if="apiKey">
			<h2>Details</h2>
			<label for="name">Name (required)</label>
			<div class="fieldWrap">
				<input type="text" class="input" id="name" v-model="apiKey.name" />
			</div>
			<label for="description">Description</label>
			<div class="fieldWrap">
				<input type="text" class="input" id="description" v-model="apiKey.description" />
			</div>
			<label for="accessToken">Access Token</label>
			<div class="fieldWrap">
				<input v-if="isNew" type="text" class="input" id="accessToken"
					v-model="apiKey.accessToken"
					placeholder="Leave empty to generate automatically"
				/>
				<div v-else style="display: flex; gap: 10px;">
					<input :type="show ? 'text' : 'password'" class="input" id="accessToken"
						readonly
						v-model="apiKey.accessToken"
						style="flex-grow: 1;"
					/>
					<ActionButton class="neutral" @click="show = !show" style="margin-bottom: 10px;"><mdi eye /></ActionButton>
				</div>
			</div>
			<h2>Environments</h2>
			<div class="environments">
				<div v-for="environment of environments" :key="environment.sys.id" class="environment">
					<input type="checkbox" :id="environment.sys.id" :value="environment.link" v-model="apiKey.environments" />
					<mdi source-branch />
					<label :for="environment.sys.id">{{ environment.name }}</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Head from './Head.vue'
import Info from './Info.vue'
import ActionButton from '../components/ActionButton.vue'

export default {
	name: 'ApiKeyEdit',
	components: { Head, Info, ActionButton },
	inject: [ 'base', 'baseEndpoint', 'spaceId' ],
	data: () => ({
		id: null,
		apiKey: null,
		show: false,
		environments: null,
	}),
	computed: {
		endpoint() {
			return this.baseEndpoint + '/spaces/' + this.spaceId
		},
		isNew() {
			return this.id == 'new'
		},
	},
	methods: {
		async load() {
			const apiKey = await this.$httpGet(this.endpoint + '/api_keys/' + this.id)
			if (!apiKey.environments) apiKey.environments = []
			this.apiKey = apiKey
		},
		async save() {
			if (this.isNew) {
				if (!this.apiKey.accessToken)
					this.apiKey.accessToken = undefined
				this.apiKey = await this.$httpPost(this.endpoint + '/api_keys', this.apiKey)
				this.$router.replace(this.base + '/settings/apikeys/' + this.apiKey.sys.id)
				return
			}
			await this.$httpPut(this.endpoint + '/api_keys/' + this.apiKey.sys.id, this.apiKey)
			this.$router.push(this.base + '/settings/apikeys')
		},
		close() {
			this.$router.push(this.base + '/settings/apikeys')
		},
		async deleteKey() {
			if (!confirm('Are you sure you want to delete this API Key?')) return
			await this.$httpDelete(this.endpoint + '/api_keys/' + this.apiKey.sys.id)
			this.close()
		},
		async loadEnvironments() {
			const r = await this.$httpGet(this.baseEndpoint + '/spaces/' + this.spaceId + '/environments')
			const environments = r.items
			for (const environment of environments) {
				environment.link = { sys: { id: environment.sys.id, linkType: 'Environment', type: 'Link' } }
			}
			environments.sort((a, b) => a.name.localeCompare(b.name))
			this.environments = environments
		},
	},
	async mounted() {
		await this.loadEnvironments()
		this.id = this.$route.params.id
		if (this.isNew) {
			this.apiKey = {
				name: '',
				description: '',
				accessToken: '',
				environments: [],
			}
			return
		}
		else {
			await this.load()
		}
		document.title = 'API Key - ' + this.apiKey.name
	},
}
</script>

<style scoped>
h2 { margin: 20px 0px 1rem; padding: 0px; font-weight: 600; color: rgb(17, 27, 43); font-size: 1rem; line-height: 1.5rem; }
.input { margin-bottom: 10px; }
.environments { display: flex; gap: 15px; flex-direction: column; padding: 0; }
.environment { display: flex; gap: 5px; align-items: center; font-family: monospace; }
</style>