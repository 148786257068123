
export default {
	name: 'Menu',
	props: {
		left: { type: String, default: undefined },
		right: { type: String, default: undefined },
		maxHeight: { type: String, default: undefined },
		autoCloseOnClick: { type: Boolean, default: true },
	},
	data: () => ({
		top: 0,
		isOpen: false,
		placeInterval: null,
		menuClicked: false,
		justClosed: false,
	}),
	methods: {
		open() {
			if (this.justClosed) return
			this.isOpen = true
			window.addEventListener('mousedown', this.clickaway)
			this.placeInterval = window.setInterval(this.place, 2)
			this.$nextTick(() => {
				this.place()
				this.$emit('open')
			})
		},
		close() {
			this.isOpen = false
			window.removeEventListener('mousedown', this.clickaway)
			window.clearInterval(this.placeInterval)
			this.$nextTick(() => {
				this.$emit('close')
			})
			this.justClosed = true
			window.setTimeout(() => this.justClosed = false, 150)
		},
		place() {
			if (!this.$refs.container || !this.$refs.content) {
				window.clearInterval(this.placeInterval)
				return
			}
			const containerRect = this.$refs.container.getBoundingClientRect()
			const contentRect = this.$refs.content.getBoundingClientRect()
			const windowRect = { width: window.innerWidth, height: window.innerHeight }
			let top = containerRect.y + 5
			if (top + contentRect.height > windowRect.height)
				top = containerRect.y - 30 - contentRect.height
			// TODO: if the available space is too small to place either up or down, reduce maxHeight of menu
			this.top = top + 'px'
		},
		clickmenu(event) {
			this.menuClicked = true
		},
		clickaway(event) {
			if (this.menuClicked) {
				this.menuClicked = false
				if (this.autoCloseOnClick)
					window.setTimeout(this.close, 300)
				return
			}
			this.close()
		},
	},
}
