// TODO: refactor the whole http call structure

type Options = { headers?: any, key: string } | undefined
type Request = {
	controller: AbortController
	promise: Promise<any>
	method: string
	url: string
	body: any
	options: Options
}

const requests: { [ key: string ]: Request } = {}

export function abort(key: string) {
	const request = requests[key]
	if (request) {
		request.controller.abort()
		delete requests[key]
	}
}

// by specifying options.key we can prevent multiple requests running at the same time for the same resource
export async function httpFetch(method: string, url: string, body: any = undefined, options: Options = undefined) {
	let status
	try {
		//if (url.indexOf('/') == 0 && url.indexOf('/api') == -1) url = '/api' + url
		let token = window.localStorage.token
		if (token == 'null') token = null

		const controller = new AbortController()
		const signal = controller.signal
		const request: any = {
			method: method,
			headers: {
				authorization: 'Bearer ' + token,
				accept: 'application/json, text/plain, */*',
				'content-type': 'application/json',
				// force proxy to use ch
				'as-use': 'ch',
			},
			body:
				body === undefined ? body :
				typeof body == 'string' ? body :
				body instanceof ArrayBuffer ? new Blob([body]) :
				JSON.stringify(body),
			signal,
		}

		if (options) {
			for (let key in options.headers ?? []) {
				const lckey = key.toLowerCase()
				request.headers[lckey] = options.headers[key]
			}
		}
		if (options?.headers?.['as-use'] === false) delete request.headers['as-use']

		const promise = fetch(url, request)

		if (options?.key) {
			abort(options.key)
			requests[options.key] = { controller, promise, method, url, body, options }
		}

		const response = await promise
		status = response.status

		delete requests[options?.key]

		if (response.status === 204) return null
		if (response.status === 401) {
			document.getElementById('root')?.dispatchEvent?.(new CustomEvent('401', { bubbles: true }))
			throw { status: 401, message: 'Access Denied' }
		}

		const responseText = await response.text()
		let result
		try {
			result = JSON.parse(responseText)
		}
		catch (e) {
			// TODO: certain bodies may be allowed, like OK
			if (response.status === 200) return {}
		}

		// regardless of the status code we throw the result if its an error object
		if (result?.isError) throw result

		// on other 200 responses we return the result
		if (response.status === 200) return result

		// on other error responses where we got a body we throw that
		if (response.status >= 400 && result) throw result // + ' (' + method + ' ' + url + ')'

		// otherwise we throw the status text (like "HTTP 404 Not Found")
		throw responseText
	}
	catch (e) {
		// TODO: we do not get any details on for example 502 responses
		//console.log('TODO: httpFetch error', e)
		if (e?.name != 'AbortError' && options?.key) {
			console.log('AbortError: request was aborted because of duplicate key', options?.key)
		}
		else if (e?.message == '401') {}
		else if (status == '404') {}
		else {
			document.getElementById('root')?.dispatchEvent?.(new CustomEvent('EntryApiError', {
				detail: { message: e?.message ?? e }, // + ' (' + method + ' ' + url + ')',
				bubbles: true
			}))
		}
/*		if (e?.status === 401 && this.$route.path !== '/') {
			// TODO: refresh the token instead?
			// TODO: make a message instead with a logot button!
			//       otherwise a programming error on the api may lead to a logout
			//       so the user still has the screen to make a grab or sth.
			this.$root.app.showLogin()
			return
		}*/
		// TODO: handle other codes specifically as well?
		// 403 -> you are not allowed here
		throw e
	}
}

export async function httpGet(url: string, query: any, key?: string) {
	if (!query) query = {}
	url += url.indexOf('?') < 0 ? '?' : '&'
	return await httpFetch('GET', url + new URLSearchParams(query).toString(), undefined, { key })
}

export type Search = {
	contentType: string
	allowedContentTypes?: string[]
	search: string
	filters: { query: { k: string, v: any } }[],
	fields?: string[]
	order: string[]
}

export async function loadEntries(endpoint: string, filter: Search, limit: number, skip: number, include = 0, key?: string): Promise<{ items: any[], total: number }> {
	const request: any = { limit, skip, select: 'sys,metadata' }

	// TODO: we get this error:
	//       message: "A Content Type ID is required. When querying for Entries and involving fields you need to limit your query to a specific Content Type. Please send a Content Type ID (not the name) as the URI query parameter \"content_type\""
	//       what to do here? currently i just prevent this case but thats not good.
	if (filter.contentType) {
		const contentTypeName = filter.contentType
		request['content_type'] = contentTypeName
		const contentType = (window as any).typeLookup[contentTypeName]

		// TODO: what happens in CF when this is ambiguous? does it then select all fields and sorts it out later?
		// TODO: we have to select the title field of these entries
		// TODO: we have to find the first media/image item of the type and add that to the search like:
		//url += '&select=sys%2Cfields.title%2Cfields.titleTag%2Cfields.media'
		//url += '&select=sys%2Cfields.title%2Cfields.frontendTitle%2Cfields.image'

		request.include = include
		if (contentType?.displayField) {
			request.select += ',fields.' + contentType.displayField // + ',fields.image'
			request.select += ',fields.media'
		}
		if (filter.fields) {
			for (const field of filter.fields) {
				if (field == 'fields') request.select += ',fields'
				else request.select += ',fields.' + field
			}
		}
	}
	else if (filter.allowedContentTypes?.length) {
		// TODO: i think we should set a select (even though CF does not in this case..)
		request['content_type[in]'] = filter.allowedContentTypes
		// TODO: add the media field more cleverly (look at type)!
		//       maybe only on MediaAsset types?
		request.select += ',fields.title,fields.name,fields.media'
	}

	// TODO: go through the content type fields and identify subtitle and image fields and add them to .select
	//request.select = 'sys,fields.title,fields.frontendTitle,fields.image'

	request['sys.archivedAt[exists]'] = false

	for (const f of filter?.filters as any[] ?? []) {
		buildRequestParamsFromFilter(f, request)
	}

	request.order = filter.order?.join?.(',')
	if (!request.order) request.order = '-sys.updatedAt'

	if (filter.search)
		request.query = filter.search

	// TODO: service implementation for this!
	//       only load certain data (sys.id, fields.title.*, sys.contentType.sys.id)!
	const entries = await httpGet(endpoint + '/entries', request, key)

	// TODO: param to also load assets?
	// TODO ARCH: how to best do this? i dont really want this responsibility in here.. but at the entry level we cannot join the requests.
	const assetIdMap: any = {}
	for (const entry of entries.items) {
		entry.selected = false
		// we take the first-best - i guess we should rather use the defaultLocale, but we dont have it here.
		const imageField = entry?.fields?.image ?? entry?.fields?.media ?? {}
		for (const localeCode in imageField) {
			const value = imageField[localeCode]
			if (!value?.sys?.id) continue
			assetIdMap[value.sys.id] = entry
			break
		}
	}
	if (Object.keys(assetIdMap).length) {
		// CF loads the corresponding assets separately by id list:
		// https://api.contentful.com/spaces/390osprlshgj/environments/staging/assets?limit=4&sys.id%5Bin%5D=7hltAmMu3wV6z1szwQznbf%2C7wyJRXBLU7Wvyj1RliP58l%2C1uJxB3TbMCM8Tj75DzM3FN%2C1gnEgqrcjoYa9msnOm2WzC
		let ids = Object.keys(assetIdMap)

		// %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
		// TODO: remove this workaround when this is fixed at the server!
		ids = [ ...ids, ...ids.map(id => 'asset/' + id) ]

		const assetsResponse = await httpGet(endpoint + '/assets', {
			limit,
			'sys.id[in]': ids.join(','),
		})
		for (const asset of assetsResponse.items) {
			assetIdMap[asset.sys.id].asset = asset
		}
	}

	return entries
}

export type Filter = {
	query: {
		k: string
		v: string
	}
	type?: string
	mode: string
}

export function buildRequestParamsFromFilter(f: Filter, r: { [ index: string ]: string | boolean } = {}) {
	const k = f.query.k
	const v = f.query.v
	// TODO: can we move this date specific logic to the FilterItem?
	//       currently not, because our interface is only {k,v}
	if (f.type == 'Date' && f.mode == 'eq') {
		r[k + '[gte]'] = v + 'T00:00:00Z'
		r[k + '[lte]'] = v + 'T23:59:59Z'
	}
	else if (f.type == 'Date' && f.mode == 'ne') {
		r[k.replace('[eq]', '[lt]')] = v + 'T00:00:00Z'
		r[k.replace('[eq]', '[gt]')] = v + 'T23:59:59Z'
	}
	else if (k == 'sys.status') {
		if (v == 'archived') {
			r['sys.archivedAt[exists]'] = true
		}
		else if (v == 'changed') {
			r['changed'] = true
			r['sys.archivedAt[exists]'] = false
			r['sys.publishedAt[exists]'] = true
		}
		else if (v == 'draft') {
			r['sys.archivedAt[exists]'] = false
			r['sys.publishedAt[exists]'] = false
		}
		else if (v == 'published') {
			r['changed'] = false
			r['sys.archivedAt[exists]'] = false
			r['sys.publishedAt[exists]'] = true
		}
	}
	else {
		r[k] = v
	}
	return r
}

export async function loadAssets(endpoint: string, filter: { search: string, filters: { query: { k: string, v: any } }[], order: string[] }, limit: number, skip: number): Promise<{ items: any[], total: number }> {
	const request: any = { limit, skip }

	request['sys.archivedAt[exists]'] = false

	for (const f of filter?.filters ?? []) {
		request[f.query.k] = f.query.v
	}

	request.order = filter.order?.join?.(',')
	if (!request.order) request.order = '-sys.updatedAt'

	if (filter.search)
		request.query = filter.search

	// TODO: service implementation for this!
	//       only load certain data (sys.id, fields.title.*, sys.contentType.sys.id)!
	const entries = await httpGet(endpoint + '/assets', request)

	return entries
}

/*async loadEntries() {
	// TODO: instead of preventing: load only part
	//       prevent picking of type "media" - there are too many entries!
	if (this.filter.contentType == 'media') {
		this.entries = []
		this.message = 'too many entries.'
		return
	}
	this.message = ''

	this.loading = true
	try {
		const res = await this.$httpGet(`/content/entries`, {
			contentType: this.filter.contentType,
			// search: this.filter.search,
			// TODO: service implementation for this!
			//       only load certain data (sys.id, fields.title.*, sys.contentType.sys.id)!
			select: 'minimal',
		})
		for (const entry of res) {
			entry.match = false
			entry.search = entry.fields.title ? JSON.stringify(Object.values(entry.fields.title)).toLowerCase() : ''
			entry.selected = false
		}
		this.entries = res
		this.loading = false
	}
	catch (e) {
		this.message = e.message == 'Request failed with status code 403' ? 'Unauthorized' : 'Error occured'
		this.entries = [];
		this.loading = false
	}
},*/