
import ContentTypesComboMulti from './fields/ContentTypesComboMulti.vue'
import SystemTags from './fields/SystemTags.vue'
import TemplateBasedList from './TemplateBasedList.vue'

export default {
	name: 'RoleConditionEditor',
	components: { TemplateBasedList, SystemTags, ContentTypesComboMulti },
	props: {
		value: Array,
		action: Array, // [ 'ch:entry:upsert' | ... ]
	},
	data: () => ({
		conditionTemplates: {
			'Type Equals': { type: 'stringEquals', key: 'ch:entry/contentType', param: [] },
			'Type Not Equals': { type: 'stringNotEquals', key: 'ch:entry/contentType', param: [] },
			'Tags Contain': { type: 'listContains', key: 'ch:entry/tags', param: [] },
			'Tags Do Not Contain': { type: 'listNotContains', key: 'ch:entry/tags', param: [] },
		},
	}),
}
