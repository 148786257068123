<template>
	<div class="dots">
		<SpinnerOct v-for="(dot, d) of dotsx" :key="d"
			class="dot"
			:style="{
				'--x': dot.x.toFixed(2) + 'px',
				'--y': dot.y.toFixed(2) + 'px',
				'--z': dot.z.toFixed(2) + 'px',
				'--s': dot.s.toFixed(2) * 0.2,
			}"
		/>
	</div>
</template>

<script>
import SpinnerOct from './SpinnerOct.vue'
export default {
	components: { SpinnerOct },
	name: 'SpinnerAnimation',
	props: {
		small: Boolean,
	},
	computed: {
		dotsx() {
			const r = []
			for (let i = 0; i < Math.random() * 15 + 3; i++) {
				r.push({
					x: Math.random() * 100 - 50,
					y: Math.random() * 100 - 50,
					z: Math.random() * 100 - 50,
					s: Math.random() * 0.5 + 0.5,
				})
			}
			return r
		},
	},
}
</script>

<style scoped>
.dots { position: relative; width: 200px; height: 200px; animation: spin 20s infinite linear; transform-style: preserve-3d; }
.dot { position: absolute; left: var(--x); top: var(--y); animation: spin-rev 20s infinite linear; transform-style: preserve-3d; transform-origin: center center var(--z); }

@keyframes spin {
	0% { transform: rotateY(0deg); }
	100% { transform: rotateY(360deg); }
}
@keyframes spin-rev {
	0% { transform: rotateY(0deg) scale(var(--s)); }
	100% { transform: rotateY(-360deg) scale(var(--s)); }
}
</style>