import BulkLoader from './BulkLoader'
import { emptyRteValueLength } from '../utils'

export default {
	inject: [ 'endpoint', 'permissions' ],
	data: () => ({
		model: null,
		typeLookup: {},
		editorInterface: null,
		loading: 0,
		references: [],
		errors: null,
	}),
	provide() {
		return {
			// TODO: we need the same for assets!
			entryLoader: new BulkLoader(this.loadEntries),
		}
	},
	methods: {
		async fetchContentType(id) {
			return await this.$httpGet(this.endpoint + '/content_types/' + id)
		},
		async loadContentTypes() {
			this.loading++
			const request = {}
			request.limit = 1000
			const types = await this.$httpGet(this.endpoint + '/content_types', request)
			const typeLookup = {}
			for (const type of types.items) {
				typeLookup[type.sys.id] = type
			}
			this.typeLookup = typeLookup
			window['typeLookup'] = typeLookup
			this.loading--
		},
		async fetchEntry(entryId) {
			const request = {}
			request['sys.id'] = entryId
			const entries = await this.$httpGet(this.endpoint + '/entries', request)
			const model = entries.items[0]

			if (!model) throw new Error('Entry not found!')

			// create empty fields where missing based on the type info
			const id = model.sys.contentType.sys.id
			const contentType = this.typeLookup[id]
			if (!model.fields) {
				model.fields = {}
			}
			for (const field of contentType?.fields ?? []) {
				if (model.fields[field.id]) continue
				// TODO: we have to pass the de model in here so we have binding
				//       thats not ideal, as locales should be flexible..
				// TODO: this is not correct for all types(?):
				//       array fields?
				//       ...?
				const value = {}
				if (field.defaultValue) {
					const fieldLocales = field.localized ? field.defaultValue : { [ this.defaultLocale ]: field.defaultValue[this.defaultLocale] }
					for (const locale in fieldLocales) {
						value[locale] = field.defaultValue[locale]
					}
				}
				model.fields[field.id] = value
			}
			if (!model.metadata) model.metadata = {}

			if (!model.sys.publishedAt) model.sys.publishedAt = null
			if (!model.sys.publishedBy) model.sys.publishedAt = null
			if (!model.sys.updatedAt) model.sys.updatedAt = null
			if (!model.sys.updatedBy) model.sys.updatedBy = null
			return model
		},
		async loadEntry() {
			try {
				this.loading++
				this.model = await this.fetchEntry(this.entryId)
			}
			finally {
				this.loading--
			}
		},
		async loadReferences() {
			this.loading++
			const ct = this.model?.sys?.contentType?.sys?.id
			let references
			if (ct == 'contentHubAsset') {
				references = await this.$httpGet(this.endpoint + '/entries', {
					'links_to_asset': this.entryId,
					limit: 101,
				})
			}
			else {
				references = await this.$httpGet(this.endpoint + '/entries', {
					'links_to_entry': this.entryId,
					limit: 101,
				})
			}
			this.references = references.items
			this.loading--
		},
		// some controls dont have a widgetId set, even though, they should due to their validation
		fixEditorInterface(editorInterface, contentType) {
			const fieldLookup = {}
			for (const field of contentType.fields) fieldLookup[field.id] = field
			for (const control of editorInterface.controls) {
				const field = fieldLookup[control.fieldId]
				if (!field) {
					console.warn('field not found for control', control.fieldId, 'in type')
					control.fieldMissing = true
					continue
				}
				// console.log('fixing control', control.fieldId, field, control)

				// for some types we dont have a widgetId and its ok
				if ([ 'Date', 'Link', 'RichText', 'Location' ].includes(field.type)) continue
				if (field.type == 'Array' && field.items?.type == 'Link') continue

				if (control.widgetId == '28onxjrLMs4C5VxLTJeuop') control.widgetId = 'checkbox'
				// TODO: which other weird widget ids do we have?
				//       can we get all of them easily?
				else {
					// we "detect" weird widget ids like "28onxjrLMs4C5VxLTJeuop"
					if (!control.widgetId) {
						console.warn('widgetId missing on field', control.fieldId)
					}
					else if (control.widgetId.length > 20) {
						console.warn('widgetId', control.widgetId, 'weird on field', control.fieldId)
						control.widgetId = null
					}
					if (control.widgetId) continue

					if (field.type == 'Array') {
						if (field.items?.type == 'Symbol') control.widgetId = 'tagEditor'
						if (field.items?.type == 'Link' && field.items?.linkType == 'Entry') control.widgetId = 'tagEditor'
					}
					if (field.type == 'Symbol') {
						// TODO: move to util func
						const validationLookup = {}
						for (const validation of field.validations) {
							for (const k in validation) {
								if (k == 'message') continue
								validationLookup[k] = validation[k]
							}
						}
						if (validationLookup.in) control.widgetId = 'dropdown'
						else control.widgetId = 'singleLine'
					}

					if (!control.widgetId)
						console.log('-> ERR: could not determine widgetId for', control.fieldId, '(', field.type, ')', field)
					else
						console.log('-> using widgetId =', control.widgetId)
				}
			}
		},
		async loadEditorInterface() {
			if (!this.contentType) return console.error('loadEditorInterface: no contentType!')
			this.loading++
			try {
				const request = {}
				const editorInterface = await this.$httpGet(this.endpoint + '/content_types/' + this.contentType.sys.id + '/editor_interface', request)
				if (editorInterface) this.fixEditorInterface(editorInterface, this.contentType)
				this.editorInterface = editorInterface
			}
			catch (e) {
				console.error('loadEditorInterface: could not load editor interface! server error:', e)
			}
			this.loading--
		},
		// TODO: we should actually only load a minimal entry here - we have to look at all use cases
		async loadEntries(ids) {
			const request = {}
			request['sys.id[in]'] = ids.join(',')
			const entries = await this.$httpGet(this.endpoint + '/entries', request)
			return entries.items
		},
		async archiveEntry(model = undefined) {
			model = model ?? this.model
			if (model == undefined)
			this.loading++
			const r = await this.$httpPut(this.endpoint + '/entries/' + model.sys.id + '/archived', null, {
				headers: {
					// TODO: is this really needed? what for?
					'X-Contentful-Skip-Transformation': true,
				},
			})
			if (r?.sys) {
				model.sys.version = r.sys.version
				model.sys.archivedAt = r.sys.archivedAt
			}
			this.loading--
		},
		async createEntry(typeId, model = {}) {
			return await this.$httpPost(this.endpoint + '/entries', this.newEntry(model), {
				headers: {
					'X-Contentful-Content-Type': typeId,
				},
			})
		},
		async saveEntry(localEntry = undefined) {
			try {
				this.loading++
				this.autoSaveTimer = null
				localEntry = localEntry ?? this.model

				// for persisting we need to copy the model, because we need to modify it
				const entry = JSON.parse(JSON.stringify(localEntry))
				for (const f in entry?.fields ?? {}) {
					const field = entry.fields[f]
					for (const l in field ?? {}) {
						if (field[l] === undefined) delete field[l]
						if (field[l] === null) delete field[l]
						if (field[l] === '') delete field[l]
						if (Array.isArray(field[l]) && field[l]?.length === 0) delete field[l]
						if (typeof field[l] == 'object' && JSON.stringify(field[l]).length == emptyRteValueLength) delete field[l]
					}
					// TODO: i think we may only do this if we copied the model
					//       which i'm not sure we do in all cases..
					// if (Object.keys(field).length == 0) delete entry.fields[f]
				}
				const r = await this.$httpPut(this.endpoint + '/entries/' + entry.sys.id, entry, {
					headers: {
						'X-Contentful-Version': entry.sys.version,
					},
				})

				// we update the local version info, so merge can work
				if (r?.sys) {
					localEntry.sys.version = r.sys.version
					localEntry.sys.updatedAt = r.sys.updatedAt
					localEntry.sys.updatedBy = r.sys.updatedBy
				}
			}
			finally {
				this.loading--
			}
		},
		// TODO: refactor: rename to publishEntry
		async publish(entry = undefined) {
			this.loading++
			if (!entry) entry = this.model
			try {
				console.log(entry)
				const r = await this.$httpPut(this.endpoint + '/entries/' + entry.sys.id + '/published', null, {
					headers: {
						'X-Contentful-Version': entry.sys.version,
					},
				})
				if (r?.sys) {
					entry.sys.version = r.sys.version
					entry.sys.publishedAt = r.sys.publishedAt ?? null
					entry.sys.publishedBy = r.sys.publishedBy ?? null
					entry.sys.updatedAt = r.sys.updatedAt
					entry.sys.updatedBy = r.sys.updatedBy
				}
			}
			catch (e) {
				if (e?.sys?.type == 'Error') {
					this.errors = e
				}
				else {
					console.error(e)
				}
			}
			this.loading--
		},
		async unpublishEntry(entry = undefined) {
			this.loading++
			if (!entry) entry = this.model
			const r = await this.$httpDelete(this.endpoint + '/entries/' + entry.sys.id + '/published', {
				headers: {
					'X-Contentful-Version': entry.sys.version,
				},
			})
			if (r?.sys) {
				entry.sys.version = r.sys.version
				entry.sys.publishedAt = r.sys.publishedAt ?? null
				entry.sys.publishedBy = r.sys.publishedBy ?? null
				entry.sys.updatedAt = r.sys.updatedAt
				entry.sys.updatedBy = r.sys.updatedBy
			}
			this.loading--
		},
		async deleteEntry() {
			this.loading++
			const r = await this.$httpDelete(this.endpoint + '/entries/' + this.model.sys.id, {
				headers: {
					'X-Contentful-Version': this.model.sys.version,
				},
			})
			this.loading--
		},

		// TODO: fix code duplication from above (*Entry)
		// ASSETS
		async loadAssets(ids) {
			const request = {}
			request['sys.id[in]'] = ids.join(',')
			const assets = await this.$httpGet(this.endpoint + '/assets', request)
			return assets.items
		},
		async archiveAsset(model = undefined) {
			model = model ?? this.model
			if (model == undefined)
			this.loading++
			const r = await this.$httpPut(this.endpoint + '/assets/' + model.sys.id + '/archived', null, {
				headers: {
					// TODO: is this really needed? what for?
					'X-Contentful-Skip-Transformation': true,
				},
			})
			if (r?.sys) {
				model.sys.version = r.sys.version
				model.sys.archivedAt = r.sys.archivedAt
			}
			this.loading--
		},
		async saveAsset(model = undefined) {
			model = model ?? this.model
			this.loading++
			const r = await this.$httpPut(this.endpoint + '/assets/' + model.sys.id, model, {
				headers: {
					'X-Contentful-Version': model.sys.version,
				},
			})
			if (r?.sys) {
				model.sys.version = r.sys.version
				model.sys.updatedAt = r.sys.updatedAt
			}
			this.loading--
		},
		async publishAsset() {
			this.loading++
			try {
				const r = await this.$httpPut(this.endpoint + '/assets/' + this.model.sys.id + '/published', null, {
					headers: {
						'X-Contentful-Version': this.model.sys.version,
					},
				})
				if (r?.sys) {
					this.model.sys.version = r.sys.version
					this.model.sys.publishedAt = r.sys.publishedAt
				}
			}
			catch (e) {
				if (e?.sys?.type == 'Error') {
					this.errors = e
				}
			}
			this.loading--
		},
		async unpublishAsset() {
			this.loading++
			const r = await this.$httpDelete(this.endpoint + '/assets/' + this.model.sys.id + '/published', {
				headers: {
					'X-Contentful-Version': this.model.sys.version,
				},
			})
			if (r?.sys) {
				this.model.sys.version = r.sys.version
				this.model.sys.publishedAt = r.sys.publishedAt
			}
			this.loading--
		},
		newEntry(o) {
			if (!o) o = {}
			if (!o.fields) o.fields = {}
			if (this.permissions?.mePolicy?.tags?.length) {
				if (!o.metadata) o.metadata = {}
				if (!o.metadata.tags) o.metadata.tags = []
				const tags = this.permissions.mePolicy.tags
					.map(tag => ({ sys: { id: tag, linkType: 'Tag', type: 'Link' } }))
				o.metadata.tags.push(...tags)
			}
			return o
		},
		newAsset(o) {
			return this.newEntry(o)
		},
	},
}