var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.node1 && _vm.node2 && !_vm.node1.removed && !_vm.node2.removed)?_c('div',{staticClass:"Link",class:{ flip: _vm.flip },style:({
		left: _vm.node1.x + 'px',
		top: _vm.node1.y + 'px',
		width: _vm.length + 'px',
		transform: 'rotate(' + _vm.angle + 'rad)',
		transformOrigin: '0 0',
		borderColor: _vm.color,
		borderBottomWidth: _vm.strokeWidth + 'px',
		borderBottomStyle: _vm.strokeStyle,
	}),on:{"mouseover":function($event){return _vm.$emit('mouseover', _vm.value)},"mouseout":function($event){return _vm.$emit('mouseout', _vm.value)}}},[_c('div',{staticClass:"labels"},[_c('div',{staticClass:"middle",style:({ color: _vm.color })},[_vm._v(_vm._s(_vm.value.rel))])]),_c('div',{staticClass:"head",style:({ color: _vm.color, zoom: (_vm.strokeWidth-1)/3+1 })},[_vm._v("➤")])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }