<template>
	<div class="AdditionalFields">
		<div v-if="additionalFields.length" class="gapped">
			<Info class="error">
				<b>Extraneous Additional Fields</b>: The object has the following fields not defined in the content model.
				These fields are not defined in the content model and you will not be able to publish this object until they are removed.
			</Info>
			<div class="additionalField" v-for="additionalField of additionalFields" :key="additionalField.name">
				<FieldWrapper class="error" v-for="locale of selectedLocaleCodes" :key="locale" :title="additionalField.name + ' - ' + locale" :locale="locale">
					<div class="code">
						{{ additionalField.value?.[locale] }}
					</div>
				</FieldWrapper>
				<ActionButton class="delete" @click="deleteField(additionalField.name)">Delete Field '{{ additionalField.name }}'</ActionButton>
			</div>
		</div>
		<div v-if="additionalLocaleFields.length" class="gapped">
			<Info class="error">
				<b>Extraneous Locale Content on Non-Localized Fields</b>: The object has the following non-localized fields containing locale-content.
				You will not be able to publish this object until these contents are removed.
				In case this happened through a locale change, you may want to copy this content to the default locale before deleting it.
			</Info>
			<div class="additionalField" v-for="additionalField of additionalLocaleFields" :key="additionalField.name">
				<FieldWrapper class="error" v-for="locale of additionalField.nonDefaultLocales" :key="locale" :title="additionalField.name + ' - ' + locale" :locale="locale">
					<div class="code">
						{{ additionalField.value?.[locale] }}
					</div>
					<div style="margin-top: 10px; display: flex; gap: 10px;">
						<ActionButton class="delete" @click="deleteFieldLocale(additionalField.name, locale)">Delete Locale '{{ locale }}' from Field '{{ additionalField.name }}'</ActionButton>
						<ActionButton class="neutral" @click="copyFieldLocale(additionalField.name, locale, defaultLocale)">Copy {{ additionalField.name }}.{{ locale }} <mdi arrow-right /> {{ additionalField.name }}.{{ defaultLocale }}</ActionButton>
					</div>
				</FieldWrapper>
			</div>
		</div>
	</div>
</template>

<script>
import Info from '../../views/Info.vue'
import ActionButton from '../ActionButton.vue'
import FieldWrapper from './FieldWrapper.vue'

export default {
	inject: [ 'locales', 'defaultLocale' ],
	components: { Info, FieldWrapper, ActionButton },
	props: {
		value: Object,
		controlsMerged: Array,
		selectedLocaleCodes: Array,
	},
	data: () => ({
		updates: 0,
	}),
	computed: {
		controlsMap() {
			return this.controlsMerged.reduce((map, control) => { map[control.fieldId] = control; return map }, {})
		},
		fields() {
			this.updates
			if (!this.value?.fields) return []
			return Object.keys(this.value.fields)
				.map(name => ({
					name,
					value: this.value.fields[name],
					control: this.controlsMap[name],
					field: this.controlsMap[name]?.field,
					nonDefaultLocales: Object.keys(this.value.fields[name]).filter(l => l !== this.defaultLocale),
				}))
		},
		additionalFields() {
			return this.fields
				// remove each supported field
				.filter(f => !f.control)
		},
		additionalLocaleFields() {
			return this.fields
				.filter(f => {
					// unsupported field -> skip, this field is handled by additionalFields anyway
					if (!f.control) return false
					// localized field -> skip
					if (f.control.field.localized) return false
					// non-localized field with non-default locale -> add
					return f.nonDefaultLocales?.length > 0
				})
		},
	},
	methods: {
		deleteField(fieldName) {
			delete this.value.fields[fieldName]
			this.updates++
			this.$emit('input', this.value)
		},
		deleteFieldLocale(fieldName, locale) {
			delete this.value.fields[fieldName][locale]
			this.updates++
			this.$emit('input', this.value)
		},
		copyFieldLocale(fieldName, fromLocale, toLocale) {
			this.value.fields[fieldName][toLocale] = this.value.fields[fieldName][fromLocale]
			this.updates++
			this.$emit('input', this.value)
		},
	},
}
</script>

<style scoped>
.AdditionalFields { display: flex; flex-direction: column; gap: 10px; }
.gapped { display: flex; flex-direction: column; gap: 10px; }
.additionalField { display: flex; flex-direction: column; gap: 10px; }
.code { font-family: monospace; white-space: pre-wrap; background: white; color: gray; padding: 10px; border: 1px solid #ddd; border-radius: 4px; }
</style>