<template>
	<span class="mdi"
		:class="{
			[ 'mdi-' + cls ]: true,
			crystal,
			clickable,
		}"
		@click="$emit('click', $event)"
	/>
</template>

<script>
// usage:
// <mdi my-icon />
// <mdi>my-icon</mdi>
// <mdi icon="my-icon" />

export default {
	name: 'mdi',
	props: {
		icon: String,
		crystal: Boolean,
	},
	computed: {
		cls() {
			// TODO: using the slot does not update the reactive binding on hot reload..
			//       maybe remove this type of usage
			return Object.keys(this.$attrs)[0] ?? this.icon ?? this.$slots?.default?.[0]?.text
		},
		clickable() {
			return !!this.$listeners.click
		},
	},
}
</script>

<style scoped>
.clickable { cursor: pointer; }
.crystal {
	color: rgba(211, 169, 239, 0.934);
	font-size: 100px;
	--dark: rgb(93, 15, 134);
	--mid: rgb(158, 71, 205);
	text-shadow:
		-1px -1px 0 rgb(235, 151, 224),
		1px 0px 0 var(--mid),
		1px 1px 0 var(--dark),
		2px 1px 0 var(--mid),
		2px 2px 0 var(--dark),
		3px 2px 0 var(--mid),
		3px 3px 0 var(--dark),
		4px 3px 0 var(--mid),
		4px 4px 0 var(--dark),
		5px 4px 0 var(--mid),
		5px 4px 10px rgba(0,0,0,0.2);
}
</style>