
export default {
	name: 'VisualisationToggle',
	props: {
		filter: Object,
		column: Object,
	},
	computed: {
		state() {
			if (!this.filter.vis) return 'hidden'
			const v = this.filter.vis.find(v => v.col == this.column.id)
			if (!v) return 'hidden'
			return v.vis
		},
		active() {
			return this.state != 'hidden'
		},
		columnSupports() {
			return this.column.type == 'Location'
		},
	},
	methods: {
		toggle() {
			if (this.active) {
				this.filter.vis = this.filter.vis.filter(v => v.col != this.column.id)
			}
			else {
				this.filter.vis.push({ col: this.column.id, vis: 'visible' })
			}
			this.$emit('change', this.filter)
		},
	},
}
