
import Info from '../views/Info.vue'
// TODO: auto select preview by content channel of entity?
// TODO: swapping back and forth between preview and edit mode should not reload the preview (only on changes)
//       or the editor / or even change the scroll position!
//       probably we have to use v-show instead of v-if in EntryEditor!
// TODO: preview tools for JAMES web
//       add attributes like:
//         data-preview-field="title"
//         data-preview-entity="CF12345678901234567890"

import EntryApiMixin from './EntryApiMixin.js'

export default {
	name: 'Preview',
	components: {Info },
	mixins: [ EntryApiMixin ],
	inject: [ 'base', 'defaultLocale' ],
	props: {
		entry: Object,
		entryId: String,
		selectedLocaleCodes: Array,
	},
	data: () => ({
		preview: null,
		previews: [],
		scale: 1,
		device: 'desktop',
		deviceType: 'medium',
		overlayPos: 'bottom',
		urlTemplates: {
			lessonCopy: 'http://www.alturos.com/?q={contentType}',

			staticContentPage: '{baseUrl}/pages/{entryId}',
			story: '{baseUrl}/stories/{entryId}',
			mediaAsset: '{baseUrl}/media/{entryId}',
		},
		cb: 0,
		locale: null,
	}),
	computed: {
		url() {
			if (!this.preview) return null
			const contentType = this.entry.sys.contentType.sys.id
			const baseUrl = this.preview.baseUrl.replace(/\/$/, '')
			const urlTemplate = this.urlTemplates[contentType]
			if (!urlTemplate) return null
			const url = urlTemplate
				.replace(/\{ *baseUrl *\}/, baseUrl)
				.replace(/\{ *entryId *\}/, this.entry.sys.id)
				.replace(/\{ *locale *\}/, this.locale)
				.replace(/\{ *contentType *\}/, contentType)
			// TODO: cater for preexisting '?'
			// add cache buster
			return url + '?cb=' + this.cb
		},
		effectiveScale() {
			if (this.device == 'desktop') return this.scale
			return 1
		},
		effectiveWidth() {
			if (this.device == 'desktop') return '100%'
			if (this.deviceType == 'small') return '320px'
			if (this.deviceType == 'medium') return '390px'
			if (this.deviceType == 'large') return '768px'
		},
	},
	watch: {
		selectedLocaleCodes(n) {
			this.fixLocale()
		},
		locale(n) {
			this.cb++
		},
		preview(n) {
			localStorage.selectedPreview = n?.sys?.id
		},
	},
	methods: {
		async load() {
			const r = await this.$httpGet(this.endpoint + '/preview_environments')
			this.previews = r.items
			this.preview = this.previews.find(p => p.sys.id == localStorage.selectedPreview)
				?? this.previews[0]
		},
		fixLocale() {
			// switch to first locale when the current locale is removed
			if (!this.selectedLocaleCodes?.length) this.locale = this.defaultLocale
			if (!this.selectedLocaleCodes.includes(this.locale)) {
				this.locale = this.selectedLocaleCodes[0]
			}
		},
		popout() {
			window.open(this.url, '_blank')
		},
		refresh() {
			this.cb++
		},
	},
	async mounted() {
		this.load()
		this.locale = this.defaultLocale
		// trigger the watcher
		this.fixLocale()
	},
}
