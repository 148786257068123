<template>
	<div class="VisualisationTray">
		<template v-for="vis of visFields">
			<!--
			{{ vis.field.id }} ({{ vis.field.type }}): {{ vis }}
			-->
			<div :key="vis.col" v-if="vis.vis != 'hidden'">
				<GoogleMap
					v-if="vis.field.type == 'Location'"
					:field="vis.field"
					:entries="entries"
					@clickEntry="$emit('clickEntry', $event, vis.field)"
					@hoverEntry="$emit('hoverEntry', $event, vis.field)"
				/>
			</div>
		</template>
	</div>
</template>

<script>
import GoogleMap from '../GoogleMap.vue'

// TODO: with the new visualisation system we dont need to treat the 'location' field special in EntryTable.loadEntries anymore.
//		now probably also the binding on GoogleMap has to change.

export default {
	components: { GoogleMap },
	props: {
		filter: Object,
		entries: Array,
	},
	computed: {
		visFields() {
			if (!this.filter?.vis) return []
			return this.filter.vis.map(v => {
				const field = this.contentType?.fields?.find?.(f => f.id == v.col) ?? []
				return { field, ...v }
			})
		},
		contentType() {
			const lookup = window['typeLookup']
			return lookup?.[this.filter?.contentType]
		},
		locationField() {
			return this.contentType?.fields?.find(f => f.type == 'Location')
		},
	},
}
</script>

<style scoped>
.GoogleMap { margin: 0; min-height: 100px; position: fixed; width: 200px; height: 130px; right: 20px; bottom: 20px; border-radius: 15px; overflow: hidden; box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
	transition: 0.5s ease-out width, 0.5s ease-out height;
}
.GoogleMap.expanded { width: 60%; height: 60%; }
</style>