
import { field } from './FieldMixin.js'

export default {
	name: 'CheckboxesField',
	mixins: [ field ],
	props: {
		locale: String,
		value: Array,
		def: Object,
	},
	data: () => ({
		id: 'CB_' + Math.floor(Math.random() * 999999) + '_',
		model: {},
		autoModel: false,
	}),
	watch: {
		value(n) {
			this.validate()
			const model = this.valueToModel(n)
			if (JSON.stringify(model) == JSON.stringify(this.model)) return
			this.model = model
		},
		model(n) {
			this.modelToValue(n)
		},
	},
	computed: {
		allowedValues() {
			// TODO: dont use 0 index!
			return this.def.items.validations?.[0]?.in ?? []
		},
		usedUnallowedValues() {
			if (!this.model) return []
			const used = Object.keys(this.model)
			return used.filter(val => !this.allowedValues.includes(val))
		},
	},
	methods: {
		valueToModel(n) {
			if (!n) return this.model = {}
			const model = {}
			for (const val of n)
				model[val] = true
			return model
		},
		modelToValue(n) {
			const value = []
			for (const val of Object.keys(n)) {
				if (!n[val]) continue
				value.push(val)
			}
			if (JSON.stringify(value) == JSON.stringify(this.value)) return
			this.$emit('input', value)
		},
		update() {
			this.modelToValue(this.model)
		},
		validate() {
			this.onErrors([
				this.validateRequired(),
				this.validateArrayMin(),
				this.validateArrayMax(),
				this.validateArrayIn(),
				// TODO: pattern
				// TODO: prohibit pattern
			])
		},
	},
	mounted() {
		// ensure that we have an array on the model when starting
		if (!this.value)
			this.$emit('input', [])
		this.model = this.valueToModel(this.value)
	},
}
