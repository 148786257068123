<template>
	<div class="SortArrows">
		<mdi triangle v-if="value?.includes(id)" class="active" />
		<mdi triangle-outline v-else @click="setAscending($event)" />
		<br />
		<mdi triangle-down v-if="value?.includes('-' + id)" class="active" />
		<mdi triangle-down-outline v-else @click="setDescending($event)" />
		<span v-if="value?.length > 1" class="number">{{ number }}</span>
	</div>
</template>

<script>
export default {
	name: 'SortArrows',
	props: {
		// array of strings
		value: Array,
		column: Object,
	},
	computed: {
		id() {
			return this.column.scope + '.' + this.column.id
		},
		number() {
			if (this.value.includes(this.id))
				return this.value.indexOf(this.id) + 1
			if (this.value.includes('-' + this.id))
				return this.value.indexOf('-' + this.id) + 1
		},
	},
	methods: {
		remove() {
			if (this.value.includes(this.id))
				this.value.splice(this.value.indexOf(this.id), 1)
			if (this.value.includes('-' + this.id))
				this.value.splice(this.value.indexOf('-' + this.id), 1)
		},
		setAscending(event) {
			if (event.shiftKey) {
				this.remove()
				this.value.push(this.id)
				return
			}
			this.$emit('input', [ this.id ])
		},
		setDescending(event) {
			if (event.shiftKey) {
				this.remove()
				this.value.push('-' + this.id)
				return
			}
			this.$emit('input', [ '-' + this.id ])
		},
	},
}
</script>

<style scoped>
.SortArrows { font-size: 10px; display: inline-block; user-select: none; color: #aaa; margin-top: -3px; }
.mdi:hover { color: black; }
.active { color: black; }
.number { margin-left: 3px; }
</style>