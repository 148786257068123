<template>
	<div class="environments" style="height: 100%;">
		<Head>
			<div class="heading" style="padding-left: 25px;">
				<h1>
					Environments
				</h1>
			</div>
		</Head>
		<div class="body" style="display: flex; height: 100%;" v-if="permissions.userIsAdmin()">
			<div class="BaseTable" style="margin: 30px; flex: 1 1 0;">
				<table>
					<tr>
						<th>Environment ID</th>
						<th>Created</th>
						<th>Status</th>
						<th></th>
					</tr>
					<tr v-for="env of environments" :key="env.key">
						<td>
							<div style="display: flex; align-items: center; gap: 12px;">
								<mdi source-branch @click="$router.push('/spaces/' + spaceId + '/environments/' + env.sys.id)" />
								<code>{{ env.sys.id }}</code>
								<mdi content-copy @click="copy(env.sys.id)" />
							</div>
						</td>
						<td>{{ formatDate(env.sys.createdAt) }}</td>
						<td>
							<span class="status" :class="env.sys.status.sys.id">
								{{ env.sys.status.sys.id }}
							</span>
						</td>
						<td style="text-align: right;">
							<ActionButton v-if="env.sys.id != 'master'" @click="deleteEnvironment(env.sys.id)" class="link delete">Delete</ActionButton>
						</td>
					</tr>
				</table>
			</div>
			<Sidebar>
				<div class="section" v-if="environments">
					<h2>Environment Usage</h2>
					<p>You are using {{ environments.length }} out of 100 environments available in this space.</p>
					<ActionButton class="add wide" @click="addEnvironment">Add environment</ActionButton>
				</div>
				<div class="section" v-if="environments && permissions.userIsOrganisationAdmin()">
					<h2>Space Name</h2>
					<p>You may rename the whole space. For renaming environments select one at the left.</p>
					<ActionButton class="add wide" @click="renameSpace">Rename space</ActionButton>
				</div>
				<div class="section" v-if="environments && permissions.userIsOrganisationAdmin()">
					<h2>Delete Space</h2>
					<p>Careful here, this will really get rid of all of it (environments, locales, etc. will all be deleted)!</p>
					<ActionButton class="delete wide" @click="deleteSpace">Delete space & all environments</ActionButton>
				</div>
				<!--
				<div class="section">
					<h2>Documentation</h2>
					<p>
						Environment Aliases
					</p>
					<p>
						An environment alias allows you to access and modify the data of an environment through a different static identifier.
						Read our 
						<a href="https://www.contentful.com/developers/docs/concepts/environment-aliases/?utm_source=webapp&utm_medium=environments-sidebar&utm_campaign=in-app-help" target="_blank">environment alias documentation</a>
						for more information.
					</p>
					<p>
						Environment Aliases
					</p>
					<p>
						Environments allow you to develop and test changes to data in isolation.
						See the 
						<a href="https://www.contentful.com/developers/docs/concepts/domain-model/?utm_source=webapp&utm_medium=environments-sidebar&utm_campaign=in-app-help" target="_blank">Contentful domain model</a>
						for details.
					</p>
				</div>
				-->
			</Sidebar>
			<Dialog ref="create" :width="530" :height="380" v-if="newEnvironment">
				<h1>Add environment</h1>
				<div style="font-size: 14px; padding: 15px 25px; font-weight: normal; position: absolute; top: 56px; bottom: 70px; left: 0; right: 0; overflow-y: auto;">
					<label>
						Name
						<span>(required)</span>
					</label>
					<input v-model="newEnvironment.name" type="text" style="font-size: 14px; border-radius: 6px; border: 1px solid silver; padding: 10px; width: 100%; box-sizing: border-box; margin: 5px 0;" />
					<div style="color: gray; margin-bottom: 20px; margin-top: 10px;" v-if="newEnvironment.name">
						<div style="margin-bottom: 3px;">ID: <span style="font-family: monospace; font-weight: bold;">{{ newEnvironment.name }}</span></div>
						The environment ID represents how it is referred to in the API
						<span style="float: right;">{{ newEnvironment.name.length }} / 40</span>
					</div>
					<label>
						Clone new environment
					</label>
					<select v-model="sourceEnvironment" style="font-size: 14px; border-radius: 6px; border: 1px solid silver; padding: 10px; width: 100%; box-sizing: border-box; margin: 5px 0;">
						<option :value="null">do not clone (empty environment)</option>
						<option v-for="env of environments" :key="env.key" :value="env.sys.id">
							{{ env.name }}
							{{ env.sys.id == environment ? '(current)' : '' }}
						</option>
					</select>
				</div>
				<div class="buttons">
					<ActionButton class="cancel" @click="$refs.create.close()">Cancel</ActionButton>
					<ActionButton class="save" @click="createEnvironment()">Add environment</ActionButton>
				</div>
			</Dialog>
		</div>
	</div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import Head from './Head.vue'
import Dialog from '../components/Dialog.vue'
import { formatDate } from '../utils'
import ActionButton from '../components/ActionButton.vue'

export default {
	name: 'Environments',
	components: { Sidebar, Head, Dialog, ActionButton },
	inject: [ 'spaceId', 'environment', 'base', 'baseEndpoint', 'permissions' ],
	props: {
		space: Object,
	},
	data: () => ({
		environments: null,
		newEnvironment: null,
		sourceEnvironment: null,
	}),
	methods: {
		// TODO: move to app?
		async load() {
			const environments = await this.$httpGet(this.baseEndpoint + '/spaces/' + this.spaceId + '/environments')
			environments.items.sort((a, b) => a.sys.id.localeCompare(b.sys.id))
			this.environments = environments.items
		},
		initNew() {
			this.newEnvironment = {
				name: '',
			}
			this.sourceEnvironment = this.environment
		},
		addEnvironment() {
			this.$refs.create.open()
		},
		async renameSpace() {
			const oldName = this.space.name
			const newName = window.prompt('Enter new name for the space', oldName)
			if (!newName || newName == oldName) return
			try {
				this.space.name = newName
				// TODO: version?
				const r = await this.$httpPut(this.baseEndpoint + '/spaces/' + this.spaceId, this.space)
			}
			catch (e) {
				console.error('renameSpace: Could not rename space: ', e)
				alert('Could not rename space: ' + e)
				this.space.name = oldName
			}
		},
		async deleteSpace() {
			if (!window.confirm(`Do you REALLY want to delete the whole SPACE "${this.spaceId}"?`)) return
			const r = await this.$httpDelete(this.baseEndpoint + '/spaces/' + this.spaceId)
			this.$router.push('/')
		},
		async createEnvironment() {
			const headers = {
				'Content-Type': 'application/vnd.contentful.management.v1+json',
			}
			if (this.sourceEnvironment)
				headers['x-contentful-source-environment'] = this.sourceEnvironment
			this.newEnvironment.name = this.newEnvironment.name
			const r = await this.$httpPost(this.baseEndpoint + '/spaces/' + this.spaceId + '/environments', this.newEnvironment, { headers })
			// TODO: only close if success
			this.$refs.create.close()
			await this.load()
			this.initNew()
		},
		async deleteEnvironment(environment) {
			if (!window.confirm('Do you really want to permanently delete the environment?\n' + environment)) return
			const r = await this.$httpDelete(this.baseEndpoint + '/spaces/' + this.spaceId + '/environments/' + environment)
			if (this.environment == environment)
				this.$router.push('/spaces/' + this.spaceId + '/environments/master/settings/environments')
			else
				await this.load()
		},
		formatDate,
		copy(text) {
			navigator.clipboard.writeText(text).then(null, err => { console.error('copy: Could not copy text: ', err)})
		},
	},
	async mounted() {
		document.title = 'Environments'
		await this.load()
		this.initNew()
	},
}
</script>

<style scoped>
.BaseTable table { width: 100%; }
.BaseTable table th { height: auto; padding: 15px; }
.BaseTable table td { cursor: default; }
.status { display: inline-block; background: #eee; color: #666; padding: 3px 10px; border-radius: 4px; font-weight: 600; text-transform: uppercase; letter-spacing: 1px; font-size: 12px; }
.status.ready { color: rgb(0, 109, 35); background-color: rgb(205, 243, 198); }

label { margin: 10px 0 5px; font-weight: 600; display: block; }
label span { color: gray; font-weight: normal; }
</style>
