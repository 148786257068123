
import Head from './Head.vue'
import { formatDate } from '../utils'
import Dialog from '../components/Dialog.vue'
import Menu from '../components/Menu.vue'
import Info from './Info.vue'
import ActionButton from '../components/ActionButton.vue'

export default {
	name: 'Tags',
	components: { Head, Dialog, Menu, Info, ActionButton },
	inject: [ 'endpoint', 'permissions' ],
	data: () => ({
		tags: null,
		tag: null,
	}),
	computed: {
		prefix() {
			const [prefix, rest] = this.tag.name.split(/[:.#_-]/, 2)
			if (rest === undefined) return undefined
			return prefix
		},
	},
	watch: {
		'tag.name'(n, o) {
			if (this.tag.sys.id && this.nameToId(o) != this.tag.sys.id) return
			this.tag.sys.id = this.nameToId(n)
		},
	},
	methods: {
		// TODO: move to app?
		async load() {
			const tags = await this.$httpGet(this.endpoint + '/tags')
			tags.items.sort((a, b) => a.sys.id.localeCompare(b.sys.id))
			this.tags = tags.items
		},
		initNew() {
			this.tag = {
				name: '',
				sys: {
					id: '',
					// TODO: should actually be default private but we wurrently dont support private tags
					visibility: 'public',
				},
			}
		},
		async renameTag(tag) {
			const name = window.prompt('Rename tag', tag.name)
			if (!name) return
			if (name == tag.name) return
			const r = await this.$httpPut(this.endpoint + '/tags/' + tag.sys.id, { name, sys: tag.sys }, {
				headers: {
					'Content-Type': 'application/vnd.contentful.management.v1+json',
					'x-contentful-version': tag.sys.version,
				}
			})
			this.load()
		},
		async createTag(createAnother = false) {
			const r = await this.$httpPut(this.endpoint + '/tags/' + this.tag.sys.id, this.tag, {
				headers: {
					'Content-Type': 'application/vnd.contentful.management.v1+json',
				}
			})
			if (!createAnother) {
				this.$refs.create.close()
			}
			this.initNew()
			this.load()
		},
		async deleteTag(tag) {
			if (!window.confirm('Do you really want to permanently delete the tag and make is unavailable for all roles?\n' + tag.sys.id)) return
			const r = await this.$httpDelete(this.endpoint + '/tags/' + tag.sys.id, {
				headers: {
					'x-contentful-version': tag.sys.version,
				},
			})
			this.load()
		},
		formatDate,
		nameToId(name) {
			return name.toLowerCase().replace(/[^a-z0-9:#._-]/g, '')
		},
		copy(text) {
			navigator.clipboard.writeText(text).then(null, err => { console.error('copy: Could not copy text: ', err)})
		},
	},
	async mounted() {
		document.title = 'Tags'
		await this.load()
		this.initNew()
	},
}
