
<template>
	<div class="loading" />
</template>

<script>
export default {
	name: 'LoadingSpinner',
}
</script>

<style scoped>
.loading {
	color: white;
	height: 5px;
	border-radius: 5px;
	background: linear-gradient(270deg, #00ff58, #317b4a, #6bcc8c);
	background-size: 600% 600%;
	-webkit-animation: LoadingAnimation 4s ease infinite;
	-moz-animation: LoadingAnimation 4s ease infinite;
	animation: LoadingAnimation 4s ease infinite;
}
@-webkit-keyframes LoadingAnimation { 0%{background-position:0% 50%} 80%{background-position:100% 50%} 100%{background-position:0% 50%} }
@-moz-keyframes LoadingAnimation { 0%{background-position:0% 50%} 80%{background-position:100% 50%} 100%{background-position:0% 50%} }
@keyframes LoadingAnimation { 0%{background-position:0% 50%} 80%{background-position:100% 50%} 100%{background-position:0% 50%} }
</style>