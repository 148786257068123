
import EntryStatus from './EntryStatus.vue'

export default {
	name: 'Entry',
	components: { EntryStatus },
	inject: [ 'endpoint', 'entryLoader', 'eventBus', 'defaultLocale', 'fallbackLocale' ],
	props: {
		locale: String,
		selectable: Boolean,
// TODO: when saving FieldMixin on Example Entry we get invalid prop check 'Array' -> investigate!
		entry: Object,
		allowedContentTypes: Array,
	},
	data: () => ({
		loading: 0,
		model: null,
		hasErrors: false,
	}),
	computed: {
		contentType() {
			if (!this.model) return null
			const contentTypeId = this.model.sys.contentType.sys.id
			return window['typeLookup'][contentTypeId] ?? contentTypeId
		},
		title() {
			const entry = this.model
			const df = this.contentType.displayField ?? 'title'
			const dl = this.defaultLocale
			const fl = this.fallbackLocale
			return entry?.fields?.[df]?.[dl] ?? entry?.fields?.[df]?.[fl] ?? 'Untitled'
		},
	},
	methods: {
		async loadEntry() {
			if (!this.entry?.sys?.id) return

			this.loading++
			this.model = await this.entryLoader.load(this.entry.sys.id)
			this.loading--
			this.hasErrors = false

			// TODO: it may not be enough to do this here.. what about after picking? - i think i did see it working..
			// the entry will validate itself because the container does not have access to the full model
			if (this.allowedContentTypes) {
				if (!this.model) {
					// TODO: not sure if this should be an error. if the entry is just not visible to me, that may be ok..
					//       but do we have this case ever?
					//this.$emit('errors', [`Entry ${this.entry.sys.id} was not found.`])
					return
				}
				const contentTypeId = this.model.sys.contentType.sys.id
				if (!this.allowedContentTypes.includes(contentTypeId)) {
					this.hasErrors = true
					this.$emit('errors', [`Entry ${this.model.sys.id} has unallowed type '${contentTypeId}'. Allowed types are ${this.allowedContentTypes.join(', ')}.`])
				}
			}
		},
	},
	async mounted() {
		if (!this.entry?.sys?.id) return

		if (!this.entry.sys.updatedAt) {
			await this.loadEntry()
		}
		else {
			this.model = this.entry
		}
		this.eventBus.$on('reloadEntry_' + this.entry.sys.id, async () => {
			await this.loadEntry()
		})
		// TODO: do we need to do this? -> test if the handler is still being called after unmount!
		//       eventBus.$off("age-changed");
	},
}
