<template>
	<div class="AssetsField" :class="{ error }">
		<!-- TODO: use the entryList comp instead? -->
		<!-- TODO: draggable for sorting - have a comp for that? -->
		<div class="assets">
			<div v-for="(entryLink, e) of entries" :key="entryLink?.sys?.id ?? e"
				:draggable="def.type == 'Array'"
				@dragstart="dragstart($event, entryLink, e)"
				@dragover="dragover($event, entryLink, e)"
				@dragend="dragend($event, entryLink, e)"
				ondragstart="event.cancelBubble = true; event.target.style.opacity = '0.6';"
				ondragover="event.preventDefault(); event.cancelBubble = true;"
				ondragend="this.style.opacity = '1';"
				ondrop="event.cancelBubble = true;"
			>
				<Asset :asset="entryLink"
					@click="$emit('subeditAsset', entryLink.sys, () => { eventBus.$emit('reloadAsset_' + entryLink.sys.id) }); return false;"
				>
					<template #actions>
						<div style="position: relative;" @click="$event.cancelBubble = true">
							<mdi dots-horizontal v-if="!disabled" @click="$refs['menu' + e][0].open()" style="margin-left: 5px;" />
							<Menu :ref="'menu' + e">
								<ul>
									<li @click="remove(e)">Remove</li>
									<li v-if="e > 0 || e < entries.length - 1" class="separator"></li>
									<!-- TODO: implement these -->
									<li v-if="e > 0">Move to top</li>
									<li v-if="e < entries.length - 1">Move to bottom</li>
								</ul>
							</Menu>
						</div>
					</template>
				</Asset>
			</div>
		</div>
		<div class="add" v-if="def.type == 'Array' || value == null">
			<button @click="menuOpen = !menuOpen" class="openMenu" :disabled="disabled">
				<mdi plus />
				Add content
				<mdi chevron-down />
			</button>
			<!-- TODO: modal -->
			<ul class="menu" v-if="menuOpen">
				<li @click="addExisting()" class="item">Add existing media</li>
				<li @click="addNew()" class="item">Add new media</li>
			</ul>
			<div v-if="adding" ref="modal" class="modal">
				<div class="panel">
					<AssetPicker
						:def="def"
						:filter="filter"
						@input="onPickerInput"
						@cancel="closeAdding"
						@ok="onPickerOk"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'
import Asset from './Asset.vue'
import AssetPicker from './AssetPicker.vue'
import Menu from '../Menu.vue'
import EntryApiMixin from '../EntryApiMixin.js'

// TODO: remove one of the entries
// TODO: out-binding for object mode
// TODO: send dialog to modal
// TODO: should menu go away when window loses focus? (contentful does this)

export default {
	name: 'AssetsField',
	mixins: [ field, EntryApiMixin ],
	components: { Asset, AssetPicker, Menu },
	inject: [ 'endpoint', 'eventBus' ],
	props: {
		value: [ Array, Object ],
	},
	data: () => ({
		model: null,
		menuOpen: false,
		adding: false,
		filter: { contentType: null, search: '' },
		dragged: null,
		draggedIndex: null,
	}),
	computed: {
		entries() {
			if (!this.value) return []
			if (Array.isArray(this.value)) return this.value
			return [ this.value ]
		},
	},
	watch: {
		value(n, o) {
			this.validate()
		},
	},
	methods: {
		validate() {
			// TODO: copy the EVE pattern from ReferencesField here?
			if (this.isArray)
				this.onErrors([
					this.validateRequired(),
					this.validateArrayMin(),
					this.validateArrayMax(),
				])
			else
				this.onErrors([
					this.validateRequired(),
				])
		},
		addExisting() {
			this.menuOpen = false
			this.adding = true
			this.$modal()
		},
		async addNew() {
			this.menuOpen = false
			const asset = await this.$httpPost(this.endpoint + '/assets', this.newAsset(), {})
			const link = { sys: { id: asset.sys.id, linkType: 'Asset', type: 'Link' } }
			if (this.def.type == 'Array') {
				if (!this.model) this.model = [link]
				else this.model.push(link)
			}
			else {
				this.model = link
			}
			this.$emit('subeditAsset', asset.sys, () => { this.eventBus.$emit('reloadAsset_' + link.sys.id) })
		},
		remove(index) {
			if (Array.isArray(this.model))
				this.model.splice(index, 1)
			else
				this.model = null
		},
		onPickerInput(selection) {
			if (this.def.type == 'Array') return
			// TODO: this doesnt work - probably because we cant expect array in model
			// for single-selections we immediately close the picker
			this.onPickerOk(selection)
		},
		onPickerOk(selection) {
			console.log('onPickerOk', selection)
			// TODO: this is not really our own concern - move this to the emit in the picker?
			const links = []
			for (const sel of selection) {
				sel.selected = undefined
				sel.match = undefined
				const link = { sys: { id: sel.sys.id, linkType: 'Asset', type: 'Link' } }
				links.push(link)
			}
			if (this.def.type == 'Array') {
				if (!this.model) this.model = links
				else this.model.push(...links)
			}
			else {
				this.model = links[0]
			}
			this.closeAdding()
		},
		closeAdding() {
			// for some reason we have to unmodal the dialog in this case, otherwise it stays as a zombie
			this.$unmodal()
			this.adding = false
		},
		// TODO: we have this in 3 comps -> move to 'dragging' mixedin?
		//       but in 1 we manipulate this.value, the other this.entries..
		dragstart(event, item, i) {
			this.dragged = item;
			this.draggedIndex = i;
		},
		dragover(event, item, i) {
			if (!this.dragged) return
			if (i == this.draggedIndex) return
			// remove dragged item at its position
			this.model.splice(this.draggedIndex, 1)
			// insert the dragged item 
			this.model.splice(i, 0, this.dragged)
			this.draggedIndex = i
		},
		dragend(event, item, i) {
			this.dragged = null
		},
	},
}
</script>

<style scoped>
.AssetsField { display: flex; flex-direction: column; gap: 15px; }
.assets { display: flex; flex-wrap: wrap; gap: 15px; }
.openMenu { display: flex; gap: 5px; white-space: nowrap; margin: 0;
border: 1px solid rgb(207, 217, 224);
box-shadow: rgb(25 37 50 / 8%) 0px 1px 0px;
border-radius: 6px;
cursor: pointer;
font-family: var(--font-stack-primary);
overflow: hidden;
transition: background 0.1s ease-in-out 0s, opacity 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
color: rgb(17, 27, 43);
background-color: rgb(255, 255, 255);
font-size: 0.875rem;
line-height: 130%;
padding: 7px 7px;
min-height: 32px;
font-weight: bold;
}
.openMenu:hover { background-color: rgb(247, 249, 250); color: rgb(17, 27, 43); }
.openMenu:active { background-color: #ddd; }
.menu { border: 1px solid #eee; border-radius: 6px; box-shadow: 0 4px 10px rgb(25 37 50 / 8%); list-style-type: none; padding: 0; display: inline-block; text-align: left; font-size: 14px; overflow: hidden; }
.menu .item { padding: 15px 18px; border-bottom: 1px solid #ddd; cursor: pointer; }
.menu .item:hover { background-color: #f6f8f9; }
.menu .item.sub { padding: 10px 18px; border-bottom: none; }
.menu .search { padding: 5px; }
.menu .search input { padding: 12px; border-radius: 6px; width: 210px; font-size: 14px; border: 1px solid rgb(207, 217, 224); outline: 0; }
.menu .search input:focus { border-color: rgb(0, 89, 200); box-shadow: rgb(152 203 255) 0px 0px 0px 3px; }
.menu .heading { padding: 5px 18px; font-family: var(--font-stack-primary); font-weight: 600; text-transform: uppercase; letter-spacing: 0.1rem; font-size: 12px; line-height: 20px; }
</style>