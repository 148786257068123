
import ActionButton from './ActionButton.vue'
import Dialog from './Dialog.vue'

// TODO: when only a secondary locale is selected, this ic currently not shown on the ui..
//       the locale name should always be added if its not the defaultlocale

export default {
	name: 'TranslationSidebar',
	components: { Dialog, ActionButton },
	inject: [ 'base', 'endpoint', 'defaultLocale' ],
	props: {
		value: Array,
	},
	data: () => ({
		model: [],
		locales: null,
		all: false,
	}),
	computed: {
		defaultLocale() {
			if (!this.locales) return undefined
			return this.locales?.filter(locale => locale.default)[0]
		},
		mode() {
			return this.value.length > 1 ? 'multi' : 'single'
		},
	},
	watch: {
		value(n) {
			this.model = n
		},
		model(n) {
			if (!n?.length) n = [ this.defaultLocale ]
			if (!this.locales) return
			// fix the sort order to be equal to the this.locales order
			const orderedN = this.locales.filter(l => n.includes(l.code)).map(l => l.code)
			if (JSON.stringify(n) != JSON.stringify(orderedN)) n = orderedN
			this.$emit('input', n)
			localStorage.setItem('selectedLocaleCodes', JSON.stringify(n))
		},
	},
	methods: {
		// TODO: move to app?
		async load() {
			const locales = await this.$httpGet(this.endpoint + '/locales')
			this.locales = locales.items
		},
	},
	async mounted() {
		await this.load()

		// TODO: restoring here is probably bad, as this only happens when the sidebar is shown..
		// make sure that all stored locales are available on the space
		const value = JSON.parse(localStorage.getItem('selectedLocaleCodes')) || [ this.defaultLocale ]
		const model = []
		for (const lc of value) {
			if (!this.locales.find(l => l.code == lc)) continue
			model.push(lc)
		}
		if (model.length == 0) model.push(this.defaultLocale)
		this.model = model
	},
}
