
import EntryApiMixin from '../EntryApiMixin.js'
import { formatDate, formatDatePoint, userName } from '../../utils'
import Menu from '../Menu.vue'
import TaskForm from './TaskForm.vue'

export default {
	name: 'Task',
	components: { Menu, TaskForm },
	mixins: [ EntryApiMixin ],
	props: {
		value: Object,
		entryId: String,
	},
	data: () => ({
		editingModel: null,
	}),
	methods: {
		async del() {
			await this.$httpDelete(this.endpoint + '/entries/' + this.entryId + '/tasks/' + this.value.sys.id)
			this.$emit('delete', this.value.sys.id)
		},
		async check() {
			this.value.status = 'resolved'
			await this.$httpPut(this.endpoint + '/entries/' + this.entryId + '/tasks/' + this.value.sys.id, this.value)
			this.$emit('update', this.value.sys.id)
		},
		edit() {
			this.editingModel = { ...this.value, assignedTo: this.value.assignedTo.sys.id }
		},
		isPast(date) {
			return date < new Date().toISOString()
		},
		formatDate,
		formatDatePoint,
		userName,
	},
}
