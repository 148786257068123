
import DataDictionaryValidation from '../components/DataDictionaryValidation.vue'
export default {
	name: 'DataDictionary',
	components: { DataDictionaryValidation },
	inject: [ 'endpoint' ],
	props: {
		filter: Object,
	},
	data: () => ({
		interfaces: {},
		history: [],
		searchTimeout: null,
		searchResults: [],
	}),
	computed: {
		types() {
			return Object.values(window['typeLookup'])
				.sort((a: { name: string }, b: { name: string }) => a.name.localeCompare(b.name))
		},
		historyPrevious() {
			if (this.history.length < 1) return
			return this.history[this.history.length - 1]
		},
	},
	watch: {
		'filter.search'() {
			this.search()
		},
	},
	methods: {
		show(typ) {
			if (!typ) return
			this.$refs['type-' + typ.sys.id][0].scrollIntoView({ behavior: 'smooth', block: 'start' })
		},
		search() {
			clearTimeout(this.searchTimeout)
			this.searchTimeout = setTimeout(() => {
				const search = this.filter.search.toLowerCase()
				const matches = new Set()
				// exact match
				for (const typ of this.types) {
					if (typ.name.toLowerCase() == search || typ.sys.id.toLowerCase() == search)
						matches.add(typ)
				}
				// sub match
				for (const typ of this.types) {
					if (typ.name.toLowerCase().includes(search) || typ.sys.id.toLowerCase().includes(search))
						matches.add(typ)
				}
				this.searchResults = Array.from(matches)
				this.show(this.searchResults[0])
			}, 200)
		},
		async loadInterface(contentTypeId) {
			try {
				const r = await this.$httpGet(this.endpoint + '/content_types/' + contentTypeId + '/editor_interface') ?? { controls: [], sys: { id: this.id } }
				r.controlsById = {}
				for (const control of r.controls) {
					r.controlsById[control.fieldId] = control
				}
				this.interfaces[contentTypeId] = r
				console.log(r)
			}
			catch {
			}
		},
		async loadEditorInterfaces() {
			let i = 0
			for (const typ of this.types) {
				if (i++ >= 3) break
				await this.loadInterface(typ.sys.id)
			}
			this.$forceUpdate()
		},
		navigate(typ, field, key, link) {
			this.history.push({ typ, field, key, link })
		},
		historyPop() {
			setTimeout(() => {
				this.history.pop()
			}, 10)
		},
	},
	async mounted() {
		await this.loadEditorInterfaces()
	},
}
