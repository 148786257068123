<template>
	<div class="EntryConflicts">
		<div class="heading">
			<div style="flex: 1;">
				MY CHANGES
			</div>
			<div style="flex: 1;">
				CHANGES BY {{ userName(value.serverModel?.sys?.updatedBy?.sys?.id) ?? 'THEM' }}
			</div>
		</div>
		<div v-for="conflict, c of value.conflicts" :key="c">
			<FieldCompare
				:entry="value.model"
				:valueA="conflict.valueA"
				:valueB="conflict.valueB"
				:control="conflict.control"
				:field="conflict.field"
				:locale="conflict.locale"
				:type="conflict.type"
				v-model="conflict.value"
			/>
		</div>
	</div>
</template>

<script>
import FieldCompare from './fields/FieldCompare.vue'
import { userName } from '../utils'

export default {
	components: { FieldCompare },
	name: 'EntryConflicts',
	props: {
		value: Object,
	},
	methods: {
		userName,
	},
}
</script>

<style scoped>
.heading { display: flex; gap: 10px; font-size: larger; font-weight: bold; background: #f6f6f6cc; z-index: 1; backdrop-filter: blur(5px); border-bottom: 1px solid #ddd; position: sticky; top: 0px; }
.heading > * { padding: 10px; }
</style>