
import Vue from 'vue'
import BulkLoader from './components/BulkLoader'

import HttpUtil from './plugins/HttpUtil.js'
import Root from './Root.vue'

Vue.use(HttpUtil)

export default {
	name: 'ContentHub',
	components: { Root },
	computed: {
		key() {
			return (this.$route.params.spaceId ?? 'NOSPACE') + '.' + (this.$route.params.environment ?? 'NOENV')
		},
		inIframe() {
			return window.self !== window.top
		},
		skin() {
			if (localStorage.skin === 'mys') return 'mys'
			return (this as any).inIframe ? 'mys' : 'default'
		},
		isStaging() {
			return window.location.href.includes('.staging.')
		},
	},
	inject: [ 'settings' ],
	provide() {
		return {
			entryLoader: new BulkLoader(this.loadEntries),
			eventBus: new Vue(),
			// this is being guessed based on the url in main.ts
			baseEndpoint: this.settings.baseEndpoint,
			skin: this.skin,
			// TODO: maybe no longer necessary as provided by Vue
			settings: this.settings,
			isAdmin: false,
			localeObject: null,
			locales: null,
			permissions: null,
			tags: null,
			me: null,
		}
	},
	methods: {
		routerBeforeEach(to, from, next) {
			this.$refs.root.routerBeforeEach(to, from, next)
		},
	},
	mounted() {
		const style = 'background-color: rgb(60, 33, 150); padding: 5px 10px; color: white; font-style: italic; border-bottom: 5px solid #6fb32c; font-size: 16px; border-radius: 5px;'
		console.log('%cContent-Hub', style)
	},
}
