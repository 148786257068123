type Vis = {
	col: string
	vis: string
}[]

export function visFromParam(param: string): Vis {
	if (!param) return []
	return param.split(',').map(s => {
		const [col, vis] = s.split('-')
		if (!vis) return
		return { col, vis }
	})
}

export function visToParam(vis: Vis): string {
	if (!vis) return ''
	return vis.map(v => v.col + '-' + v.vis).join(',')
}
