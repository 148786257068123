<template>
	<div class="RadioField" :class="{ error }">
		<div v-for="val of allowedValues" :key="val" class="option">
			<input type="radio" v-model="model" :name="id" :id="id + '_' + val" :value="val" @focus="onFocus" @blur="onBlur" :disabled="disabled" />
			<label :for="id + '_' + val">{{ val }}</label>
			<span class="clear" v-if="!disabled && val === model" @click="$emit('input', undefined)">
				Clear
			</span>
		</div>
	</div>
</template>

<script>
import { field } from './FieldMixin.js'

export default {
	name: 'RadioField',
	mixins: [ field ],
	props: {
		value: [ Number, String ],
	},
	data: () => ({
		id: 'RG-' + Math.random(),
		model: undefined,
	}),
	computed: {
		allowedValues() {
			return this.def.validations?.[0]?.in ?? []
		},
	},
	watch: {
		value(n) {
			// TODO: should we do the validation at the server? CF seems to do that.
			this.onErrors([
				this.validateRequired(),
				this.def.type == 'Number' ? this.validateMax() : undefined,
				this.def.type == 'Number' ? this.validateMin() : undefined,
				this.validateIn(),
			])
		},
	},
}
</script>

<style scoped>
label { padding-left: 5px; margin-right: 15px; }
.clear { color: rgb(0, 89, 200); padding-left: 15px; cursor: pointer; }
.option { margin-bottom: 13px; }
</style>
