<template>
	<div class="Roles" style="height: 100%;">
		<Head>
			<div class="heading" style="padding-left: 25px;">
				<h1>
					Roles
				</h1>
			</div>
			<div class="actions">
				<button class="create" @click="create">&plus; Create role</button>
			</div>
		</Head>
		<div class="body" style="display: flex; height: 100%;">
			<div class="BaseTable" style="margin: 30px; flex: 1 1 0;">
				<table>
					<tr>
						<th>Name</th>
						<th>Description</th>
						<!--<th>Policies</th>-->
					</tr>
					<tr v-for="role of roles" :key="role.sys.id"
						@click="editRole(role)"
					>
						<td style="vertical-align: top; font-weight: bold;">
							{{ role.name }}
						</td>
						<td>
							{{ role.description }}
						</td>
						<!--<td style="white-space: pre;">
							{{ role.policies }}
						</td>-->
					</tr>
				</table>
			</div>
			<Sidebar>
				<div class="section">
					You may add roles to specify easily manageable permission-sets for your space.<br />
					<br />
					You may assign these roles to users in the <a href="/users">Users</a> section.
				</div>
			</Sidebar>
		</div>
	</div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import Head from './Head.vue'

export default {
	name: 'Roles',
	components: { Sidebar, Head },
	inject: [ 'base', 'baseEndpoint', 'spaceId' ],
	data: () => ({
		roles: null,
	}),
	methods: {
		async load() {
			const r = await this.$httpGet(`${ this.baseEndpoint }/spaces/${ this.spaceId }/roles`)
			this.roles = r.items
		},
		async create() {
			this.$router.push(this.base + '/settings/roles/new')
		},
		editRole(role) {
			if (role.policies == 'user:isAdmin(true);') return alert('The admin role cannot be edited.')
			this.$router.push(this.base + '/settings/roles/' + role.sys.id)
		},
	},
	async mounted() {
		document.title = 'Roles'
		await this.load()
	},
}
</script>

<style scoped>
table { width: 100%; }
table th { height: auto; padding: 15px; }
table td { vertical-align: top; }
button.create { border: 1px solid #ddd; background: transparent; border-radius: 6px; cursor: pointer; font-size: 15px; font-weight: 500; padding: 10px 15px; }
</style>
