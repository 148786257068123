
import { columnFieldsForColumnIds, commonFields, getColumnFields } from './fields/FilterUtil'
import Menu from './Menu.vue'

// ATT: we do not handle the Name and Status columns here.
//      they are shown as first and last columns in the list, but they are statically added by the table view.

// TODO: contentful seems to hide (remove?) columns from the view when not enough space is available
// TODO: for EntryTable: i guess we need specific column rendering for each type in the table..

export default {
	name: 'TableColumnsMenu',
	components: { Menu },
	inject: [ 'endpoint', 'defaultLocale', 'fallbackLocale' ],
	props: {
		filter: Object,
	},
	data: () => ({
		find: '',
	}),
	computed: {
		fields() {
			return getColumnFields()
		},
		filteredFields() {
			return this.fields.filter(field => {
				if (!this.filter) return false
				if (field.contentType == '_all') return true
				if (this.filter.contentType && field.contentType == this.filter.contentType) return true
				return false
			})
		},
		foundFields() {
			return this.filteredFields.filter(field => {
				if (this.find) {
					if ((!field.name || !field.name.toLowerCase().includes(this.find.toLowerCase()))
						&& (!field.id || !field.id.toLowerCase().includes(this.find.toLowerCase()))) return false
				}
				return true
			})
		},
		availableFields() {
			const existingIds = this.filter.columns.map(field => field.id)
			return this.foundFields.filter(field => !existingIds.includes(field.id))
		},
	},
	watch: {
		// remove unallowed fields from value
		'filter.contentType'() {
			if (!this.filter?.contentType) return
			if (!this.filteredFields.length) return
			const allowedIds = this.filteredFields.map(field => field.id)
			this.filter.columns = this.filter.columns.filter(field => allowedIds.includes(field.id))
		},
	},
	methods: {
		niceName(id) {
			id = id.replace(/([A-Z])/g, ' $1')
			id = id.charAt(0).toUpperCase() + id.slice(1)
			return id
		},
		addField(field) {
			this.filter.columns.push(field)
		},
		deleteAt(v, val) {
			this.filter.columns.splice(v, 1)
		},
		dragstart(event, item, i) {
			this.dragged = item
			this.draggedIndex = i
		},
		dragover(event, item, i) {
			if (!this.dragged) return
			if (i == this.draggedIndex) return
			// remove dragged item at its position
			this.filter.columns.splice(this.draggedIndex, 1)
			// insert the dragged item 
			this.filter.columns.splice(i, 0, this.dragged)
			this.draggedIndex = i
		},
		dragend(event, item, i) {
			this.dragged = null
		},
	},
	mounted() {
		if (!this.filter.columns) {
			// we add the default fields on mount
			this.filter.columns = columnFieldsForColumnIds(null)
		}
	},
}
