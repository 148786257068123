<template>
	<div class="locales" style="height: 100%;" v-if="permissions.userIsAdmin()">
		<Head>
			<div class="heading" style="padding-left: 25px;">
				<h1>
					Locales
				</h1>
			</div>
		</Head>
		<div class="body" style="display: flex; height: 100%;">
			<div class="BaseTable" style="margin: 30px; flex: 1 1 0;">
				<table>
					<tr>
						<th>Locale</th>
						<th>Fallback</th>
						<th>Incl. in response</th>
						<th>Editing</th>
						<th>Required fields</th>
					</tr>
					<tr v-for="locale of locales" :key="locale.key" @click="$router.push(base + '/settings/locales/' + locale.sys.id)">
						<td>
							{{ locale.name }} ({{ locale.code }})
							<span v-if="locale.default" class="statusLabel">default</span>
						</td>
						<!-- TODO: name -->
						<td v-if="locale.fallbackCode">({{ locale.fallbackCode }})</td>
						<td v-else>None</td>
						<td>{{ locale.contentDeliveryApi ? 'Enabled' : 'Disabled' }}</td>
						<td>{{ locale.contentManagementApi ? 'Enabled' : 'Disabled' }}</td>
						<td>{{ locale.optional ? 'Can be published empty' : 'Content is required' }}</td>
					</tr>
				</table>
			</div>
			<Sidebar>
				<div class="section" v-if="locales">
					<h2>Usage</h2>
					<p>You are using {{ locales.length }} out of 100 locales available in this environment.</p>
					<ActionButton class="wide neutral" @click="addLocale()">Add Locale</ActionButton>
					<p>You may change the default locale:</p>
					<ActionButton class="wide delete" @click="defaultLocaleChanging = !defaultLocaleChanging">Change Default Locale</ActionButton>
					<div v-if="defaultLocaleChanging" style="display: flex; flex-direction: column; gap: 10px;">
						<Info type="alert">
							Be careful here, this is potentially a very invasive operation!
						</Info>
						<select v-model="newDefaultLocale" class="input">
							<option v-for="locale in newDefaultLocales" :key="locale.key" :value="locale">{{ locale.name }} ({{ locale.code }})</option>
						</select>
						<ActionButton class="wide delete"
							@click="changeDefaultLocale"
							:disabled="!newDefaultLocale"
						>
							Set New Default Locale
						</ActionButton>
					</div>
				</div>
				<!--
				<div class="section">
					<h2>Documentation</h2>
					<p>Locales enable you to publish in multiple languages.</p>
					<p>
						See our
						<a style="text-decoration: none;" href="https://www.contentful.com/developers/docs/concepts/locales/?utm_source=webapp&utm_medium=locales-sidebar&utm_campaign=in-app-help">
							developer documentation
						</a>
						for details.
					</p>
				</div>
				-->
			</Sidebar>
		</div>
	</div>
</template>

<script>
import Sidebar from './Sidebar.vue'
import Head from './Head.vue'
import ActionButton from '../components/ActionButton.vue'
import Info from './Info.vue'

export default {
	name: 'Locales',
	components: { Sidebar, Head, ActionButton, Info },
	inject: [ 'base', 'endpoint', 'permissions' ],
	data: () => ({
		locales: null,
		defaultLocaleChanging: false,
		newDefaultLocale: null,
	}),
	computed: {
		newDefaultLocales() {
			return this.locales.filter(locale => !locale.default)
		},
	},
	methods: {
		async load() {
			const locales = await this.$httpGet(this.endpoint + '/locales')
			this.locales = locales.items
		},
		async addLocale() {
			this.$router.push(this.base + '/settings/locales/new')
		},
		async changeDefaultLocale() {
			const currentDefaultLocales = this.locales.filter(locale => locale.default)

			this.newDefaultLocale.default = true
			await this.$httpPut(this.endpoint + '/locales/' + this.newDefaultLocale.sys.id, this.newDefaultLocale)

			for (const currentDefaultLocale of currentDefaultLocales) {
				currentDefaultLocale.default = false
				await this.$httpPut(this.endpoint + '/locales/' + currentDefaultLocale.sys.id, currentDefaultLocale)
			}
			this.defaultLocaleChanging = false
			await this.load()
		},
	},
	async mounted() {
		document.title = 'Locales'
		await this.load()
	},
}
</script>

<style scoped>
table { width: 100%; }
table th { height: auto; padding: 15px; }
button.add { border: 1px solid var(--primary); border-radius: 6px; cursor: pointer; width: 100%; font-weight: 500; outline: none; transition: background 0.1s ease-in-out 0s, opacity 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s; color: white; background-color: var(--primary); font-size: 0.875rem; padding: 10px 15px; }
</style>
