
import SpinnerIco from '../SpinnerIco.vue'
import { loadAssets } from '../../EntryApi'
import EntryStatus from './EntryStatus.vue'
import BaseTable from '../BaseTable.vue'
import { formatDate } from '../../utils'
import Paginator from '../Paginator.vue'
import MultiTagDialog from '../MultiTagDialog.vue'
import ActionButton from '../ActionButton.vue'
import SortArrows from '../SortArrows.vue'

// TODO: refactor: this has much in common with EntryTable

export default {
	name: 'AssetTable',
	extends: BaseTable,
	components: { SpinnerIco, EntryStatus, Paginator, MultiTagDialog, ActionButton, SortArrows },
	inject: [ 'endpoint', 'defaultLocale' ],
	props: {
		locale: String,
		filter: Object,
		selectable: Boolean,
	},
	data: () => ({
		loading: 1,
		loadingAction: '',
		entries: [],
		timeout: null,
		message: '',
		limit: 40,
		skip: 0,
		total: 0,
		selection: [],
		selectionAll: false,
		loadAssetsPromise: null,
		requestNr: 0,
		debounceTimeout: null,
	}),
	computed: {
		selectedEntries() {
			this.selectionAll
			return this.entries.filter(entry => {
				return !!this.selection.includes(entry.sys.id)
			})
		},
		selectedEntriesPublished() {
			return this.selectedEntries.filter(entry => {
				// TODO: refactor: introduce a util func getStatus - this is also in EntryStatus end in EntryTable
				if (entry.sys.archivedAt) return false
				if (!entry.sys.publishedAt) return false
				if (entry.sys.updatedAt > entry.sys.publishedAt) return false
				return true
			})
		},
		selectedEntriesChanged() {
			return this.selectedEntries.filter(entry => {
				// TODO: refactor: introduce a util func getStatus - this is also in EntryStatus end in EntryTable
				if (entry.sys.archivedAt) return false
				if (!entry.sys.publishedAt) return false
				if (entry.sys.updatedAt > entry.sys.publishedAt) return true
				return false
			})
		},
	},
	watch: {
		filter: {
			deep: true,
			handler() {
				this.filterChanged()
			},
		},
		skip() {
			this.loadAssets()
		},
		selectionAll(n) {
			const r = []
			if (n) {
				for (const entry of this.entries) {
					r.push(entry.sys.id)
				}
			}
			this.selection = r
		},
	},
	methods: {
		filterChanged() {
			if (this.debounceTimeout)
				window.clearTimeout(this.debounceTimeout)

			this.debounceTimeout = window.setTimeout(() => {
				this.skip = 0
				this.loadAssets()
			}, 250)
		},
		// TODO: refactor: same as AssetList -> move to EntryApi
		async loadAssets(append = false) {
			this.loading++
			try {
				const requestNr = ++this.requestNr
				const entries = await loadAssets(this.endpoint, this.filter, this.limit, this.skip)
				// we ensure that the response belongs to the latest request
				if (requestNr != this.requestNr) return
				entries.items = entries.items.filter(e => !!e)
				this.total = entries.total

				if (append)
					this.entries.push(...entries.items)
				else
					this.entries = entries.items

				this.selection = []
			}
			finally {
				this.loading--
				this.loadingAction = null
			}
		},
		clickAsset(entry) {
			if (this.selectable) {
				entry.selected = !entry.selected
				this.$emit('input', this.entries.filter((e) => e.selected))
			}
			else {
				this.$emit('input', [ entry ])
			}
		},
		userName(id: string) {
			const u = window['userLookup']?.[id]
			return u ? (u.firstName + ' ' + u.lastName) : (id)
		},
		formatDate,
		// TODO: there is a lot of code duplication from EntryTable!
		async bulkDelete() {
			// TODO: show dialog:
			//       permanently delete X entries?
			//       Once you delete an entry, it’s gone for good and cannot be retrieved. We suggest archiving if you need to retrieve it later.
			//       cancel, archive instead, permanently delete
			if (!window.confirm(`Permanently delete ${ this.selectedEntries.length } assets?`)) return
			this.loadingAction = 'delete'
			for (const entry of this.selectedEntries) {
				await this.delete(entry)
			}
			this.loadAssets()
		},
		async bulkArchive() {
			// TODO: show dialog?
			if (!window.confirm(`Archive ${ this.selectedEntries.length } assets?`)) return
			this.loadingAction = 'archive'
			for (const entry of this.selectedEntries) {
				await this.archive(entry)
			}
			this.loadAssets()
		},
		async bulkPublish() {
			// TODO: show dialog?
			if (!window.confirm(`Publish ${ this.selectedEntries.length } assets?`)) return
			this.loadingAction = 'publish'
			for (const entry of this.selectedEntries) {
				await this.publish(entry)
			}
			this.loadAssets()
		},
		async bulkUnpublish() {
			// TODO: show dialog?
			if (!window.confirm(`Unpublish ${ this.selectedEntries.length } assets?`)) return
			this.loadingAction = 'unpublish'
			for (const entry of this.selectedEntries) {
				await this.unpublish(entry)
			}
			this.loadAssets()
		},
		// TODO: move this stuff to a mixin - we need it in multiple places (also EntryEditor)
		async publish(entry) {
			this.loading++
			const r = await this.$httpPut(this.endpoint + '/assets/' + entry.sys.id + '/published', null, {
				headers: {
					'X-Contentful-Version': entry.sys.version,
				},
			})
			if (r?.sys?.version) entry.sys.version = r.sys.version
			if (r?.sys?.publishedAt) entry.sys.publishedAt = r.sys.publishedAt
			this.loading--
		},
		async unpublish(entry) {
			this.loading++
			const r = await this.$httpDelete(this.endpoint + '/assets/' + entry.sys.id + '/published', {
				headers: {
					'X-Contentful-Version': entry.sys.version,
				},
			})
			if (r?.sys?.version) entry.sys.version = r.sys.version
			if (r?.sys?.publishedAt) entry.sys.publishedAt = r.sys.publishedAt
			this.loading--
		},
		async archive(entry) {
			this.loading++
			const r = await this.$httpPut(this.endpoint + '/assets/' + entry.sys.id + '/archived', null, {
				headers: {
					// TODO: is this really needed? what for?
					'X-Contentful-Skip-Transformation': true,
				},
			})
			if (r?.sys?.version) entry.sys.version = r.sys.version
			if (r?.sys?.archivedAt) entry.sys.archivedAt = r.sys.archivedAt
			this.loading--
		},
		async delete(entry) {
			this.loading++
			await this.$httpDelete(this.endpoint + '/assets/' + entry.sys.id)
			// TODO: how to purge entry from list?
			this.loading--
		},
		is(entry, mime, ext) {
			const ct = entry?.fields?.file?.[this.defaultLocale]?.contentType
			const url = entry?.fields?.file?.[this.defaultLocale]?.url
			return ct?.startsWith(mime) || new RegExp('\\.(' + ext + ')$', 'i').test(url)
		},
		isImage(entry) {
			return this.is(entry, 'image/', 'jpeg|jpg|gif|png|svg')
		},
		isPdf(entry) {
			return this.is(entry, 'application/pdf', 'pdf')
		},
		isAudio(entry) {
			return this.is(entry, 'audio/', 'mp3')
		},
		isVideo(entry) {
			return this.is(entry, 'video/', 'mp4|webm|ogg')
		},
	},
	mounted() {
		this.loading = 0
		this.loading++
		// we have to do this in a timeout because filterChanged will also only trigger on a timeout
		window.setTimeout(() => { this.loading-- }, 250)

		this.filterChanged(true)
	},
}
